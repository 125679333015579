import React, { useEffect, useState } from 'react';
import { Card, Grid, Typography, Container, Button, Alert, Snackbar } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import GestureIcon from '@mui/icons-material/Gesture';
import PlaceIcon from '@mui/icons-material/Place';
import SelectedLocationTable from './selectlocation/selectedlocationtable';
import SelectLocation from './selectlocation/index';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { BaseUrl } from 'config';
import { v4 as uuidv4 } from 'uuid';
// import UnbilledJobList from './unbilledjoblist';
import { useSelector } from 'react-redux';
import SessionExpired from 'pages/session/sessionExpired';
// import { locationDetails } from 'store/reducers/menu';
// let locationList = [];
const AddJobs = () => {
  const navigate = useNavigate();
  const locationState = useLocation();
  // const dispatch = useDispatch();
  const { user, TOKEN } = useSelector((state) => state.menu);
  const [locationList, setLocationList] = useState([]);
  // const [locationlist, setLocationlist] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [locationType, setLocationType] = useState({
    upload: false,
    draw: false,
    search: false
  });
  const [jobIdKey, setJobIdKey] = useState(locationState?.state?.job_id ? locationState.state.job_id : null);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [updatedLocationData, setUpdatedLocationData] = useState([]);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: '',
    status: ''
  });
  const isUserVerifiedInAddJob = user.is_verified;
  console.log(isUserVerifiedInAddJob);
  const userPlan = user.plan != null  && user.plan; 
  const unprocessedJobName = locationState?.state?.unprocessedJobName ? locationState?.state?.unprocessedJobName : '';
  const handleSessionExpiredClose = (status) => {
    setSessionExpired(status);
  };

  const handleCloseAlert = () => {
    setOpenSnackBar(false);
  };
  const handleSelectLocation = (type, location) => {
    if (type == 'upload') {
      setLocationType({
        upload: true,
        draw: false,
        search: false,
        location
      });
    } else if (type == 'draw') {
      setLocationType({
        upload: false,
        draw: true,
        search: false,
        location
      });
    } else if (type == 'select') {
      setLocationType({
        upload: false,
        draw: false,
        search: true,
        location
      });
    }
  };
  const addLocationIdToFeatures = (data) => {
    const updatedFeatures = data.locations.features.map((feature) => {
      const location_id = uuidv4();
      return {
        ...feature,
        location_id: location_id
      };
    });

    return {
      ...data,
      locations: {
        ...data.locations,
        features: updatedFeatures
      }
    };
  };
  const handleDeleteLocation = (location) => {
    // const locationToDelete = { ...location, query_type: 'delete' };
    storeData({ ...location, query_type: 'delete' });
    // setLocationList((prev) => prev.filter((val) => val.location_id !== location.location_id).concat(locationToDelete));
    // dispatch(locationDetails(locationList.filter((val) => val.id !== location.id)));
    // setLocationList(locationList.filter((val) => val.id !== location.id));k,
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const getLocationList = (location_list) => {
    console.log('location_list', location_list);
    const job_id = uuidv4();
    // Check if the location with the same ID already exists in locationList
    const existingLocation = locationList.find((loc) => loc?.location_id === location_list?.location_id);
    handleSelectLocation(location_list.type);

    if (existingLocation) {
      // console.log('existingLocation==========', existingLocation);
      // console.log('existingLocation==========2', locationList);

      // alert('inside update--');
      // Update the existing location if found
      // storeData({ ...location_list, query_type: 'update' });
      storeData({
        ...location_list,
        query_type: 'update',
        locations: {
          ...location_list.locations,
          features: location_list.locations.features.map((feature) => ({
            ...feature,
            location_id: feature.location_id || existingLocation.locations.features[0].location_id
          }))
        }
      });
      // setLocationList((prev) =>
      //   prev.map((loc) => (loc.location_id === location_list.location_id ? { ...loc, ...location_list, query_type: 'update' } : loc))
      // );
      // dispatch(locationDetails(locationList.map((loc) => (loc.id === locationlist.id ? { ...loc, ...locationlist } : loc))));
      // setLocationList(locationList.map((loc) => (loc.id === locationlist.id ? { ...loc, ...locationlist } : loc)));
    } else {
      // Add the new location if it doesn't exist in locationList
      storeData({ ...location_list, query_type: 'insert', job_id: job_id });
      // setLocationList((prev) => [...prev, { ...location_list, query_type: 'insert' }]);
      // dispatch(locationDetails([...locationList, { id: locationList.length, ...locationlist }]));
      // setLocationList([...locationList, { id: locationList.length, ...locationlist }]);
    }
  };

  const storeData = async (locationData) => {
    let updatedFeatures;

    if (locationData.query_type == 'insert') {
      updatedFeatures = addLocationIdToFeatures(locationData);
    } else {
      updatedFeatures = { ...locationData };
    }

    setUpdatedLocationData(updatedFeatures);
    // const passedRequest= delete? locationData : updatedFeatures
    try {
      setIsLoading(true);
      const response = await axios.post(`${BaseUrl}/prejoblocation`, updatedFeatures, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TOKEN}`
        }
      });
      if (response?.status === 200) {
        setIsLoading(false);
        setOpenSnackBar(true);
        setJobIdKey(updatedFeatures?.job_id);
        get_location_list(updatedFeatures?.job_id);

        //         if(locationData.query_type == "insert"){
        //           console.log("locationData=============in key",locationData)

        //           setJobIdKey(null)
        //         get_location_list(null);
        //         }
        //         else{
        //           setJobIdKey(locationData.job_id)
        // console.log("locationData=============in key",locationData)
        //           get_location_list(locationData.job_id);

        //         } // get the updated location list

        if (response?.data?.message === 'All locations inserted successfully') {
          setSnackBarMessage({
            message: 'Location Added Successfully!',
            status: 'success'
          });
        } else if (response?.data?.message === 'Location updated successfully') {
          setSnackBarMessage({
            message: 'Location Updated Successfully!',
            status: 'success'
          });
        } else if (response?.data?.message === 'Location deleted successfully') {
          setSnackBarMessage({
            message: 'Location Deleted Successfully!',
            status: 'success'
          });
        }
      }
    } catch (error) {
      setIsLoading(false);
      setOpenSnackBar(true);
      // get_location_list(); // get the updated location list
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired please login again to continue',
          status: 'error'
        });
      } else {
        setSnackBarMessage({
          message: 'Something went wrong. Try again later!',
          status: 'error'
        });
      }
    }
  };

  const get_location_list = async (jobIdKey) => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${BaseUrl}/get_prejobrequest`,
        { job_id: jobIdKey },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`
          }
        }
      );
      if (response?.status === 200 && response?.data?.length > 0) {
        setIsLoading(false);
        setLocationList(response?.data);
      } else if (response?.data?.length === 0) {
        setIsLoading(false);
        setLocationList([]);
      }
    } catch (error) {
      setIsLoading(false);
      setOpenSnackBar(true);
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired please login again to continue',
          status: 'error'
        });
      } else {
        setSnackBarMessage({
          message: 'Something went wrong. Try again later!',
          status: 'error'
        });
      }
    }
  };

  useEffect(() => {
    get_location_list(jobIdKey);
  }, [jobIdKey]);
  useEffect(() => {
    console.log(isUserVerifiedInAddJob);
    if (isUserVerifiedInAddJob == false) {
      setOpenSnackBar(true);
      setSnackBarMessage({
        message: 'User is not verified. Please verify user!',
        status: 'error'
      });
      navigate('/unverified_user');
    }
  });
  const handleNextClick = () => {
    // const getDate = () => {
    //   const options = { month: 'short', day: 'numeric', year: 'numeric' };
    //   return new Date().toLocaleDateString('en-US', options);
    // };

    let listArr = [];

    //format location quadinates

    // locationList.map((val) => val.locations.features.filter((feature) => listArr.push(feature)));

    //convert geojson data according to backend requirements
    locationList.forEach((item) => {
      if (item.type === 'draw') {
        const data = item.locations.features.map((feature) => {
          if (feature.geometry.type === 'Polygon') {
            return {
              ...feature,
              geometry: {
                type: feature.geometry.type,
                coordinates: [feature.geometry.coordinates[0].map((point) => [point[1], point[0]])]
              }
            };
          }
        });
        listArr.push(...data);
      } else {
        item.locations.features.filter((feature) => listArr.push(feature));
      }
    });
    const jobGeoJson = {
      // user: user,
      job_name: '',
      // job_id: updatedLocationData.job_id,
      job_id: updatedLocationData.job_id ? updatedLocationData.job_id : locationState.state.job_id,

      prejoblocationids: locationList.map((val) => val.location_id),
      area_sq_mile: 0,
      calculated_cost: 0,
      locations: {
        type: 'FeatureCollection',
        features: listArr
      }
    };

    if (!isUserVerifiedInAddJob) {
      navigate('/unverified_user', { state: { jobGeoJson, locationList } });
    } else {
      navigate('/payment', { state: { jobGeoJson, locationList, unprocessedJobName } });
    }
    setOpenSnackBar(false);
  };
  return (
    <Container fixed>
      <div className="addjobs">
        <Grid container rowSpacing={1}>
          <Grid item md={4} sm={4} xs={12}>
            <Card
              className={`${locationType.upload ? 'selectLocationTypeHighlighted' : 'selectlocationtype'}`}
              onClick={() => handleSelectLocation('upload')}
            >
              <div className="icon">
                <UploadIcon />
                <Typography variant="caption" component="div">
                  Upload File
                </Typography>
              </div>
              <Typography variant="body1" component="div">
                Upload file or use drag and drop method to view carbon footprint of your area.
              </Typography>
            </Card>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <Card
              className={`${locationType.draw ? 'selectLocationTypeHighlighted' : 'selectlocationtype'}`}
              onClick={() => handleSelectLocation('draw')}
            >
              <div className="icon">
                <GestureIcon />
                <Typography variant="caption" component="div">
                  Draw Area
                </Typography>
              </div>
              <Typography variant="body1" component="div">
                Draw shapes like polygon, square, circle to view carbon footprint of your area.
              </Typography>
            </Card>
          </Grid>
          <Grid item md={4} sm={4} xs={12}>
            <Card
              className={`${locationType.search ? 'selectLocationTypeHighlighted' : 'selectlocationtype'}`}
              onClick={() => handleSelectLocation('select')}
            >
              <div className="icon">
                <PlaceIcon />
                <Typography variant="caption" component="div">
                  Select Location
                </Typography>
              </div>
              <Typography variant="body1" component="div">
                Search locations to find out the carbon footprint of your area.
              </Typography>
            </Card>
          </Grid>
        </Grid>
        <Card className="SelectLocation">
          <Grid item xs={12}>
            <SelectLocation locationType={locationType} getLocationList={getLocationList} />
          </Grid>
          <Grid item xs={12}>
            <SelectedLocationTable
              locationlist={locationList}
              handleSelectLocation={handleSelectLocation}
              locationType={locationType}
              handleDeleteLocation={handleDeleteLocation}
              isLoading={isLoading}
            />
          </Grid>
        </Card>
        <Grid sx={{ mt: 2 }}>
          <Grid item xs={12}>
            {locationList?.length > 0 ? (
              <Button
                endIcon={<ArrowForwardIcon />}
                sx={{ float: 'right' }}
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleNextClick}
              >
                Next
              </Button>
            ) : (
              ''
            )}

            <Button
              startIcon={<ArrowBackIcon />}
              sx={{ mr: 2, float: 'right' }}
              variant="contained"
              color="primary"
              onClick={handleBackClick}
            >
              Back
            </Button>
          </Grid>
        </Grid>
        {/* <UnbilledJobList /> */}
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{marginTop:50}}
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>
      {sessionExpired ? <SessionExpired handleSessionExpiredClose={handleSessionExpiredClose} /> : ''}
    </Container>
  );
};

export default AddJobs;
