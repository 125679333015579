import React, { useState, useEffect } from 'react';
import { Grid, Typography, Container, Button, Snackbar, Alert, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import SessionExpired from 'pages/session/sessionExpired';
import { BaseUrl } from 'config';
import enterprisePic from './../../image/enterprise.png';
import ppuPic from './../../image/pay_per_click.png';
import { color } from 'openlayers';
import { userDetails } from 'store/reducers/menu';
import CircularProgress from '@mui/material/CircularProgress';
const AccountType = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { TOKEN, user } = useSelector((state) => state.menu);
  const [loading, setLoading] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: 'Account type selected successfully!',
    status: 'success'
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Check if user has already selected an account type
    const hasSelectedAccountType = localStorage.getItem('hasSelectedAccountType');
    if (hasSelectedAccountType) {
      navigate('/user/dashboard'); // Redirect if already selected
    }
  }, [navigate]);

  const getLatestUserInfoAfterSelectType = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/users/me`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TOKEN}`
        }
      });
      console.log('response-----', response);
      if (response?.data?.id && response?.status === 200) {
        dispatch(userDetails(response?.data));
      }
    } catch (error) {
      return false;
    }
  };
  const fetchUserDetails = async (token) => {
    try {
      const response = await axios.get(`${BaseUrl}/users/me`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      if (response?.data?.id && response?.status === 200) {
        dispatch(userDetails(response?.data));
        return response?.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const updateUserAccountType = async (accountType) => {
    setIsLoading(true);

    console.log('in api update');
    console.log(accountType);
    try {
      console.log('in try update');

      const userPlanUpdateData = {
        plan: accountType
      };

      const userPlanUpdateResponse = await axios.post(`${BaseUrl}/userplan_update`, userPlanUpdateData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TOKEN}`
        }
      });

      if (userPlanUpdateResponse?.status === 200) {
        fetchUserDetails(TOKEN);
        setIsLoading(false);
        setOpenSnackBar(true);
        if (accountType == 'PPU') {
          setSnackBarMessage({
            message: 'Your request for change account sent successfully. You will soon get a confirmation mail.',
            status: 'success'
          });
        } else {
          setSnackBarMessage({
            message: 'Your request for change account sent successfully. You will soon get a confirmation mail. Till then you are a PPU.',
            status: 'success'
          });
        }
        setTimeout(() => {
          navigate('/user/dashboard');
        }, 2500);
      } else {
        alert('in ry else');
        return false;
      }
    } catch (error) {
      setIsLoading(false);
      setOpenSnackBar(true);
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired please login again to continue',
          status: 'error'
        });
      } else {
        setSnackBarMessage({
          message: 'Something went wrong. Try again later! in elsecatch',
          status: 'error'
        });
      }
      return false;
    }
  };

  const handlePersonalClick = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BaseUrl}/userplan_update`,
        { user_id: user.id, plan: 'PPU' },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`
          }
        }
      );
      if (response.status === 200) {
        // Set flag indicating that user has selected an account type
        localStorage.setItem('hasSelectedAccountType', true);
        localStorage.setItem('userPlan', 'PPU');
        await getLatestUserInfoAfterSelectType();
        navigate('/user/dashboard');
      }
    } catch (error) {
      setOpenSnackBar(true);
      setSnackBarMessage({
        message: 'Something went wrong. Try again later',
        status: 'error'
      });
    }
    setLoading(false);
  };

  const handleEnterpriseClick = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${BaseUrl}/userplan_update`,
        { user_id: user?.id, plan: 'ENT' },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`
          }
        }
      );
      if (response.status === 200) {
        // Set flag indicating that user has selected an account type
        localStorage.setItem('hasSelectedAccountType', true);
        await getLatestUserInfoAfterSelectType();
        navigate('/companyProfile');
      }
    } catch (error) {
      setOpenSnackBar(true);
      setSnackBarMessage({
        message: 'Something went wrong. Try again later',
        status: 'error'
      });
    }
    setLoading(false);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  return (
    <>
      <Container maxWidth="false" style={{ textAlign: 'center', paddingTop: '0px' }}>
        <Typography component="h1" variant="h5" className="heading">
          Account Type
        </Typography>
        <Typography variant="subtitle1" gutterBottom style={{ marginTop: '20px', marginBottom: '20px', color: '#4CAF50' }}>
          Click on box for enterprises or Pay per use
        </Typography>

        <Grid container spacing={4}>
          <Grid item md={6} sm={12}>
            <Box className="boundryBox" style={{ padding: 0, textAlign: 'left' }}>
              <Grid container>
                <Grid item xs={8}>
                  <div style={{ padding: '20px 30px' }}>
                    <Typography variant="h5" gutterBottom style={{ fontSize: '1.2rem', color: 'rgb(45,122,116)', marginBottom: '10px' }}>
                      Enterprises
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{ margin: '10px 0', textAlign: 'left', color: '#a09e9e', fontSize: '0.9em', fontWeight: '500' }}
                    >
                      The Enterprise (ENT) plan caters to large organizations with advanced needs, offering comprehensive solutions,
                      scalability, and dedicated support for efficient operations.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => updateUserAccountType('ENT')}
                      disabled={loading}
                      size="small"
                      style={{ fontSize: '0.8em' }}
                    >
                      {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Click here'}
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <img src={enterprisePic} alt="Enterprise" style={{ maxWidth: '100%', height: 'auto' }} />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item md={6} sm={12}>
            <Box className="boundryBox" style={{ padding: 0, textAlign: 'left' }}>
              <Grid container>
                <Grid item xs={8}>
                  <div style={{ padding: '20px 30px' }}>
                    <Typography variant="h5" gutterBottom style={{ fontSize: '1.2rem', color: 'rgb(45,122,116)', marginBottom: '10px' }}>
                      Pay Per Use
                    </Typography>
                    <Typography
                      variant="body2"
                      gutterBottom
                      style={{ margin: '10px 0', textAlign: 'left', color: '#a09e9e', fontSize: '0.9em', fontWeight: '500' }}
                    >
                      The PPU plan offers flexible, cost-effective solutions for individuals or small teams. You pay only for the services
                      you use, avoiding unnecessary expenses.
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => updateUserAccountType('PPU')}
                      disabled={loading}
                      size="small"
                      style={{ fontSize: '0.8em' }}
                    >
                      {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Click here'}
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <img src={ppuPic} alt="Enterprise" style={{ maxWidth: '100%', height: 'auto' }} />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ marginTop: 50 }}
        open={openSnackBar}
        autoHideDuration={10000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default AccountType;
