import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// material-ui
import { Box, List, Typography } from '@mui/material';

// project import
import NavItem from './NavItem';
import Profile from '../../../../MainLayout/Header/HeaderContent/Profile';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  const navItems = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      case 'collapse':
        return (
          <Typography key={menuItem.id} variant="caption" color="error" sx={{ p: 2.5 }}>
            collapse - only available in paid version
          </Typography>
        );
      case 'item':
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography key={menuItem.id} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  return (
    <Box>
     
      <List
        subheader={
          item.title &&
          drawerOpen && (
            <Box sx={{ pl: 3, mb: 1.5 }}>
              <Typography variant="subtitle2" color="textSecondary">
                {item.title}
              </Typography>
              {/* only available in paid version */}
            </Box>
          )
        }
        sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
      >
        {navItems}
      </List>
      <Box sx={{ ml: 4 }}>
        {/* <Profile /> Render Profile component just below the List */}
      </Box>
    </Box>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object,
};

export default NavGroup;
