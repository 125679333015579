import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
const SessionExpired = ({ handleSessionExpiredClose }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    handleSessionExpiredClose(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm" className="sessionExpired">
        <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography variant="h6">Session Expired</Typography>
          <IconButton edge="end" variant="contained" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography>Your session is expired. Please login again to continue</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
          <Button onClick={() => navigate('/login')} variant="contained">
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SessionExpired;
