// assets
import { DashboardOutlined, LogoutOutlined } from '@ant-design/icons';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import LiveHelpOutlinedIcon from '@mui/icons-material/LiveHelpOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import PersonIcon from '@mui/icons-material/Person';
import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined';
// ==============================|| MENU ITEMS - DASHBOARD ||============================== //
const mainmenu = {
  id: 'group-dashboard',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'home',
      title: 'Home',
      type: 'item',
      url: '/',
      icon: HomeIcon,
      breadcrumbs: false
    },
    {
      id: 'about',
      title: 'About',
      type: 'item',
      url: '/about',
      icon: InfoOutlinedIcon,
      breadcrumbs: false
    },
    {
      id: 'howitworks',
      title: 'How It Works',
      type: 'item',
      url: '/#howitworks',
      icon: SettingsIcon,
      breadcrumbs: false
    },
    {
      id: 'faq',
      title: 'FAQ',
      type: 'item',
      url: '/#faq',
      icon: LiveHelpOutlinedIcon,
      breadcrumbs: false
    },
    {
      id: 'contact',
      title: 'Contact',
      type: 'item',
      url: '/contact',
      icon: PhoneOutlinedIcon,
      breadcrumbs: false
    }
  ]
};

const loginMenu = {
  id: 'authentication',
  title: 'Navigation',
  type: 'group',
  children: [
    {
      id: 'dashboad',
      title: 'Dashboard',
      type: 'item',
      url: '/user/dashboard',
      icon: DashboardOutlined,
      breadcrumbs: false
    },
    {
      id: 'joblists',
      title: 'Job List',
      type: 'item',
      url: '/joblists',
      icon: WorkOutlineOutlinedIcon,
      breadcrumbs: false
    },
    {
      id: 'payment_history',
      title: 'Payment History',
      type: 'item',
      url: '/payment_history',
      icon: PaymentOutlinedIcon,
      breadcrumbs: false
    },
    {
      id: 'contact',
      title: 'Contact',
      type: 'item',
      url: '/contact',
      icon: ConnectWithoutContactOutlinedIcon,
      breadcrumbs: false
    },
    {
      id: 'logout',
      title: 'Logout',
      type: 'item',
      url: '/login',
      icon: LogoutOutlined,
      breadcrumbs: false
    }
  ]
};

export { loginMenu };
export default mainmenu;
