import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Container,
  TableContainer,
  Table,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
  Button
} from '@mui/material';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import { useSelector } from 'react-redux';

import { useLocation, useNavigate } from 'react-router-dom';

let sortedData = [];

const PaymentDetail = () => {
  const { paymentHistoryInfo, user } = useSelector((state) => state.menu);
  const location = useLocation();
  const orderRes = location?.state?.data;
  const navigate = useNavigate();
  const getDate = (timestampString) => {
    const timestamp = new Date(timestampString);
    const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = timestamp.toLocaleDateString('en-US', dateOptions);
    return formattedDate;
  };
  let gostatus = false;
console.log(orderRes)
  const handlePdfDownload = () => {
    const element = document.getElementById('pdf-container');
    const buttons = document.querySelectorAll('.downloadpdf ');

    // Hide buttons before capturing the PDF
    buttons.forEach((button) => (button.style.display = 'none'));

    const pageWidth = 792; // A4 page width in pixels
    const margin = 40; // Margin for left and right sides of each page

    const pdf = new jsPDF({
      orientation: 'landscape', // Set orientation to landscape
      unit: 'px',
      format: 'a4'
    });

    const renderPdfPage = () => {
      html2canvas(element, {
        windowWidth: pageWidth - margin
      }).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = canvas.width;
        const imgHeight = canvas.height;
        const ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);
        const centeredX = (pageWidth - imgWidth * ratio) / 2;
        const centeredY = (pageHeight - imgHeight * ratio) / 2;
        pdf.addImage(imgData, 'PNG', 0, 0);
        pdf.save('payment_detail.pdf');
      });

      // Restore buttons after capturing the PDF
      buttons.forEach((button) => (button.style.display = 'inline-block'));
    };

    renderPdfPage(); // Render the entire content into a single PDF page
  };

  const handleNextClick = () => {
    gostatus = true;
    // setGoFurtherStatus(true);
    navigate('/joblists', { replace: true });
  };
  return (
    <Container id="pdf-container">
      <Typography variant="h2" className="heading" style={{ margin: '15px' }}>
        <center>Payment Detail</center>
      </Typography>
      <Typography variant="h3" color="green" className="successful">
        Payment Successfully Completed
      </Typography>
      <Box className="boundaryBox recipt">
        <Grid container spacing={2}>
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <Typography variant="body1">
              <strong>Name</strong>
              {`${orderRes?.orderApiRes?.payer?.name?.given_name} ${orderRes?.orderApiRes?.payer?.name?.surname}`}
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <Typography variant="body1">
              <strong>User ID</strong>
              {orderRes?.orderApiRes?.payer?.payer_id}{' '}
            </Typography>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <Typography variant="body1">
              <strong>Method</strong>
Paypal            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={5} md={5} sm={5} xs={12}>
            <Typography variant="body1">
              <strong>Job Id</strong>
              {orderRes?.job_id}
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={12}>
            <Typography variant="body1">
              <strong>Status</strong>
              {orderRes?.orderApiRes?.status}{' '}
            </Typography>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <Typography variant="body1">
              <strong>Date</strong>
              {getDate(orderRes?.orderApiRes?.update_time)}{' '}
            </Typography>
          </Grid>
          <TableContainer className="newtable">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Merchant ID</TableCell>
                  <TableCell>Authorization Code</TableCell>
                  <TableCell>Amount (in $)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{orderRes?.orderApiRes?.purchase_units[0]?.payee?.merchant_id}</TableCell>
                  <TableCell>{orderRes?.orderID}</TableCell>
                  <TableCell> {orderRes?.orderApiRes?.purchase_units[0]?.amount?.value}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          <Box className="btnAlign">
            <Button className="downloadpdf" onClick={handlePdfDownload}>
              DOWNLOAD PDF
            </Button>
            <Button className="downloadpdf" onClick={handleNextClick}>
              NEXT
            </Button>
          </Box>
        </Grid>
      </Box>
    </Container>
  );
};

export default PaymentDetail;
