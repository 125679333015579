import React from 'react';
import { Grid, Box, Typography, CircularProgress, Container } from '@mui/material';
import CarIcon from '../../image/carIcon.png';
import industryIcon from '../../image/industryIcon.png';
import HouseHoldIcon from '../../image/householdIcon.png';
import VegitationIcon from '../../image/vegitationIcon.png';
import Water from '../../image/water.png';
import Solar from '../../image/solar.png';

const CarbonEmissionStats = ({ isLoading, dataFetchedStatus, detectedData }) => {
  console.log('detectedData', detectedData);
  const totalco2 = detectedData
    ? detectedData?.calculated_emissions.total_emissions_household +
      detectedData?.calculated_emissions.co2_emission_normal_vehicle +
      detectedData?.calculated_emissions.industry_coal_co2_emission +
      detectedData?.calculated_emissions.emission_from_vegeatation_rainforest
    : 0;

  console.log(totalco2, 'totalCo2');

  return (
    <>
      <Typography variant="h3" gutterBottom style={{ textAlign: 'center' }}>
        CO<sub>2</sub> Emission in Metric Ton
      </Typography>
      <Grid container spacing={2}>
        <Grid item lg={2} md={4} sm={6} xs={12}>
          <Box className="boundryBox">
            <div className="stats-container">
              <div className="icon">
                <img src={HouseHoldIcon} alt="" />
              </div>
              <div>
                <p>HOUSEHOLD</p>
                <span>
                  {isLoading ? (
                    <CircularProgress size={20} />
                  ) : dataFetchedStatus ? (
                    detectedData?.calculated_emissions.total_emissions_household?.toFixed(0)
                  ) : (
                    0
                  )}
                </span>
                <p className="metric-ton">metric ton</p>
              </div>
            </div>
          </Box>
        </Grid>
        <Grid item lg={2} md={4} sm={6} xs={12}>
          <Box className="boundryBox">
            <div className="stats-container">
              <div className="icon">
                <img src={CarIcon} alt="" />
              </div>
              <div>
                <p>TRANSPORT</p>

                <span>
                  {isLoading ? (
                    <CircularProgress size={20} />
                  ) : dataFetchedStatus ? (
                    detectedData?.calculated_emissions.co2_emission_normal_vehicle?.toFixed(0)
                  ) : (
                    0
                  )}
                </span>
                <p className="metric-ton">metric ton</p>
              </div>
            </div>
          </Box>
        </Grid>
        <Grid item lg={2} md={4} sm={6} xs={12}>
          <Box className="boundryBox">
            <div className="stats-container">
              <div className="icon">
                <img src={VegitationIcon} alt="" />
              </div>
              <div>
                <p>VEGETATION</p>

                <span>
                  {isLoading ? (
                    <CircularProgress size={20} />
                  ) : dataFetchedStatus ? (
                    detectedData?.calculated_emissions.emission_from_vegeatation_rainforest?.toFixed(0)
                  ) : (
                    0
                  )}
                </span>
                <p className="metric-ton">metric ton</p>
              </div>
            </div>
          </Box>
        </Grid>
        <Grid item lg={2} md={4} sm={6} xs={12}>
          <Box className="boundryBox">
            <div className="stats-container">
              <div className="icon">
                <img src={industryIcon} alt="" />
              </div>
              <div>
                <p>INDUSTRIES</p>

                <span>
                  {isLoading ? (
                    <CircularProgress size={20} />
                  ) : dataFetchedStatus ? (
                    detectedData?.calculated_emissions.industry_coal_co2_emission?.toFixed(0)
                  ) : (
                    0
                  )}
                </span>
                <p className="metric-ton">metric ton</p>
              </div>
            </div>
          </Box>
        </Grid>
        <Grid item lg={2} md={4} sm={6} xs={12}>
          <Box className="boundryBox">
            <div className="stats-container">
              <div className="icon">
                <img src={Solar} alt="" />
              </div>
              <div>
                <p>SOLAR PANNEL</p>

                <span>
                  {isLoading ? (
                    <CircularProgress size={20} />
                  ) : dataFetchedStatus ? (
                    detectedData?.objectdetection?.CarbonByType?.SolarPanel?.toFixed(0)
                  ) : (
                    0
                  )}
                </span>
                <p className="metric-ton">metric ton</p>
              </div>
            </div>
          </Box>
        </Grid>
        <Grid item lg={2} md={4} sm={6} xs={12}>
          <Box className="boundryBox">
            <div className="stats-container">
              <div className="icon">
                <img src={Water} alt="" />
              </div>
              <div>
                <p>WATER BODIES</p>

                <span>
                  {isLoading ? (
                    <CircularProgress size={20} />
                  ) : dataFetchedStatus ? (
                    detectedData?.objectdetection?.CarbonByType?.Waterbodies?.toFixed(0)
                  ) : (
                    0
                  )}
                </span>
                <p className="metric-ton">metric ton</p>
              </div>
            </div>
          </Box>
        </Grid>

        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Box className="para" sx={{ bgcolor: '#257874' }}>
            <Typography variant="h4" gutterBottom>
              {isLoading ? <CircularProgress size={20} /> : dataFetchedStatus ? totalco2?.toFixed(0) : 0}
              {dataFetchedStatus && <Typography variant="span" style={{fontSize: 16}}> metric ton</Typography>}
            </Typography>

            <Typography variant="body1" className="totalResult stats-container">
              Overall CO<sub>2</sub> Emission in Metric Ton
            </Typography>
          </Box>
        </Grid>

        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Box className="para" sx={{ bgcolor: '#01A6D0' }}>
            <Typography variant="h4" gutterBottom>
              {isLoading ? <CircularProgress size={20} /> : dataFetchedStatus ? detectedData?.agb?.TotalAGB : 0}
              {dataFetchedStatus && <Typography variant="span" style={{fontSize: 16}}> metric ton</Typography>}
            </Typography>

            <Typography variant="body1" className="totalResult">
              TOTAL ABOVEGROUND BIOMASS(AGB)
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={4} md={4} sm={12} xs={12}>
          <Box className="para" sx={{ bgcolor: '#FF9900' }}>
            <Typography variant="h4" gutterBottom>
              {isLoading ? <CircularProgress size={20} /> : dataFetchedStatus ? detectedData?.ndvi?.MeanNDVI?.toFixed(4) : 0}
              {dataFetchedStatus && <Typography variant="span" style={{fontSize: 16}}></Typography>}
            </Typography>

            <Typography variant="body1" className="totalResult">
              MEAN NORMALIZED DIFFERENCE VEGETATION INDEX (NDVI)
            </Typography>
          </Box>
        </Grid>
        
      </Grid>
    </>
  );
};

export default CarbonEmissionStats;
