import React from 'react';
import { Box, Grid, Link, Button, Container, Typography } from '@mui/material';
import Step3Image from '../../image/step3.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';


const Step3 = () => {
  return (
    <>
      <Box className="step3" sx={{ flexGrow: 1 }} id="unveiling" style={{ height: window.innerHeight, paddingTop: '100px' }}>
        <Container>
          <Grid container>
            <Grid item lg={6} sm={12}>
              {/* <Link href="#harnessingAI"><Button variant="contained" size="large">
        <ArrowBackIcon/> Back 
            
            </Button></Link> */}
              <Box data-aos="slide-right">
                <Typography variant="h2" gutterBottom>Unveiling the Culprits</Typography>
                <Typography variant="body1" gutterBottom>Once the estimation is complete, you will receive a detailed summary:</Typography>
                <Typography variant='body1' gutterBottom>
                  Discover which activities contribute the most to carbon emissions. Understand the magnitude of each contributor. Armed
                  with knowledge, you can take informed action.
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={6} sm={12} sx={{ textAlign: 'center' }}>
              <Box data-aos="zoom-in-up">
                <img src={Step3Image} alt="About Carbon Footprint" className="step3-image steps3" />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Step3;
