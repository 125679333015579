import React, { useState, useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Polygon, FeatureGroup } from 'react-leaflet';
import { Grid, TextField, Button, IconButton, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import PointerIcon from '@mui/icons-material/Room';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
// import AddIcon from '@mui/icons-material/Add';
import { EditControl } from 'react-leaflet-draw';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
let selected_shape_Index = '';

const DrawLocation = ({ getLocationList, locationData }) => {
  const [shapes, setShapes] = useState([]);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [location, setLocation] = useState('');
  const [locationName, setLocationName] = useState('');
  const [loading, setLoading] = useState(false);
  const [centerPoint, setCenterPoint] = useState([]);
  const [error, setError] = useState('');
  const [mapKey, setMapKey] = useState(1);
  const [locationNameError, setLocationNameError] = useState('');
  const [zoomInOut, setZoomInOut] = useState(11);
  const [selectedShapeIndex, setSelectedShapeIndex] = useState(null);
  const featureGroupRef = useRef(null);
  const mapRef = useRef(null);
  const locationDataRef = useRef(locationData);

  const createCircleCoordinates = (center, radius) => {
    const numSegments = 64; // You can adjust this value based on your needs
    const coordinates = [];
    for (let i = 0; i < numSegments; i++) {
      const angle = (i / numSegments) * 2 * Math.PI;
      const x = center?.lat + radius * Math.cos(angle);
      const y = center?.lng + radius * Math.sin(angle);
      coordinates.push([x, y]);
    }
    return coordinates;
  };

  const findCentroid = (coordinates) => {
    if (coordinates && coordinates?.length > 0) {
      const totalPoints = coordinates?.length;
      let centerX = 0;
      let centerY = 0;

      coordinates?.forEach(([lat, lon]) => {
        centerX += lat;
        centerY += lon;
      });

      return [centerX / totalPoints, centerY / totalPoints];
    } else {
      [29.797703, -95.400004];
    }
  };

  const handleDrawCreate = (e) => {
    const { layerType, layer } = e;
    if (layerType && layer) {
      let coordinates;
      let id = L.stamp(layer);

      if (layerType === 'polygon' || layerType === 'rectangle') {
        coordinates = layer.getLatLngs()[0]?.map((latLng) => [latLng.lng, latLng.lat]);
      } else if (layerType === 'circle') {
        const center = layer.getLatLng();
        const radius = layer.getRadius();
        coordinates = createCircleCoordinates(center, radius);
      }

      if (coordinates) {
        layer.options.id = id;
        setShapes((prevShapes) => [...prevShapes, { id, coordinates }]);

        const area = calculateCoordsArea(coordinates);
        console.log('area of drawn shape', area);
        if (area < 1) {
          setOpenSnackBar(true);
          setSnackBarMessage({ message: 'Shape must have an area greater than or equal to 1 square mile.', status: 'error' });
          return;
        }
      }
    }
  };
  const handleDelete = () => {
    if (featureGroupRef.current && selected_shape_Index !== '') {
      let remainingShapes = shapes?.filter((value) => value.id !== selected_shape_Index);
      setShapes(remainingShapes);
      featureGroupRef.current.clearLayers();
      if (remainingShapes && remainingShapes?.length > 0) {
        remainingShapes?.forEach((shape) => {
          const highlightColor = shape.id === selected_shape_Index ? 'red' : '#469100';
          const polygonLayer = L.polygon(
            shape.coordinates.map((coord) => [coord[1], coord[0]]),
            { color: highlightColor }
          );
          if (mapRef.current && featureGroupRef.current) {
            featureGroupRef.current.addLayer(polygonLayer);
          }
        });
      }
      setSelectedShapeIndex(null);
      selected_shape_Index = '';
    }
  };
  const handleDeleteOldWorking = () => {
    if (featureGroupRef.current && selected_shape_Index !== '') {
      let remainingShapes = shapes?.filter((value) => value.id !== selected_shape_Index);
      setShapes(remainingShapes);
      featureGroupRef.current.clearLayers();
      if (remainingShapes && remainingShapes?.length > 0) {
        remainingShapes?.forEach((shape) => {
          const highlightColor = shape.id === selected_shape_Index ? 'red' : '#469100';
          const polygonLayer = L.polygon(shape.coordinates, { color: highlightColor });
          if (mapRef.current && featureGroupRef.current) {
            featureGroupRef.current.addLayer(polygonLayer);
          }
        });
      }
      setSelectedShapeIndex(null); // Reset selectedShapeIndex after deletion
      selected_shape_Index = '';
    }
  };

  // const isEqual = (array1, array2) => {
  //   return JSON.stringify(array1) === JSON.stringify(array2);
  // };

  // const handleEditOLd = (e) => {
  //   const editedLayers = e.layers.getLayers();
  //   const editedShapes = editedLayers?.map((layer) => {
  //     const id = layer.options.id;
  //     const coordinates = layer.getLatLngs()[0]?.map((latLng) => [latLng.lat, latLng.lng]);
  //     return { id: id, coordinates };
  //   });

  //   setShapes((prevShapes) => {
  //     const updatedShapes = prevShapes?.map((prevShape) => {
  //       const editedShape = editedShapes?.find((shape) => shape.id === prevShape.id);
  //       return editedShape ? editedShape : prevShape;
  //     });

  //     if (featureGroupRef.current) {
  //       featureGroupRef.current.clearLayers();
  //       updatedShapes?.forEach((shape) => {
  //         if (shape && shape.coordinates && shape.coordinates.length > 0) {
  //           const polygonLayer = L.polygon(shape.coordinates, { color: '#469100', id: shape.id });
  //           featureGroupRef.current.addLayer(polygonLayer);
  //         }
  //       });
  //     }

  //     return updatedShapes;
  //   });
  // };
  // const handleEdit = (e) => {
  //   const editedLayers = e.layers.getLayers();
  //   const editedShapes = editedLayers && editedLayers.length>0 && editedLayers?.map((layer) => {
  //     const id = layer.options.id;
  //     const coordinates = layer?.getLatLngs()[0]?.map((latLng) => [latLng.lat, latLng.lng]);
  //     return { id: id, coordinates };
  //   });

  //   setShapes((prevShapes) => {
  //     console.log("prevShapes ", prevShapes, editedShapes)
  //     const updatedShapes = prevShapes && prevShapes.length>0 && prevShapes?.map((prevShape) => {
  //       const editedShape = editedShapes && editedShapes.length>0 && editedShapes?.find((shape) => shape.id === prevShape.id);
  //       return editedShape ? editedShape : prevShape;
  //     });

  //     if (featureGroupRef.current) {
  //       featureGroupRef.current.clearLayers();
  //       updatedShapes?.forEach((shape) => {
  //         if (shape && shape?.coordinates && shape?.coordinates?.length > 0) {
  //           const polygonLayer = L.polygon(shape.coordinates, { color: '#469100', id: shape.id });
  //           featureGroupRef.current.addLayer(polygonLayer);
  //         }
  //       });
  //     }
  //     // Check if any shape has an area less than 1 square mile
  //     const invalidShapes = updatedShapes?.filter((shape) => {
  //       const area = calculateCoordsArea(shape.coordinates);
  //       return area < 1;
  //     });
  //     // If there are invalid shapes, display error and optionally highlight the invalid shape
  //     if (invalidShapes?.length > 0) {
  //       setOpenSnackBar(true);
  //       setSnackBarMessage({ message: 'All shapes must have an area greater than or equal to 1 square mile.', status: 'error' });

  //       return;
  //     }

  //     return updatedShapes;
  //   });
  // };
  const handleEdit = (e) => {
    const editedLayers = e.layers.getLayers();
    const editedShapes = editedLayers.map((layer) => {
      const id = layer.options.id;
      const coordinates = layer.getLatLngs()[0]?.map((latLng) => [latLng.lng, latLng.lat]);
      return { id, coordinates };
    });

    setShapes((prevShapes) => {
      console.log('prevShapes ', prevShapes, editedShapes);
      let updatedShapes = prevShapes.map((prevShape) => {
        const editedShape = editedShapes.find((shape) => shape.id === prevShape.id);
        return editedShape ? editedShape : prevShape;
      });

      // Check if any shape has an area less than 1 square mile
      const invalidShapes = updatedShapes.filter((shape) => {
        const area = calculateCoordsArea(shape.coordinates);
        console.log('Area of drawn shape:', area);

        return area < 1;
      });

      if (featureGroupRef.current) {
        featureGroupRef.current.clearLayers();
        updatedShapes.forEach((shape) => {
          if (shape && shape.coordinates && shape.coordinates.length > 0) {
            const polygonLayer = L.polygon(
              shape.coordinates.map((coord) => [coord[1], coord[0]]),
              { color: '#469100', id: shape.id }
            );
            featureGroupRef.current.addLayer(polygonLayer);
          }
        });
      }

      // If there are invalid shapes, display error
      if (invalidShapes.length > 0) {
        setOpenSnackBar(true);
        setSnackBarMessage({ message: 'All shapes must have an area greater than or equal to 1 square mile.', status: 'error' });
      }

      return updatedShapes;
    });
  };

  const createGeoJson = () => {
    // Prepare GeoJSON FeatureCollection
    // const actualShape = shapes;
    const geoJsonFeatures = shapes?.map((shape) => ({
      type: 'Feature',
      id: shape.id,
      properties: {
        LocationName: locationName
      },
      geometry: {
        type: 'Polygon', // or 'Circle' based on your shape type
        coordinates: [shape.coordinates] // For Circle, you might need to adjust this
      }
    }));

    const geoJsonData = {
      type: 'FeatureCollection',
      features: geoJsonFeatures
    };

    return geoJsonData;

    //*************download geojson when user complete drawn process******************* */
    // Convert GeoJSON object to JSON string
    // const jsonString = JSON.stringify(geoJsonData, null, 2);

    // // Create a Blob from the JSON string
    // const blob = new Blob([jsonString], { type: 'application/json' });

    // // Create a download link
    // const link = document.createElement('a');
    // link.href = URL.createObjectURL(blob);
    // link.download = 'drawn_shapes.geojson';

    // // Append the link to the document and trigger the click event
    // document.body.appendChild(link);
    // link.click();

    // // Remove the link from the document
    // document.body.removeChild(link);
  };

  const calculateAreaOld = () => {
    let totalAreaInSquareMeters = 0;

    shapes?.forEach((shape) => {
      if (shape.coordinates && shape?.coordinates?.length > 0) {
        const latLngs = shape?.coordinates?.map(([lat, lon]) => L.latLng(lat, lon));
        const validCoordinates = latLngs.every((latLng) => latLng instanceof L.LatLng);
        if (validCoordinates) {
          const areaInSquareMeters = L.GeometryUtil.geodesicArea(latLngs);
          totalAreaInSquareMeters += areaInSquareMeters;
        } else {
          console.warn('Invalid coordinates for shape:', shape);
        }
      } else {
        console.warn('Empty or invalid coordinates for shape:', shape);
      }
    });
    console.log('asdkfjsdk', totalAreaInSquareMeters * 3.861e-7);
    return totalAreaInSquareMeters * 3.861e-7;
  };
  const calculateCoordsArea = (coordinates) => {
    let totalAreaInSquareMeters = 0;
    console.log('coordinates-----------', coordinates);

    if (coordinates && coordinates.length > 0) {
      const latLngs = coordinates.map(([lon, lat]) => L.latLng(lat, lon));
      const validCoordinates = latLngs.every((latLng) => latLng instanceof L.LatLng);

      if (validCoordinates) {
        const areaInSquareMeters = L.GeometryUtil.geodesicArea(latLngs);
        totalAreaInSquareMeters += areaInSquareMeters;
      } else {
        console.warn('Invalid coordinates:', coordinates);
      }
    } else {
      console.warn('Empty or invalid coordinates:', coordinates);
    }

    // Convert total area to square miles
    const totalAreaInSquareMiles = totalAreaInSquareMeters * 3.861e-7;
    console.log('Total area in square miles:', totalAreaInSquareMiles);

    return totalAreaInSquareMiles;
  };

  const arrangedLocationData = () => {
    //const totalArea =  calculateArea();
    const totalArea = shapes?.reduce((total, shape) => {
      // Calculate area for each shape and add it to the total
      return total + calculateCoordsArea(shape.coordinates);
    }, 0);
    const geolocation = createGeoJson();
    const calculated_cost = 86 * totalArea;
    const getDate = () => {
      const options = { month: 'short', day: 'numeric', year: 'numeric' };
      return new Date().toLocaleDateString('en-US', options);
    };

    function capitalizeEachWord(text) {
      return text?.replace(/\b\w/g, (match) => match.toUpperCase());
    }

    // let location_list = {};

    // if (locationData) {
    //   location_list = {
    //     ...locationData,
    //     calculated_cost: calculated_cost.toFixed(2),
    //     location_area_sq_mile: totalArea.toFixed(2),
    //     requested_timestamp: getDate(),
    //     locations: geolocation
    //   };
    // } else {

    const location_list = {
      ...locationData,
      filename: `${locationName?.replace(/\s/g, '')?.toLowerCase()}.geojson`,
      locationName: capitalizeEachWord(locationName),
      calculated_cost: calculated_cost?.toFixed(2),
      location_area_sq_mile: totalArea?.toFixed(2),
      requested_timestamp: getDate(),
      locations: geolocation,
      type: 'draw'
    };
    // if (locationData) {
    //   location_list.id = locationData.id;
    // setSnackBarMessage('Location updated Successfully!');
    // }
    // else {
    //   setSnackBarMessage('Location Added Successfully!');
    // }

    // console.log('location_list', location_list);
    getLocationList(location_list);
  };

  const handleAdd = () => {
    const location_name = locationName;
    if (!location_name?.trim()) {
      setLocationNameError('Please enter a location Name.');
      return;
    }
    if (shapes?.length === 0) {
      setLocationNameError('Please draw at least one location.');
      return;
    }

    // Clear all shapes from the map
    if (featureGroupRef.current) {
      featureGroupRef.current.clearLayers();
    }

    // Check if any shape has an area less than 1 square mile
    const invalidShapes = shapes?.filter((shape) => {
      const area = calculateCoordsArea(shape.coordinates);
      return area < 1;
    });

    if (invalidShapes?.length > 0) {
      setOpenSnackBar(true);
      setSnackBarMessage({
        message: 'All shapes must have an area greater than or equal to 1 square mile. Please draw again.',
        status: 'error'
      });

      //setLocationNameError('All shapes must have an area greater than or equal to 1 square mile.');
      invalidShapes?.forEach((invalidShape) => {
        setShapes((prevShapes) => prevShapes?.filter((shape) => shape.id !== invalidShape.id));
      });
      return;
    }
    // Clear any existing errors
    setLocationNameError('');

    // Proceed with arranging and saving location data
    arrangedLocationData();

    // Reset states
    setShapes([]);
    setLocation('');
    setLocationName('');
  };

  const handleSearch = async () => {
    try {
      if (!location?.trim()) {
        setError('Please enter a location before searching.');
        return;
      }
      setLoading(true);
      const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${location}&polygon_geojson=1`);
      const data = await response.json();

      if (data && data?.length > 0) {
        const { lon, lat } = data[0];
        setCenterPoint([parseFloat(lon), parseFloat(lat)]);
        // Update center and zoom properties of MapContainer
        mapRef.current.setView([parseFloat(lat), parseFloat(lon)], 15);
        // setMapKey(mapKey + 1);
        setZoomInOut(15);
        setError('');
        setLoading(false);
      } else {
        setLoading(false);
        setError('No results found for the provided location.');
      }
    } catch (error) {
      setLoading(false);
      // setError('Something went wrong try again later!');
      setOpenSnackBar(true);
      setSnackBarMessage({ message: 'Something went wrong try again later!', status: 'error' });
    }
  };
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
    setSnackBarMessage({ message: '', status: '' });
  };
  // const handleClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setOpenSnackBar(false);
  // };

  useEffect(() => {
    if (!locationData) {
      //clear the drawn polygons on map
      if (featureGroupRef.current) {
        featureGroupRef.current.clearLayers();
        setShapes([]);
        setLocationName('');
      }
    }
  }, [!locationData]);

  useEffect(() => {
    // Display initial polygons based on locationData
    locationDataRef.current = locationData;
    // Clear existing layers from FeatureGroup
    if (featureGroupRef.current) {
      featureGroupRef.current.clearLayers();
    }
    setSelectedShapeIndex('');
    setLocationNameError('');

    if (locationData && locationData.locations && locationData.locations.features) {
      setLocationName(locationData.locationName);
      const initialShapes = locationData.locations.features.map((feature) => {
        return {
          id: feature.id,
          coordinates: feature.geometry.coordinates[0].map(([lon, lat]) => [lon, lat])
        };
      });
      setShapes(initialShapes);
      const centerpoint = findCentroid(initialShapes[0].coordinates);
      setCenterPoint(centerpoint);
      // Add existing polygons to FeatureGroup
      if (featureGroupRef.current) {
        locationData?.locations?.features?.forEach((feature) => {
          if (feature.geometry && feature.geometry.type === 'Polygon') {
            const id = feature.id;
            const coordinates = feature.geometry.coordinates[0];
            setMapKey(id);
            // Create a Leaflet polygon layer
            const polygonLayer = L.polygon(
              coordinates.map((coord) => [coord[1], coord[0]]),
              { color: '#469100' }
            );
            polygonLayer.options.id = id;
            featureGroupRef.current.addLayer(polygonLayer);
          }
        });

        // Fit the map to the bounds of the default polygons
        //Redirect map to give polygon coordinates
        const bounds = featureGroupRef.current.getBounds();
        mapRef.current.fitBounds(bounds);
      }
    } else {
      setCenterPoint([29.797703, -95.400004]);
      setZoomInOut(11);
    }
  }, [locationData]);

  return (
    <div className="drawBox">
      <Box className="joblist_heading">
        <Grid container>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <h1 className="heading" style={{fontSize:"35px"}}>Draw Location</h1>
          </Grid>
          <Grid item md={2} sm={0} xs={0}></Grid>

          <Grid item lg={6} md={6} sm={12} xs={12}>
            {!locationData ? (
              <div className="locationSearch">
                <TextField
                  label="Search Location"
                  variant="outlined"
                  className="locationName searchLocation"
                  value={location}
                  fullWidth
                  onChange={(e) => {
                    setLocation(e.target.value);
                    setError('');
                  }}
                  style={{ border: 'none' }}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleSearch();
                    }
                  }}
                  error={Boolean(error)}
                  helperText={error}
                  InputProps={{
                    startAdornment: (
                      <Button disabled className="icon_btn">
                        <PointerIcon />
                      </Button>
                    )
                  }}
                />
                <IconButton color="primary" className="searchbtnicon" onClick={handleSearch} disabled={loading}>
                  {loading ? <CircularProgress size={24} color="inherit" /> : <SearchIcon />}
                </IconButton>
              </div>
            ) : (
              ''
              // <Button variant="contained" style={{ float: 'right' }} className="addbtn searchbtn" fullWidth onClick={handleAdd}>
              //   Update
              // </Button>
            )}
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {centerPoint && centerPoint.length > 0 ? (
            <MapContainer center={centerPoint} ref={mapRef} zoom={zoomInOut} style={{ height: '500px', width: '100%' }}>
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />

              <FeatureGroup ref={featureGroupRef}>
                <EditControl
                  position="topright"
                  onCreated={handleDrawCreate}
                  onDeleted={handleDelete}
                  onEdited={(e) => handleEdit(e)}
                  draw={{
                    rectangle: true,
                    polyline: false,
                    circle: false,
                    circlemarker: false,
                    marker: false,
                    polygon: true
                  }}
                />
              </FeatureGroup>

              {shapes.map((shape) => (
                <Polygon
                  key={shape.id}
                  pathOptions={{ color: shape.id === selectedShapeIndex ? 'red' : '#469100' }}
                  //positions={shape.coordinates}
                  positions={shape.coordinates.map((coord) => [coord[1], coord[0]])}
                  eventHandlers={{
                    click: () => {
                      console.log('shape.id', shape.id);
                      selected_shape_Index = shape.id;
                      setSelectedShapeIndex(shape.id);
                    },
                    onEdit: (event) => {
                      // Assign the stored id to the layer options during edit
                      event.target.options.id = shape.id;
                    }
                  }}
                />
              ))}
            </MapContainer>
          ) : (
            ''
          )}
        </Grid>
        <Grid item md={10} xs={8}>
          <TextField
            label="Enter Location Name"
            variant="outlined"
            className="locationName"
            value={locationName}
            style={{ width: '100%' }}
            onChange={(e) => {
              setLocationName(e.target.value);
              setLocationNameError('');
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleAdd();
              }
            }}
            error={Boolean(locationNameError)}
            helperText={locationNameError}
            inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          {locationData?.mode == 'edit' ? (
            <Button variant="contained" sx={{ mb: 5 }} className="addbtn searchbtn" fullWidth onClick={handleAdd}>
              Update
            </Button>
          ) : locationData?.mode == 'view' ? (
            ''
          ) : (
            <Button variant="contained" sx={{ mb: 5 }} className="addbtn searchbtn" fullWidth onClick={handleAdd}>
              Add <KeyboardArrowRightSharpIcon className="arrowicon" />
            </Button>
          )}
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{marginTop:50}}
      >
        <MuiAlert onClose={handleCloseSnackbar} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.locationNameError || snackBarMessage.message}
        </MuiAlert>
      </Snackbar>
      {/* <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackBar} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar> */}
    </div>
  );
};

export default DrawLocation;
