import React, { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Container,
  TextField,
  Button,
  Link,
  Paper,
  CircularProgress,
  Snackbar,
  Alert,
  Autocomplete,
  Dialog,
  Box,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Switch
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { BaseUrl } from 'config';
import companyDefaultPic from '../../image/companyDefaultPic.avif';

import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import SessionExpired from 'pages/session/sessionExpired';
import { companyDetails, userDetails, EditProfileClicked, AccountTypeChangeToEnt, updateUserPlan } from 'store/reducers/menu';
import UploadIcon from '@mui/icons-material/Upload';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
const CompanyProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const profileNavigate = location?.state?.isAccountTypeEnt;
  const dispatch = useDispatch();
  const { TOKEN, companyInfo, user, isAccountTypeChangeToEnt, isEditProfileClicked, companyVerifyVisited, companyVerifyToken } =
    useSelector((state) => state.menu);
  const [isAddingNew, setIsAddingNew] = useState(true);
  // const [companyInfo, setCompanyInfo]= useState()
  const [isLoading, setIsLoading] = useState(false);
  const [isChangePlanLoading, setIsChangePlanLoading] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [formData, setFormData] = useState({
    // company_id: companyInfo ? companyInfo?.company_id : '',
    company_name: companyInfo ? companyInfo?.company_name : '',
    address: companyInfo ? companyInfo?.address : '',
    city: companyInfo ? companyInfo?.city : '',
    state_province: companyInfo ? companyInfo?.state_province : '',
    plan_company: 'ENT',
    url: companyInfo ? companyInfo?.url : 'https://',
    company_email: companyInfo ? companyInfo?.company_email : '',
    company_contact: companyInfo ? companyInfo?.company_contact : '',
    apartment_suite_etc: companyInfo ? companyInfo?.apartment_suite_etc : '',
    country: companyInfo ? companyInfo?.country : '',
    postal_code: companyInfo ? companyInfo?.postal_code : ''
  });
  console.log(companyInfo);
  const [companyName, setCompanyName] = useState(companyInfo ? companyInfo?.company_name : '');
  const [addNewCompany, setAddNewCompany] = useState(false);
  const [addExisitingCompany, setAddExistingCompany] = useState(false);
  // const userPlan = user.plan != null ? user.plan : localStorage.getItem('userPlan');
  const [openDialog, setOpenDialog] = useState(false);
  const [companyList, setCompanyList] = useState([]);
  const [validationErrors, setValidationErrors] = useState({
    company_name: '',
    address: '',
    city: '',
    state_province: '',
    url: '',
    apartment_suite_etc: '',
    country: '',
    postal_code: '',
    company_email: '',
    company_contact: ''
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: '',
    status: ''
  });
  useEffect(() => {
    setAddExistingCompany(false);
    if (isAccountTypeChangeToEnt) {
      dispatch(companyDetails(''));
    }
    fetchFilledCompanyDetail();
    fetchCompanyDetails();
  }, []);
  useEffect(() => {
    if (companyInfo?.data) {
      setFormData({
        company_name: companyInfo.company_name || '',
        address: companyInfo.address || '',
        city: companyInfo.city || '',
        state_province: companyInfo.state_province || '',
        plan_company: 'ENT',
        url: companyInfo.url || 'https://',
        company_email: companyInfo.company_email || '',
        company_contact: companyInfo.company_contact || '',
        apartment_suite_etc: companyInfo.apartment_suite_etc || '',
        country: companyInfo.country || '',
        postal_code: companyInfo.postal_code || ''
      });
      setCompanyName(companyInfo.company_name || '');
      setSelectedCompany(companyInfo);
    }
  }, [companyInfo]);
  const openConfirmationDialog = () => {
    setOpenDialog(true);
    //handleConfirmation()
  };
  const closeConfirmationDialog = () => {
    setOpenDialog(false);
  };
  const handleConfirmation = async () => {
    //openConfirmationDialog();
    // Perform skip action here
    try {
      const response = await axios.get(
        `${BaseUrl}/company_skip_plan`,
        // {
        //   id: user?.id
        // },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`
          }
        }
      );
      if (response?.status === 200) {
        setIsChangePlanLoading(false);
        setOpenSnackBar(true);
        setSnackBarMessage({
          message: 'Plan Changed successfully!',
          status: 'success'
        });
        dispatch(
          userDetails({
            ...user,
            plan: 'PPU'
          })
        );
      }
      localStorage.setItem('userPlan', 'PPU');
      setTimeout(() => {
        navigate('/user/dashboard');
      }, 3000);
      closeConfirmationDialog();
    } catch (error) {
      setOpenSnackBar(true);
      setIsChangePlanLoading(false);
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired please login again to continue',
          status: 'error'
        });
      } else {
        setSnackBarMessage({
          message: 'Something went wrong. Try again later! handleconfirm',
          status: 'error'
        });
      }
    }
    // Close the dialog after handling skip action
  };
  const fetchFilledCompanyDetail = async () => {
    try {
      const response = await axios.get(`${BaseUrl}/company_detail`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TOKEN}`
        }
      });

      if (response?.data && response?.status === 200) {
        dispatch(companyDetails(response?.data));
        return true;
      } else if (response?.data?.status == 'User is not having any company registered' && response?.status === 200) {
        return true;
      } else if (response?.data?.Error == 'user plan is Not ENT') {
        return false;
      }
    } catch (error) {
      return false;
    }
  };
  const fetchCompanyDetails = async () => {
    try {
      const response = await axios.get('https://datacarbonui.digitalglyde.com/admin_user_company_details', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TOKEN}`
        }
      });
      const data = response?.data;

      if (data.length > 0) {
        setCompanyList(data);
      } else if (data.length == 0) {
        setCompanyList([]);
      } else {
        setCompanyList([]);
      }
    } catch (err) {
      console.log('error occurred while fetching company details:', err);
    }
  };

  const handleSessionExpiredClose = (status) => {
    setSessionExpired(status);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleChange = (prop) => (event) => {
    let value = event.target.value;
    setFormData({ ...formData, [prop]: value });

    setValidationErrors({ ...validationErrors, [prop]: '' });

    // Validate postal code
    if (prop === 'postal_code' && !/^\d{5}$/.test(value)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, [prop]: 'Enter a valid 5-digit zip code' }));
    }

    // Validate URL
    if (prop === 'url' && !/^(ftp|http|https):\/\/[^ "]+$/.test(value)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, [prop]: 'Enter a valid URL' }));
    }

    //Validate email
    if (!isAddingNew && prop === 'company_email' && !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, [prop]: 'Enter a valid email address' }));
    }

    if (prop === 'company_contact') {
      value = value.replace(/\D/g, '');
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      if (value.length === 10) {
        value = value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
      setFormData({ ...formData, [prop]: value });
      if (value.length !== 12) {
        setValidationErrors((prevErrors) => ({ ...prevErrors, [prop]: 'Enter a valid 10-digit contact number' }));
      }
    }
  };

  const handleSkip = () => {
    openConfirmationDialog();
  };

  const validateForm = () => {
    let isValid = true;
    if (
      isAddingNew &&
      (!/^\d{5}$/.test(formData?.postal_code) ||
        !/^(ftp|http|https):\/\/[^ "]+$/.test(formData?.url) ||
        formData?.company_contact.replace(/\D/g, '').length !== 10)
    ) {
      isValid = false;
    } else if (!isAddingNew && !/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(formData?.company_email)) {
      {
        isValid = false;
      }
    }
    return isValid;
  };

  const handleAddCompany = async (requestData) => {
    try {
      // const response = await axios.post(`${BaseUrl}/companyregistration`, requestData, {
      const response = await axios.post(`${BaseUrl}/add_company`, requestData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TOKEN}`
        }
      });
      if (response?.status === 200) {
        setIsLoading(false);
        setOpenSnackBar(true);
        if (response?.data?.message == 'company Already Exist') {
          setSnackBarMessage({
            message: response?.data?.message,
            status: 'error'
          });
          setTimeout(() => {
            navigate('/joblists');
          }, 2500);

          return;
        }
        // setIsLoading(false);
        setSnackBarMessage({
          message: 'Company registered successfully! You will be informed shortly after company confirmation.',
          status: 'success'
        });
        // }
        await fetchCompanyDetails();
        dispatch(companyDetails(response?.data));
        dispatch(
          userDetails({
            ...user,
            company_name: response?.data?.company_name

            //company_id: response?.data?.company_id
          })
        );
        // dispatch(updateUserPlan('PPU'));

        setFormData({
          company_name: '',
          address: '',
          city: '',
          state_province: '',
          url: '',
          apartment_suite_etc: '',
          country: '',
          postal_code: ''
        });
        setTimeout(() => {
          if (isAccountTypeChangeToEnt) {
            dispatch(EditProfileClicked({ isEditProfileClicked: !isEditProfileClicked }));
            dispatch(AccountTypeChangeToEnt({ isAccountTypeChangeToEnt: !isAccountTypeChangeToEnt }));
            navigate('/profile');
          } else {
            navigate('/user/dashboard');
          }
        }, 2500);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      const response = error?.response;
      if (response?.data?.detail === 'Unauthorized' && response?.status == 401) {
        setSessionExpired(true);
      } else {
        setOpenSnackBar(true);
        setSnackBarMessage({
          message: 'Something went wrong. Try again later storecompany!',
          status: 'error'
        });
      }
    }
  };
  // const setAddCompany = () => {
  //   setAddNewCompany(true);
  // };
  // const setExistingAddCompany = () => {
  //   setAddExistingCompany(true);
  // };
  const handleAddExisting = async (requestData) => {
    console.log(requestData);
    try {
      // const response = await axios.post(`${BaseUrl}/companyregistration`, requestData, {
      const response = await axios.post(
        `${BaseUrl}/add_existing_company`,
        {
          company_email: requestData
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`
          }
        }
      );
      if (response?.status === 200) {
        setIsLoading(false);
        setOpenSnackBar(true);

        console.log(response?.data?.message);
        if (response?.data?.message.includes('Company status is False')) {
          setSnackBarMessage({
            message: response?.data?.message,
            status: 'error'
          });
          setTimeout(() => {
            navigate('/joblists');
          }, 2500);

          return;
        }

        setIsLoading(false);
        setSnackBarMessage({
          message: 'Company registered successfully! You will be informed shortly after company confirmation.',
          status: 'success'
        });
        // }
        await fetchCompanyDetails();
        dispatch(companyDetails(response?.data));
        dispatch(
          userDetails({
            ...user
            //  company_name: response?.data?.company_name

            //company_id: response?.data?.company_id
          })
        );
        //  dispatch(updateUserPlan('PPU'));

        setFormData({
          company_name: '',
          address: '',
          city: '',
          state_province: '',
          url: '',
          apartment_suite_etc: '',
          country: '',
          postal_code: ''
        });
        setTimeout(() => {
          if (isAccountTypeChangeToEnt) {
            dispatch(EditProfileClicked({ isEditProfileClicked: !isEditProfileClicked }));
            dispatch(AccountTypeChangeToEnt({ isAccountTypeChangeToEnt: !isAccountTypeChangeToEnt }));
            navigate('/profile');
          } else {
            navigate('/user/dashboard');
          }
        }, 1200);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      const response = error?.response;
      if (response?.data?.detail === 'Unauthorized' && response?.status == 401) {
        setSessionExpired(true);
      } else {
        setOpenSnackBar(true);
        setSnackBarMessage({
          message: 'Something went wrong. Try again later storecompany!',
          status: 'error'
        });
      }
    }
  };
  const updateUserAccountType = async (accountType) => {
    try {
      const userPlanUpdateData = {
        user_id: user?.id,
        plan: accountType
      };

      const userPlanUpdateResponse = await axios.post(`${BaseUrl}/userplan_update`, userPlanUpdateData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TOKEN}`
        }
      });

      if (userPlanUpdateResponse?.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setIsLoading(false);
      setOpenSnackBar(true);
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired please login again to continue',
          status: 'error'
        });
      } else {
        setSnackBarMessage({
          message: 'Something went wrong. Try again later updateaccount!',
          status: 'error'
        });
      }
      return false;
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (user.is_verified == false) {
      setOpenSnackBar(true);
      setSnackBarMessage({
        message: 'User is not verified. Please verify user!',
        status: 'error'
      });
      setTimeout(() => {
        navigate('/unverified_user');
      }, 2000);
      return;
    }

    // Validate before submitting

    if (formData?.company_name?.trim() === '' && isAddingNew) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, company_name: 'Company name is required' }));
      return;
    }

    if (validateForm()) {
      setIsLoading(true);
      const {
        //  company_id,
        company_name,
        companyName,
        address,
        city,
        state_province,
        url,
        country,
        postal_code,
        plan_company,
        company_email,
        company_contact
      } = formData;
      const requestData = {
        company_name,
        address: address,
        city,
        state_province,
        url,
        apartment_suite_etc: address,
        country,
        postal_code,
        // company_id,
        plan_company,
        company_email,
        company_contact
      };
      // localStorage.setItem('userPlan', 'ENT');

      if (!isAddingNew) {
        handleAddExisting(requestData.company_email);
        //  updateComanyData(requestData);
      } else {
        handleAddCompany(requestData);
      }
    }
  };
  const handleCompanyNameChange = (event, value) => {
    if (value && typeof value === 'object') {
      // Selected from suggestions
      setSelectedCompany(value);
      setCompanyName(value.company_name);
      setFormData((prevFormData) => ({
        ...prevFormData,
        ...value,
        company_name: value.company_name
      }));
    } else {
      // Manually entered
      const inputValue = value || '';
      setCompanyName(inputValue);
      setSelectedCompany({ company_name: inputValue });
      setFormData((prevFormData) => ({
        ...prevFormData,
        company_name: inputValue,
        //company_id: '',
        address: '',
        city: '',
        state_province: '',
        url: 'https://',
        apartment_suite_etc: '',
        country: '',
        postal_code: '',
        company_email: '',
        company_contact: ''
      }));
    }
  };

  // const handleBackClick = () => {
  //   if (isAccountTypeChangeToEnt) {
  //     if (companyInfo) {
  //       dispatch(updateUserPlan('ENT'));
  //     }
  //     dispatch(EditProfileClicked({ isEditProfileClicked: !isEditProfileClicked }));
  //     dispatch(AccountTypeChangeToEnt({ isAccountTypeChangeToEnt: !isAccountTypeChangeToEnt }));
  //     navigate('/profile');
  //   }

  //   navigate(-1);
  // };

  const handleRemoveCompany = async () => {
    try {
      const response = await axios.post(
        'https://datacarbonui.digitalglyde.com/user_req_to_remove_company',
        {
          company_id: companyInfo?.company_id
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`
          }
        }
      );
      console.log(response.data.message);
      if (response?.data?.notification_type == 'Green - Persistant') {
        setOpenSnackBar(true);
        setSnackBarMessage({
          message: response.data.message,
          status: 'success'
        });
        setTimeout(() => {
          navigate('/joblists');
        }, 2000);
      }
    } catch (err) {
      console.log('error occurred while fetching company details:', err);
    }
  };
  const handleSwitchChange = (event) => {
    setIsAddingNew(event.target.checked);
  };

  const handleButtonClick = () => {
    if (isAddingNew) {
      setAddExistingCompany(false);
    } else {
      setAddExistingCompany(true);
    }
  };
  const handleGoBack = () => {
    navigate(-1); // This navigates back to the previous location in history
  };

  return (
    <>
      <Container fixed>
        <Typography component="h1" variant="h5" className="heading" sx={{ mt: 5 }} style={{ textAlign: 'center' }}>
          Company Profile
        </Typography>
        {/* {!companyInfo.company_id ? */}
        <>
          <Grid container spacing={2}>
            {/* Buttons on the top row */}
            <Grid container spacing={1} sx={{ mb: 2 }}>
              {!companyInfo?.company_id && (
                <>
                  <Grid item xs={5}>
                    <FormControlLabel
                      style={{ padding: 0, margin: '-10px 0 0 0' }}
                      control={<Switch checked={isAddingNew} onChange={handleSwitchChange} />}
                    />
                    {isAddingNew ? (
                      <Typography variant="h3" style={{ display: 'inline', color: '#469100' }}>
                        Add New Company
                      </Typography>
                    ) : (
                      <Typography variant="h3" style={{ display: 'inline', color: '#469100' }}>
                        Add Existing Company
                      </Typography>
                    )}

                    {/* <Button
                        sx={{ mr: 2 }}
                        className="login_button"
                        variant="contained"
                        size="large"
                        onClick={handleButtonClick}
                        style={{ paddingTop: 1, paddingBottom: 1, margin: 0 }}
                      >
                        {isAddingNew ? 'Add New Company' : 'Add Existing Company'}
                        <FormControlLabel
                          style={{ padding: 0, margin: 0 }}
                          control={<Switch checked={isAddingNew} onChange={handleSwitchChange} />}
                        />
                      </Button> */}
                  </Grid>
                </>
              )}

              {/* <Grid item xs={2} style={{ textAlign: 'right' }}>
           <Button className="login_button" variant="contained" size="large" onClick={handleRemoveCompany}>
             Remove{' '}
           </Button>
         </Grid> */}
            </Grid>
            {!companyInfo?.company_id ? (
              <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                <Box className="boundaryBoxprofile company_form">
                  {!isAddingNew ? (
                    <>
                      <Typography variant="h4" style={{ textAlign: 'left', marginBottom: '10px' }}>
                        Existing Company
                      </Typography>
                      <TextField
                        label="Admin Email"
                        fullWidth
                        required
                        value={formData.company_email}
                        onChange={handleChange('company_email')}
                        margin="normal"
                        variant="outlined"
                        placeholder="Company Email"
                        InputLabelProps={{
                          shrink: true
                        }}
                        error={!!validationErrors.company_email}
                        helperText={validationErrors.company_email}
                        // disabled={companyInfo ? true : false}
                        inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
                      />
                    </>
                  ) : (
                    <>
                      <Typography variant="h4" style={{ textAlign: 'left', marginBottom: '10px' }}>
                        Add Company
                      </Typography>
                      <Grid container spacing={2}>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                           <Autocomplete
                              options={companyList}
                              getOptionLabel={(option) => (typeof option === 'string' ? option : option.company_name || '')}
                              value={selectedCompany}
                              onChange={handleCompanyNameChange}
                              inputValue={companyName}
                              onInputChange={(event, newInputValue) => {
                                setCompanyName(newInputValue);
                                setFormData((prevFormData) => ({
                                  ...prevFormData,
                                  company_name: newInputValue
                                }));
                              }}
                              freeSolo

                              className="myAutocomplete"
                              isOptionEqualToValue={(option, value) => option.company_name === value.company_name}
                              renderInput={(params) => (
                                <TextField
                                  {...params}

                                  label="Company Name"
                                  margin="normal"
                                  variant="outlined"
                                  fullWidth
                                  error={!!validationErrors.company_name}
                                  helperText={validationErrors.company_name}
                                  InputLabelProps={{ shrink: true }}
                                />
                              )}
                            /> 
                          {/* <TextField
                            label="Company Name"
                            fullWidth
                            value={companyName}
                            onChange={(event) => {
                              const newInputValue = event.target.value;
                              setCompanyName(newInputValue);
                              setFormData((prevFormData) => ({
                                ...prevFormData,
                                company_name: newInputValue
                              }));
                            }}
                            margin="normal"
                            variant="outlined"
                            error={!!validationErrors.company_name}
                            helperText={validationErrors.company_name}
                            InputLabelProps={{ shrink: true }}
                          /> */}
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                          <TextField
                            label="Company URL"
                            fullWidth
                            required
                            value={formData.url}
                            onChange={handleChange('url')}
                            margin="normal"
                            variant="outlined"
                            placeholder="https://companyurl.com"
                            InputLabelProps={{
                              shrink: true
                            }}
                            error={!!validationErrors.url}
                            helperText={validationErrors.url}
                            inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
                          />
                        </Grid>
                        <Grid item lg={4} md={4} sm={4} xs={12}>
                          <TextField
                            label="Company Contact"
                            fullWidth
                            required
                            value={formData.company_contact}
                            onChange={handleChange('company_contact')}
                            margin="normal"
                            variant="outlined"
                            placeholder="Company Contact"
                            InputLabelProps={{
                              shrink: true
                            }}
                            error={!!validationErrors.company_contact}
                            helperText={validationErrors.company_contact}
                            inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2}>
                        <Grid item lg={8} md={6} sm={6} xs={12}>
                          <TextField
                            label="Address"
                            fullWidth
                            required
                            value={formData.address}
                            onChange={handleChange('address')}
                            margin="normal"
                            variant="outlined"
                            error={!!validationErrors.address}
                            helperText={validationErrors.address}
                            inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <TextField
                            label="Zip Code"
                            fullWidth
                            required
                            type="number"
                            value={formData.postal_code}
                            onChange={handleChange('postal_code')}
                            margin="normal"
                            variant="outlined"
                            error={!!validationErrors.postal_code}
                            helperText={validationErrors.postal_code}
                            inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <TextField
                            label="City"
                            fullWidth
                            required
                            value={formData.city}
                            onChange={handleChange('city')}
                            margin="normal"
                            variant="outlined"
                            error={!!validationErrors.city}
                            helperText={validationErrors.city}
                            inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <TextField
                            label="State"
                            fullWidth
                            required
                            value={formData.state_province}
                            onChange={handleChange('state_province')}
                            margin="normal"
                            variant="outlined"
                            error={!!validationErrors.state_province}
                            helperText={validationErrors.state_province}
                            inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <TextField
                            label="Country"
                            fullWidth
                            required
                            value={formData.country}
                            onChange={handleChange('country')}
                            margin="normal"
                            variant="outlined"
                            error={!!validationErrors.country}
                            helperText={validationErrors.country}
                            inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
                            InputLabelProps={{ shrink: true }}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Box>

                {/* Submit and Cancel Buttons */}
                <Grid container sx={{ mb: 1, mt: 1 }}>
                  <Grid item xs>
                    <Link href="#" variant="body2"></Link>
                  </Grid>
                  <Grid item>
                    <Button sx={{ mr: 2 }} className="login_button" variant="contained" size="large" onClick={handleGoBack}>
                      Cancel
                    </Button>
                  </Grid>
                  <Grid item>
                    {/* {!companyInfo?.company_id ? ( */}
                    <Button type="submit" className="login_button" variant="contained" size="large">
                      {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
                    </Button>
                    {/* ) : (
                          <Button className="login_button" variant="contained" size="large" onClick={handleRemoveCompany}>
                            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Remove'}
                          </Button>
                        )} */}
                  </Grid>
                </Grid>
              </form>
            ) : (
              <Container>
                {/* <Grid container>
                      <Grid item lg={2}></Grid>
                      <Grid item lg={8}>
 

                        <form>
                          <Grid container spacing={2} >
                            <Grid item lg={4} md={4} sm={6} xs={12} className="pic">
                              <img src={companyDefaultPic} width="150px" height={'150px'} />
                            </Grid>
                            <Grid item lg={8} md={8} sm={6} xs={12}>
                              <Grid container className="detail">
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                  <Typography variant="body1" className={'userdetailssection1'}>
                                    Name{' '}
                                  </Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sm={9} xs={9}>
                                  <Typography variant="body1">{companyInfo?.company_name} </Typography>
                                </Grid>
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                  <Typography variant="body1" className={'userdetailssection1'}>
                                    Email
                                  </Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sm={9} xs={9}>
                                  <Typography variant="body1">{companyInfo.company_email} </Typography>
                                </Grid>
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                  <Typography variant="body1" className={'userdetailssection1'}>
                                    Domain{' '}
                                  </Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sm={9} xs={9}>
                                  <Typography variant="body1">{companyInfo?.url} </Typography>

                                </Grid>
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                  <Typography variant="body1" className={'userdetailssection1'}>
                                    Contact
                                  </Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sm={9} xs={9}>
                                  <Typography variant="body1">{companyInfo?.company_contact}</Typography>
                                </Grid>
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                  <Typography variant="body1" className={'userdetailssection1'}>
                                    Address
                                  </Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sm={9} xs={9}>
                                  <Typography variant="body1">{companyInfo?.address} </Typography>
                                </Grid>

                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                  <Typography variant="body1" className={'userdetailssection1'}>
                                    Zipcode
                                  </Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sm={9} xs={9}>
                                  <Typography variant="body1">{companyInfo?.postal_code}</Typography>
                                </Grid>
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                  <Typography variant="body1" className={'userdetailssection1'}>
                                    City
                                  </Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sm={9} xs={9}>
                                  <Typography variant="body1">{companyInfo?.city}</Typography>
                                </Grid>
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                  <Typography variant="body1" className={'userdetailssection1'}>
                                    State
                                  </Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sm={9} xs={9}>
                                  <Typography variant="body1">{companyInfo?.state_province}</Typography>
                                </Grid>
                                <Grid item lg={3} md={3} sm={3} xs={3}>
                                  <Typography variant="body1" className={'userdetailssection1'}>
                                    Country
                                  </Typography>
                                </Grid>
                                <Grid item lg={9} md={9} sm={9} xs={9}>
                                  <Typography variant="body1">{companyInfo?.country}</Typography>
                                </Grid>
                              </Grid>
                              <br />
                              <Grid container sx={{ mb: 1, mt: 1 }}>
                                <Grid item xs>
                                  <Link href="#" variant="body2"></Link>
                                </Grid>
                                <Grid item>
                                  <Button sx={{ mr: 2 }} className="login_button" variant="contained" size="medium" onClick={handleGoBack}>
                                    Cancel
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button className="login_button" variant="contained" size="medium" onClick={handleRemoveCompany}>
                                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Remove'}
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </form>
                        {sessionExpired ? <SessionExpired handleSessionExpiredClose={handleSessionExpiredClose} /> : ''}
                      </Grid>
                    </Grid> */}
                <Grid container spacing={3}>
                  <Grid item md={3}>
                    <Box className="boundaryBoxprofile ">
                      <div className="profilePic">
                        <input type="file" />
                        <img src={companyDefaultPic} className="profilePicImg" />
                        <span>
                          <UploadIcon /> <br />
                          Change or upload <br />
                          profile pic
                        </span>
                      </div>
                      <Typography variant="h4" style={{ fontWeight: 400, textAlign: 'center', color: '#469100' }}>
                        Company
                      </Typography>
                      <Typography variant="h3" style={{ fontWeight: 400, textAlign: 'center' }}>
                        {companyInfo?.company_name}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item md={9}>
                    <form>
                      <Box className="boundaryBoxprofile">
                        <Grid container spacing={2}>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container className="detail">
                              <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Typography variant="h4" className={'userdetailssection1'}>
                                  Email
                                </Typography>
                              </Grid>
                              <Grid item lg={9} md={9} sm={9} xs={9}>
                                <Typography variant="h4">{companyInfo.company_email} </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container className="detail">
                              <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Typography variant="h4" className={'userdetailssection1'}>
                                  Domain{' '}
                                </Typography>
                              </Grid>
                              <Grid item lg={9} md={9} sm={9} xs={9}>
                                <Typography variant="h4">{companyInfo?.url} </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container className="detail">
                              <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Typography variant="h4" className={'userdetailssection1'}>
                                  Contact
                                </Typography>
                              </Grid>
                              <Grid item lg={9} md={9} sm={9} xs={9}>
                                <Typography variant="h4">{companyInfo?.company_contact}</Typography>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container className="detail">
                              <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Typography variant="h4" className={'userdetailssection1'}>
                                  Address
                                </Typography>
                              </Grid>
                              <Grid item lg={9} md={9} sm={9} xs={9}>
                                <Typography variant="h4">{companyInfo?.address} </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container className="detail">
                              <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Typography variant="h4" className={'userdetailssection1'}>
                                  Zipcode
                                </Typography>
                              </Grid>
                              <Grid item lg={9} md={9} sm={9} xs={9}>
                                <Typography variant="h4">{companyInfo?.postal_code} </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container className="detail">
                              <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Typography variant="h4" className={'userdetailssection1'}>
                                  City
                                </Typography>
                              </Grid>
                              <Grid item lg={9} md={9} sm={9} xs={9}>
                                <Typography variant="h4">{companyInfo?.city} </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container className="detail">
                              <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Typography variant="h4" className={'userdetailssection1'}>
                                  State
                                </Typography>
                              </Grid>
                              <Grid item lg={9} md={9} sm={9} xs={9}>
                                <Typography variant="h4">{companyInfo?.state_province} </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item lg={6} md={6} sm={12} xs={12}>
                            <Grid container className="detail">
                              <Grid item lg={3} md={3} sm={3} xs={3}>
                                <Typography variant="h4" className={'userdetailssection1'}>
                                  Country
                                </Typography>
                              </Grid>
                              <Grid item lg={9} md={9} sm={9} xs={9}>
                                <Typography variant="h4">{companyInfo?.country} </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box className="boundaryBoxprofile" style={{ marginTop: 20 }}>
                        <Grid container>
                          <Grid item sm={6}>
                            {/* <Typography variant="h5" style={{lineHeight:"40px", fontSize: "1.05rem", color:"#469100", display:"inline-block"}}>Change Account Type</Typography> */}
                          </Grid>
                          <Grid item xs={6} style={{ textAlign: 'right' }}>
                            {!companyInfo?.company_id ? (
                              <Button type="submit" className="login_button" variant="contained" size="large">
                                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
                              </Button>
                            ) : (
                              <Button className="login_button" variant="contained" size="large" onClick={handleRemoveCompany}>
                                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Remove'}
                              </Button>
                            )}

                            <Button size="large" style={{ marginLeft: 10 }} onClick={() => navigate('/profile')}>
                              <KeyboardArrowLeftIcon /> Back
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </form>
                  </Grid>
                </Grid>
              </Container>
            )}
          </Grid>

          {/* Additional content */}
          <Grid container spacing={2} style={{ textAlign: 'left', fontSize: '14px', marginTop: '20px' }}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <ul>
                <li>
                  <h3>Registration Benefit:</h3>
                  <p> By signing up with our platform, your company gains access to premium features and tailored services.</p>
                </li>
                <li>
                  <h3>Discount Incentive:</h3>
                  <p>
                    Once your company is registered, every job request you submit will automatically receive a 10% discount, providing cost
                    savings on your projects.
                  </p>
                </li>
                <li>
                  <h3>Value-Added Services:</h3>
                  <p>
                    Our commitment to registered enterprises extends beyond discounts; it ensures a seamless and enhanced user experience,
                    adding substantial value to your business operations.
                  </p>
                </li>
              </ul>
            </Grid>
          </Grid>

          {/* Dialog */}
          <Dialog open={openDialog} onClose={closeConfirmationDialog}>
            <DialogTitle className="dialog">Are you sure?</DialogTitle>
            <DialogContent>
              <Typography variant="body1">By skipping, the default plan will be set as PPU. Are you sure you want to skip?</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={closeConfirmationDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleConfirmation} color="primary" autoFocus>
                {isChangePlanLoading ? <CircularProgress /> : 'Proceed'}
              </Button>
            </DialogActions>
          </Dialog>
        </>
        {/* //         :
//         <CompanyViewProfile companyInfo={companyInfo}/>
// } */}
      </Container>

      {sessionExpired && <SessionExpired handleSessionExpiredClose={handleSessionExpiredClose} />}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{ marginTop: 50 }}
        open={openSnackBar}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CompanyProfile;
