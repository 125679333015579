// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner {
  display: inline-block;
  position: relative;
}

.spinner > div {
  animation: spinner_anim 1.2s linear infinite;
  border-radius: 5px;
  background-color: var(--text-color);
  position: absolute;
  width: 20%;
  height: 7.8%;
  top: 46.1%;
  left: 40%;
}

@keyframes spinner_anim {
 0% { opacity: 1; }
 100% { opacity: 0.15; }
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/components/style/Spinner.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EAGE,4CAA4C;EAC5C,kBAAkB;EAClB,mCAAmC;EACnC,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,UAAU;EACV,SAAS;AACX;;AAEA;CACC,KAAK,UAAU,EAAE;CACjB,OAAO,aAAa,EAAE;AACvB","sourcesContent":[".spinner {\n  display: inline-block;\n  position: relative;\n}\n\n.spinner > div {\n  -webkit-animation: spinner_anim 1.2s linear infinite;\n  -moz-animation: spinner_anim 1.2s linear infinite;\n  animation: spinner_anim 1.2s linear infinite;\n  border-radius: 5px;\n  background-color: var(--text-color);\n  position: absolute;\n  width: 20%;\n  height: 7.8%;\n  top: 46.1%;\n  left: 40%;\n}\n\n@keyframes spinner_anim {\n 0% { opacity: 1; }\n 100% { opacity: 0.15; }\n}\n\n@-moz-keyframes spinner_anim {\n 0% { opacity: 1; }\n 100% { opacity: 0.15; }\n}\n\n@-webkit-keyframes spinner_anim {\n 0% { opacity: 1; }\n 100% { opacity: 0.15; }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
