// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.link-feature-form {
    background-color: var(--container-bg-color);
    border: 2px solid var(--border-color);
    display: flex;
    flex-direction: column;
    padding: 0.5em;
    height: 100%;
}

div.link-feature-form > div.AttributeForm {
    flex: 1 1 auto;
    height: 0;
}

div.link-feature-form-feature-list {
    flex: 1 1 auto;
    overflow-y: auto;
    background-color: var(--list-bg-color);
    border-style: solid;
    border-color: var(--border-color);
    border-width: 1px 1px 0 1px;
}

div.link-feature-form-feature-list > div {
    padding: 0.125em 0.25em;
}

div.link-feature-form-feature-list > div:hover {
    background-color: var(--list-item-bg-color-hover);
    color: var(--list-item-text-color-hover);
}

div.link-feature-form-close {
    border-top: 1px solid var(--border-color);
    padding-top: 0.5em;
    flex: 0 0 auto;
}

div.link-feature-form-hint {
    font-style: italic;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
div.link-feature-form-close > button {
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/components/style/LinkFeatureForm.css"],"names":[],"mappings":"AAAA;IACI,2CAA2C;IAC3C,qCAAqC;IACrC,aAAa;IACb,sBAAsB;IACtB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,SAAS;AACb;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,sCAAsC;IACtC,mBAAmB;IACnB,iCAAiC;IACjC,2BAA2B;AAC/B;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,iDAAiD;IACjD,wCAAwC;AAC5C;;AAEA;IACI,yCAAyC;IACzC,kBAAkB;IAClB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA;IACI,WAAW;AACf","sourcesContent":["div.link-feature-form {\n    background-color: var(--container-bg-color);\n    border: 2px solid var(--border-color);\n    display: flex;\n    flex-direction: column;\n    padding: 0.5em;\n    height: 100%;\n}\n\ndiv.link-feature-form > div.AttributeForm {\n    flex: 1 1 auto;\n    height: 0;\n}\n\ndiv.link-feature-form-feature-list {\n    flex: 1 1 auto;\n    overflow-y: auto;\n    background-color: var(--list-bg-color);\n    border-style: solid;\n    border-color: var(--border-color);\n    border-width: 1px 1px 0 1px;\n}\n\ndiv.link-feature-form-feature-list > div {\n    padding: 0.125em 0.25em;\n}\n\ndiv.link-feature-form-feature-list > div:hover {\n    background-color: var(--list-item-bg-color-hover);\n    color: var(--list-item-text-color-hover);\n}\n\ndiv.link-feature-form-close {\n    border-top: 1px solid var(--border-color);\n    padding-top: 0.5em;\n    flex: 0 0 auto;\n}\n\ndiv.link-feature-form-hint {\n    font-style: italic;\n    flex: 1 1 auto;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\ndiv.link-feature-form-close > button {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
