// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.Toolbar {
    margin: 0.25em;
    flex: 0 0 auto;
    display: flex;
}

div.Toolbar > .icon {
    width: 2.5em;
    height: 2.5em;
    margin-right: 0.25em;
    padding: 0.25em;
    border: 1px solid transparent;
}

div.Toolbar > .icon:hover,
div.Toolbar > .toolbar-item-active {
    border: 1px solid var(--border-color);
    background-color: var(--button-bg-color-hover);
    color: var(--button-text-color-hover);
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/components/style/Toolbar.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,oBAAoB;IACpB,eAAe;IACf,6BAA6B;AACjC;;AAEA;;IAEI,qCAAqC;IACrC,8CAA8C;IAC9C,qCAAqC;AACzC","sourcesContent":["div.Toolbar {\n    margin: 0.25em;\n    flex: 0 0 auto;\n    display: flex;\n}\n\ndiv.Toolbar > .icon {\n    width: 2.5em;\n    height: 2.5em;\n    margin-right: 0.25em;\n    padding: 0.25em;\n    border: 1px solid transparent;\n}\n\ndiv.Toolbar > .icon:hover,\ndiv.Toolbar > .toolbar-item-active {\n    border: 1px solid var(--border-color);\n    background-color: var(--button-bg-color-hover);\n    color: var(--button-text-color-hover);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
