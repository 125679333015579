import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, CircularProgress, useMediaQuery, useTheme } from '@mui/material';
import ReactApexChart from 'react-apexcharts';

const Loader = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="200px">
    <CircularProgress />
  </Box>
);

const ObjectDetectionPieChart = ({ isLoading, dataFetchedStatus, detectedData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [objectDetection, setObjectDetection] = useState({
    series: [],
    options: {
      chart: {
        type: 'donut',
        width: '100%'
      },
      legend: {
        show: false,
        position: 'bottom'
      },
      plotOptions: {
        pie: {
          donut: {
            size: '45%',
            labels: {
              show: true,
              total: {
                show: true,
                label: 'Total',
                fontSize: '13px',
                formatter: function (w) {
                  console.log(w, 'charts');
                  return w.globals.seriesTotals
                    .reduce((a, b) => {
                      return a + b;
                    }, 0)
                    .toFixed(0);
                }
              }
            }
          }
        }
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 250
            },
            legend: {
              position: 'bottom-right'
            }
          }
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 250
            },
            legend: {
              position: 'bottom-right'
            }
          }
        }
      ]
    }
  });

  const [carbonEmission, setCarbonEmission] = useState({
    series: [],
    options: {
      chart: {
        type: 'donut',
        width: '100%'
      },
      legend: {
        position: 'bottom',
        show: false
      },
      plotOptions: {
        pie: {
          donut: {
            size: '45%',
            labels: {
              show: true,
              total: {
                show: true,
                label: 'Total',
                fontSize: '13px',
                formatter: function (w) {
                  console.log(w, 'charts1');
                  return w.globals.seriesTotals
                    .reduce((a, b) => {
                      return a + b;
                    }, 0)
                    .toFixed(0);
                }
              }
            }
          }
        }
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            chart: {
              width: 250
            },
            legend: {
              position: 'bottom-right'
            }
          }
        },
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 250
            },
            legend: {
              position: 'bottom-right'
            }
          }
        }
      ]
    }
  });
  const LegendContent = () => {
    const legendItems = [
      { color: '#FF9900', label: 'Industries' },
      { color: '#6AD20B', label: 'Vegetation' },
      { color: '#01A6D0', label: 'Household' },
      { color: '#FFDD00', label: 'Transportation' }
    ];

    return (
      <Box
        className="chart_bar"
        sx={
          isMobile
            ? {
                display: 'flex',
                flexDirection: 'column',
                gap: '20px',
                mt: 2
              }
            : {}
        }
      >
        {legendItems.map((item, index) => (
          <Box
            key={index}
            className="piecharts_bar"
            sx={
              isMobile
                ? {
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    padding: '10px',
                    borderRadius: '5px'
                  }
                : {}
            }
          >
            <Box
              className="piecharts"
              sx={{
                background: item.color,
                ...(isMobile && { width: '20px', height: '20px' })
              }}
            />
            <Typography variant="body2" sx={{ color: '#01A6D0' }}>
              {item.label}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  };
  useEffect(() => {
    if (detectedData) {
      const object_detection_data = [
        Math.trunc(detectedData.no_of_house_detected),
        Math.trunc(detectedData.number_of_vehicles_detected),
        Math.trunc(detectedData?.number_of_vegetation),
        Math.trunc(detectedData?.number_of_industries)
      ];
      const carbon_emission_data = [
        Math.trunc(detectedData.total_emissions_household),
        Math.trunc(detectedData.co2_emission_normal_vehicle),
        Math.trunc(detectedData?.emission_from_vegeatation_rainforest),
        Math.trunc(detectedData?.industry_coal_co2_emission)
      ];

      if (dataFetchedStatus) {
        setObjectDetection({ ...objectDetection, series: object_detection_data });
        setCarbonEmission({ ...carbonEmission, series: carbon_emission_data });
      } else {
        setObjectDetection({ ...objectDetection, series: [0, 0, 0, 0] });
        setCarbonEmission({ ...carbonEmission, series: [0, 0, 0, 0] });
      }
    }
  }, [detectedData]);

  return (
    <Box className="boundaryBox cust_piechart">
      <Typography variant="h3" gutterBottom>
        Carbon Pie Chart
      </Typography>
      {isLoading ? (
        <Loader />
      ) : (
        <Grid container spacing={1}>
          {/* <Grid item lg={2} style={{marginTop:'45px'}} md={1} sm={1} xs={12}>
            <Box className="chart_bar">
            <Box className="piecharts_bar">
            <Box className="piecharts" sx={{background:'#FF9900'}}/>
            <Typography variant='body2' sx={{color:'#01A6D0'}}>Industries</Typography>
            </Box>
            <Box className="piecharts_bar">
            <Box className="piecharts" sx={{background:'#6AD20B'}}/>
            <Typography variant='body2' sx={{color:'#01A6D0'}}>Vegetation</Typography>
            </Box>
            <Box className="piecharts_bar">
            <Box className="piecharts" sx={{background:'#01A6D0'}}/>
            <Typography variant='body2' sx={{color:'#01A6D0'}}>Household</Typography>
            </Box>
            <Box className="piecharts_bar">
            <Box className="piecharts" sx={{background:'#FFDD00'}}/>
            <Typography variant='body2' sx={{color:'#01A6D0'}}>Transportation</Typography>
            </Box>
            </Box>
          </Grid> */}
          <Grid item lg={2} md={1} sm={1} xs={12} style={{ marginTop: isMobile ? 0 : '45px' }}>
            <LegendContent />
          </Grid>
          <Grid item xs={12} sm={5} md={5} lg={5}>
            <center>
              <Typography variant="body1" gutterBottom>
                Object Identification
              </Typography>
              <ReactApexChart
                options={{
                  ...objectDetection.options,
                  labels: ['Household', 'Vehicle', 'Vegetation', 'Industries'],
                  colors: ['#01A6D0', '#FFDD00', '#6AD20B', '#FF9900'],
                  tooltip: {
                    fillSeriesColor: false,
                    theme: 'dark',
                    style: {
                      fontSize: '12px'
                      // fontFamily: undefined
                    },
                    formatter: function (val, opts) {
                      return `<div style='background-color:${opts.w.config.colors[opts.seriesIndex]}; color:#000; padding: 5px'>${
                        opts.series[opts.seriesIndex]
                      } - ${val}%</div>`;
                    }
                  },
                  dataLabels: {
                    style: {
                      fontSize: '0.7rem'
                    }
                  }
                }}
                series={objectDetection.series}
                type="donut"
                width={250}
              />
            </center>
          </Grid>
          <Grid item xs={12} sm={5} md={6} lg={5}>
            <center>
              <Typography variant="body1" gutterBottom>
                Carbon Emission
              </Typography>
              <ReactApexChart
                options={{
                  ...carbonEmission.options,
                  labels: ['Household', 'Vehicle', 'Vegetation', 'Industries'],
                  colors: ['#01A6D0', '#FFDD00', '#6AD20B', '#FF9900'],
                  tooltip: {
                    fillSeriesColor: false,
                    theme: 'dark',
                    formatter: function (val, opts) {
                      return `<div style='background-color:${opts.w.config.colors[opts.seriesIndex]}; color:#000; padding: 5px'>${
                        opts.series[opts.seriesIndex]
                      } - ${val}%</div>`;
                    }
                  },
                  dataLabels: {
                    style: {
                      fontSize: '0.7rem'
                    }
                  }
                }}
                series={carbonEmission.series}
                type="donut"
                width={250}
              />
            </center>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default ObjectDetectionPieChart;
