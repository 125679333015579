// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ol-popup {
    position: absolute;
    text-align: center;
    font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif; /* IE 9 */ /* Safari */
    transform: translate(-50%,-100%);
}
a.ol-popup-close-btn {
  position: absolute;
  top: 0;
  right: 0;
  padding: 4px 4px 0 0;
  text-align: center;
  width: 18px;
  height: 14px;
  font: 16px/14px Tahoma, Verdana, sans-serif;
  color: #c3c3c3;
  text-decoration: none;
  font-weight: bold;
  background: transparent;
}
.ol-popup-cnt-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 12px;
}
.ol-popup-cnt-wrapper, .ol-popup-tip {
  background: white;
  box-shadow: 0 3px 14px rgba(0,0,0,0.4);
}
.ol-popup-cnt {
  margin: 13px 19px;
  line-height: 1.4;
   white-space: nowrap;

}
.ol-popup-tip-wrapper {
  margin: 0 auto;
  width: 40px;
  height: 20px;
  position: relative;
  overflow: hidden;

}
.ol-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  transform: rotate(45deg);
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/components/map/OlLocate.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,6DAA6D,EACvB,SAAS,EACL,WAAW;IACrD,gCAAgC;AACpC;AACA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,oBAAoB;EACpB,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,2CAA2C;EAC3C,cAAc;EACd,qBAAqB;EACrB,iBAAiB;EACjB,uBAAuB;AACzB;AACA;EACE,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,sCAAsC;AACxC;AACA;EACE,iBAAiB;EACjB,gBAAgB;GACf,mBAAmB;;AAEtB;AACA;EACE,cAAc;EACd,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;;AAElB;AACA;EACE,WAAW;EACX,YAAY;EACZ,YAAY;EACZ,oBAAoB;EAKpB,wBAAwB;AAC1B","sourcesContent":[".ol-popup {\n    position: absolute;\n    text-align: center;\n    font: 12px/1.5 \"Helvetica Neue\", Arial, Helvetica, sans-serif;\n    -ms-transform: translate(-50%,-100%); /* IE 9 */\n    -webkit-transform: translate(-50%,-100%); /* Safari */\n    transform: translate(-50%,-100%);\n}\na.ol-popup-close-btn {\n  position: absolute;\n  top: 0;\n  right: 0;\n  padding: 4px 4px 0 0;\n  text-align: center;\n  width: 18px;\n  height: 14px;\n  font: 16px/14px Tahoma, Verdana, sans-serif;\n  color: #c3c3c3;\n  text-decoration: none;\n  font-weight: bold;\n  background: transparent;\n}\n.ol-popup-cnt-wrapper {\n  padding: 1px;\n  text-align: left;\n  border-radius: 12px;\n}\n.ol-popup-cnt-wrapper, .ol-popup-tip {\n  background: white;\n  box-shadow: 0 3px 14px rgba(0,0,0,0.4);\n}\n.ol-popup-cnt {\n  margin: 13px 19px;\n  line-height: 1.4;\n   white-space: nowrap;\n\n}\n.ol-popup-tip-wrapper {\n  margin: 0 auto;\n  width: 40px;\n  height: 20px;\n  position: relative;\n  overflow: hidden;\n\n}\n.ol-popup-tip {\n  width: 17px;\n  height: 17px;\n  padding: 1px;\n  margin: -10px auto 0;\n  -webkit-transform: rotate(45deg);\n  -moz-transform: rotate(45deg);\n  -ms-transform: rotate(45deg);\n  -o-transform: rotate(45deg);\n  transform: rotate(45deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
