import React from 'react';
import { Grid, Typography, Box, CircularProgress } from '@mui/material';
import PopulationDensityImage from '../../image/populationDensityImage.png';
const PopulationDensityStats = ({ isLoading, dataFetchedStatus, detectedData, isToken }) => {
  return (
    <Box className="boundryBox populationStats">
      {console.log(isToken)}

      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <img className="populationImage" src={PopulationDensityImage} alt="" />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6} style={{ marginTop: '30px' }}>
          <Typography variant="h3" gutterBottom>
            Carbon Emission Population Density
          </Typography>
          <p className="head" style={{ marginTop: '10px' }}>
            POPULATION
          </p>
          <span className="value">{isLoading ? <CircularProgress size={20} /> : dataFetchedStatus ? detectedData?.Population : 0}</span>
          <p className="head">CARBON EMISSION METRIC TON/YEAR</p>
          <span className="value">
            {isLoading ? <CircularProgress size={20} /> : dataFetchedStatus ? detectedData?.carbonEmission_metric_ton_per_year : 0}
          </span>
          {console.log("isToken ============",isToken)}
          {isToken != undefined ? (
            <>
              {' '}
              <p className="head" style={{ marginTop: '10px' }}>
                HOUSES
              </p>
              <span className="value">{isLoading ? <CircularProgress size={20} /> : dataFetchedStatus ? detectedData?.Houses : 0}</span>
              <p className="head">HOUSE CARBON EMISSION METRIC TON/YEAR</p>
              <span className="value">
                {isLoading ? (
                  <CircularProgress size={20} />
                ) : dataFetchedStatus ? (
                  detectedData?.House_carbonEmission_metric_ton_per_year
                ) : (
                  0
                )}
              </span>
            </>
          ) : " "}
        </Grid>
      </Grid>
    </Box>
  );
};

export default PopulationDensityStats;
