// Register.js

import Header from 'layout/MainLayout/Header/index';
import React, { useState, useRef, useEffect } from 'react';
import { BaseUrl } from 'config';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  TextField,
  Link,
  Grid,
  InputAdornment,
  IconButton,
  Typography,
  Container,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import BgImage from '../../../src/image/login_bg.png';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Register = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isCaptchaLoading, setIsCaptchaLoading] = useState(true); // State to track loading state of reCAPTCHA
  const [inputValue, setInputValue] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    showPassword: false,
    showConfirmPassword: false
  });

  const [validationErrors, setValidationErrors] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: 'User Login Successfully!',
    status: 'success'
  });
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [resetRecaptcha, setResetRecaptcha] = useState(false);
  const recaptchaRef = useRef();
  useEffect(() => {
    // Function to execute when reCAPTCHA is fully loaded
    const handleRecaptchaLoad = () => {
      setIsCaptchaLoading(false); // Set loading state to false once reCAPTCHA is loaded
    };

    // Dynamically load the reCAPTCHA script
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoadCallback&render=explicit';
    script.async = true;
    document.body.appendChild(script);

    // Set window function to handle reCAPTCHA load callback
    window.onRecaptchaLoadCallback = handleRecaptchaLoad;

    // Cleanup function to remove event listener and window function on component unmount
    return () => {
      document.body.removeChild(script);
      window.onRecaptchaLoadCallback = null;
    };
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleChange = (prop) => (event) => {
    setInputValue({ ...inputValue, [prop]: event.target.value });
    if (prop === 'confirmPassword') {
      if (inputValue.password !== event.target.value) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: 'Passwords do not match'
        }));
      } else {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          confirmPassword: ''
        }));
      }
    } else {
      // Reset validation errors when the user types
      setValidationErrors({ ...validationErrors, [prop]: '' });
    }
    // Reset validation errors when the user types
    // setValidationErrors({ ...validationErrors, [prop]: '' });
  };

  const handlePasswordVisibility = (name) => {
    if (name == 'showPassword') {
      setInputValue({ ...inputValue, [name]: !inputValue.showPassword });
    } else {
      setInputValue({ ...inputValue, [name]: !inputValue.showConfirmPassword });
    }
  };
  function onChange(value) {
    console.log('Captcha value:', value);
    setRecaptchaToken(value);
    setRecaptchaError(false);
  }
  const validateForm = () => {
    let isValid = true;

    // Add your validation logic here for each field
    const requiredFields = ['firstName', 'lastName', 'email', 'password', 'confirmPassword'];

    requiredFields.forEach((field) => {
      if (!inputValue[field]) {
        setValidationErrors((prevErrors) => ({ ...prevErrors, [field]: 'This field is required' }));
        isValid = false;
      }
    });

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputValue.email)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, email: 'Enter a valid email address' }));
      isValid = false;
    }

    // Password validation
    const passwordRegex = /^(?=.*[!@#$%^&*])(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/;
    if (!passwordRegex.test(inputValue.password)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        password: 'Password must contain at least 6 characters, one special character, and a combination of letters and numbers'
      }));
      isValid = false;
    }

    // Confirm password validation
    if (inputValue.password !== inputValue.confirmPassword) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: 'Passwords do not match'
      }));
      isValid = false;
    }
    return isValid;
  };

  const sendEmailVerificationLink = async () => {
    try {
      const response = await axios.post(
        `${BaseUrl}/auth/request-verify-token`,
        { email: inputValue.email },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log('User verification code', response?.data);
      if (response?.data == null) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validate the reCAPTCHA token
    if (!recaptchaToken) {
      setRecaptchaError(true);
      return; // Prevent form submission if reCAPTCHA token is missing
    }
    // Validate before submitting
    if (validateForm()) {
      setIsLoading(true);
      const { firstName, lastName, email, password } = inputValue;
      const requestData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        username: email,
        password: password,
        is_active: true,
        is_superuser: false,
        is_verified: false,
        company_status: false,
        // is_user_approved_by_company: false,
        recaptchaToken: recaptchaToken
      };
      try {
        const response = await axios.post(`${BaseUrl}/auth/register`, requestData, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response?.status === 201 && response?.data) {
          const status = await sendEmailVerificationLink();
          if (status) {
            setIsLoading(false);
            setOpenSnackBar(true);
            setSnackBarMessage({
              message: 'User registered successfully! We have sent verification link to your email address.',
              status: 'success'
            });

            setTimeout(() => {
              navigate('/login');
            }, 2500);
            recaptchaRef.current.reset();
            setResetRecaptcha(true);
            setRecaptchaToken('');
          } else {
            setIsLoading(false);
            setOpenSnackBar(true);
            setSnackBarMessage({
              message: 'Something went wrong. Try again later',
              status: 'error'
            });
          }
        }
      } catch (error) {
        setIsLoading(false);
        setOpenSnackBar(true);
        if (error?.response?.data?.detail === 'REGISTER_USER_ALREADY_EXISTS') {
          setSnackBarMessage({
            message: 'User already registered.',
            status: 'error'
          });
        } else if (error?.response?.data?.detail?.code === 'REGISTER_INVALID_PASSWORD') {
          setSnackBarMessage({
            message: 'Password should be at least 3 characters',
            status: 'error'
          });
        } else {
          setSnackBarMessage({
            message: 'Something went wrong. Try again later',
            status: 'error'
          });
        }
      }
    }
  };

  return (
    <>
      <Header />
      <div className="loginreg">
        <img src={BgImage} alt="" />
        <Grid container>
          <Grid item lg={5} md={5} xs={0}></Grid>
          <Grid item lg={6} md={7} xs={12} className="log_spacing">
            <Container component="main" maxWidth="xs">
              <Typography component="h1" variant="h5" className="heading">
                New here?
              </Typography>
              <Typography component="h3" variant="h5" className="small-heading">
                Signing up is easy. It takes only a few steps
              </Typography>
              <form style={{ width: '100%' }} className="myInput" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      label="First Name"
                      fullWidth
                      required
                      value={inputValue.firstName}
                      onChange={handleChange('firstName')}
                      margin="normal"
                      variant="outlined"
                      error={!!validationErrors.firstName}
                      helperText={validationErrors.firstName}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { backgroundColor: 'white' } }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      label="Last Name"
                      fullWidth
                      required
                      value={inputValue.lastName}
                      onChange={handleChange('lastName')}
                      margin="normal"
                      variant="outlined"
                      error={!!validationErrors.lastName}
                      helperText={validationErrors.lastName}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { backgroundColor: 'white' } }}
                    />
                  </Grid>
                </Grid>

                {/* <TextField
                label="Username"
                fullWidth
                required
                value={inputValue.username}
                onChange={handleChange('username')}
                margin="normal"
                variant="outlined"
                error={!!validationErrors.username}
                helperText={validationErrors.username}
                InputLabelProps={{ shrink: true }}
                inputProps={{ style: { backgroundColor: 'white' } }}
              /> */}

                <TextField
                  label="Email"
                  type="email"
                  fullWidth
                  required
                  value={inputValue.email}
                  onChange={handleChange('email')}
                  margin="normal"
                  variant="outlined"
                  error={!!validationErrors.email}
                  helperText={validationErrors.email}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ style: { backgroundColor: 'white' } }}
                />

                <TextField
                  label="Password"
                  type={inputValue.showPassword ? 'text' : 'password'}
                  fullWidth
                  required
                  value={inputValue.password}
                  onChange={handleChange('password')}
                  margin="normal"
                  variant="outlined"
                  error={!!validationErrors.password}
                  helperText={validationErrors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => handlePasswordVisibility('showPassword')} tabIndex="-1">
                          {inputValue.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ style: { backgroundColor: 'white' } }}
                />

                <TextField
                  label="Confirm Password"
                  type={inputValue.showConfirmPassword ? 'text' : 'password'}
                  fullWidth
                  required
                  value={inputValue.confirmPassword}
                  onChange={handleChange('confirmPassword')}
                  margin="normal"
                  variant="outlined"
                  error={!!validationErrors.confirmPassword}
                  helperText={validationErrors.confirmPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={() => handlePasswordVisibility('showConfirmPassword')} tabIndex="-1">
                          {inputValue.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  InputLabelProps={{ shrink: true }}
                  inputProps={{ style: { backgroundColor: 'white' } }}
                />
                <Grid item xs={12}>
                  {isCaptchaLoading ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : (
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      // sitekey="6LdwA-ApAAAAAH563WW4vxWvcCzmXVbj01ME4DgO"
                      //sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // use this token for local
                      sitekey="6Ld3THgkAAAAANncBZ0lLFUeMUnoCjJOOa4bRoux"
                      onChange={onChange}
                      onExpired={() => {
                        setRecaptchaToken('');
                        setRecaptchaError(true);
                      }}
                      hl="en"
                      size="normal"
                      tabIndex={0}
                      reset={resetRecaptcha}
                      style={{ color: 'transparent', marginTop: '10px', marginBottom: '10px' }} // Hide the "Privacy" and "Terms" text
                    />
                  )}

                  {recaptchaError && <span style={{ color: 'red', marginBottom: '10px' }}>Please complete the reCAPTCHA challenge</span>}
                  <br />
                  <Button type="submit" className="login_button" variant="contained" size="large">
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Register'}
                  </Button>
                </Grid>
                <Grid container sx={{ mb: 1 }}>
                  <Grid item xs>
                    <Link href="login" className="form_text">
                      Already have an account?{' '}
                      <span style={{ color: '#51970F', fontWeight: 'bold', textDecoration: 'underline' }}>Login</span>
                    </Link>
                  </Grid>
                  <Grid item>
                    {/* <Button type="submit" className="login_button" variant="contained" size="large">
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Register'}
                  </Button> */}
                  </Grid>
                </Grid>
              </form>
            </Container>
          </Grid>
          <Grid item lg={1} md={0} xs={0}></Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          style={{ marginTop: 50 }}
          open={openSnackBar}
          autoHideDuration={10000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
            {snackBarMessage.message}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default Register;
