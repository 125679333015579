// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div#map {
    position: absolute;
}

/** Move and re-style scalebar */
div.ol-scale-line {
    z-index: 101;
    bottom: 3px !important;
    left: 3px!important;
    background-color: transparent!important;
}

div.ol-scale-line > div.ol-scale-line-inner {
    color: var(--panel-text-color)!important;
    border-color: var(--panel-text-color)!important;
}

/* Move and re-style rotation reset button */
div.ol-rotate {
    padding: 0;
    top: 4em!important;
    right: 0.5em!important;
}

div.ol-rotate button {
    font-size: 1.25em!important;
    background-color: var(--panel-text-color)!important;
}

div.ol-overlaycontainer-stopevent {
    z-index: 101!important;
}

span.map-loading-indicator {
    display: flex;
    align-items: center;
    opacity: 0;
    transition: opacity 0.5s;
    color: var(--panel-text-color);
    background-color: var(--panel-bg-color);
    box-shadow: 0 -2px 4px rgba(136, 136, 136, 0.5);
    position: absolute;
    bottom: 2.7em;
    left: 0.25em;
    padding: 0.25em;
    border-radius: 0.25em;
    font-size: small;
}

span.map-loading-indicator > div.spinner {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.25em;
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/plugins/style/Map.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA,gCAAgC;AAChC;IACI,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,uCAAuC;AAC3C;;AAEA;IACI,wCAAwC;IACxC,+CAA+C;AACnD;;AAEA,4CAA4C;AAC5C;IACI,UAAU;IACV,kBAAkB;IAClB,sBAAsB;AAC1B;;AAEA;IACI,2BAA2B;IAC3B,mDAAmD;AACvD;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,wBAAwB;IACxB,8BAA8B;IAC9B,uCAAuC;IACvC,+CAA+C;IAC/C,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,eAAe;IACf,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,oBAAoB;AACxB","sourcesContent":["div#map {\n    position: absolute;\n}\n\n/** Move and re-style scalebar */\ndiv.ol-scale-line {\n    z-index: 101;\n    bottom: 3px !important;\n    left: 3px!important;\n    background-color: transparent!important;\n}\n\ndiv.ol-scale-line > div.ol-scale-line-inner {\n    color: var(--panel-text-color)!important;\n    border-color: var(--panel-text-color)!important;\n}\n\n/* Move and re-style rotation reset button */\ndiv.ol-rotate {\n    padding: 0;\n    top: 4em!important;\n    right: 0.5em!important;\n}\n\ndiv.ol-rotate button {\n    font-size: 1.25em!important;\n    background-color: var(--panel-text-color)!important;\n}\n\ndiv.ol-overlaycontainer-stopevent {\n    z-index: 101!important;\n}\n\nspan.map-loading-indicator {\n    display: flex;\n    align-items: center;\n    opacity: 0;\n    transition: opacity 0.5s;\n    color: var(--panel-text-color);\n    background-color: var(--panel-bg-color);\n    box-shadow: 0 -2px 4px rgba(136, 136, 136, 0.5);\n    position: absolute;\n    bottom: 2.7em;\n    left: 0.25em;\n    padding: 0.25em;\n    border-radius: 0.25em;\n    font-size: small;\n}\n\nspan.map-loading-indicator > div.spinner {\n    width: 1.5em;\n    height: 1.5em;\n    margin-right: 0.25em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
