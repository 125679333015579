import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Container, Grid } from '@mui/material';
import { styled } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CustomAccordion = styled(Accordion)(({ theme }) => ({
  border: '1px solid #ddd',
  boxShadow: 'none',
  marginBottom: theme.spacing(2),
  '&:before': {
    display: 'none'
  },
  '&.Mui-expanded': {
    margin: 0
  }
}));

const CustomAccordionSummary = styled(AccordionSummary)(() => ({
  backgroundColor: '#f5f5f5',
  borderBottom: '1px solid #ddd',
  minHeight: 56,
  '&.Mui-expanded': {
    minHeight: 56
  }
}));

const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2)
}));

const FAQ = () => {
  const [expanded, setExpanded] = useState('panel1');

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container className="faq" id="faq">
      <Typography variant="h1" className="heading" style={{ marginBottom: 50, color:'rgb(37,120,116)' }}>
        FAQ<span style={{fontSize:'27px', fontWeight:'700'}}>S</span>
      </Typography>

  
      <CustomAccordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
        <CustomAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
          <Typography variant="h6">What is carbon sequestration?</Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Typography>
            Carbon sequestration is the process of capturing and storing atmospheric carbon dioxide. It aims to reduce the amount of CO₂ in
            the atmosphere, contributing to global climate change mitigation.
          </Typography>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
        <CustomAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
          <Typography variant="h6">What are the major types of carbon sequestration?</Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Typography>
            There are two main types: <br />
            <b>Geologic:</b> Storing CO₂ underground in rock formations.<br/>
            <b>Biologic:</b> Capturing carbon through natural processes like forests and wetlands.
          </Typography>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')}>
        <CustomAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
          <Typography variant="h6">How does carbon get into the atmosphere?</Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Typography>
            Natural sources (like animal respiration) and human activities (such as burning fossil fuels) release carbon dioxide into the
            air.
          </Typography>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={expanded === 'panel4'} onChange={handleAccordionChange('panel4')}>
        <CustomAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
          <Typography variant="h6">How much CO₂ can the U.S. store via geologic sequestration?</Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Typography>
            The U.S. has a mean storage potential of 3,000 metric gigatons of CO₂. The Coastal Plains region (from Texas to Georgia)
            accounts for 65% of this potential.
          </Typography>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={expanded === 'panel5'} onChange={handleAccordionChange('panel5')}>
        <CustomAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
          <Typography variant="h6">How much CO₂ does the U.S. emit annually from energy sources?</Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Typography>
            In 2019, the U.S. emitted 5,130 million metric tons of energy-related CO₂. Globally, energy-related emissions totaled 33,621.5
            million metric tons.
          </Typography>
        </CustomAccordionDetails>
      </CustomAccordion>

      <CustomAccordion expanded={expanded === 'panel6'} onChange={handleAccordionChange('panel6')}>
        <CustomAccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
          <Typography variant="h6">Has the USGS assessed biologic carbon sequestration?</Typography>
        </CustomAccordionSummary>
        <CustomAccordionDetails>
          <Typography>
            Yes, the USGS has conducted assessments for various regions, including Alaska, the Eastern U.S., the Great Plains, and the
            Western U.S.
          </Typography>
        </CustomAccordionDetails>
      </CustomAccordion>

    </Container>
  );
};

export default FAQ;
