// material-ui
import React, { useEffect, useState } from 'react';
import { Grid, Box, Container } from '@mui/material';
import Image from '../../../image/footer.png';

const Footer = () => {
  const [hasScrollbar, setHasScrollbar] = useState(false);

  // useEffect(()=>{
  //   const bodyHeight = document.body.scrollHeight;
  //   const innerHeight = window.innerHeight;
  //   if (bodyHeight <= innerHeight) {
  //     console.log("inner height less")
  //     setHasScrollbar(true)
  //   } else {
  //     console.log("inner height high")
  //     setHasScrollbar(false)
  //   }
  
  // },[hasScrollbar])

  useEffect(() => {
    const handleResize = () => {
      const bodyHeight = document.body.scrollHeight;
      const innerHeight = window.innerHeight;
      if(bodyHeight<=innerHeight){
        setHasScrollbar(true)
      }
      else{
        setHasScrollbar(false)
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener on component unmount
    return () => window.removeEventListener('resize', handleResize);
  }, [hasScrollbar]);

    // console.log("height", document.body.scrollHeight);

 
  return (
    <>
      <Grid container className={hasScrollbar ? 'footer custom_footer' : 'footer auto_footer'}>
        <img src={Image} alt="Carbon Footprint" className="footerphone" />
        <Box  className="footer-text">
          <span>&copy; copyright 2024 Carbon Footprint. All right reserved.</span>
          <span className='footer_powered'>Powered By Digital Glyde</span>
        </Box>
      </Grid>
    </>
  );
};

export default Footer;
