import React from 'react';
import { Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="section">
      <h1 className="error">404</h1>
      <div className="page">Ooops!!! The page you are looking for is not found</div>
      <Button startIcon={<HomeIcon />} className='404button' variant="contained" color="primary" onClick={()=>navigate('/')}>
        Back To Home
      </Button>
    </div>
  );
};

export default PageNotFound;
