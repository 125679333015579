import React, { useState, useEffect } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TextField,
  Grid,
  Container,
  Typography,
  Box
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useSelector } from 'react-redux';
import PrintIcon from '@mui/icons-material/Print';
import { SaveAlt } from '@mui/icons-material'; // Import SaveAlt for CSV icon
import { GetApp } from '@mui/icons-material'; // Import GetApp for download icon
import jsPDF from 'jspdf';

let sortedData = [];

const PaymentHistory = () => {
  const { paymentHistoryInfo } = useSelector((state) => state.menu);
  const [orderBy, setOrderBy] = useState('requested_time');
  const [sortDirection, setSortDirection] = useState('desc');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [searchTerm, setSearchTerm] = useState(''); // Add this line for search functionality
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (paymentHistoryInfo) {
      setIsLoading(false); // Once data is available, set loading state to false
    }
  }, [paymentHistoryInfo]);
  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && sortDirection === 'asc';
    setOrderBy(columnId);
    setSortDirection(isAsc ? 'desc' : 'asc');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const downloadPDF = (item) => {
    const doc = new jsPDF();

    // Set page dimensions
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();

    // Draw outer border
    doc.rect(5, 5, pageWidth - 10, pageHeight - 10);

    // Set initial y-coordinate for text
    let yPos = 20;

    // Add Payment Invoice heading
    doc.setFontSize(16);
    doc.text('Payment Invoice', 10, yPos);

    // Increment y-coordinate for next line
    yPos += 10;

    // Set font size for field names and values
    doc.setFontSize(12);

    // Map of custom field names to corresponding keys
    const fieldNames = {
      'Job Id': 'job_id',
      Name: 'payer',
      // 'Job Payment Id': 'job_payment_id',
      Method: 'method',
      'Merchant Id': 'merchant_id',
      'Payer Id': 'payer_id',
      // 'Email Address': 'email_address',
      'Amount (in $)': 'payment_amount',
      'OrderId': 'payment_authorization_code',
      Status: 'payment_status',
      Date: 'payment_time'
    };

    // Iterate over custom field names and add each key-value pair to the PDF
    Object.entries(fieldNames).forEach(([fieldName, key]) => {
      // Add field name on the left
      doc.text(`${fieldName}:`, 10, yPos);

      // Add corresponding value on the right
      const value = key === 'payment_time' ? getDate(item[key]) : item[key];
      doc.text(`${value}`, 70, yPos);

      yPos += 10; // Increment y-coordinate for the next line
    });

    // Save PDF
    doc.save(`payment_details_${item?.job_name}.pdf`);
  };

  // Filter data based on search term

  const filteredData = paymentHistoryInfo?.filter((item) =>
    Object.values(item).some((value) => {
      if (typeof value === 'string') {
        return value.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    })
  );

  if (filteredData?.length > 0) {
    sortedData = [...filteredData].sort((a, b) => {
      const dateA = new Date(a.requested_time);
      const dateB = new Date(b.requested_time);

      // Sort in descending order based on the dates
      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });
  } else {
    sortedData = [];
  }

  const getDate = (timestampString) => {
    const timestamp = new Date(timestampString);
    // Format for the date (e.g., Feb 12, 2024)
    const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = timestamp.toLocaleDateString('en-US', dateOptions);
    return formattedDate;
  };
  const exportToCSV = () => {
    // Define constant array of headers
    const headers = ['Payer', 'Job Id', 'Method', 'Payment Amount', 'Payment Authorization Code', 'Payment Status', 'Date'];
    console.log('sortedData---------', sortedData);
    // Generate CSV data based on constant headers
    const csvData = [
      headers.join(','),
      ...sortedData.map((item) =>
        headers
          .map((header) => {
            const key = header.toLowerCase().replace(/\s/g, '_'); // Convert header to corresponding object key
            const value = key === 'date' ? `"${getDate(item.payment_time)}"` : item[key];
            return value;
          })
          .join(',')
      )
    ].join('\n');

    // Add metadata for auto-sizing columns
    const autoSizeMetadata = '\ufeff';

    // Create and download CSV file
    const csvBlob = new Blob([autoSizeMetadata, csvData], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(csvBlob);
    link.download = 'payment_history.csv';
    link.click();
  };

  return (
    <Container fixed className="joblist">
      <Box className="joblist_heading" style={{ position: 'relative' }}>
        <Grid container>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <h1 className="sub-heading">Payment History</h1>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              size="small"
              sx={{ mt: 2 }}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputLabelProps={{ shrink: true }}
              inputProps={{ style: { padding: '3px 10px' } }}
            />
          </Grid>
        </Grid>
        <SaveAlt onClick={exportToCSV} className="exportXL" />
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {/* Render your table headers here */}
              <TableCell style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }} onClick={() => handleSort('payer')}>
                Name
                {orderBy === 'payer' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell>
              <TableCell
                style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                onClick={() => handleSort('job_payment_id')}
              >
                Job Name {orderBy === 'job_name' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell>
              {/* <TableCell
                style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                onClick={() => handleSort('method')}
              >
                Method
                {orderBy === 'method' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell> */}
              <TableCell
                style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                onClick={() => handleSort('payment_amount')}
              >
                Amount (in $)
                {orderBy === 'payment_amount' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell>
              <TableCell
                style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                onClick={() => handleSort('payment_authorization_code')}
              >
                Order Id
                {orderBy === 'payment_authorization_code' && (
                  <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>
                )}
              </TableCell>
              <TableCell
                style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                onClick={() => handleSort('payment_status')}
              >
                Status
                {orderBy === 'payment_status' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell>
              <TableCell
                style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                onClick={() => handleSort('payment_time')}
              >
                Date
                {orderBy === 'payment_time' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableCell colSpan={8} align="center">
              <CircularProgress />
            </TableCell>
          ) : (
            // <CircularProgress size={40} color="inherit" />
            <>
              {sortedData?.length === 0 ? (
                <TableCell colSpan={8} align="center">
                  <Typography variant="h4" color="green" component="span" style={{ marginLeft: '0px', lineHeight: 4 }}>
                    No Records Found!
                  </Typography>
                </TableCell>
              ) : (
                <>
                  <TableBody>
                    {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{item.payer}</TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            {item.job_name}
                          </TableCell>
                          {/* <TableCell style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            {item.method}
                          </TableCell> */}
                          <TableCell style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            {item.payment_amount}
                          </TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            {item.payment_authorization_code}
                          </TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            {item.payment_status}
                          </TableCell>
                          <TableCell style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            {getDate(item.payment_time)}
                          </TableCell>
                          <TableCell>
                            <GetApp onClick={() => downloadPDF(item)} style={{ cursor: 'pointer' }} />
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </>
              )}
            </>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        className="page_btn"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
};

export default PaymentHistory;
