import React, { useState, useEffect } from 'react';
import { Card, Container, Button, Snackbar, Alert, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { BaseUrl } from 'config';
import SessionExpired from 'pages/session/sessionExpired';
import Unverified from '../../image/unverifed.jpg';
import Verified from '../../image/verified.png';
import { color } from 'openlayers';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress } from '../../../node_modules/@mui/material/index';
import { userDetails } from 'store/reducers/menu';

const UnverifiedUser = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { user, TOKEN } = useSelector((state) => state.menu);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: '',
    status: 'info' // Default to info status
  });
  const [isEmailSend, setIsEmailSend] = useState(false);
  const [isUserVerifiedStatus, setIsUserVerifiedStatus] = useState(false);

  const handleSessionExpiredClose = (status) => {
    setSessionExpired(status);
  };

  const handleStatusCheck = async () => {
    // console.log('handleStatusCheck---------------', user);

    try {
      const response = await axios.post(
        `${BaseUrl}/user_verfied_result`,
        { id: user && user.id },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`
          }
        }
      );
      // console.log('User verification status', response);
      if (response?.status === 200 && response?.data?.is_verified === true) {
        setIsEmailSend(false);
        setIsUserVerifiedStatus(true);
        dispatch(
          userDetails({
            ...user,
            is_verified: true
          })
        );
        setTimeout(() => {
          navigate('/payment', { state: { jobGeoJson: location?.state?.jobGeoJson, locationList: location?.state?.locationList } });
        }, 1500);
      }
    } catch (error) {
      console.error('Error sending email verification:', error);
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired. Please login again to continue.',
          status: 'error'
        });
      } else {
        setSnackBarMessage({
          message: 'Something went wrong. Please try again later!',
          status: 'error'
        });
      }
      return false;
    }
  };

  const sendEmailVerificationLink = async () => {
    try {
      const response = await axios.post(
        `${BaseUrl}/auth/request-verify-token`,
        { email: user && user.email },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      // console.log('User verification code inside unverified', response?.data);
      return response?.data === null;
    } catch (error) {
      console.error('Error sending email verification:', error);
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired. Please login again to continue.',
          status: 'error'
        });
      } else {
        setSnackBarMessage({
          message: 'Something went wrong. Please try again later!',
          status: 'error'
        });
      }
      return false;
    }
  };

  const handleClick = async () => {
    const sendMail = await sendEmailVerificationLink();
    // console.log('sendMail==========', sendMail);
    if (sendMail) {
      setSnackBarMessage({
        message: 'You have sent an email for verification. Please check and verify.',
        status: 'success'
      });
      setIsEmailSend(true);
      // setTimeout(() => {
      //   navigate('/login');
      // }, 3000); // Snackbar auto close after 6 seconds
    }
  };

  const handleCloseAlert = () => {
    setSnackBarMessage({ ...snackBarMessage, message: '', status: 'info' });
  };

  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (!document.hidden && isEmailSend) {
        await handleStatusCheck();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isEmailSend]);

  return (
    <Container fixed>
      <div className="addjobs" style={{ color: 'grey' }}>
        {!isEmailSend && !isUserVerifiedStatus ? (
          <Card style={{ padding: 30, textAlign: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
              <img src={Unverified} width={50} />
            </div>
            <h5 style={{ color: '#cc0001', marginTop: -5 }}>User Not verified</h5>
            <h4 style={{ marginBottom: 20 }}>
              To get job details, user must be verified. Please click below button to get verified and have job details.
            </h4>
            <Button type="submit" variant="contained" color="primary" onClick={handleClick}>
              Click here
            </Button>
          </Card>
        ) : isUserVerifiedStatus ? (
          <Card style={{ padding: 30, textAlign: 'center', marginTop: '100px' }}>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
              <img src={Verified} width={100} />
            </div>
            <Typography component="h1" variant="h5" className="heading">
              Your account is successfully verified!
            </Typography>
          </Card>
        ) : (
          <Card style={{ padding: 30, textAlign: 'center', marginTop: '100px' }}>
            <CircularProgress />
            <Typography component="h1" variant="h5" className="heading">
              Waiting for verfication your account..
            </Typography>
            <Typography component="h1" variant="p">
              We have sent a verification link to your registered email address. Please check your inbox (and spam/junk folder) for an email
              from us containing the link. This step is essential to confirm your identity and secure your account.
            </Typography>
          </Card>
        )}
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{marginTop:50}}
        open={!!snackBarMessage.message}
        autoHideDuration={10000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>

      {sessionExpired && <SessionExpired handleSessionExpiredClose={handleSessionExpiredClose} />}
    </Container>
  );
};

export default UnverifiedUser;
