// project import
import pages from './pages';
import mainmenu, {loginMenu} from './dashboard';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
let webPage = [mainmenu, loginMenu];

// ==============================|| MENU ITEMS ||============================== //
const Page = () => {
  const { isLoggedIn } = useSelector((state) => state.menu);
  useEffect(() => {
    if (!isLoggedIn && !webPage.includes(pages)) {
      webPage.push(pages);
    }
  });

  return <></>;
};


const menuItems = {
  items: webPage
};

export { Page };
export default menuItems;
