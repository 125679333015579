import React, { useState, useEffect, useRef } from 'react';
import {
  TextField,
  Button,
  Typography,
  Paper,
  Grid,
  Box,
  Container,
  FormControl,
  Snackbar,
  CircularProgress,
  Alert,
  IconButton,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import axios from 'axios';
import { BaseUrl } from 'config';
import { useSelector, useDispatch } from 'react-redux';
import SessionExpired from 'pages/session/sessionExpired';
import { notificationDetails, userDetails } from 'store/reducers/menu';
import PayPal from './paypal';

const PaymentScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { TOKEN, user } = useSelector((state) => state.menu);
  const [jobName, setJobName] = useState(location?.state?.unprocessedJobName ? location?.state?.unprocessedJobName : '');
  const [jobId, setJobId] = useState('');
  const [cardHolderName, setCardHolderName] = useState('');
  const [cardNumber, setCardNumber] = useState('');
  const [cardExpiry, setCardExpiry] = useState('');
  const [cvv, setCvv] = useState('');
  const [paidJobs, setPaidJobs] = useState([]);
  const [paymentDetails] = useState({
    job_id: '',
    payment_amount: '',
    payment_time: '2024-02-21 05:08:01.245705',
    method: 'Card',
    payment_authorization_code: 'yfeyafgyidsgflsfds',
    payment_related_information: ''
  });
  const [jobNameError, setJobNameError] = useState('');
  const [cardNumberError, setCardNumberError] = useState('');
  const [cardExpiryError, setCardExpiryError] = useState('');
  const [cvvError, setCvvError] = useState('');
  const [cardHolderNameError, setCardHolderNameError] = useState('');
  const [isProceedToPayLoading, setProceedToPayLoading] = useState(false);
  const [isPayLaterLoading, setPayLaterLoading] = useState(false);
  const [entPayLoading, setEntPayLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentContainer, setPaymentContainer] = useState(false);
  const [showCvv, setShowCvv] = useState(false);
  const [expandedAccordion, setExpandedAccordion] = useState('panel1');
  const [locationList] = useState(location);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [jobSubmitStatus, setJobSubmitStatus] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [jobSubmitSuccess, setJobSubmitSuccess] = useState(false);
  const [orderApiResChild, setOrderApiResChild] = useState(null);
  const [paymentOrderId, setPaymentOrderId] = useState('');
  // const [orderApiRes, setOrderApiRes] =useState()
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: '',
    status: ''
  });
  const userPlan = user.plan != null && user.plan;
  const orderApiResRef = useRef(null);
  const orderApiPayDeatilRef = useRef(null);

  const userCmpId = user?.company_id;
  let userCmpStatus = user?.company_status;
  const [sessionExpired, setSessionExpired] = useState(false);
  const handleBackOrTabChange = () => {
    handleAddToCart();
    setShowPayment(false);
  };
  const handleOrderApiRes = (paymentReq) => {
    setPaymentOrderId(paymentReq?.orderID);
    orderApiResRef.current = paymentReq?.orderID;
    orderApiPayDeatilRef.current = paymentReq;
    setOrderApiResChild(paymentReq);
  };
  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpandedAccordion(isExpanded ? panel : null);
  };

  const handleSessionExpiredClose = (status) => {
    setSessionExpired(status);
  };

  const validateJobName = () => {
    if (!jobName.trim()) {
      setJobNameError('Job Name is required');
      return false;
    }
    setJobNameError('');
    return true;
  };

  const handleCvvVisibilityToggle = () => {
    setShowCvv(!showCvv);
  };

  const handleCardNumberChange = (e) => {
    const sanitizedInput = e.target.value.replace(/\D/g, '');
    const limitedInput = sanitizedInput.slice(0, 16);
    let formattedInput = '';
    for (let i = 0; i < limitedInput.length; i += 4) {
      formattedInput += limitedInput.slice(i, i + 4) + '-';
    }
    formattedInput = formattedInput.slice(0, -1);
    setCardNumber(formattedInput);
  };

  const handleCardExpiryChange = (e) => {
    const sanitizedInput = e.target.value.replace(/\D/g, '');
    const formattedInput = sanitizedInput.replace(/(\d{2})/, '$1/').slice(0, 5);
    setCardExpiry(formattedInput);
  };

  const handleCvvChange = (e) => {
    const digit = e.target.value.slice(0, 3);
    setCvv(digit);
  };

  const validateCardNumber = () => {
    const cardNumberRegex = /^\d{4}-\d{4}-\d{4}-\d{4}$/;
    if (!cardNumber.match(cardNumberRegex)) {
      setCardNumberError('Invalid card number format');
      return false;
    }
    setCardNumberError('');
    return true;
  };

  const validateCardExpiry = () => {
    const cardExpiryRegex = /^(0[1-9]|1[0-2])\/\d{2}$/;
    console.log('sdfsd', isProceedToPayLoading, !cardExpiry.match(cardExpiryRegex));
    if (!cardExpiry.match(cardExpiryRegex)) {
      setCardExpiryError('Invalid expiry date format');
      return false;
    }
    setCardExpiryError('');
    return true;
  };

  const validateCVV = () => {
    const cvvRegex = /^\d{3}$/;
    if (!cvv.match(cvvRegex)) {
      setCvvError('Invalid CVV format');
      return false;
    }
    setCvvError('');
    return true;
  };

  const validateCardHolderName = () => {
    if (!cardHolderName.trim()) {
      setCardHolderNameError('Card Holder Name is required');
      return false;
    }
    setCardHolderNameError('');
    return true;
  };

  const handleMakePayment = async () => {
    const isCardHolderNameValid = validateCardHolderName();
    const isCardNumberValid = validateCardNumber();
    const isCardExpiryValid = validateCardExpiry();
    const isCVVValid = validateCVV();

    if (isCardHolderNameValid && isCardNumberValid && isCardExpiryValid && isCVVValid) {
      //payment gateway logic here

      setIsLoading(true);
      try {
        const response = await axios.post(
          `${BaseUrl}/payment_details`,
          {
            ...paymentDetails,
            job_id: jobId,
            payment_amount: Number(
              locationList?.state?.locationList?.reduce((acc, location) => acc + Number(location.calculated_cost), 0).toFixed(2)
            ),
            payment_time: new Date(),
            payment_related_information: cardHolderName
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${TOKEN}`
            }
          }
        );

        if (response?.status === 200 && response?.data == null) {
          setExpandedAccordion(null);
          setCardHolderName('');
          setCardNumber('');
          setCardExpiry('');
          setCvv('');

          //once payment done call the steps of paid jobs api
          generateJobSteps();
        }
      } catch (error) {
        setOpenSnackBar(true);
        setIsLoading(false);
        if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
          setSessionExpired(true);
          setSnackBarMessage({
            message: 'Your session expired please login again to continue',
            status: 'error'
          });
        } else {
          setSnackBarMessage({
            message: 'Something went wrong. Try again later!',
            status: 'error'
          });
        }
      }
    }
  };

  const getNotification = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}/notifications`,
        // { user_id: user.id },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`
          }
        }
      );
      if (response?.status === 200 && response?.data?.length > 0) {
        dispatch(notificationDetails(response?.data));
      }
    } catch (error) {
      setOpenSnackBar(true);
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired please login again to continue',
          status: 'error'
        });
      } else {
        setSnackBarMessage({
          message: 'Something went wrong. Try again later!not',
          status: 'error'
        });
      }
    }
  };

  const handleJobSubmit = async (orderId) => {
    //console.log(orderId);
    const status = validateJobName();
    if (!status) {
      return;
    }

    let geojson;

    if (user.plan != 'ENT') {
      geojson = {
        // ...locationList?.state?.jobGeoJson,
        calculated_cost: locationList?.state?.locationList?.reduce((acc, location) => acc + Number(location.calculated_cost), 0),
        job_name: jobName,
        area_sq_mile: locationList?.state?.locationList?.reduce((acc, location) => acc + Number(location.location_area_sq_mile), 0),
        job_id: location?.state?.jobGeoJson?.job_id,
        order_id: orderId
      };
    } else {
      geojson = {
        // ...locationList?.state?.jobGeoJson,
        calculated_cost: locationList?.state?.locationList?.reduce((acc, location) => acc + Number(location.calculated_cost), 0),
        job_name: jobName,
        area_sq_mile: locationList?.state?.locationList?.reduce((acc, location) => acc + Number(location.location_area_sq_mile), 0),
        job_id: location?.state?.jobGeoJson?.job_id
      };
    }

    try {
      const response = await axios.post(`${BaseUrl}/job/submitjobrequest`, geojson, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TOKEN}`
        }
      });

      if (response?.status === 200) {
        const Job_Id = response?.data?.job_id;
        setJobId(Job_Id);
        await getNotification();
        if (response?.data?.message == 'Added to cart notification-user_plan ENT - company_status: False - user_verified : True  ') {
          if (user.plan === 'ENT') {
            setOpenSnackBar(true);
            setSnackBarMessage({
              message: 'Due to company dormancy, you could not proceed further. Please contact the administrator!',
              status: 'error'
            });
          } else {
            setOpenSnackBar(true);
            setSnackBarMessage({
              message: 'Job successfully added to your unbilled list!',
              status: 'success'
            });
          }

          setTimeout(() => {
            navigate('/joblists');
          }, 2000);

          return;
        } else if (userPlan == 'PPU' && !response?.data?.message) {
          while (!orderApiPayDeatilRef.current) {
            await new Promise((resolve) => setTimeout(resolve, 100)); // Polling interval
          }
          const payDetail = orderApiPayDeatilRef.current;
          setTimeout(() => {
            navigate('/payment_detail', { state: { data: payDetail }, replace: true });
          }, 2000);
          return;
        }
        setOpenSnackBar(true);

        setSnackBarMessage({
          message: 'Job successfully added to your job list!',
          status: 'success'
        });
        setTimeout(() => {
          navigate('/joblists');
        }, 1000);

        setProceedToPayLoading(false);
        setPayLaterLoading(false);
        setEntPayLoading(false);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };
  // Function to handle tab changes
  const handleTabChange = () => {
    if (!orderApiResRef.current && showPayment) {
      handleAddToCart();
      setShowPayment(false);
    }
  };

  // Set up event listener for visibility change
  useEffect(() => {
    const handleVisibilityChange = () => {
      console.log(document.hidden);
      if (document.hidden) {
        handleTabChange();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

 

  const handleProceedToPay = async () => {
    const status = validateJobName();
    if (!status) {
      return;
    }
    setShowPayment(true);
    const updatedPaidJobs = [...paidJobs, location?.state?.jobGeoJson?.job_id];
    //let storedPaidJobs = updatedPaidJobs;
    console.log(updatedPaidJobs);
    setPaidJobs(updatedPaidJobs);
    // Wait until paymentOrderId is set
    while (!orderApiResRef.current) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // Polling interval
    }
    let orderId = orderApiResRef.current;
    console.log(orderApiResRef.current);
    handleJobSubmit(orderId);
  };
  const getJobDetails = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${BaseUrl}/home/jobslist`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TOKEN}`
        }
      });
      console.log('JobDetails response', response);
    } catch (error) {
      console.log('errror of JobDetails', error);
      setIsLoading(false);
      setOpenSnackBar(true);
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired please login again to continue',
          status: 'error'
        });
      } else {
        setSnackBarMessage({
          message: 'Something went wrong. Try again later!',
          status: 'error'
        });
      }
    }
  };
  const handleAddToCart = async () => {
    const status = validateJobName();
    if (!status) {
      return;
    }
    if (!user.is_verified) {
      setOpenSnackBar(true);
      setSnackBarMessage({
        message: 'You have to verify your account.',
        status: 'error'
      });
      setTimeout(() => {
        navigate('/unverified_user');
      }, 1000);
      return;
    }

    const geojson = {
      calculated_cost: locationList?.state?.locationList?.reduce((acc, location) => acc + Number(location.calculated_cost), 0),
      job_name: jobName,
      area_sq_mile: locationList?.state?.locationList?.reduce((acc, location) => acc + Number(location.location_area_sq_mile), 0),
      job_id: locationList?.state?.jobGeoJson?.job_id
    };

    try {
      const response = await axios.post(`${BaseUrl}/home/addToCart`, geojson, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${TOKEN}`
        }
      });

      if (response?.status === 200) {
        await getNotification();
        await getJobDetails();
        // Handle different response messages
        if (response.data.message.includes('notification-user_plan ENT')) {
          if (user.plan === 'ENT' && response.data.company_status === false) {
            setOpenSnackBar(true);
            setSnackBarMessage({
              message: 'Due to company dormancy, you could not proceed further. Please contact the administrator!',
              status: 'error'
            });
          } else {
            setOpenSnackBar(true);
            setSnackBarMessage({
              message: 'Job successfully added to your unbilled list!',
              status: 'success'
            });
          }
        } else {
          setOpenSnackBar(true);
          setSnackBarMessage({
            message: 'Job successfully added to your unbilled list!',
            status: 'success'
          });
        }

        setTimeout(() => {
          navigate('/joblists');
        }, 1000);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  };

  const handleErrorResponse = (error) => {
    setProceedToPayLoading(false);
    setPayLaterLoading(false);
    setEntPayLoading(false);
    setOpenSnackBar(true);
    if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
      setSessionExpired(true);
      setSnackBarMessage({
        message: 'Your session expired please login again to continue',
        status: 'error'
      });
    } else {
      setSnackBarMessage({
        message: 'Something went wrong. Try again later!',
        status: 'error'
      });
    }
  };
  const handleClosePaymentAlert = () => {
    setOpenSnackBar(false);
  };
  useEffect(() => {
    if (orderApiResChild && user.plan === 'PPU') {
      console.log(orderApiResChild);
      //generateJobSteps();
      setPaymentOrderId(orderApiResChild.orderID);
    }
  }, [orderApiResChild]);
  useEffect(() => {
    if (locationList?.state?.locationList[0]?.job_location_id) {
      setShowPayment(true);
      setJobName(locationList?.state?.locationList[0]?.job_name);
      setJobSubmitStatus(true);
      setJobId(locationList?.state?.locationList[0]?.job_id);
    }
  }, [locationList]);

  return (
    <Container className="payments">
      <Grid container spacing={2}>
        <Grid item md={showPayment ? 5 : 9} sm={12} xs={12}>
          <Paper>
            <Typography component="h3" style={{ padding: 16 }} variant="h5" className="sub-heading">
              Job Request
            </Typography>
            <hr />

            <Box style={{ padding: 16 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <FormControl fullWidth margin="normal">
                      <TextField
                        label="Enter Job Name"
                        fullWidth
                        value={jobName}
                        onChange={(e) => setJobName(e.target.value)}
                        onBlur={validateJobName}
                        error={Boolean(jobNameError)}
                        helperText={jobNameError}
                        disabled={showPayment}
                        inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </FormControl>
                  </Grid>
                  <Typography component="h3" variant="h5">
                    Summary
                  </Typography>
                  <p>
                    We will proceed to calculate the NDVI (Normalized Difference Vegetation Index), perform Object Detection, and conduct
                    other Function for the specified areas for Calculating the Carbon Emission.
                  </p>
                </Grid>
                <Grid container spacing={0.2} style={{ padding: 16 }}>
                  <Grid item xs={4} className="tableHeader">
                    Location Name
                  </Grid>
                  <Grid item xs={4} className="tableHeader">
                    Area (in sq miles)
                  </Grid>
                  <Grid item xs={4} className="tableHeader">
                    Price (in $)
                  </Grid>
                  <Grid item xs={12}>
                    <hr className="horizontal" />
                  </Grid>
                  {locationList &&
                    locationList.state &&
                    locationList.state.locationList &&
                    locationList.state.locationList.length > 0 &&
                    locationList.state.locationList.map((location) => (
                      <>
                        <Grid item xs={4}>
                          {location.locationName}
                        </Grid>
                        <Grid item xs={4}>
                          {location?.location_area_sq_mile?.toFixed(2)}
                        </Grid>
                        <Grid item xs={4}>
                          {location?.calculated_cost?.toFixed(2)}
                        </Grid>
                        <Grid item xs={12}>
                          <hr className="horizontal" />
                        </Grid>
                      </>
                    ))}
                </Grid>
              </Grid>

              <Box mt={3} className="price">
                <Typography component="h3" variant="h5">
                  Job Request Price
                </Typography>
                <Typography variant="h4" color="green" component="span">
                  ${locationList?.state?.locationList?.reduce((acc, location) => acc + Number(location.calculated_cost), 0).toFixed(2)}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Grid>
        {console.log(showPayment)}
        {showPayment && user.plan == 'PPU' ? (
          <Grid item md={7} sm={12} xs={12}>
            <Paper>
              <Typography component="h3" style={{ padding: 16 }} variant="h5" className="sub-heading">
                Payment Method
              </Typography>
              <hr />
              <div style={{ padding: 16, marginTop: 20 }}>
                <PayPal
                  price={locationList?.state?.locationList?.reduce((acc, location) => acc + Number(location.calculated_cost), 0).toFixed(2)}
                  jobId={locationList?.state?.jobGeoJson?.job_id}
                  jobName={locationList?.state?.jobGeoJson?.job_name}
                  //generateJobSteps={generateJobSteps}
                  handleOrderApiRes={handleOrderApiRes}
                  paidJobs={paidJobs}
                  showPayment={showPayment}
                  onBackOrTabChange={handleBackOrTabChange}
                />
                {/* <Accordion expanded={expandedAccordion === 'panel1'} onChange={handleAccordionChange('panel1')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                    <Typography>Paypal</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <PayPal
                        price={locationList?.state?.locationList
                          ?.reduce((acc, location) => acc + Number(location.calculated_cost), 0)
                          .toFixed(2)}
                        jobId={jobId}
                        generateJobSteps={generateJobSteps}
                      />
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}
                {/* <Accordion expanded={expandedAccordion === 'panel2'} onChange={handleAccordionChange('panel2')}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                    <Typography>Credit Card</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <Box mt={2}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <FormControl fullWidth margin="normal">
                              <TextField
                                label="Card Number"
                                fullWidth
                                value={cardNumber}
                                onChange={handleCardNumberChange}
                                onBlur={validateCardNumber}
                                error={Boolean(cardNumberError)}
                                helperText={cardNumberError}
                                inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
                                InputLabelProps={{ shrink: true }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                              <TextField
                                label="Expiry Date"
                                fullWidth
                                placeholder="MM/YY"
                                value={cardExpiry}
                                onChange={handleCardExpiryChange}
                                onBlur={validateCardExpiry}
                                error={Boolean(cardExpiryError)}
                                helperText={cardExpiryError}
                                inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
                                InputLabelProps={{ shrink: true }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControl fullWidth margin="normal">
                              <TextField
                                label="CVV"
                                fullWidth
                                type={showCvv ? 'text' : 'password'}
                                value={cvv}
                                onChange={(e) => handleCvvChange(e)}
                                onBlur={validateCVV}
                                inputProps={{ maxLength: 3 }}
                                error={Boolean(cvvError)}
                                helperText={cvvError}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton onClick={handleCvvVisibilityToggle} edge="end">
                                        {showCvv ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                                InputLabelProps={{ shrink: true }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl fullWidth margin="normal">
                              <TextField
                                label="Card Holder Name"
                                fullWidth
                                value={cardHolderName}
                                onChange={(e) => setCardHolderName(e.target.value)}
                                onBlur={validateCardHolderName}
                                error={Boolean(cardHolderNameError)}
                                helperText={cardHolderNameError}
                                inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
                                InputLabelProps={{ shrink: true }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Box mt={3} display="flex" justifyContent="space-between">
                          <Button variant="contained" color="primary" onClick={handleMakePayment}>
                            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Make Payment'}
                          </Button>
                        </Box>
                      </Box>
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}
                {!paymentContainer ? (
                  <Box mt={3} display="flex" justifyContent="center">
                    <Button variant="contained" onClick={handleAddToCart}>
                      {locationList?.state?.locationList[0]?.job_id ? 'Pay Later' : 'Back'}
                    </Button>
                  </Box>
                ) : (
                  ''
                )}
              </div>
            </Paper>
          </Grid>
        ) : (
          <Grid item md={3} sm={12} xs={12} sx={{ margin: 'auto' }} className="paymentButton">
            <Box mt={3} className="price">
              {/* <div>Subtotal:</div>
              <span>
                $ {locationList?.state?.locationList?.reduce((acc, location) => acc + Number(location.calculated_cost), 0).toFixed(2)}
              </span> */}
              <Typography component="h3" variant="h5">
                Subtotal:
              </Typography>
              <Typography variant="h4" color="green" component="span">
                ${locationList?.state?.locationList?.reduce((acc, location) => acc + Number(location.calculated_cost), 0).toFixed(2)}
              </Typography>
            </Box>
            {userPlan != 'ENT' ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  startIcon={isProceedToPayLoading ? '' : <CurrencyExchangeIcon />}
                  fullWidth
                  onClick={() => {
                    handleProceedToPay();

                    // handleJobSubmit();
                  }}
                >
                  {isProceedToPayLoading ? <CircularProgress size={24} color="inherit" /> : 'Proceed To Pay'}
                </Button>
                <Tooltip
                  title="If you choose the 'Pay Later' option, your created jobs will be added to the unbilled list, allowing you the flexibility to make payments at your convenience."
                  arrow
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ mt: 2 }}
                    startIcon={isPayLaterLoading ? '' : <AddShoppingCartIcon />}
                    fullWidth
                    onClick={() => {
                      // handleJobSubmit(false);
                      handleAddToCart();
                    }}
                  >
                    {isPayLaterLoading && !orderApiPayDeatilRef.current ? <CircularProgress size={24} color="inherit" /> : 'Save For Later'}
                  </Button>
                </Tooltip>
                {/* <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  startIcon={<ArrowBackIcon />}
                  fullWidth
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button> */}
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  //startIcon={isPayLaterLoading ? '' : <AddShoppingCartIcon />}
                  fullWidth
                  onClick={() => {
                    handleJobSubmit();
                  }}
                >
                  {entPayLoading ? <CircularProgress size={24} color="inherit" /> : 'Submit'}
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  startIcon={isPayLaterLoading ? '' : <AddShoppingCartIcon />}
                  fullWidth
                  onClick={() => {
                    handleAddToCart();
                    // handleJobSubmit(false);
                  }}
                >
                  {isPayLaterLoading ? <CircularProgress size={24} color="inherit" /> : 'Add To Cart'}
                </Button>
                {/* <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  startIcon={<ArrowBackIcon />}
                  fullWidth
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button> */}
              </>
            )}
            {!jobSubmitStatus && (
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                startIcon={<ArrowBackIcon />}
                fullWidth
                onClick={() => navigate('/addjobs')}
              >
                Back
              </Button>
            )}
          </Grid>
        )}
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{marginTop:50}}
        open={openSnackBar}
        autoHideDuration={10000}
        onClose={handleClosePaymentAlert}
      >
        <Alert onClose={handleClosePaymentAlert} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>

      {sessionExpired ? <SessionExpired handleSessionExpiredClose={handleSessionExpiredClose} /> : ''}
    </Container>
  );
};

export default PaymentScreen;
