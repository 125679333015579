import React from 'react';
import { Grid, Box, Typography, TableContainer, Table, TableRow, TableHead, TableCell, TableBody, CircularProgress } from '@mui/material';
import CarIcon from '../../image/carIcon.png';
import industryIcon from '../../image/industryIcon.png';
import HouseHoldIcon from '../../image/householdIcon.png';
import VegetationIcon from '../../image/vegitationIcon.png';

const Loader = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="100px">
    <CircularProgress />
  </Box>
);

const EmissionTable = ({ rows, title, icon, isLoading, dataFetchedStatus }) => (
  <Grid item md={6} sm={12}>
    <Box className="boundryBox">
      <Box sx={{ display: 'flex', paddingBottom: '20px' }}>
        <img src={icon} alt="" />
        <Typography variant="h4" gutterBottom>
          {title}
        </Typography>
      </Box>
      <TableContainer>
        <Table sx={{ width: '100%' }}>
          <TableHead>
            {rows?.length > 0 &&
              rows.map((row, index) => {
                if (index === 0) {
                  return (
                    <TableRow key={row.emissionType}>
                      <TableCell component="th" scope="row">
                        {row.emissionType}
                      </TableCell>
                      <TableCell align="right">{dataFetchedStatus ? row.metricTons : 0}</TableCell>
                    </TableRow>
                  );
                }
              })}
            <TableRow>
              <TableCell align="left" style={{ border: 'none' }}>
                Emission Type
              </TableCell>
              <TableCell align="right" style={{ border: 'none' }}>
                Metric Tons
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={2}>
                  <Loader />
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row, index) => {
                if (index > 0) {
                  return (
                    <TableRow key={row.emissionType}>
                      <TableCell component="th" scope="row">
                        {row.emissionType}
                      </TableCell>
                      <TableCell align="right">{dataFetchedStatus ? row.metricTons : 0}</TableCell>
                    </TableRow>
                  );
                }
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </Grid>
);

const OverallEmissionTable = ({ rows, isLoading, dataFetchedStatus, detectedData }) => (
  <Grid item lg={12} sm={12}>
    <Box className="boundryBox">
      <Typography variant="h4" gutterBottom>
        Overall CO<sub>2</sub> Emission
      </Typography>
      <TableContainer>
        <Table sx={{ width: '100%' }}>
          <TableHead>
            <TableRow>
              <TableCell align="left">Emission Type</TableCell>
              <TableCell align="right">Metric Tons</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={2}>
                  <Loader />
                </TableCell>
              </TableRow>
            ) : (
              rows.map((row) => (
                <TableRow key={row.text}>
                  <TableCell component="th" scope="row">
                    <Box sx={{ display: 'flex', alignItems: 'end', paddingBottom: '20px' }}>
                      <img src={row.image} alt="" className="overallIcon" />
                      {row.text}
                    </Box>
                  </TableCell>
                  <TableCell align="right">{dataFetchedStatus ? row.value : 0}</TableCell>
                </TableRow>
              ))
            )}
            {!isLoading && (
              <TableRow key="total">
                <TableCell component="th" scope="row">
                  <h3>
                    Total CO<sub>2</sub> Emission
                  </h3>
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  <h3>
                    {dataFetchedStatus
                      ? (
                          detectedData?.industry_coal_co2_emission +
                          detectedData?.emission_from_vegeatation_rainforest +
                          detectedData?.co2_emission_normal_vehicle +
                          detectedData?.total_emissions_household
                        ).toFixed(2)
                      : 0.0}
                  </h3>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </Grid>
);

const IndividualStats = ({ isLoading, dataFetchedStatus, detectedData }) => {
  const householdRows = [
    { emissionType: 'Number of Houses Detected', metricTons: detectedData?.objectdetection?.counts?.House?.toFixed(0) },
    { emissionType: 'Carbon Emission Household', metricTons: detectedData?.calculated_emissions?.total_emissions_household.toFixed(2) },
    {
      emissionType: 'Clean Energy Percentage 10%',
      metricTons: detectedData?.calculated_emissions?.total_emissions_household_clean_energy_10?.toFixed(2)
    },
    {
      emissionType: 'Clean Energy Percentage 20%',
      metricTons: detectedData?.calculated_emissions?.total_emissions_household_clean_energy_20?.toFixed(2)
    },
    {
      emissionType: 'Clean Energy Percentage 30%',
      metricTons: detectedData?.calculated_emissions?.total_emissions_household_clean_energy_30.toFixed(2)
    }
  ];
  const transportationRows = [
    { emissionType: 'Number of Vehicles Detected', metricTons: detectedData?.objectdetection?.counts?.Vehicles?.toFixed(0) },
    {
      emissionType: 'Emission from Transportation(Normal Vehicle)',
      metricTons: detectedData?.calculated_emissions?.co2_emission_normal_vehicle?.toFixed(2)
    },
    {
      emissionType: 'Emission from Transportation(Electric Vehicle)',
      metricTons: detectedData?.calculated_emissions?.co2_emissions_electric_vehicle?.toFixed(2)
    },
    {
      emissionType: 'Emission from Transportation(CO₂ Compensated)',
      metricTons: detectedData?.calculated_emissions?.co2_emissions_total_compenstated?.toFixed(2)
    },
    {
      emissionType: 'Emission Reduction(Lifecycle = 15 Years)',
      metricTons: detectedData?.calculated_emissions?.co2_emission_less_while_using_electric_vehicles.toFixed(2)
    }
  ];
  const vegitationRows = [
    { emissionType: 'Number of Vegetation Paches', metricTons: detectedData?.objectdetection?.counts?.Vegetations?.toFixed(0) },
    {
      emissionType: 'Emission from Vegetation(Rain Forest)',
      metricTons: detectedData?.calculated_emissions?.emission_from_vegeatation_rainforest?.toFixed(2)
    },
    {
      emissionType: 'Emission from Vegetation(Forest)',
      metricTons: detectedData?.calculated_emissions?.emission_from_vegeatation_forest?.toFixed(2)
    },
    {
      emissionType: 'Emission from Vegetation(Grassland)',
      metricTons: detectedData?.calculated_emissions?.emission_from_vegeatation_grassland?.toFixed(2)
    }
  ];
  const industryRows = [
    { emissionType: 'Number of Industries Detected(100 sq.m)', metricTons: detectedData?.objectdetection?.counts?.Industry?.toFixed(0) },
    {
      emissionType: 'Emission from Coal Fire Power Plant',
      metricTons: detectedData?.calculated_emissions?.industry_coal_co2_emission?.toFixed(2)
    },
    {
      emissionType: 'Emission from Natural Gas Fire Power Plant',
      metricTons: detectedData?.calculated_emissions?.industry_natural_co2_emission?.toFixed(2)
    },
    {
      emissionType: 'Carbon Saving if Natural Gas Used',
      metricTons: detectedData?.calculated_emissions?.industry_PowerShift_of_natural_gas_over_coal?.toFixed(2)
    }
  ];

  const overallEmission = [
    {
      image: HouseHoldIcon,
      text: 'House',
      value: detectedData?.calculated_emissions?.total_emissions_household?.toFixed(2)
    },
    {
      image: CarIcon,
      text: 'Vehicles',
      value: detectedData?.calculated_emissions?.co2_emission_normal_vehicle?.toFixed(2)
    },
    {
      image: VegetationIcon,
      text: 'Vegetation',
      value: detectedData?.calculated_emissions?.emission_from_vegeatation_rainforest?.toFixed(2)
    },
    {
      image: industryIcon,
      text: 'Industries',
      value: detectedData?.calculated_emissions?.industry_coal_co2_emission?.toFixed(2)
    }
  ];

  return (
    <Grid container spacing={3} item xs={12} md={12} lg={12} className="individualstats">
      <EmissionTable
        rows={householdRows}
        title="Emission from Household"
        icon={HouseHoldIcon}
        isLoading={isLoading}
        dataFetchedStatus={dataFetchedStatus}
      />
      <EmissionTable
        rows={transportationRows}
        title="Emission from Transportation"
        icon={CarIcon}
        isLoading={isLoading}
        dataFetchedStatus={dataFetchedStatus}
      />
      <EmissionTable
        rows={vegitationRows}
        title="Emission from Vegetation"
        icon={VegetationIcon}
        isLoading={isLoading}
        dataFetchedStatus={dataFetchedStatus}
      />
      <EmissionTable
        rows={industryRows}
        title="Emission from Industry"
        icon={industryIcon}
        isLoading={isLoading}
        dataFetchedStatus={dataFetchedStatus}
      />
      <OverallEmissionTable
        rows={overallEmission}
        isLoading={isLoading}
        dataFetchedStatus={dataFetchedStatus}
        detectedData={detectedData?.calculated_emissions}
      />
    </Grid>
  );
};

export default IndividualStats;
