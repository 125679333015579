// ==============================|| THEME CONFIG  ||============================== //

const config = {
  defaultPath: '/home',
  // fontFamily: `'Public Sans', sans-serif`,
  i18n: 'en',
  miniDrawer: false,
  container: true,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr'
};

export default config;
export const drawerWidth = 260;
export const BaseUrl = "https://datacarbonui.digitalglyde.com";

export const twitterColor = '#1DA1F2';
export const facebookColor = '#3b5998';
export const linkedInColor = '#0e76a8';
// export const CLIENT_ID = process.env.CLIENT_ID || 'AR3Z5DM5Q48IKggHn_rpXApQnR7_T-SpQbW35qStJO5Jz3y9XmyHvA1N_bUchnrpjuPyfNdEcWgLjcRD'
// export const APP_SECRET = process.env.APP_SECRET || 'EEEn9vQxoZPF2-bVVduqc17PGyIfE8zw4Soc-AQ0vDKS96Wxp7CT2E56otegjmeGwnMDlg6IDdLikR7L'
export const CLIENT_ID = process.env.CLIENT_ID || 'AR3Z5DM5Q48IKggHn_rpXApQnR7_T-SpQbW35qStJO5Jz3y9XmyHvA1N_bUchnrpjuPyfNdEcWgLjcRD'
export const APP_SECRET = process.env.APP_SECRET || 'EEEn9vQxoZPF2-bVVduqc17PGyIfE8zw4Soc-AQ0vDKS96Wxp7CT2E56otegjmeGwnMDlg6IDdLikR7L'