import React from 'react';
import { Box, Grid, Typography, Button, Link, Container } from '@mui/material';
import Step1Image from '../../image/step1.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const Step1 = () => {
  return (
    <>
      <Box className="step1" sx={{ flexGrow: 1 }} id="select" style={{height:window.innerHeight}}>
        <Grid container sx={{mb:10}}>
        <Grid item xs={12}>
        <Typography variant="h1" className='heading heading2'>How it works</Typography>
          <Typography variant="h3" sx={{fontWeight:'500'}} className="heading2">There are below steps to know what we provide.</Typography>
        </Grid>
        </Grid>
        <Container>
        <Grid container spacing={2}>
          <Grid item lg={6} sm={12} >
            <Box data-aos="slide-right">
              <Typography variant='h2' gutterBottom>Select an Area of Interest</Typography>
              <Typography variant="body1">
                Choose a specific region, city, or even your own neighborhood where you want to estimate carbon footprints. Whether it is a
                bustling urban center, a rural community, or an industrial zone, this step allows you to focus on the environmental impact
                of a particular area.
              </Typography> 
            </Box>
          </Grid>
          <Grid item lg={6} sm={12}>
            <Box data-aos="zoom-in-up" style={{ textAlign: 'center' }}>
              {/* <div className="select">
                <Typography variant="h3">Select Area of Interest</Typography>
              </div> */}
              <img src={Step1Image} alt="About Carbon Footprint" style={{ width: '50%' }} />
            </Box>
            
          </Grid>
        </Grid>
        <Link href="#harnessingAI" style={{margin:"10% auto", display:"table"}}><Button variant="contained" size="large">
              Next <ArrowForwardIcon/>            
            </Button></Link>
        </Container>
      </Box>
      {/* <div style={{ textAlign: 'center', marginTop:'-60px' }}>
        <a href="#harnessingAI">
          <ArrowDownwardSharpIcon sx={{ color: 'green', textAlign: 'center' }} />
        </a>
      </div> */}
    </>
  );
};

export default Step1;
