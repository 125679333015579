import React from 'react';
import { Box, Grid, Typography, Button } from '@mui/material';
import KeyboardArrowLeftSharpIcon from '@mui/icons-material/KeyboardArrowLeftSharp';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';

const Content = () => {
  const slideData = [
    {
      h2: 'Lorem ispum dolor sit am 1',
      p: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua'
    },
    {
      h2: 'Lorem ispum dolor sit am 2',
      p: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua'
    },
    {
      h2: 'Lorem ispum dolor sit am 3',
      p: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua'
    }
  ];
  return (
    <>
      <Box className="contents" style={{marginTop: "60px"}}>
        <Grid container>
          <Grid item lg={3} md={3} xs={12}>
            <Typography variant="h1">What's New </Typography>
          </Grid>
          <Grid item lg={6} md={6} xs={12} className="itemsecond">
            <Swiper
              autoplay={{
                delay: 3000
              }}
              loop={true}
              navigation={true}
              modules={[Navigation,Autoplay]}
              className="mySwiper"
            >
              {slideData.map((slide, index) => (
                <SwiperSlide key={index} className="back">
                    <Typography variant="h2" className="headings">
                      {slide.h2}
                    </Typography>
                    <p className="paraas">{slide.p}</p>
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
          <Grid item lg={3} md={3} xs={12} className="itemthird">
            <Button variant="outlined" className="btnnew">
              What's new
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Content;
