// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.map-legend {
    background-color: white;
    overflow: auto;
    height: calc(100% + 0.5em);
    margin: -0.25em;
    padding: 0.25em;
}

span.map-legend-color-box {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin: 0.25em 1em 0.25em 0.25em;
    border: 1px solid black;
}

div.map-legend-group-title {
    font-weight: bold;
    margin: 0.25em;
}

div.map-legend-group-entries {
    margin-left: 0.5em;
    border-left: 1px solid var(--border-color);
    padding-left: 1em;
}

div.map-legend-legend-entry {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
}

div.map-legend-entry-title {
    font-size: 95%;
}`, "",{"version":3,"sources":["webpack://./src/qwc2/plugins/style/MapLegend.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,cAAc;IACd,0BAA0B;IAC1B,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,qBAAqB;IACrB,UAAU;IACV,WAAW;IACX,gCAAgC;IAChC,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,0CAA0C;IAC1C,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["div.map-legend {\n    background-color: white;\n    overflow: auto;\n    height: calc(100% + 0.5em);\n    margin: -0.25em;\n    padding: 0.25em;\n}\n\nspan.map-legend-color-box {\n    display: inline-block;\n    width: 1em;\n    height: 1em;\n    margin: 0.25em 1em 0.25em 0.25em;\n    border: 1px solid black;\n}\n\ndiv.map-legend-group-title {\n    font-weight: bold;\n    margin: 0.25em;\n}\n\ndiv.map-legend-group-entries {\n    margin-left: 0.5em;\n    border-left: 1px solid var(--border-color);\n    padding-left: 1em;\n}\n\ndiv.map-legend-legend-entry {\n    display: flex;\n    align-items: center;\n    margin-bottom: 1em;\n}\n\ndiv.map-legend-entry-title {\n    font-size: 95%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
