import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { AppBar, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import Logo from '../../../image/logo.png';
// project import
import AppBarStyled from './AppBarStyled';
import HeaderContent from './HeaderContent';
import { Page } from 'menu-items/index';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { BorderColor } from '../../../../node_modules/@mui/icons-material/index';


// ==============================|| MAIN LAYOUT - HEADER ||============================== //

const Header = ({ open, handleDrawerToggle }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector((state) => state.menu);
  const [hovered, setHovered] = useState(false);

  const matchDownMD = useMediaQuery(theme.breakpoints.down('lg'));

  const iconBackColor = 'grey.100';
  const iconBackColorOpen = 'grey.200';

  const handleNavigation = () => {
    console.log("inside navigation")
    navigate(isLoggedIn ? '/user/dashboard' : '/');
  };

  // Handlers for mouse enter and leave events
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  // common header
  const mainHeader = (
    <Toolbar style={{background:"#469100", BorderColor:"green"}}>
      <IconButton
        disableRipple
        aria-label="open drawer"
        onClick={handleDrawerToggle}
        edge="start"
        color="secondary"
        // sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor, ml: { xs: 0, lg: -2 } }}
        className="headerToggle"
      >
        {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
      </IconButton>
      <button
        type="button"
        onClick={handleNavigation}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          border: 'none',
          padding: 0,
          background: 'none',
          cursor: 'pointer'
        }}
      >
        <img
          src={Logo}
          alt="Logo"
          style={{
            maxWidth: 60,
            marginRight: 'auto',
            filter: hovered ? 'brightness(0.8)' : 'none' /* Apply filter on hover */
          }}
        />
      </button>
      {/* <img src={Logo} alt="Logo" style={{ maxWidth: 60, marginRight: 'auto' }} onClick={handleNavigation} /> */}
      <HeaderContent />
    </Toolbar>
  );

  // app-bar params
  const appBar = {
    position: 'fixed',
    color: 'inherit',
    elevation: 0,
    sx: {
      // borderBottom: `1px solid ${theme.palette.divider}`
      boxShadow: theme.customShadows.z1,
      borderBottom: `1px solid green`

    }
  };

  return (
    <>
      <Page />
      {!matchDownMD ? (
        <AppBarStyled open={open} {...appBar}>
          {mainHeader}
        </AppBarStyled>
      ) : (
        <AppBar {...appBar}>{mainHeader}</AppBar>
      )}
    </>
  );
};

Header.propTypes = {
  open: PropTypes.bool,
  handleDrawerToggle: PropTypes.func
};

export default Header;
