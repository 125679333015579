import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  TextField,
  InputAdornment,
  Grid,
  Alert,
  Snackbar,
  Typography,
  Box
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CircularProgress from '@mui/material/CircularProgress';
import SessionExpired from 'pages/session/sessionExpired';
import axios from 'axios';
import { BaseUrl } from 'config';
import { useSelector } from 'react-redux';
import SearchMapLocation from './selectlocation/selectmaplocation';

const UnbilledJobList = ({ isUserVerified, unbilledJobList }) => {
  console.log(unbilledJobList);
  const navigate = useNavigate();
  const { TOKEN, user } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState(unbilledJobList);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState('request_date');
  const [sortDirection, setSortDirection] = useState('asc');
  const [sessionExpired, setSessionExpired] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: '',
    status: ''
  });
  const handleSessionExpiredClose = (status) => {
    setSessionExpired(status);
  };
  const handleClosePaymentAlert = () => {
    setOpenSnackBar(false);
  };
  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter data based on search term
  const filteredData = jobList?.filter((item) =>
    Object.values(item).some((value) => {
      if (typeof value === 'string') {
        return value.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    })
  );

  // Handle column sorting
  // const handleSort = (columnId) => {
  //   const isAsc = orderBy === columnId && sortDirection === 'asc';
  //   setOrderBy(columnId);
  //   setSortDirection(isAsc ? 'desc' : 'asc');
  // };

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && sortDirection === 'asc';
    setOrderBy(columnId);
    // If sorting by requested_date or requested_time, set sort direction to descending
    if (columnId === 'requested_date' || columnId === 'requested_time') {
      setSortDirection('asc');
    } else {
      // For other columns, toggle the sort direction
      setSortDirection(isAsc ? 'desc' : 'asc');
    }
  };

  // Sort data based on current sorting state
  const sortedData = [...filteredData].sort((a, b) => {
    const compareValue = (a, b) => {
      if (a[orderBy] < b[orderBy]) return -1;
      if (a[orderBy] > b[orderBy]) return 1;
      return 0;
    };

    if (orderBy === 'requested_date' || orderBy === 'requested_time') {
      // If sorting by requested_date or requested_time, prioritize sorting by date and then time
      if (a.requested_date === b.requested_date) {
        return sortDirection === 'desc'
          ? a.requested_time.localeCompare(b.requested_time)
          : b.requested_time.localeCompare(a.requested_time);
      } else {
        return sortDirection === 'asc'
          ? a.requested_date.localeCompare(b.requested_date)
          : b.requested_date.localeCompare(a.requested_date);
      }
    } else {
      // For other columns, use regular sorting logic
      return sortDirection === 'asc' ? compareValue(a, b) : -compareValue(a, b);
    }
  });

  const checkStatus = (status) => {
    // if (status == 'Pending') {
    //   return {
    //     color: 'black'
    //   };
    // }
  };

  const handleUnbilledJobPayment = (item) => {
    console.log(item);
    //format data according payment screen
    if (!isUserVerified) {
      navigate('/unverified_user');
    }
    // else if (user?.plan == 'ENT') {
    //   navigate('/payment', {
    //     state: {
    //       locationList: [{ ...item, location_area_sq_mile: item.area_sq_miles, locationName: item.job_name, unprocessedDetails: true }]
    //     }
    //   });
    // }
    else if (item.job_id != null) {
      navigate('/addjobs', {
        state: { job_id: item.job_id, unprocessedJobName: item.job_name }
      });
    }
    // else {
    //   navigate('/payment', {
    //     state: { locationList: [{ ...item, location_area_sq_mile: item.area_sq_miles, locationName: item.job_name }] }
    //   });
    // }
  };
  function getFormattedDate(timestamp) {
    const date = new Date(timestamp);

    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    console.log(`${month}/${day}/${year}`);
    return `${month}/${day}/${year}`;
  }
  function getFormattedTime(timestamp) {
    const date = new Date(timestamp);

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    console.log(`${hours}:${minutes}:${seconds}`);
    return `${hours}:${minutes}:${seconds}`;
  }
  const groupByJobId = (jobs) => {
    return jobs.reduce((result, item) => {
      const existingItem = result.find((groupedItem) => groupedItem.job_id === item.job_id);
      const getTime = (timestampString) => {
        const timestamp = new Date(timestampString);
        // Format for the time (e.g., 07:32:52)
        const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
        const formattedTime = timestamp.toLocaleTimeString('en-US', timeOptions);
        return formattedTime;
      };
      const getDate = (timestampString) => {
        const timestamp = new Date(timestampString);
        // Format for the date (e.g., Feb 12, 2024)
        const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };
        const formattedDate = timestamp.toLocaleDateString('en-US', dateOptions);
        return formattedDate;
      };
      if (existingItem) {
        existingItem.Location_geojson.push({
          Location_geojson: item.Location_geojson,
          location_name: item.location_name
        });
      } else {
        result.push({
          area_sq_miles: item.area_sq_miles,
          calculated_cost: item.calculated_cost,
          job_id: item.job_id,
          job_location_id: item.job_location_id,
          job_name: item.job_name,
          overall_status: item.overall_status,
          plan_applied: item.plan_applied,
          // requested_timestamp: getTime(item.requested_timestamp),
          requested_date: getDate(item.requested_timestamp),
          requested_time: getTime(item.requested_timestamp),
          Location_geojson: [
            {
              Location_geojson: item.Location_geojson,
              location_name: item.location_name
            }
          ]
        });
      }

      return result;
    }, []);
  };

  const getJobList = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${BaseUrl}/home/jobslist`,
        // { payment_status: false },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`
          }
        }
      );
      if (response?.status == 200) {
        setIsLoading(false);
        const data = response.data;

        // const groupedData = groupByJobId(response?.data.filter((val) => !val.job_payment_id));
        // const groupedData = groupByJobId(response?.data[0].filter((val) => val.payment_status != true && val.plan_applied == user.plan));
        // const sorted_data = response?.data[1].sort((a, b) => {
        //   let dateA = new Date(`${a.getFormattedDate(requested_timestamp)} ${a.getFormattedTime(requested_timestamp)}`);
        //   let dateB = new Date(`${b.getFormattedDate(requested_timestamp)} ${b.getFormattedTime(requested_timestamp)}`);
        //   return dateB - dateA;
        // });
        const filteredData = data[1].filter((item) => item.payment_status == false);
        const sorted_data = filteredData.sort((a, b) => {
          let dateA = new Date(`${getFormattedDate(a.requested_timestamp)} ${getFormattedTime(a.requested_timestamp)}`);
          let dateB = new Date(`${getFormattedDate(b.requested_timestamp)} ${getFormattedTime(b.requested_timestamp)}`);
          return dateB - dateA;
        });

        setJobList(sorted_data);
      } else if (response?.status === 200 && data[1]?.length === 0) {
        //if user does not have jobs
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setOpenSnackBar(true);
        setSnackBarMessage({
          message: 'Something went wrong. Try again later!',
          status: 'error'
        });
      }
    } catch (error) {
      setIsLoading(false);
      setOpenSnackBar(true);
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired please login again to continue',
          status: 'error'
        });
      } else {
        setSnackBarMessage({
          message: 'Something went wrong. Try again later! ',
          status: 'error'
        });
      }
    }
  };

  const handleRowClick = (job_id) => {
    const isRowExpanded = expandedRows.includes(job_id);
    setExpandedRows(isRowExpanded ? expandedRows.filter((id) => id !== job_id) : [...expandedRows, job_id]);
  };

  // const renderExpandableRow = (item) => {
  //   const locationdata = {
  //     mode: 'view',
  //     type: 'select',
  //     hide: true,
  //     locations: {
  //       type: 'FeatureCollection',
  //       features: item?.Location_geojson?.map((val) => {
  //         return val.Location_geojson.features[0];
  //       })
  //     }
  //   };
  //   return <SearchMapLocation location={'nothing'} locationData={locationdata} />;
  // };
  function checkCoordinateOrder(response) {
    const coordinates = response.locations.features[0].geometry.coordinates[0];
    if (coordinates[0][0] > coordinates[0][1]) {
      return 'LatLng';
    } else {
      return 'LngLat';
    }
  }

  const renderExpandableRow = (item) => {
    // Check if Location_geojson and its features are defined
    const features = item?.Location_geojson?.features;

    const reverseCoordinates = (coordinates) => {
      return coordinates.map((polygon) => polygon.map(([lat, lng]) => [lng, lat]));
    };

    let locationdata = {
      mode: 'view',
      type: 'select',
      hide: true,
      locations: {
        type: 'FeatureCollection',
        features: []
      }
    };

    if (features) {
      const tempResponse = { locations: { features } };
      const coordinateOrder = checkCoordinateOrder(tempResponse);

      locationdata.locations.features = features.map((feature) => {
        let coordinates = feature.geometry.coordinates;
        if (coordinateOrder === 'LatLng') {
          coordinates = reverseCoordinates(coordinates);
        }
        return {
          ...feature,
          geometry: {
            ...feature.geometry,
            coordinates
          }
        };
      });
    }

    return <SearchMapLocation location={'nothing'} locationData={locationdata} />;
  };

  useEffect(() => {
    setJobList(unbilledJobList);
    //getJobList([]);
  }, [unbilledJobList]);

  return (
    <Grid className="joblist">
      <Box className="joblist_heading">
        <Grid container>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            {user?.plan != 'ENT' ? (
              <h1 className="sub-heading">Unbilled Job List</h1>
            ) : (
              <h1 className="sub-heading">Unprocessed Jobs List</h1>
            )}
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              size="large"
              sx={{ mt: 2 }}
              onChange={(e) => setSearchTerm(e.target.value)}
              // InputProps={{
              //   startAdornment: <InputAdornment position="start">{/* You can add a search icon here if needed */}</InputAdornment>
              // }}
              inputProps={{ style: { padding: '3px 10px' } }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {/* Render your table headers here */}
              {user.company_id && (
                <TableCell onClick={() => handleSort('user_email')}>
                  User Email
                  {orderBy === 'user_email' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
                </TableCell>
              )}
              <TableCell onClick={() => handleSort('job_name')}>
                Job Name
                {orderBy === 'job_name' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell>
              <TableCell onClick={() => handleSort('requested_date')}>
                Request Date
                {orderBy === 'requested_timestamp' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell>
              {/* <TableCell onClick={() => handleSort('requested_time')}>
                Request Time
                {orderBy === 'requested_time' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell> */}
              <TableCell onClick={() => handleSort('calculated_cost')}>
                Calculated Cost
                {orderBy === 'calculated_cost' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell>
              <TableCell onClick={() => handleSort('area_sq_miles')}>
                Area Sq Miles
                {orderBy === 'area_sq_miles' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell>
              {/* <TableCell onClick={() => handleSort('plan_applied')}>
                Plan
                {orderBy === 'plan_applied' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell> */}
              <TableCell onClick={() => handleSort('overall_status')}>
                Status
                {orderBy === 'overall_status' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={8} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {sortedData?.length === 0 ? (
                <TableCell colSpan={8} align="center">
                  <Typography variant="h4" color="green" component="span" style={{ marginLeft: '0px', lineHeight: 4 }}>
                    No Records Found!
                  </Typography>
                </TableCell>
              ) : (
                <>
                  <TableBody>
                    {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                      <React.Fragment key={item.job_guid}>
                        <TableRow onClick={() => handleRowClick(item.job_id)}>
                          {user.company_id && <TableCell style={checkStatus(item.overall_status)}>{item.user_email}</TableCell>}
                          <TableCell style={checkStatus(item.overall_status)}>{item.job_name}</TableCell>
                          <TableCell style={checkStatus(item.overall_status)}></TableCell>
                          {/* <TableCell style={checkStatus(item.overall_status)}>{item.requested_time}</TableCell> */}
                          <TableCell style={checkStatus(item.overall_status)}>
                            {item?.calculated_cost != '' && item?.calculated_cost?.toFixed(2)}
                          </TableCell>
                          <TableCell style={checkStatus(item.overall_status)}>
                            {item.area_sq_miles != '' && item.area_sq_miles?.toFixed(2)}
                          </TableCell>
                          <TableCell style={checkStatus(item.overall_status)}>
                            {item.overall_status == 'Completed' ? 'Completed' : 'Pending'}
                          </TableCell>
                          {/* <TableCell style={checkStatus(item.job_status)}>{item.job_guid}</TableCell> */}
                          <TableCell>
                            <Button
                              variant="contained"
                              className="viewresult"
                              color="primary"
                              onClick={() => handleUnbilledJobPayment(item)}
                            >
                              {user?.plan == 'PPU' ? 'Make Payment' : 'Unprocessed Details'}
                            </Button>
                          </TableCell>
                          {/* Add more cells as needed */}
                        </TableRow>
                        {expandedRows.includes(item.job_id) && (
                          <TableRow>
                            <TableCell colSpan={7}>
                              <Table>
                                <TableCell>{expandedRows.includes(item.job_id) && renderExpandableRow(item)}</TableCell>
                              </Table>
                            </TableCell>
                          </TableRow>
                        )}
                      </React.Fragment>
                    ))}
                  </TableBody>
                </>
              )}
            </>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        className="page_btn"
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{marginTop:50}}
        open={openSnackBar}
        autoHideDuration={10000}
        onClose={handleClosePaymentAlert}
      >
        <Alert onClose={handleClosePaymentAlert} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>

      {sessionExpired ? <SessionExpired handleSessionExpiredClose={handleSessionExpiredClose} /> : ''}
    </Grid>
  );
};

export default UnbilledJobList;
