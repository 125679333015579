// Login.js

// import Header from 'layout/MainLayout/Header/index';
import React, { useEffect, useState, useRef } from 'react';
import { BaseUrl } from 'config';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, TextField, Link, Grid, InputAdornment, IconButton, Typography, Container, Snackbar, Alert } from '@mui/material';
import BgImage from '../../../src/image/login_bg.png';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  login,
  userDetails,
  logout,
  companyDetails,
  mapKeyDetails,
  notificationDetails,
  paymentHistoryDetails,
  AccountTypeChangeToEnt,
  EditProfileClicked
} from 'store/reducers/menu';
import { color } from 'openlayers';
import ReCAPTCHA from 'react-google-recaptcha';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn, isEditProfileClicked, isAccountTypeChangeToEnt } = useSelector((state) => state.menu);

  const [isCaptchaLoading, setIsCaptchaLoading] = useState(true); // State to track loading state of reCAPTCHA
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [resetRecaptcha, setResetRecaptcha] = useState(false);
  const recaptchaRef = useRef();
  const [inputValue, setInputValue] = useState({
    email: '',
    password: '',
    showPassword: false
  });
  const [validationErrors, setValidationErrors] = useState({
    email: '',
    password: ''
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: 'User Login Successfully!',
    status: 'success'
  });
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    // Function to execute when reCAPTCHA is fully loaded
    const handleRecaptchaLoad = () => {
      setIsCaptchaLoading(false); // Set loading state to false once reCAPTCHA is loaded
    };

    // Dynamically load the reCAPTCHA script
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoadCallback&render=explicit';
    script.async = true;
    document.body.appendChild(script);

    // Set window function to handle reCAPTCHA load callback
    window.onRecaptchaLoadCallback = handleRecaptchaLoad;

    // Cleanup function to remove event listener and window function on component unmount
    return () => {
      document.body.removeChild(script);
      window.onRecaptchaLoadCallback = null;
    };
  }, []);
  function onChange(value) {
    setRecaptchaToken(value);
    setRecaptchaError(false);
  }
  const handleChange = (prop) => (event) => {
    setInputValue({ ...inputValue, [prop]: event.target.value });
    // Reset validation errors when the user types
    setValidationErrors({ ...validationErrors, [prop]: '' });
  };

  const handlePasswordVisibility = () => {
    setInputValue({ ...inputValue, showPassword: !inputValue.showPassword });
  };

  const validateForm = () => {
    let isValid = true;

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputValue.email)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, email: 'Enter a valid email address' }));
      isValid = false;
    }

    // Password validation
    const passwordRegex = /^(?=.*[!@#$%^&*])(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/;
    if (!passwordRegex.test(inputValue.password)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        password: 'Password must contain at least 6 characters, one special character, and a combination of letters and numbers'
      }));
      isValid = false;
    }

    return isValid;
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const fetchUserDetails = async (token) => {
    try {
      const response = await axios.get(`${BaseUrl}/users/me`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      if (response?.data?.id && response?.status === 200) {
        dispatch(userDetails(response?.data));
        return response?.data;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const fetchCompanyDetails = async (token) => {
    try {
      const response = await axios.get(`${BaseUrl}/company_detail`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      if (response?.data && response?.status === 200) {
        dispatch(companyDetails(response?.data));
        return true;
      } else if (response?.data?.status == 'User is not having any company registered' && response?.status === 200) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validate the reCAPTCHA token
    if (!recaptchaToken) {
      setRecaptchaError(true);
      return; // Prevent form submission if reCAPTCHA token is missing
    }
    // Validate before submitting

    if (validateForm()) {
      setIsLoading(true);
      const formData = new FormData();
      formData.set('username', inputValue.email);
      formData.set('password', inputValue.password);
      axios
        .post(`${BaseUrl}/auth/jwt/login`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(async (response) => {
          if (response?.status == 200 && response?.data) {
            const token = response.data?.access_token;
            const userStatus = await fetchUserDetails(token);
            //const is_user_approved_by_company = await fetchCompanyDetails(token);

            if (userStatus) {
              localStorage.setItem('token', token);
              localStorage.setItem('accounTypeChangeNavigation', false);
              localStorage.setItem('onEditProfileClick', false);
              dispatch(login(token));
              setOpenSnackBar(true);
              setIsLoading(false);
              setSnackBarMessage({
                message: 'User login successfully!',
                status: 'success'
              });
              dispatch(EditProfileClicked({ isEditProfileClicked: isEditProfileClicked }));
              dispatch(AccountTypeChangeToEnt({ isAccountTypeChangeToEnt: isAccountTypeChangeToEnt }));

              if (userStatus?.plan == 'ENT') {
                setTimeout(() => {
                  navigate('/user/dashboard');
                }, 1200);
              } else if (userStatus?.plan == 'PPU') {
                setTimeout(() => {
                  navigate('/user/dashboard');
                }, 1200);
                recaptchaRef.current.reset();
                setResetRecaptcha(true);
                setRecaptchaToken('');
              } else {
                // setTimeout(() => {
                //   navigate('/companyProfile', { state: 'joblist' });
                // }, 1200);
                setTimeout(() => {
                  navigate('/accountType', { state: 'joblist' });
                }, 1200);
              }
            } else {
              setIsLoading(false);
              setOpenSnackBar(true);
              setSnackBarMessage({
                message: 'Something went wrong. Try again later.',
                status: 'error'
              });
            }
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpenSnackBar(true);
          if (error?.response?.data?.detail == 'LOGIN_BAD_CREDENTIALS') {
            setSnackBarMessage({
              message: 'Please verify your credentials and, if confirmed, kindly contact the administrator to grant login access.',
              status: 'error'
            });
          } else {
            setSnackBarMessage({
              message: 'Something went wrong. Try again later.',
              status: 'error'
            });
          }
        });
    }
  };

  useEffect(() => {
    //logout user automatically
    localStorage.clear();
    dispatch(logout(null));
    dispatch(userDetails(null));
    dispatch(companyDetails(null));
    dispatch(notificationDetails(null));
    dispatch(paymentHistoryDetails(null));
    dispatch(mapKeyDetails(1));
    if (isLoggedIn) {
      navigate('/user/dashboard');
    } else {
      navigate('/login');
    }
  }, []);

  return (
    <div className="loginreg">
      {/* <Header /> */}
      <img src={BgImage} className="logRegImg" alt="" />
      <Grid container>
        <Grid item lg={5} md={5} xs={0}></Grid>
        <Grid item lg={6} md={7} xs={12} className="log_spacing">
          <Container component="main" maxWidth="xs">
            <Typography component="h1" variant="h5">
              Hello! lets get started
            </Typography>
            <Typography component="h3" variant="h5">
              Sign in to continue
            </Typography>
            <form onSubmit={handleSubmit} className="myInput">
              <TextField
                label="Email"
                type="email"
                fullWidth
                required
                value={inputValue.email?.toLocaleLowerCase()}
                onChange={handleChange('email')}
                margin="normal"
                variant="outlined"
                error={!!validationErrors.email}
                helperText={validationErrors.email}
                InputLabelProps={{ shrink: true }}
                inputProps={{ style: { backgroundColor: 'white' } }}
              />

              <TextField
                label="Password"
                type={inputValue.showPassword ? 'text' : 'password'}
                fullWidth
                required
                value={inputValue.password}
                onChange={handleChange('password')}
                margin="normal"
                variant="outlined"
                error={!!validationErrors.password}
                helperText={validationErrors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={handlePasswordVisibility} tabIndex="-1">
                        {inputValue.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                InputLabelProps={{ shrink: true }}
                inputProps={{ style: { backgroundColor: 'white'} }}
              />
              <br />
              {isCaptchaLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  // sitekey="6LdwA-ApAAAAAH563WW4vxWvcCzmXVbj01ME4DgO"
                // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // use this token for local
                  sitekey="6Ld3THgkAAAAANncBZ0lLFUeMUnoCjJOOa4bRoux"
                  onChange={onChange}
                  onExpired={() => {
                    setRecaptchaToken('');
                    setRecaptchaError(true);
                  }}
                  hl="en"
                  size="normal"
                  tabIndex={0}
                  reset={resetRecaptcha}
                  style={{ color: 'transparent', marginTop: '10px', marginBottom: '10px' }} // Hide the "Privacy" and "Terms" text
                />
              )}

              {recaptchaError && <span style={{ color: 'red', marginBottom: '10px' }}>Please complete the reCAPTCHA challenge</span>}
              <br />
              <Button type="submit" className="login_button" variant="contained" size="large">
                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Login'}
              </Button>
              <Grid container>
                <Grid item xs={6}>
                  <Link href="register" variant="body2" className="signUp">
                    Sign up{' '}
                  </Link>
                </Grid>
                <Grid item xs={6}>
                  <Link href="forgotpassword" variant="body2" className="forgot">
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Grid>
        <Grid item lg={1} md={0} xs={0}></Grid>
      </Grid>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{marginTop:50}}open={openSnackBar} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Login;
