import React, { useState, useEffect } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TextField,
  InputAdornment,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip,
  Typography,
  Box
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { DeleteOutlined, EyeOutlined, EditOutlined } from '@ant-design/icons';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
let sortedData = [];

const SelectedLocationTable = ({ locationlist, handleSelectLocation, locationType, handleDeleteLocation, isLoading }) => {
  const [locationList, setLocationList] = useState(locationlist);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState('file');
  const [sortDirection, setSortDirection] = useState('asc');
  const [deletedItem, setDeletedItem] = useState();
  const [displayDeleteAlert, setDisplayDeleteAlert] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState();
  console.log(`Locationlist location:  `, locationlist, locationType);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleClose = () => {
    setDisplayDeleteAlert(false);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && sortDirection === 'asc';
    setOrderBy(columnId);
    setSortDirection(isAsc ? 'desc' : 'asc');
  };

  const handleDelete = (location) => {
    console.log(`Deleting location:  `, selectedLocation, location);
    setLocationList((prev) => prev.filter((val) => val.id !== location.id));
    const mode = locationType.upload ? 'upload' : locationType.draw ? 'draw' : locationType.search ? 'select' : '';
    console.log('pepelsdf', mode);
    if (selectedLocation?.id == location?.id) {
      handleSelectLocation(mode, undefined);
    }
    handleDeleteLocation(location);
    setDeletedItem();
    setDisplayDeleteAlert(false);
  };
  // Function to check coordinate order and show appropriate message
  function checkCoordinateOrder(response) {
    const coordinates = response.locations.features[0].geometry.coordinates[0];
    //response.locations.features[0].geometry.coordinates[0] = coordinates.map(coord => [coord[1], coord[0]]);

    if (coordinates[0][0] > coordinates[0][1]) {
      return 'LatLng';
    } else {
      return 'LngLat';
    }
  }
  const handleView = async (location) => {
    console.log('locaito viewn', location);

    const resultCoords = await checkCoordinateOrder(location);
    if (resultCoords != 'LngLat') {
      setSelectedLocation(location);
      handleSelectLocation('select', { ...location, mode: 'view' });
    } else {
      location.locations.features[0].geometry.coordinates[0] = location.locations.features[0].geometry.coordinates[0].map((coord) => [
        coord[1],
        coord[0]
      ]);
      setSelectedLocation(location);
      handleSelectLocation('select', { ...location, mode: 'view' });
    }
  };

  const handleEdit = (location) => {
    console.log('locaito viewn', location);
    setSelectedLocation(location);
    if (location.type == 'draw') {
      handleSelectLocation('draw', { ...location, mode: 'edit' });
    } else if (location.type == 'select') {
      handleSelectLocation('select', { ...location, mode: 'edit', clearMapStatus: false });
    } else {
      handleSelectLocation('upload', { ...location, mode: 'edit' });
    }
  };

  if (locationList?.length > 0) {
    const filteredData = locationList.filter((item) =>
      Object.values(item).some((value) => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return false;
      })
    );

    sortedData = [...filteredData].sort((a, b) => {
      const compareValue = (a, b) => {
        if (a[orderBy] < b[orderBy]) return -1;
        if (a[orderBy] > b[orderBy]) return 1;
        return 0;
      };

      return sortDirection === 'asc' ? compareValue(a, b) : -compareValue(a, b);
    });
  } else {
    sortedData = [];
  }

  useEffect(() => {
    // Fetch data from API or set initial data
    console.log('location list in table', locationlist);
    setLocationList(locationlist);
  }, [locationlist]);

  return (
    <>
      <Box className="joblist_heading">
        <Grid container>
          <Grid item lg={9} md={9} sm={12} xs={12}>
            <h1 className="sub-heading">Locations List</h1>
          </Grid>
          <Grid item lg={3} md={3} sm={12} xs={12}>
            <TextField
              label="Search"
              variant="outlined"
              fullWidth
              size="small"
              onChange={(e) => setSearchTerm(e.target.value)}
              // InputProps={{
              //   startAdornment: <InputAdornment position="start">{/* You can add a search icon here if needed */}</InputAdornment>
              // }}
              inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
        </Grid>
      </Box>

      <TableContainer className="locationListTable">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => handleSort('locationName')}>
                Location Name
                {orderBy === 'locationName' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell>
              <TableCell onClick={() => handleSort('file')}>
                File Name
                {orderBy === 'filename' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell>
              <TableCell onClick={() => handleSort('location_area_sq_mile')}>
                Area (in sq miles)
                {orderBy === 'location_area_sq_mile' && (
                  <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>
                )}
              </TableCell>
              <TableCell onClick={() => handleSort('calculated_cost')}>
                Price (in $)
                {orderBy === 'calculated_cost' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell>
              <TableCell onClick={() => handleSort('requested_timestamp')}>
                Created On
                {orderBy === 'requested_timestamp' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={8} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {sortedData?.length === 0 ? (
                <TableCell colSpan={8} align="center">
                  <Typography variant="h4" color="green" component="span" style={{ marginLeft: '0px', lineHeight: 4 }}>
                    No Records Found!
                  </Typography>
                </TableCell>
              ) : (
                <>
                  <TableBody>
                    {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                      <TableRow key={item.locationName}>
                        <TableCell>{item.locationName}</TableCell>
                        <TableCell>{item.filename}</TableCell>
                        <TableCell>{item.location_area_sq_mile?.toFixed(2)}</TableCell>
                        <TableCell>${item.calculated_cost?.toFixed(2)}</TableCell>
                        <TableCell>{item.requested_timestamp}</TableCell>
                        <TableCell>
                          <div className="editdeleteview">
                            <Tooltip title="View" arrow>
                              <EyeOutlined className="actionIcon view" onClick={() => handleView(item)} />
                            </Tooltip>
                            <Tooltip title="Edit" arrow>
                              <EditOutlined className="actionIcon edit" onClick={() => handleEdit(item)} />
                            </Tooltip>
                            {/* <DeleteOutlined className="actionIcon delete" onClick={() => handleDelete(item)} /> */}
                            <Tooltip title="Delete" arrow>
                              <DeleteOutlined
                                className="actionIcon delete"
                                onClick={() => {
                                  setDeletedItem(item);
                                  setDisplayDeleteAlert(true);
                                }}
                              />
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </>
              )}
            </>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className="page_btn"
      />
      <Dialog
        open={displayDeleteAlert}
        // TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle style={{ color: 'red' }}>Delete Alert</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">Are you sure? you want to delete the location</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Close
          </Button>
          <Button className="delete" variant="contained" onClick={() => handleDelete(deletedItem)}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectedLocationTable;
