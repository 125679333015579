import React, { useState, useEffect, useRef } from 'react';
import { BaseUrl } from 'config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Tooltip from '@mui/material/Tooltip';
import { useSelector, useDispatch } from 'react-redux';
import {
  TextField,
  Grid,
  Typography,
  Snackbar,
  Alert,
  Container,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  Switch
} from '@mui/material';
import SessionExpired from 'pages/session/sessionExpired';
import userProfile from '../../image/userprofile.png';
import CircularProgress from '@mui/material/CircularProgress';
import ModeIcon from '@mui/icons-material/Mode';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { userDetails } from 'store/reducers/menu';
import { FormatColorResetOutlined } from '../../../node_modules/@mui/icons-material/index';
import { useLocation } from '../../../node_modules/react-router-dom/dist/index';
import { EditProfileClicked, AccountTypeChangeToEnt } from 'store/reducers/menu';
import { transform } from 'lodash';
import UploadIcon from '@mui/icons-material/Upload';
// import profilePic from "../../image/profile_pic.png"
const Profile = () => {
  const navigate = useNavigate();
  const editButtonRef = useRef(null);
  const dispatch = useDispatch();
  const { user, companyInfo, isAccountTypeChangeToEnt, isEditProfileClicked } = useSelector((state) => state.menu);
  const token = localStorage.getItem('token');
  const userPlan = user?.plan != null && user.plan;
  const [isLoading, setIsLoading] = useState(false);
  const [showAccountTypeUpdateAlert, setShowAccountTypeUpdateAlert] = useState(false);

  const [inputValue, setInputValue] = useState({
    firstName: user ? user?.first_name : '',
    lastName: user ? user?.last_name : '',
    username: user ? user?.username : '',
    email: user ? user?.email : '',
    password: user ? user?.password : '',
    isActive: user ? user?.is_active : '',
    isSuperUser: user ? user?.is_superuser : '',
    isVerified: user ? user?.is_verified : '',
    companyName: companyInfo ? companyInfo?.company_name : '',
    companyUrl: companyInfo ? companyInfo?.url : '',
    phone: user ? user?.phone : '',
    //accountType: user ? user?.plan : '',
    accountType: isEditProfileClicked == true ? 'PPU' : user.plan,
    address: user ? user?.address : '',
    city: user ? user?.city : '',
    state: user ? user?.state : '',
    zipcode: user ? user?.zipcode : ''
  });
  const [editMode, setEditMode] = useState(false); // State to track edit mode
  // const [selectedAccountType, setSelectedAccountType] = useState(inputValue.accountType);
  const [isEditModeOn, setIsEditModeOn] = useState(false); // State to track whether edit mode is enabled or not
  const [validationErrors, setValidationErrors] = useState({
    firstName: '',
    lastName: '',
    username: ''
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: '',
    status: ''
  });
  const [openDialog, setOpenDialog] = useState(false);
  //const [pendingAccountType, setPendingAccountType] = useState(user.plan);
  const [pendingAccountType, setPendingAccountType] = useState(null);
  const [sessionExpired, setSessionExpired] = useState(false);
  useEffect(() => {
    if (isEditProfileClicked && editButtonRef.current) {
      // editButtonRef.current.click();
      setShowAccountTypeUpdateAlert(true);
    }
  }, [isEditProfileClicked]);

  const handleSessionExpiredClose = (status) => {
    setSessionExpired(status);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };
  const handleChange = (prop) => (event) => {
    let value = event.target.value;

    if (prop === 'phone') {
      value = value.replace(/\D/g, '');
      if (value.length > 10) {
        value = value.slice(0, 10);
      }
      if (value.length === 10) {
        value = value.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
      setInputValue({ ...inputValue, [prop]: value });
      if (value.length !== 12) {
        setValidationErrors((prevErrors) => ({ ...prevErrors, [prop]: 'Enter a valid 10-digit contact number' }));
      }
    } else if (prop === 'accountType') {
      if (value !== inputValue.accountType) {
        //setPendingAccountType(inputValue.accountType);
        setPendingAccountType(value);
        // if (value === 'ENT' && isEditProfileClicked) {
        //   dispatch(EditProfileClicked({ isEditProfileClicked: false }));
        //   dispatch(AccountTypeChangeToEnt({ isAccountTypeChangeToEnt: false }));
        // }
        setOpenDialog(true);
      }
    } else {
      setInputValue({ ...inputValue, [prop]: value });
    }

    // Reset validation errors when the user types
    setValidationErrors((prevErrors) => ({ ...prevErrors, [prop]: '' }));
  };

  const handleDialogConfirm = () => {
    //setInputValue({ ...inputValue, accountType: pendingAccountType });
    updateUserAccountType(pendingAccountType);
    setOpenDialog(false);
  };

  const handleDialogCancel = () => {
    setPendingAccountType('');
    setOpenDialog(false);
  };
  const validateForm = () => {
    let isValid = true;

    // Add your validation logic here for each field
    const requiredFields = ['firstName', 'lastName', 'username', 'email', 'phone', 'accountType'];

    requiredFields.forEach((field) => {
      if (!inputValue[field]) {
        setValidationErrors((prevErrors) => ({ ...prevErrors, [field]: 'This field is required' }));
        isValid = false;
      }
    });
    if (!/^\d{5}$/.test(inputValue.zipcode)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, zipcode: 'Zip code must be exactly 5 digits' }));
      isValid = false;
    }
    // if (!/^\d{10,12}$/.test(inputValue.phone)) {
    if (inputValue?.phone.replace(/\D/g, '').length !== 10) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, phone: 'Phone number must be between 10 to 12 digits' }));
      isValid = false;
    }
    if (!/^[a-zA-Z\s]*$/.test(inputValue.city)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, city: 'City must contain only letters and spaces' }));
      isValid = false;
    }
    if (!/^[a-zA-Z\s]*$/.test(inputValue.state)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, state: 'State must contain only letters and spaces' }));
      isValid = false;
    }
    return isValid;
  };

  const updateUserAccountType = async (accountType) => {
    console.log(accountType);
    try {
      const userPlanUpdateData = {
        plan: accountType
      };

      const userPlanUpdateResponse = await axios.post(`${BaseUrl}/userplan_update`, userPlanUpdateData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      });

      if (userPlanUpdateResponse?.status === 200) {
        setOpenSnackBar(true);
        setSnackBarMessage({
          message: 'Your request for change account sent successfully. You will soon get a confirmation mail.',
          status: 'success'
        });
        setPendingAccountType(null); // Reset pending change

        setTimeout(() => {
          navigate('/joblists');
        }, 1000);
      } else {
        return false;
      }
    } catch (error) {
      setIsLoading(false);
      setOpenSnackBar(true);
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired please login again to continue',
          status: 'error'
        });
      } else {
        setSnackBarMessage({
          message: 'Something went wrong. Try again later!',
          status: 'error'
        });
      }
      return false;
    }
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();

    // Validate before submitting
    if (validateForm()) {
      setIsLoading(true);
      const {
        firstName,
        lastName,
        email,
        accountType,
        address,
        zipcode,
        city,
        state,
        phone,
        username,
        password,
        isActive,
        isSuperUser,
        isVerified,
        companyName,
        companyUrl
      } = inputValue;
      const requestData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        username: username,
        password: password,
        is_active: isActive,
        is_superuser: isSuperUser,
        is_verified: isVerified,
        accountType: accountType,
        address: address,
        zipcode: zipcode,
        city: city,
        state: state,
        phone: phone,
        companyName: companyName,
        companyUrl: companyUrl
      };
      try {
        const response = await axios.patch(`${BaseUrl}/users/me`, requestData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        });

        if (response?.status === 200 && response?.data) {
          // await handleEnterpriseClick(accountType);

          let updateAccTypeRespone = false;
          if (accountType !== user?.plan) {
            console.log(accountType);
            updateAccTypeRespone = await updateUserAccountType(accountType);
          }
          setOpenSnackBar(true);
          setIsLoading(false);
          // update account type details
          dispatch(
            userDetails({
              ...response?.data,
              plan: updateAccTypeRespone ? accountType : user?.plan
            })
          );
          dispatch(EditProfileClicked({ isEditProfileClicked: false }));
          dispatch(AccountTypeChangeToEnt({ isAccountTypeChangeToEnt: false }));
          setSnackBarMessage({
            message: 'User profile updated successfully!',
            status: 'success'
          });
          setIsEditModeOn((prev) => !prev); // Toggle edit mode state
          setEditMode(!editMode); // Toggle edit mode

          if (accountType === 'ENT' && updateAccTypeRespone) {
            setTimeout(() => {
              navigate('/companyProfile');
            }, 1000);
          }
        }
      } catch (error) {
        setIsLoading(false);
        setOpenSnackBar(true);
        if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
          setSessionExpired(true);
          setSnackBarMessage({
            message: 'Your session expired please login again to continue',
            status: 'error'
          });
        } else {
          dispatch(EditProfileClicked({ isEditProfileClicked: false }));
          dispatch(AccountTypeChangeToEnt({ isAccountTypeChangeToEnt: false }));
          setSnackBarMessage({
            message: 'Something went wrong. Try again later!',
            status: 'error'
          });
        }
      }
    } else {
      setIsLoading(false); // Reset loading state if there are validation errors
    }
  };

  const handleEditModeChange = () => {
    setIsEditModeOn((prev) => !prev); // Toggle edit mode state
    setEditMode(!editMode); // Toggle edit mode
    setInputValue({
      firstName: user ? user?.first_name : '',
      lastName: user ? user?.last_name : '',
      username: user ? user?.username : '',
      email: user ? user?.email : '',
      password: user ? user?.password : '',
      isActive: user ? user?.is_active : '',
      isSuperUser: user ? user?.is_superuser : '',
      isVerified: user ? user?.is_verified : '',
      companyName: companyInfo ? companyInfo?.company_name : '',
      companyUrl: companyInfo ? companyInfo?.url : '',
      phone: user ? user?.phone : '',
      accountType: isEditProfileClicked === true ? 'PPU' : user.plan,
      // accountType: user ? user?.plan : '',
      address: user ? user?.address : '',
      city: user ? user?.city : '',
      state: user ? user?.state : '',
      zipcode: user ? user?.zipcode : ''
    });
  };
  return (
    <Container>
      <Typography variant="h5" className="heading" gutterBottom>
                  User Profile
                  {/* <Tooltip title="Add Company" arrow>
                    <AddCircleIcon
                      color="#257874"
                      onClick={() => navigate('/companyProfile')}
                      sx={{
                        marginLeft: '10px',
                        cursor: 'pointer',
                        fontSize: '35px'
                      }}
                    />
                  </Tooltip> */}
                </Typography>
      {/* <Grid container>
        <Grid item lg={2}></Grid>
        <Grid item lg={8}>
          <Grid container alignItems="center" justifyContent="center">
            <Grid item xs={8}>
              <Box display="flex" alignItems="baseline">
                <Typography variant="h5" className="heading" gutterBottom>
                  User Profile
                  <Tooltip title="Add Company" arrow>
                    <AddCircleIcon
                      color="#257874"
                      onClick={() => navigate('/companyProfile')}
                      sx={{
                        marginLeft: '10px',
                        cursor: 'pointer',
                        fontSize: '35px'
                      }}
                    />
                  </Tooltip>
                </Typography>
              </Box>
            </Grid>
        
            <Grid item xs={4} style={{ textAlign: 'right' }}>
            
              {editMode ? (
                <Button size="large" onClick={handleEditModeChange}>
                  <KeyboardArrowLeftIcon /> Back
                </Button>
              ) : (
                <>
                <Button size="large" onClick={() => navigate('/companyProfile')}>
                  <AddCircleIcon style={{transform:"scale(0.8)", marginRight:5}} /> Add Company
                </Button>
                <Button size="large" ref={editButtonRef} onClick={handleEditModeChange} style={{marginLeft:10}}>
                  <EditIcon style={{transform:"scale(0.8)", marginRight:5}} /> Edit Profile
                </Button>
                </>
              )}
            </Grid>
          </Grid>
          <Box className="boundaryBoxprofile">
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={6} xs={12} className="pic">
                  <img src={userProfile} width="150px" height={'150px'} />
                </Grid>
                <Grid item lg={8} md={8} sm={6} xs={12}>
                  <Grid container className="detail">
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography variant="body1" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                        First Name
                      </Typography>
                    </Grid>
   
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      {editMode ? (
                        <TextField
                          
                          fullWidth
                          required
                          value={inputValue.firstName}
                          onChange={handleChange('firstName')}
                          margin="normal"
                          variant="outlined"
                          disabled={!editMode} 
                          error={!!validationErrors.firstName}
                          helperText={validationErrors.firstName}
                          
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Typography variant="body1">{inputValue?.firstName} </Typography>
                      )}
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography variant="body1" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                        Last Name
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      {editMode ? (
                        <TextField

                          fullWidth
                          required
                          value={inputValue.lastName}
                          onChange={handleChange('lastName')}
                          margin="normal"
                          variant="outlined"
                          disabled={!editMode} 
                          error={!!validationErrors.lastName}
                          helperText={validationErrors.lastName}

                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Typography variant="body1">{inputValue?.lastName} </Typography>
                      )}
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography variant="body1" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                        Username
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      {editMode ? (
                        <TextField
                          fullWidth
                          required
                          value={inputValue.username}
                          onChange={handleChange('username')}
                          margin="normal"
                          variant="outlined"
                          disabled={!editMode} 
                          error={!!validationErrors.username}
                          helperText={validationErrors.username}
                          inputProps={{ style: { padding: '3px 10px' } }}
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Typography variant="body1">{inputValue?.username} </Typography>
                      )}
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography variant="body1" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                        Phone
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      {editMode ? (
                        <TextField

                          fullWidth
                          required
                          value={inputValue.phone}
                          onChange={handleChange('phone')}
                          margin="normal"
                          variant="outlined"
                          disabled={!editMode} 
                          error={!!validationErrors.phone}
                          helperText={validationErrors.phone}
                          inputProps={{ style: { padding: '3px 10px' } }}
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Typography variant="body1">{inputValue?.phone}</Typography>
                      )}
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography variant="body1" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                        Email
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      {editMode ? (
                        <TextField

                          type="email"
                          fullWidth
                          required
                          value={inputValue.email}
                          onChange={handleChange('email')}
                          margin="normal"
                          variant="outlined"
                          disabled={!editMode} 
                          error={!!validationErrors.email}
                          helperText={validationErrors.email}
                          inputProps={{ style: { padding: '3px 10px' } }}
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Typography variant="body1">{inputValue?.email} </Typography>
                      )}
                    </Grid>
                    {!editMode && (
                      <>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                          <Typography variant="body1" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                            Account Type
                          </Typography>
                        </Grid>
                        <Grid item lg={9} md={9} sm={9} xs={9}>
                          {console.log('accountType', inputValue.accountType)}
                          {editMode ? (
                            <TextField
                              select
                              fullWidth
                              required
                              value={inputValue.accountType}
                              // onChange={(event) => setSelectedAccountType(event.target.value)}
                              onChange={handleChange('accountType')}
                              margin="normal"
                              variant="outlined"
                              // disabled={true}
                              inputProps={{ style: { padding: '3px 10px' } }}
                              InputLabelProps={{ shrink: true }}
                            >
                              <MenuItem value="PPU">PPU</MenuItem>
                              <MenuItem value="ENT">ENT</MenuItem>
                            </TextField>
                          ) : (
                            <Typography variant="body1">{inputValue?.accountType}</Typography>
                          )}
                          <FormControlLabel
                            style={{ marginTop: '10px', marginLeft: '0px', paddingLeft: '0px' }}
                            control={
                              <Switch
                                checked={inputValue.accountType === 'ENT'}
                                // onChange={() =>
                                //   handleChange('accountType')({ target: { value: inputValue.accountType === 'ENT' ? 'PPU' : 'ENT' } })
                                // }
                                onChange={() => {
                                  const newAccountType = inputValue.accountType === 'ENT' ? 'PPU' : 'ENT';
                                  setPendingAccountType(newAccountType);
                                  setOpenDialog(true);
                                }}
                                color="primary"
                              />
                            }
                            label={inputValue.accountType === 'ENT' ? 'ENT' : 'PPU'}
                          />{' '}
                        </Grid>
                      </>
                    )}
                    {userPlan != 'PPU' && user?.company_status && (
                      <>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                          <Typography variant="body1" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                            Company Name
                          </Typography>
                        </Grid>
                        <Grid item lg={9} md={9} sm={9} xs={9}>
                          {editMode ? (
                            <TextField

                              type="text"
                              fullWidth
                              required
                              value={inputValue.companyName}
                              onChange={handleChange('companyName')}
                              margin="normal"
                              variant="outlined"
                              disabled={!editMode} 
                              error={!!validationErrors.companyName}
                              helperText={validationErrors.companyName}
                              inputProps={{ style: { padding: '3px 10px' } }}
                              InputLabelProps={{ shrink: true }}
                            />
                          ) : (
                            <Typography variant="body1">{inputValue?.companyName}</Typography>
                          )}
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                          <Typography variant="body1" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                            Company Url
                          </Typography>
                        </Grid>
                        <Grid item lg={9} md={9} sm={9} xs={9}>
                          {editMode ? (
                            <TextField
                              type="text"
                              fullWidth
                              required
                              value={inputValue.companyUrl}
                              onChange={handleChange('companyUrl')}
                              margin="normal"
                              variant="outlined"
                              disabled={!editMode} 
                              error={!!validationErrors.companyUrl}
                              helperText={validationErrors.companyUrl}
                              inputProps={{ style: { padding: '3px 10px' } }}
                              InputLabelProps={{ shrink: true }}
                            />
                          ) : (
                            <Typography variant="body1">{inputValue?.companyUrl}</Typography>
                          )}
                        </Grid>
                      </>
                    )}
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography variant="body1" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                        Address
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      {editMode ? (
                        <TextField

                          type="text"
                          fullWidth

                          value={inputValue.address}
                          onChange={handleChange('address')}
                          margin="normal"
                          variant="outlined"
                          disabled={!editMode} 
                          error={!!validationErrors.address}
                          helperText={validationErrors.address}
                          inputProps={{ style: { padding: '3px 10px' } }}
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Typography variant="body1">{inputValue?.address}</Typography>
                      )}
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography variant="body1" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                        Zipcode
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      {editMode ? (
                        <TextField

                          type="text"
                          fullWidth
 
                          value={inputValue.zipcode}
                          onChange={handleChange('zipcode')}
                          margin="normal"
                          variant="outlined"
                          disabled={!editMode}
                          error={!!validationErrors.zipcode}
                          helperText={validationErrors.zipcode}
                          inputProps={{ style: { padding: '3px 10px' } }}
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Typography variant="body1">{inputValue?.zipcode}</Typography>
                      )}
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography variant="body1" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                        City
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      {editMode ? (
                        <TextField

                          type="text"
                          fullWidth

                          value={inputValue.city}
                          onChange={handleChange('city')}
                          margin="normal"
                          variant="outlined"
                          disabled={!editMode}
                          error={!!validationErrors.city}
                          helperText={validationErrors.city}
                          inputProps={{ style: { padding: '3px 10px' } }}
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Typography variant="body1">{inputValue?.city}</Typography>
                      )}
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography variant="body1" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                        State
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      {editMode ? (
                        <TextField
                      
                          type="text"
                          fullWidth
             
                          value={inputValue.state}
                          onChange={handleChange('state')}
                          margin="normal"
                          variant="outlined"
                          disabled={!editMode} 
                          error={!!validationErrors.state}
                          helperText={validationErrors.state}
                          inputProps={{ style: { padding: '3px 10px' } }}
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Typography variant="body1">{inputValue?.state}</Typography>
                      )}
                    </Grid>
                  </Grid>
                  {editMode && (
                    <Button
                      type="submit"

                      variant="contained"
                      size="large"
                      style={{ margin: '30px auto', display: 'table' }}

                    >
                      {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Update'}
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Dialog
                open={openDialog}
                onClose={handleDialogCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{'Confirm Account Type Change'}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to change your account type to {pendingAccountType === 'ENT' ? 'Enterprise' : 'Pay-Per-Use'}?
                    {pendingAccountType === 'ENT' && ' You will be redirected to the company profile page.'}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDialogCancel}>No</Button>
                  <Button onClick={handleDialogConfirm} autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={showAccountTypeUpdateAlert}
                autoHideDuration={10000}
                onClose={handleAccountTypeUpdateAlertClose}
              >
                <Alert onClose={handleAccountTypeUpdateAlertClose} severity="success" variant="filled" sx={{ width: '100%' }}>
                  Account type has been updated successfully.
                </Alert>
              </Snackbar>
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                style={{marginTop:50}}
                open={openSnackBar}
                autoHideDuration={8000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
                  {snackBarMessage.message}
                </Alert>
              </Snackbar>
            </form>
            {sessionExpired ? <SessionExpired handleSessionExpiredClose={handleSessionExpiredClose} /> : ''}
          </Box>
        <Box style={{height:20}}></Box>
          <Box className="boundaryBoxprofile">
            <Grid container >
              <Grid item sm={9}>
              <Typography variant="h5" style={{lineHeight:"40px", fontSize: "1.05rem", color:"#469100"}}>Change Account Type</Typography>
              </Grid>
              <Grid item sm={3}>
              <FormControlLabel
                            style={{ marginTop: '10px', marginLeft: '0px', paddingLeft: '0px' }}
                            control={
                              <Switch
                                checked={inputValue.accountType === 'ENT'}
                                onChange={() =>
                                  handleChange('accountType')({ target: { value: inputValue.accountType === 'ENT' ? 'PPU' : 'ENT' } })
                                }
                                color="primary"
                              />
                            }
                            label={inputValue.accountType === 'ENT' ? 'ENT' : 'PPU'}
                          />
              </Grid>
            </Grid>
           
          </Box>
        </Grid>
      </Grid> */}



      <Grid container spacing={3}>
           <Grid item lg={3} md={3} sm={12} xs={12}>
            <Box className="boundaryBoxprofile ">
              <div className='profilePic'>
             <input type='file' />
             <img src={userProfile} className='profilePicImg'/>
             <span>
              <UploadIcon/> <br/>
             Change or upload <br/>
             profile pic
             </span>
             </div>
             <Typography variant="h3" style={{fontWeight:400, textAlign:"center"}}>
             {inputValue?.firstName} {inputValue?.lastName}
             </Typography>
             <Typography variant="h4" style={{fontWeight:400, textAlign:"center", color:"#469100"}}>
             {inputValue.accountType === 'ENT' ? 'Enterprise User' : 'Pay Per Use'}
             </Typography>
            </Box>
            </Grid> 
            <Grid item lg={9} md={9} sm={12} xs={12}>
            
            <form onSubmit={handleSubmit}>
            <Box className="boundaryBoxprofile">
              <Grid container spacing={2}>
                
                <Grid item lg={7} md={7} sm={12} xs={12}>
                  <Grid container className="detail">

                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography variant="h4" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                        Username
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      {editMode ? (
                        <TextField
                          
                          fullWidth
                          required
                          value={inputValue.username}
                          onChange={handleChange('username')}
                          margin="normal"
                          variant="outlined"
                          disabled={!editMode} 
                          error={!!validationErrors.username}
                          helperText={validationErrors.username}
                          inputProps={{ style: { padding: '3px 10px' } }}
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Typography variant="h4">{inputValue?.username} </Typography>
                      )}
                    </Grid>
                    </Grid>
                    </Grid>
                    <Grid item lg={5} md={5} sm={12} xs={12}>
                  <Grid container className="detail">
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography variant="h4" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                        Phone
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      {editMode ? (
                        <TextField
                          
                          fullWidth
                          required
                          value={inputValue.phone}
                          onChange={handleChange('phone')}
                          margin="normal"
                          variant="outlined"
                          disabled={!editMode} 
                          error={!!validationErrors.phone}
                          helperText={validationErrors.phone}
                          inputProps={{ style: { padding: '3px 10px' } }}
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Typography variant="h4">{inputValue?.phone}</Typography>
                      )}
                    </Grid>
                    </Grid>
                    </Grid>
                    <Grid item lg={7} md={7} sm={12} xs={12}>
                  <Grid container className="detail">
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography variant="h4" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                        Email
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      {editMode ? (
                        <TextField
                          //label="Email"
                          type="email"
                          fullWidth
                          required
                          value={inputValue.email}
                          onChange={handleChange('email')}
                          margin="normal"
                          variant="outlined"
                          disabled={!editMode} 
                          error={!!validationErrors.email}
                          helperText={validationErrors.email}
                          inputProps={{ style: { padding: '3px 10px' } }}
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Typography variant="h4">{inputValue?.email} </Typography>
                      )}
                    </Grid>
                    </Grid>
                    </Grid>
                    
                    
                    {userPlan != 'PPU' && user?.company_status && (
                      <>
                      <Grid item lg={6} md={6} sm={6} xs={12}>
                      <Grid container className="detail">
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                          <Typography variant="h4" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                            Company Name
                          </Typography>
                        </Grid>
                        <Grid item lg={9} md={9} sm={9} xs={9}>
                          {editMode ? (
                            <TextField
                              // label="Email"
                              type="text"
                              fullWidth
                              required
                              value={inputValue.companyName}
                              onChange={handleChange('companyName')}
                              margin="normal"
                              variant="outlined"
                              disabled={!editMode} 
                              error={!!validationErrors.companyName}
                              helperText={validationErrors.companyName}
                              inputProps={{ style: { padding: '3px 10px' } }}
                              InputLabelProps={{ shrink: true }}
                            />
                          ) : (
                            <Typography variant="h4">{inputValue?.companyName}</Typography>
                          )}
                        </Grid>
                        </Grid>
                        </Grid>

                        <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Grid container className="detail"></Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                          <Typography variant="h4" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                            Company Url
                          </Typography>
                        </Grid>
                        <Grid item lg={9} md={9} sm={9} xs={9}>
                          {editMode ? (
                            <TextField
                              // label="Email"
                              type="text"
                              fullWidth
                              required
                              value={inputValue.companyUrl}
                              onChange={handleChange('companyUrl')}
                              margin="normal"
                              variant="outlined"
                              disabled={!editMode} 
                              error={!!validationErrors.companyUrl}
                              helperText={validationErrors.companyUrl}
                              inputProps={{ style: { padding: '3px 10px' } }}
                              InputLabelProps={{ shrink: true }}
                            />
                              ) : (
                            <Typography variant="h4">{inputValue?.companyUrl}</Typography>
                          )}
                        </Grid>
                        </Grid>
          
                      </>
                    )}
                    
                    <Grid item lg={5} md={5} sm={12} xs={12}>
                  <Grid container className="detail">
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography variant="h4" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                        Address
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      {editMode ? (
                        <TextField
                          // label="Email"
                          type="text"
                          fullWidth
                          //required
                          value={inputValue.address}
                          onChange={handleChange('address')}
                          margin="normal"
                          variant="outlined"
                          disabled={!editMode} 
                          error={!!validationErrors.address}
                          helperText={validationErrors.address}
                          inputProps={{ style: { padding: '3px 10px' } }}
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Typography variant="h4">{inputValue?.address}</Typography>
                      )}
                    </Grid>

                    </Grid>
                    </Grid>
                    <Grid item lg={7} md={7} sm={12} xs={12}>
                  <Grid container className="detail">
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography variant="h4" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                        Zipcode
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      {editMode ? (
                        <TextField
                          // label="Email"
                          type="text"
                          fullWidth
                          //required
                          value={inputValue.zipcode}
                          onChange={handleChange('zipcode')}
                          margin="normal"
                          variant="outlined"
                          disabled={!editMode} 
                          error={!!validationErrors.zipcode}
                          helperText={validationErrors.zipcode}
                          inputProps={{ style: { padding: '3px 10px' } }}
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Typography variant="h4">{inputValue?.zipcode}</Typography>
                      )}
                    </Grid>

                    </Grid>
                    </Grid>
                    <Grid item lg={5} md={5} sm={12} xs={12}>
                  <Grid container className="detail">
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography variant="h4" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                        City
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      {editMode ? (
                        <TextField
                          // label="Email"
                          type="text"
                          fullWidth
                          //required
                          value={inputValue.city}
                          onChange={handleChange('city')}
                          margin="normal"
                          variant="outlined"
                          disabled={!editMode} 
                          error={!!validationErrors.city}
                          helperText={validationErrors.city}
                          inputProps={{ style: { padding: '3px 10px' } }}
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Typography variant="h4">{inputValue?.city}</Typography>
                      )}
                    </Grid>

                    </Grid>
                    </Grid>
                    <Grid item lg={7} md={7} sm={12} xs={12}>
                  <Grid container className="detail">
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      <Typography variant="h4" className={!editMode ? 'userdetailssection1' : 'userdetailssectionEdit'}>
                        State
                      </Typography>
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      {editMode ? (
                        <TextField
                          // label="Email"
                          type="text"
                          fullWidth
                          //required
                          value={inputValue.state}
                          onChange={handleChange('state')}
                          margin="normal"
                          variant="outlined"
                          disabled={!editMode} 
                          error={!!validationErrors.state}
                          helperText={validationErrors.state}
                          inputProps={{ style: { padding: '3px 10px' } }}
                          InputLabelProps={{ shrink: true }}
                        />
                      ) : (
                        <Typography variant="h4">{inputValue?.state}</Typography>
                      )}
                    </Grid>
          </Grid>
                  </Grid>
                  {/* {editMode && (
                    <Button
                      type="submit"
                      // className="editbtn"
                      variant="contained"
                      size="large"
                      style={{ margin: '30px auto', display: 'table' }}
                      // style={{ marginLeft: '80px', marginBottom: '15px' }}
                    >
                      {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Update'}
                    </Button>
                  )} */}
                </Grid>
           
              <Dialog
                open={openDialog}
                onClose={handleDialogCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">{'Confirm Account Type Change'}</DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to change your account type to {pendingAccountType === 'ENT' ? 'Enterprise' : 'Pay-Per-Use'}?
                    {/* {pendingAccountType === 'ENT' && ' You will be redirected to the company profile page.'} */}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleDialogCancel}>No</Button>
                  <Button onClick={handleDialogConfirm} autoFocus>
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>
              {/* <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={showAccountTypeUpdateAlert}
                autoHideDuration={10000}
                onClose={handleAccountTypeUpdateAlertClose}
              >
                <Alert onClose={handleAccountTypeUpdateAlertClose} severity="success" variant="filled" sx={{ width: '100%' }}>
                  Account type has been updated successfully.
                </Alert>
              </Snackbar> */}
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                style={{ marginTop: 50 }}
                open={openSnackBar}
                autoHideDuration={8000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
                  {snackBarMessage.message}
                </Alert>
              </Snackbar>
            
            {sessionExpired ? <SessionExpired handleSessionExpiredClose={handleSessionExpiredClose} /> : ''}
          </Box>
          <Box className="boundaryBoxprofile" style={{marginTop:20}}>
            <Grid container >
              <Grid item sm={6}>
              <Typography variant="h5" style={{lineHeight:"40px", fontSize: "1.05rem", color:"#469100", display:"inline-block"}}>Change Account Type</Typography>
             
              <FormControlLabel
                            style={{display:"inline-block" }}
                            control={
                              <Switch
                                checked={inputValue.accountType === 'ENT'}
                                onChange={() =>
                                  handleChange('accountType')({ target: { value: inputValue.accountType === 'ENT' ? 'PPU' : 'ENT' } })
                                }
                                color="primary"
                              />
                            }
                            label={inputValue.accountType === 'ENT' ? 'ENT' : 'PPU'}
                          />
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'right' }}>
            
              {editMode ? (
                <>
                 <Button
                      type="submit"
                      // className="editbtn"
                      variant="contained"
                      size="large"
                      // style={{ marginLeft: '80px', marginBottom: '15px' }}
                    >
                      {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Update'}
                    </Button>
                    <Button size="large" onClick={handleEditModeChange} style={{marginLeft:10}}>
                  <KeyboardArrowLeftIcon /> Back
                </Button>
</>
                
              ) : (
                <>
                {inputValue.accountType === 'ENT' && user?.company_id != null ?
                
                (
                  
                  <Button size="large" onClick={() => navigate('/companyProfile')}>
                   View Company
                </Button>
                ) : (
                  <Button size="large" onClick={() => navigate('/companyProfile')}>
                  <AddCircleIcon style={{transform:"scale(0.8)", marginRight:5}} /> Add Company
                </Button>
                )}
               
                <Button size="large" ref={editButtonRef} onClick={handleEditModeChange} style={{marginLeft:10}}>
                  <EditIcon style={{transform:"scale(0.8)", marginRight:5}} /> Edit Profile
                </Button>
                </>
              )}
              
            </Grid>
            </Grid>
           
          </Box>
          </form>
            </Grid>                  
      </Grid>
    </Container>
  );
};

export default Profile;
