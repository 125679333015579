// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DxfExport div.help-text {
    font-style: italic;
    padding-bottom: 0.5em;
}

.DxfExport div.export-settings {
    font-size: small;
}

.DxfExport div.export-settings > span {
    margin: 0 0.25em;
}

.DxfExport div.export-settings select {
    border: 1px solid var(--border-color);
    background-color: var(--input-bg-color);
    display: inline-flex;
    align-items: center;
}

.DxfExport div.input-container input {
    width: 8ch;
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/plugins/style/DxfExport.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,qCAAqC;IACrC,uCAAuC;IACvC,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd","sourcesContent":[".DxfExport div.help-text {\n    font-style: italic;\n    padding-bottom: 0.5em;\n}\n\n.DxfExport div.export-settings {\n    font-size: small;\n}\n\n.DxfExport div.export-settings > span {\n    margin: 0 0.25em;\n}\n\n.DxfExport div.export-settings select {\n    border: 1px solid var(--border-color);\n    background-color: var(--input-bg-color);\n    display: inline-flex;\n    align-items: center;\n}\n\n.DxfExport div.input-container input {\n    width: 8ch;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
