// material-ui
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// project import
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useEffect, useState } from 'react';

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
  const [menuItems, setMenuItems] = useState([]);
  const { isLoggedIn, user } = useSelector((state) => state.menu);
  const navigate = useNavigate();
  useEffect(() => {
    let resultPage = [];
    if (isLoggedIn && user?.plan === 'PPU') {
      resultPage = menuItem.items.filter((val) => val.id == 'authentication');
    } else if (isLoggedIn && user?.plan === 'ENT') {
      const res = menuItem.items.filter((val) => val.id == 'authentication')[0]?.children?.filter((val) => val?.id !== 'payment_history');
      resultPage = [
        {
          children: res,
          id: 'authentication',
          title: 'Navigation',
          type: 'group'
        }
      ];
    } else {
      resultPage = menuItem.items.filter((val) => val.id != 'authentication');
    }
    setMenuItems(resultPage);
  }, [isLoggedIn, user]);

  const navGroups = menuItems.map((item) => {
    switch (item.type) {
      case 'group':
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Navigation Group
          </Typography>
        );
    }
  });

  return <Box sx={{ pt: 2 }}>{navGroups}</Box>;
};

export default Navigation;
