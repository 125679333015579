import React from 'react';
import { Grid, Box, Typography, CircularProgress } from '@mui/material';
import CarIcon from '../../image/carIcon.png';
import industryIcon from '../../image/industryIcon.png';
import HouseHoldIcon from '../../image/householdIcon.png';
import VegitationIcon from '../../image/vegitationIcon.png';


const CaseComponents = ({ isLoading, dataFetchedStatus, detectedData }) => {
  console.log('detectedData', detectedData);
//   const totalco2 =
//     detectedData?.total_emissions_household 
//     detectedData?.co2_emission_normal_vehicle +
//     detectedData?.industry_coal_co2_emission +
//     detectedData?.emission_from_vegeatation_rainforest;
    
  
  return (
    <Grid container spacing={3}>
      <Grid item md={12} sm={12}>
          <Typography variant="h3" gutterBottom style={{textAlign : 'center'}}>
            CO<sub>2</sub> Emission in Metric Ton
          </Typography>
          <Grid container spacing={3}>
            <Grid item lg={3} md={3} sm={6} xs={12}>
            <Box className="boundryBox">
              <div className="stats-container">
                <div className="icon">
                  <img src={HouseHoldIcon} alt="" />
                </div>
                <div>
                  <p>HOUSEHOLD</p>
                  <span>
                    {isLoading ? (
                      <CircularProgress size={20} />
                    ) : dataFetchedStatus ? (
                      detectedData?.total_emissions_household?.toFixed(0)
                    ) : (
                      0
                    )}
                   <span style={{fontSize: 12}}>metric ton</span> 
                  </span>
                </div>
              </div>
              </Box>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
            <Box className="boundryBox">
              <div className="stats-container">
                <div className="icon">
                  <img src={CarIcon} alt="" />
                </div>
                <div>
                  <p>TRANSPORTATION</p>
                  <span>
                    {isLoading ? (
                      <CircularProgress size={20} />
                    ) : dataFetchedStatus ? (
                      detectedData?.co2_emission_normal_vehicle?.toFixed(0)
                    ) : (
                      0
                    )}
                    <span style={{fontSize: 12}}>metric ton</span> 
                  </span>
                </div>
              </div>
              </Box>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
            <Box className="boundryBox">
              <div className="stats-container">
                <div className="icon">
                  <img src={VegitationIcon} alt="" />
                </div>
                <div>
                  <p>VEGETATION</p>
                  <span>
                    {isLoading ? (
                      <CircularProgress size={20} />
                    ) : dataFetchedStatus ? (
                      detectedData?.emission_from_vegeatation_rainforest?.toFixed(0)
                    ) : (
                      0
                    )}
                    <span style={{fontSize: 12}}>metric ton</span> 
                  </span>
                </div>
              </div>
              </Box>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
            <Box className="boundryBox">
              <div className="stats-container">
                <div className="icon">
                  <img src={industryIcon} alt="" />
                </div>
                <div>
                  <p>INDUSTRIES</p>
                  <span>
                    {isLoading ? (
                      <CircularProgress size={20} />
                    ) : dataFetchedStatus ? (
                      detectedData?.industry_coal_co2_emission?.toFixed(0)
                    ) : (
                      0
                    )}
                    <span style={{fontSize: 12}}>metric ton</span> 
                  </span>
                </div>
              </div>
              </Box>
            </Grid>
            {/* <Grid item sm={2}>
              <div className="stats-container">
                <div className="icon">
                  <img src={Solar} alt="" />
                </div>
                <div>
                  <p>SOLAR PANNEL</p>
                  <span>
                    {isLoading ? (
                      <CircularProgress size={20} />
                    ) : dataFetchedStatus ? (
                      detectedData?.objectdetection?.CarbonByType?.SolarPanel.toFixed(0)
                    ) : (
                      0
                    )}
                  </span>
                </div>
              </div>
            </Grid> */}
            {/* <Grid item sm={2}>
              <div className="stats-container">
                <div className="icon">
                  <img src={Water} alt="" />
                </div>
                <div>
                  <p>WATER BODIES</p>
                  <span>
                    {isLoading ? (
                      <CircularProgress size={20} />
                    ) : dataFetchedStatus ? (
                      detectedData?.objectdetection?.CarbonByType?.Waterbodies.toFixed(0)
                    ) : (
                      0
                    )}
                  </span>
                </div>
              </div>
            </Grid> */}
          </Grid>
      </Grid>
      {/* <Grid item md={4} sm={12}>
        <Box className="para" sx={{ bgcolor: '#01A6D0' }}>
          <Typography variant="h4" gutterBottom>
            {isLoading ? <CircularProgress size={20} /> : dataFetchedStatus ? detectedData?.agb?.TotalAGB : 0}
          </Typography>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <div>
                <p>TOTAL ABOVEGROUND BIOMASS(AGB)</p>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Grid> */}
      {/* <Grid item md={4} sm={12}>
        <Box className="para" sx={{ bgcolor: '#FF9900' }}>
          <Typography variant="h4" gutterBottom>
            {isLoading ? <CircularProgress size={20} /> : dataFetchedStatus ? detectedData?.ndvi?.MeanNDVI.toFixed(4) : 0}
          </Typography>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <div>
                <p>MEAN NORMALIZED DIFFERENCE VEGETATION INDEX (NDVI)</p>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Grid> */}
      {/* <Grid item md={4} sm={12}>
        <Box className="para" sx={{ bgcolor: '#257874' }}>
          <Typography variant="h4" gutterBottom>
            {isLoading ? <CircularProgress size={20} /> : dataFetchedStatus ? totalco2?.toFixed(0) : 0}
          </Typography>
          <Grid container spacing={3}>
            <Grid item sm={12}>
              <div className="stats-container">
                <p>Overall CO2 Emission in Metric Ton</p>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Grid> */}
    </Grid>
  );
};

export default CaseComponents;
