import React, { useState } from 'react';
import { Button, Table, Typography, TableContainer, TableHead, TableBody, TableRow, TableCell, Grid } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const JobDetails = ({ isLoading, jobDetails, personalJobList }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm] = useState('');
  const [orderBy, setOrderBy] = useState('request_date');
  const [sortDirection, setSortDirection] = useState('asc');
  const { user } = useSelector((state) => state.menu);

  // Filter data based on search term
  const filteredData = jobDetails?.filter((item, index) =>
    Object.values(item).some((value) => {
      if (typeof value === 'string' && index <= 4) {
        return value.toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    })
  );
  function getFormattedDate(timestamp) {
    const date = new Date(timestamp);
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  }

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && sortDirection === 'asc';
    setOrderBy(columnId);
    // If sorting by requested_date or requested_time, set sort direction to descending
    if (columnId === 'requested_date' || columnId === 'requested_time') {
      setSortDirection('asc');
    } else {
      // For other columns, toggle the sort direction
      setSortDirection(isAsc ? 'desc' : 'asc');
    }
  };

  const sortedData = Array.isArray(filteredData) ? [...filteredData].sort((a, b) => {
    const compareValue = (a, b) => {
      if (a[orderBy] < b[orderBy]) return -1;
      if (a[orderBy] > b[orderBy]) return 1;
      return 0;
    };
  
    if (orderBy === 'requested_date' || orderBy === 'requested_time') {
      // If sorting by requested_date or requested_time, prioritize sorting by date and then time
      if (a.requested_date === b.requested_date) {
        return sortDirection === 'desc'
          ? b.requested_time.localeCompare(a.requested_time)
          : a.requested_time.localeCompare(b.requested_time);
      } else {
        return sortDirection === 'asc'
          ? a.requested_date.localeCompare(b.requested_date)
          : b.requested_date.localeCompare(a.requested_date);
      }
    } else {
      // For other columns, use regular sorting logic
      return sortDirection === 'asc' ? compareValue(a, b) : compareValue(b, a);
    }
  }) : [];
  
  console.log(sortedData);
  
  
  const onDetailsClick = () => {
    if (sortedData?.length === 0) {
      navigate('/addjobs');
    } else {
      navigate('/joblists');
    }
  };
console.log(personalJobList)
  return (
    <Grid>
      <Grid item xs={12} className="joblisthead">
        <h3>{personalJobList ? "Personal Jobs List" : "Jobs List"}</h3>
        <Button size="small" variant="contained" color="primary" onClick={onDetailsClick}>
          {sortedData?.length === 0 ? 'Add Job Request' : 'More Details'}
        </Button>
      </Grid>
      {isLoading ? (
        <center>
          {' '}
          <CircularProgress size={12} />
        </center>
      ) : (
        <>
          {sortedData?.length === 0 ? (
            'No Records Found!'
          ) : (
            <>
              <TableContainer className="dashboardTable">
                <Table>
                  <TableHead>
                    <TableRow key="job_id">
                      {/* Render your table headers here */}
                      {user.company_id && (
                        <TableCell onClick={() => handleSort('user_email')}>
                          User Email
                          {orderBy === 'user_email' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
                        </TableCell>
                      )}
                      <TableCell onClick={() => handleSort('job_name')}>
                        Job Name
                        {orderBy === 'job_name' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
                      </TableCell>
                      <TableCell onClick={() => handleSort('requested_timestamp')}>
                        Request Date
                        {orderBy === `requested_timestamp` && (
                          <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>
                        )}
                      </TableCell>
                      {/* <TableCell onClick={() => handleSort('requested_time')}>
                Request Time
                {orderBy === 'requested_time' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell> */}
                      <TableCell onClick={() => handleSort('calculated_cost')}>
                        Calculated Cost
                        {orderBy === 'calculated_cost' && (
                          <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>
                        )}
                      </TableCell>
                      <TableCell onClick={() => handleSort('area_sq_miles')}>
                        Area Sq Miles
                        {orderBy === 'area_sq_miles' && (
                          <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>
                        )}
                      </TableCell>
                      {/* <TableCell onClick={() => handleSort('plan_applied')}>
                Plan
                {orderBy === 'plan_applied' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell> */}
                      <TableCell onClick={() => handleSort('overall_status')}>
                        Status
                        {orderBy === 'overall_status' && (
                          <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {sortedData.map((item) => (
                      <React.Fragment key={item.job_guid}>
                        <TableRow>
                          {user.company_id && <TableCell>{item.user_email}</TableCell>}
                          <TableCell>{item.job_name}</TableCell>
                          <TableCell>{getFormattedDate(item.requested_timestamp)}</TableCell>
                          <TableCell>${item.calculated_cost?.toFixed(2)}</TableCell>
                          <TableCell>{item.area_sq_miles?.toFixed(2)}</TableCell>
                          {/* <TableCell>{item.plan_applied}</TableCell> */}
                          <TableCell>{item.overall_status == 'Completed' ? 'Completed' : 'Pending'}</TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      )}
    </Grid>
  );
};

export default JobDetails;
