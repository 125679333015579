// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.input-container {
    display: inline-flex;
    align-items: center;
    height: 2em;
    border: 1px solid var(--border-color);
    background-color: var(--input-bg-color);
}

div.input-container-focus {
    outline: var(--focus-outline);
}

div.input-container .input-container-prefix,
div.input-container .input-container-suffix {
    flex: 0 0 auto;
    padding: 0 0.25em;
}

div.input-container .input-container-input {
    flex: 1 1 auto;
    border: none!important;
    outline: none!important;
    width: 0;
    min-width: 0;
}

div.input-container .input-container-input {
    height: calc(2em - 2px)!important;
}`, "",{"version":3,"sources":["webpack://./src/qwc2/components/style/InputContainer.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,WAAW;IACX,qCAAqC;IACrC,uCAAuC;AAC3C;;AAEA;IACI,6BAA6B;AACjC;;AAEA;;IAEI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,sBAAsB;IACtB,uBAAuB;IACvB,QAAQ;IACR,YAAY;AAChB;;AAEA;IACI,iCAAiC;AACrC","sourcesContent":["div.input-container {\n    display: inline-flex;\n    align-items: center;\n    height: 2em;\n    border: 1px solid var(--border-color);\n    background-color: var(--input-bg-color);\n}\n\ndiv.input-container-focus {\n    outline: var(--focus-outline);\n}\n\ndiv.input-container .input-container-prefix,\ndiv.input-container .input-container-suffix {\n    flex: 0 0 auto;\n    padding: 0 0.25em;\n}\n\ndiv.input-container .input-container-input {\n    flex: 1 1 auto;\n    border: none!important;\n    outline: none!important;\n    width: 0;\n    min-width: 0;\n}\n\ndiv.input-container .input-container-input {\n    height: calc(2em - 2px)!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
