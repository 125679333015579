import React from 'react';
import {connect} from 'react-redux';
import {zoomToExtent} from '../../actions/map';
import {remove as removeDiacritics} from 'diacritics';
import isEmpty from 'lodash.isempty';
import PropTypes from 'prop-types';
import LayerUtils from '../../utils/LayerUtils';
import LocaleUtils from '../../utils/LocaleUtils';
import ServiceLayerUtils from '../../utils/ServiceLayerUtils';
import Icon from '../Icon';
import {addLayer, refreshLayer, LayerRole} from '../../actions/layers';
import InputContainer from '../InputContainer';
import './style/LayerCatalogWidget.css';

class LayerCatalogWidget extends React.PureComponent {
    static propTypes = {
        addLayer: PropTypes.func,
        catalog: PropTypes.array,
        levelBasedIndentSize: PropTypes.bool,
        mapCrs: PropTypes.string,
        pendingRequests: PropTypes.number,
        zoomToExtent: PropTypes.func
    };
    state = {
        catalog: [],
        filter: "",
        zoom: false
    };
    constructor(props) {
        super(props);
        this.state.catalog = props.catalog || [];
    }
    syncWait = ms => {
        const end = Date.now() + ms
        while (Date.now() < end) continue
    }
    async fetchData(num) { 
        // Simulating an API call
        return new Promise(resolve => {
          setTimeout(() => resolve(`Data for ${num}`), 1000);
        });
      }  
      async myFunction() { 
        for (let i = 0; i < this.state.catalog.length; i++) { 
          const data = await this.fetchData(i); 
          this.addServiceLayer(this.state.catalog && this.state.catalog[i]);
        } 
      }
      async componentDidMount() {
      //  console.log('catalog in did mount=====', this.state.catalog);
        await this.myFunction()     
           console.log('catalog in did mount=====', this.state.catalog);


    }
    async componentDidUpdate(prevProps) {
        if (this.props.catalog !== prevProps.catalog) {
            //this.setState({catalog: this.props.catalog || []});
            await this.myFunction()
            console.log('catalog in did update=====', this.state.catalog);

        }
    }
    renderCatalogEntry(entry, filter, path, level = 0, idx) {
        console.log("inside renderCatalogEntry===========",entry, filter, path, idx)
        const hasSublayers = !isEmpty(entry.sublayers);
        const sublayers = hasSublayers ? entry.sublayers.map((sublayer, i) => this.renderCatalogEntry(sublayer, filter, [...path, i], level + 1, i)) : [];
        if (sublayers.filter(item => item).length === 0 && filter && !removeDiacritics(entry.title).match(filter)) {
            return null;
        }
        const type = entry.resource ? entry.resource.slice(0, entry.resource.indexOf(":")) : entry.type;
        const key = (entry.resource || (entry.type + ":" + entry.name)) + ":" + idx;
        const indentSize = !this.props.levelBasedIndentSize && level > 0 ? 1.5 : level;
        return (
            <div key={key} style={{paddingLeft: indentSize + 'em'}}>
                <div className="layer-catalog-widget-entry">
                    {hasSublayers ? (
                        <Icon icon={entry.expanded ? 'tree_minus' : 'tree_plus'} onClick={() => this.toggleLayerListEntry(path)} />
                    ) : (
                        <span className="layer-catalog-widget-entry-iconspacer" />
                    )}
                    <span className="layer-catalog-widget-entry-contents" onClick={() => type ? this.addServiceLayer(entry) : this.toggleLayerListEntry(path)}>
                        {type ? (<span className="layer-catalog-widget-entry-service">{type}</span>) : null}
                        {entry.title}
                    </span>
                </div>
                {entry.expanded ? sublayers : null}
            </div>
        );
    }
    toggleLayerListEntry = (path) => {
        this.setState((state) => {
            const newCatalog = [...state.catalog];
            newCatalog[path[0]] = {...newCatalog[path[0]]};
            let cur = newCatalog[path[0]];
            for (const idx of path.slice(1)) {
                cur.sublayers[idx] = {...cur.sublayers[idx]};
                cur = cur.sublayers[idx];
            }
            cur.expanded = !cur.expanded;
            return {catalog: newCatalog};
        });
    };
    render() {
        const filter = new RegExp(removeDiacritics(this.state.filter).replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&"), "i");
        let emptyEntry = null;
        if (isEmpty(this.state.catalog) && this.props.pendingRequests === 0) {
            emptyEntry = (
                <div className="layer-catalog-placeholder">{LocaleUtils.tr("importlayer.noresults")}</div>
            );
        } else if (isEmpty(this.state.catalog)) {
            emptyEntry = (
                <div className="layer-catalog-placeholder">{LocaleUtils.tr("importlayer.loading")}</div>
            );
        }
        const filterplaceholder = LocaleUtils.tr("importlayer.filter");
        return (
            <div className="layer-catalog-widget">
                {/* <InputContainer className="layer-catalog-widget-filter">
                    <input
                        onChange={ev => this.setState({filter: ev.target.value})} placeholder={filterplaceholder}
                        role="input" type="text" value={this.state.filter} />
                    <Icon icon="clear" onClick={() => this.setState({filter: ""})} role="suffix" />
                </InputContainer>*/}
                <div className="layer-catalog-widget-body">
                    {this.state.catalog.map((entry, idx) => this.renderCatalogEntry(entry, filter, [idx], 0, idx))}
                    {emptyEntry}
                </div>
            </div>
        );
    }
    addServiceLayer = (entry) => {
      console.log("inside add layers serviec method====")
        console.log("add service layer in layer catalog widget=====", entry);
        if (entry.resource) {
            const params = LayerUtils.splitLayerUrlParam(entry.resource);
            ServiceLayerUtils.findLayers(params.type, params.url, [params], this.props.mapCrs, (id, layer) => {
                if (layer) {
                    if (entry.sublayers === false) {
                        layer.sublayers = null;
                    }
                    this.props.addLayer(layer);
                } else {
                    // eslint-disable-next-line
                    alert(LocaleUtils.tr("importlayer.addfailed"));
                }
            });
        } else if (entry.type === "wms" || entry.type === "wfs" || entry.type === "wmts") {

            // this.props.addLayer({...entry, sublayers: null});
            // this.props.zoomToExtent(entry.bbox.bounds, entry.bbox.crs);
            if(this.props.layers.filter(layer => layer.name === entry.name).length > 0) {
                console.log("entry in add service layer=====", entry, this.props.layers);
                if(!this.state.zoom) {
                    this.props.zoomToExtent(entry.bbox.bounds, entry.bbox.crs);
                    this.setState({zoom: true});
                }
        } else {

        this.props.addLayer({...entry, sublayers: null });
        }
        }
    };
}
export default connect((state) => ({
    layers: state.layers.flat,
}), {
    addLayer: addLayer,
    zoomToExtent: zoomToExtent,
    refreshLayer: refreshLayer,
})(LayerCatalogWidget);
// export default connect(() => ({
// }), {
//     addLayer: addLayer,
//     zoomToExtent: zoomToExtent
// })(LayerCatalogWidget);
