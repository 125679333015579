import React, { useEffect, useState } from 'react';
import { Grid, Snackbar, Alert } from '@mui/material';
// import CarbonEmissionStats from 'pages/dashboard/carbonemissionstats';
import CaseComponents from '../casestudy-dashboard/casecomponents';
import ObjectDetectionPieChart from 'pages/dashboard/objectdetectionpiechart';
import PopulationDensityStats from 'pages/dashboard/populationdensitystats';
// import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BaseUrl } from 'config';
import axios from 'axios';
import SessionExpired from 'pages/session/sessionExpired';

const CaseStudy = () => {
  const { TOKEN } = useSelector((state) => state.menu);
  const [sessionExpired, setSessionExpired] = useState(false);
  //   const location = useLocation();
  const [detectedData, setDetectedData] = useState();
  const [dataFetchedStatus, setDataFetchedStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: '',
    status: ''
  });
  const handleSessionExpiredClose = (status) => {
    setSessionExpired(status);
  };
  const handleClosePaymentAlert = () => {
    setOpenSnackBar(false);
  };

  const getObjectDetectionStats = async () => {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${BaseUrl}/dashboard_info`,
        {
          city: 'Friendswood'
        },
        {
          headers: {
            'Content-Type': 'application/json'
            //Authorization: `Bearer ${TOKEN}`
          }
        }
      );
      if (response?.data?.number_of_industries && response?.status === 200) {
        setIsLoading(false);
        setDataFetchedStatus(true);
        setDetectedData(response?.data);
      } else {
        setIsLoading(false);
        setOpenSnackBar(true);
        setSnackBarMessage({
          message: 'Something went wrong. Try again later!',
          status: 'error'
        });
      }
    } catch (error) {
      console.log('errror of calculate emission', error);
      setIsLoading(false);
      setOpenSnackBar(true);
      console.log('job request error', error);
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired please login again to continue',
          status: 'error'
        });
      } else {
        setSnackBarMessage({
          message: 'Something went wrong. Try again later!',
          status: 'error'
        });
      }
    }
  };

  useEffect(() => {
    getObjectDetectionStats();
  }, []);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CaseComponents isLoading={isLoading} dataFetchedStatus={dataFetchedStatus} detectedData={detectedData} />
        {/* <CarbonEmissionStats isLoading={isLoading} dataFetchedStatus={dataFetchedStatus} detectedData={detectedData} /> */}
      </Grid>
      <Grid container spacing={2} item xs={12} md={12} lg={12} className="charts">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <ObjectDetectionPieChart isLoading={isLoading} dataFetchedStatus={dataFetchedStatus} detectedData={detectedData} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <PopulationDensityStats isLoading={isLoading} dataFetchedStatus={dataFetchedStatus} detectedData={detectedData} />
        </Grid>
      </Grid>

      {/* <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClosePaymentAlert}
      >
        <Alert onClose={handleClosePaymentAlert} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>
      {sessionExpired ? <SessionExpired handleSessionExpiredClose={handleSessionExpiredClose} /> : ''} */}
    </Grid>
  );
};

export default CaseStudy;
