import PropTypes from 'prop-types';
import { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// material-ui
import axios from 'axios';
import { BaseUrl } from 'config';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Grid,
  Paper,
  Popper,
  Stack,
  Typography,
  Badge,
  Alert,
  Snackbar
} from '@mui/material';
import SessionExpired from 'pages/session/sessionExpired';
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import ProfileTab from './ProfileTab';
import PersonIcon from '@mui/icons-material/Person';
import { notificationDetails, paymentHistoryDetails } from 'store/reducers/menu';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

// function a11yProps(index) {
//   return {
//     id: `profile-tab-${index}`,
//     'aria-controls': `profile-tabpanel-${index}`
//   };
// }

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user, isLoggedIn, TOKEN, notificationInfo, paymentHistoryInfo, readNotificationIds } = useSelector((state) => state.menu);
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: '',
    status: ''
  });
  const userId = user?.id;
  const userPlan = user?.plan != null && user.plan;

  // const userPlan = user?.plan != null ? user?.plan : localStorage.getItem('userPlan');
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const unreadNotifications = notificationInfo?.filter(
    (notification) => !readNotificationIds?.includes(notification.job_id) && !notification.read
  );
  const handleClosePaymentAlert = () => {
    setOpenSnackBar(false);
  };

  const handleSessionExpiredClose = (status) => {
    setSessionExpired(status);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = 'grey.300';

  const getNotification = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}/notifications`,
        // { user_id: user.id },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`
          }
        }
      );
      if (response?.status === 200 && response?.data?.length > 0) {
        dispatch(notificationDetails(response?.data));
        const readNotificationIds = response?.data?.filter((notification) => notification.read).map((notification) => notification.job_id);
        dispatch(markNotificationsAsRead(readNotificationIds));
      } else {
        dispatch(notificationDetails([]));
      }
    } catch (error) {
      setOpenSnackBar(true);
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired please login again to continue',
          status: 'error'
        });
      }
      // else {
      // setSnackBarMessage({
      //   message: 'Something went wrong Profile1. Try again later!',
      //   status: 'error'
      // });
      // }
    }
  };
  const getPaymentHistory = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}/user_payment_details`,
        // `${BaseUrl}/admin_panel_payment`,
        // { id: user.id },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`
          }
        }
      );

      if (response?.status === 200) {
        const paymentResponse = response?.data?.user_payment_details;
        // const filteredPaymentResponse= paymentResponse?.filter((user)=>user.created_by == userId )
        // console.log("filteredPaymentResponse",filteredPaymentResponse);
        if (paymentResponse?.length > 0) {
          dispatch(paymentHistoryDetails(paymentResponse));
        } else {
          dispatch(paymentHistoryDetails([]));
        }
      }
    } catch (error) {
      setOpenSnackBar(true);
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired please login again to continue',
          status: 'error'
        });
      }
      // setSnackBarMessage({
      //   message: 'Something went wrong Profile2. Try again later!',
      //   status: 'error'
      // });
    }
  };
  useEffect(() => {
    getNotification();
    if (user?.plan === 'PPU') {
      getPaymentHistory();
    }
  }, []);

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }} className="profileMenu">
      {isLoggedIn ? (
        <>
          <ButtonBase
            // classname="userBtn"
            sx={{
              p: 0.25,
              color: '#fff',
              //  bgcolor: open ? iconBackColorOpen : 'transparent',
              borderRadius: 1,
              // '&:hover': { bgcolor: 'secondary.lighter' }
              '&:hover': { bgcolor: '#469100 !important' }
            }}
            aria-label="open profile"
            ref={anchorRef}
            aria-controls={open ? 'profile-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            <Stack direction="row" spacing={1} alignItems="center" sx={{ p: 0.5 }}>
              <Typography variant="h5">{`${user?.first_name} ${user?.last_name}`}</Typography>
              <Badge badgeContent={unreadNotifications?.length} color="error">
                {/* <Avatar alt="profile user" src={avatar1} sx={{ width: 32, height: 32 }} /> */}
                <PersonIcon style={{ height: 20, width: 20 }} />
              </Badge>
            </Stack>
          </ButtonBase>

          <Popper
            placement="bottom-end"
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
            popperOptions={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, 9]
                  }
                }
              ]
            }}
          >
            {({ TransitionProps }) => (
              <Transitions type="fade" in={open} {...TransitionProps}>
                {open && (
                  <Paper
                    sx={{
                      boxShadow: theme.customShadows.z1,
                      width: 290,
                      minWidth: 240,
                      maxWidth: 290,
                      [theme.breakpoints.down('md')]: {
                        maxWidth: 250
                      }
                    }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <MainCard elevation={0} border={false} content={false}>
                        <CardContent sx={{ px: 2.5, pt: 3 }}>
                          <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                              <Stack direction="row" spacing={1.25} alignItems="center">
                                {/* <Avatar alt="profile user" src={avatar1} sx={{ width: 32, height: 32 }} /> */}
                                <PersonIcon style={{ height: 20, width: 20 }} />
                                <Stack>
                                  <Typography variant="h6">{`${user?.first_name} ${user?.last_name}`}</Typography>
                                  <Grid container alignItems="baseline">
                                    <Typography variant="body2" color="textSecondary">
                                      User
                                    </Typography>
                                    <Grid item ml={3}>
                                      <Badge badgeContent={userPlan} color="success" />
                                    </Grid>
                                  </Grid>
                                </Stack>
                              </Stack>
                            </Grid>
                          </Grid>
                        </CardContent>
                        {open && (
                          <>
                            <ProfileTab />
                          </>
                        )}
                      </MainCard>
                    </ClickAwayListener>
                  </Paper>
                )}
              </Transitions>
            )}
          </Popper>
        </>
      ) : (
        ''
      )}

      {/* <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleClosePaymentAlert}
      >
        <Alert onClose={handleClosePaymentAlert} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar> */}

      {sessionExpired ? <SessionExpired handleSessionExpiredClose={handleSessionExpiredClose} /> : ''}
    </Box>
  );
};

export default Profile;
