import React, { useState, useEffect } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  TextField,
  InputAdornment,
  Grid,
  Alert,
  Snackbar,
  Tooltip,
  Box,
  Typography
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Container } from '@mui/material';
import UnbilledJobList from './unbilledjoblist';
import PersonalJobList from './personalJobList';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CircularProgress from '@mui/material/CircularProgress';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SessionExpired from 'pages/session/sessionExpired';
import axios from 'axios';
import { BaseUrl } from 'config';
import { useSelector } from 'react-redux';
import SearchMapLocation from './selectlocation/selectmaplocation';
import Map from '../../image/viewMap.png';

const JobList = () => {
  const navigate = useNavigate();
  const { user, TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [unbilledJobList, setUnbilledJobList] = useState([]);
  const [personalJobList, setPersonalJobList] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState('');
  const [orderBy, setOrderBy] = useState('request_date');
  const [sortDirection, setSortDirection] = useState('asc');
  const [sessionExpired, setSessionExpired] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: '',
    status: ''
  });
  const isUserVerified = user.is_verified;
  const userPlan = user.plan != null ? user.plan : localStorage.getItem('userPlan');

  const handleSessionExpiredClose = (status) => {
    setSessionExpired(status);
  };

  const handleClosePaymentAlert = () => {
    setOpenSnackBar(false);
  };
  // Handle pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter data based on search term
  const filteredData =
    jobList &&
    jobList.length > 0 &&
    jobList.filter((item) =>
      Object.values(item).some((value) => {
        if (typeof value === 'string') {
          return value.toLowerCase().includes(searchTerm.toLowerCase());
        }
        return false;
      })
    );

  // Handle column sorting
  // const handleSort = (columnId) => {
  //   const isAsc = orderBy === columnId && sortDirection === 'asc';
  //   setOrderBy(columnId);
  //   setSortDirection(isAsc ? 'desc' : 'asc');
  // };

  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && sortDirection === 'asc';
    setOrderBy(columnId);
    // If sorting by requested_date or requested_time, set sort direction to descending
    if (columnId === 'requested_date' || columnId === 'requested_time') {
      setSortDirection('asc');
    } else {
      // For other columns, toggle the sort direction
      setSortDirection(isAsc ? 'desc' : 'asc');
    }
  };

  // Sort data based on current sorting state
  // const sortedData = [...filteredData].sort((a, b) => {
  //   const compareValue = (a, b) => {
  //     if (a[orderBy] < b[orderBy]) return -1;
  //     if (a[orderBy] > b[orderBy]) return 1;
  //     return 0;
  //   };

  //   return sortDirection === 'asc' ? compareValue(a, b) : -compareValue(a, b);
  // });

  // Sort data based on current sorting state
  let sortedData;
  if (filteredData.length > 0) {
    sortedData = [...filteredData].sort((a, b) => {
      const compareValue = (a, b) => {
        if (a[orderBy] < b[orderBy]) return -1;
        if (a[orderBy] > b[orderBy]) return 1;
        return 0;
      };

      if (orderBy === 'requested_date' || orderBy === 'requested_time') {
        // If sorting by requested_date or requested_time, prioritize sorting by date and then time
        if (a.requested_date === b.requested_date) {
          return sortDirection === 'desc'
            ? a.requested_time.localeCompare(b.requested_time)
            : b.requested_time.localeCompare(a.requested_time);
        } else {
          return sortDirection === 'asc'
            ? a.requested_date.localeCompare(b.requested_date)
            : b.requested_date.localeCompare(a.requested_date);
        }
      } else {
        // For other columns, use regular sorting logic
        return sortDirection === 'asc' ? compareValue(a, b) : -compareValue(a, b);
      }
    });
  } else {
    sortedData = [];
  }
  const checkStatus = (status) => {
    // if (status == 'Pending') {
    //   return {
    //     color: 'black'
    //   };
    // }
  };

  const handleAdjustOutput = (item) => {
    localStorage.setItem('job_id', item?.job_id);
    // localStorage.setItem('job_location_id', item?.job_location_id);
    navigate('/dashboard/default', { state: item });
  };

  const groupByJobId = (jobs) => {
    return jobs.reduce((result, item) => {
      const existingItem = result.find((groupedItem) => groupedItem.job_id === item.job_id);
      const getTime = (timestampString) => {
        const timestamp = new Date(timestampString);
        // Format for the time (e.g., 07:32:52)
        const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
        const formattedTime = timestamp.toLocaleTimeString('en-US', timeOptions);
        return formattedTime;
      };
      const getDate = (timestampString) => {
        const timestamp = new Date(timestampString);
        // Format for the date (e.g., Feb 12, 2024)
        const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };
        const formattedDate = timestamp.toLocaleDateString('en-US', dateOptions);
        return formattedDate;
      };
      if (existingItem) {
        existingItem.Location_geojson.push({
          Location_geojson: item.Location_geojson,
          location_name: item.location_name
        });
      } else {
        result.push({
          area_sq_miles: item.area_sq_miles,
          calculated_cost: item.calculated_cost,
          job_id: item.job_id,
          job_location_id: item.job_location_id,
          job_name: item.job_name,
          overall_status: item.overall_status,
          plan_applied: item.plan_applied,
          // requested_timestamp: getTime(item.requested_timestamp),
          requested_date: getDate(item.requested_timestamp),
          requested_time: getTime(item.requested_timestamp),
          Location_geojson: [
            {
              Location_geojson: item.Location_geojson,
              location_name: item.location_name
            }
          ]
        });
      }

      return result;
    }, []);
  };

  const getJobList = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${BaseUrl}/home/jobslist`,
        // { payment_status: user?.company_status ? false : true },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`
          }
        }
      );
      console.log('joblist response', response);
      if (response?.status === 200 && response?.data && !response.data.message) {
        const data = response?.data;
        setIsLoading(false);

        // setJobList(
        //   response?.data.map((val) => {
        //     return {
        //       ...val,
        //       requested_date: getDate(val.requested_timestamp),
        //       requested_time: getTime(val.requested_timestamp)
        //     };
        //   })
        // );

        // const groupedData = groupByJobId(response?.data);
        // let groupedPaidData = groupByJobId(response?.data);
        // const groupedPaidData = groupByJobId(response?.data.filter((val) => val.payment_status != false && val.plan_applied == user.plan));

        let sorted_data;

        // let unpaidResponse;
        // let groupedUnpaidData;
        // let unpaidsorted_data;
        // if (userPlan != 'PPU') {
        //   unpaidResponse = await axios.post(
        //     `${BaseUrl}/home/jobslist`,
        //     { payment_status: false },
        //     {
        //       headers: {
        //         'Content-Type': 'application/json',
        //         Authorization: `Bearer ${TOKEN}`
        //       }
        //     }
        //   );
        //   if (unpaidResponse?.data?.length > 0) {
        //     groupedUnpaidData = groupByJobId(unpaidResponse?.data);
        //     unpaidsorted_data = groupedUnpaidData.sort((a, b) => new Date(b.requested_date) - new Date(a.requested_date));
        //   } else {
        //     unpaidsorted_data = [];
        //   }
        // }
        //sorting the latest job at top

        // const sorted_data = groupedData.sort((a, b) => new Date(b.requested_date) - new Date(a.requested_date));
        // setJobList(sorted_data);
        // console.log('grouped data before sort', groupedPaidData);
        //sorted_data = groupedPaidData.sort((a, b) => new Date(b.requested_date) - new Date(a.requested_date));
        //   sorted_data = response?.data[0].sort((a, b) => {
        //     let dateA = new Date(`${a.requested_date} ${a.requested_time}`);
        //     let dateB = new Date(`${b.requested_date} ${b.requested_time}`);
        //     return dateB - dateA;
        // });
        const billedData = data && data.length > 0 && data[0]?.filter((item) => item.payment_status == true);
        const unbilledData = data && data.length > 0 && data[1];
        const personalData = data && data.length > 0 && data[2];

        const sorted_data_billed =
          billedData &&
          billedData?.length > 0 &&
          billedData?.sort((a, b) => {
            let dateA = new Date(`${getFormattedDate(a.requested_timestamp)} ${getFormattedTime(a.requested_timestamp)}`);
            let dateB = new Date(`${getFormattedDate(b.requested_timestamp)} ${getFormattedTime(b.requested_timestamp)}`);
            return dateB - dateA;
          });
        const sorted_data_unbilled =
          unbilledData &&
          unbilledData?.length > 0 &&
          unbilledData?.sort((a, b) => {
            let dateA = new Date(`${getFormattedDate(a.requested_timestamp)} ${getFormattedTime(a.requested_timestamp)}`);
            let dateB = new Date(`${getFormattedDate(b.requested_timestamp)} ${getFormattedTime(b.requested_timestamp)}`);
            return dateB - dateA;
          });
        const sorted_data_personal =
          personalData &&
          personalData?.length > 0 &&
          personalData?.sort((a, b) => {
            let dateA = new Date(`${getFormattedDate(a.requested_timestamp)} ${getFormattedTime(a.requested_timestamp)}`);
            let dateB = new Date(`${getFormattedDate(b.requested_timestamp)} ${getFormattedTime(b.requested_timestamp)}`);
            return dateB - dateA;
          });

        // sorted_data=data[0]

        setJobList(sorted_data_billed?.length > 0 ? sorted_data_billed : []);
        setPersonalJobList(sorted_data_personal?.length > 0 ? sorted_data_personal : []);
        setUnbilledJobList(sorted_data_unbilled?.length > 0 ? sorted_data_unbilled : []);

        // {
        //   userPlan != 'PPU' ? setJobList([...sorted_data, ...unpaidsorted_data]) : setJobList(sorted_data);
        // }
      } else if (response?.status === 200 && response.data && response.data.length > 0 && data[0]?.length === 0) {
        //if user does not have jobs
        setIsLoading(false);
      } else if (response?.data?.message == 'Added to cart notification-user_plan PPU - company_status: False - user_verified : False') {
        setIsLoading(false);
        setOpenSnackBar(true);
        setSnackBarMessage({
          message: 'User is not verified. Please verify user!',
          status: 'error'
        });
        navigate('/unverified_user');
      } else {
        setIsLoading(false);
        setOpenSnackBar(true);
        setSnackBarMessage({
          message: 'Something went wrong. Try again later!',
          status: 'error'
        });
      }
    } catch (error) {
      console.log('errror of joblist', error);
      setIsLoading(false);
      setOpenSnackBar(true);
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired please login again to continue',
          status: 'error'
        });
      } else {
        setSnackBarMessage({
          message: 'Something went wrong. Try again later!',
          status: 'error'
        });
      }
    }
  };

  // const handleRowClick = (job_id) => {
  //   const isRowExpanded = expandedRows.includes(job_id);
  //   setExpandedRows(isRowExpanded ? expandedRows.filter((id) => id !== job_id) : [...expandedRows, job_id]);
  // };

  const handleRowClick = (job_id) => {
    const isRowExpanded = expandedRows.includes(job_id);
    setExpandedRows(isRowExpanded ? [] : [job_id]);
  };

  // const renderExpandableRow = (item) => (
  //   <TableRow key={`${item.job_id}-expanded`} style={{ backgroundColor: '#f9f9f9' }}>
  //     <TableCell colSpan={7}>
  //       <Table>
  //         <TableHead>
  //           <TableRow>
  //             <TableCell>Location Name</TableCell>
  //             <TableCell>View</TableCell>
  //           </TableRow>
  //         </TableHead>
  //         <TableBody>
  //           {item.Location_geojson.map((location, index) => (
  //             <TableRow key={`${item.job_id}-location-${index}`}>
  //               <TableCell>{location.location_name}</TableCell>
  //               <TableCell>View</TableCell>
  //             </TableRow>
  //           ))}
  //         </TableBody>
  //       </Table>
  //     </TableCell>
  //   </TableRow>
  // );

  // const renderExpandableRow = (item) => {
  //   const locationdata = {
  //     mode: 'view',
  //     type: 'select',
  //     hide: true,
  //     locations: {
  //       type: 'FeatureCollection',
  //       features: item?.Location_geojson?.map((val) => {
  //         return val.Location_geojson.features[0];
  //       })
  //     }
  //   };
  //   return <SearchMapLocation location={'nothing'} locationData={locationdata} />;
  // };
  function checkCoordinateOrder(response) {
    const coordinates = response.locations.features[0].geometry.coordinates[0];
    if (coordinates[0][0] > coordinates[0][1]) {
      return 'LatLng';
    } else {
      return 'LngLat';
    }
  }

  const renderExpandableRow = (item) => {
    // Check if Location_geojson and its features are defined
    const features = item?.Location_geojson?.features;

    const reverseCoordinates = (coordinates) => {
      return coordinates.map((polygon) => polygon.map(([lat, lng]) => [lng, lat]));
    };

    let locationdata = {
      mode: 'view',
      type: 'select',
      hide: true,
      locations: {
        type: 'FeatureCollection',
        features: []
      }
    };

    if (features) {
      const tempResponse = { locations: { features } };
      const coordinateOrder = checkCoordinateOrder(tempResponse);

      locationdata.locations.features = features.map((feature) => {
        let coordinates = feature.geometry.coordinates;
        if (coordinateOrder === 'LatLng') {
          coordinates = reverseCoordinates(coordinates);
        }
        return {
          ...feature,
          geometry: {
            ...feature.geometry,
            coordinates
          }
        };
      });
    }

    return <SearchMapLocation location={'nothing'} locationData={locationdata} />;
  };

  function getFormattedDate(timestamp) {
    const date = new Date(timestamp);

    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    console.log(`${month}/${day}/${year}`);
    return `${month}/${day}/${year}`;
  }

  function getFormattedTime(timestamp) {
    const date = new Date(timestamp);

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    console.log(`${hours}:${minutes}:${seconds}`);
    return `${hours}:${minutes}:${seconds}`;
  }
  useEffect(() => {
    getJobList();
  }, []);

  useEffect(() => {
    const handleBackNavigation = (event) => {
      event.preventDefault();
      // Disable back navigation to the payment detail screen
      window.history.pushState(null, null, window.location.pathname);
    };
    // Listen for the popstate event to prevent back navigation
    window.addEventListener('popstate', handleBackNavigation);

    // Cleanup function to remove the event listener when the component unmounts
    return () => {
      window.removeEventListener('popstate', handleBackNavigation);
    };
  }, []);
  return (
    <Container fixed className="joblist">
      <Grid>
        <Grid container justifyContent="space-between" alignItems="center" mb={2}>
          <Grid item>
            <h1 className="heading">Job Request</h1>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className="largebtn add_job_btn"
              color="primary"
              startIcon={<PlusOutlined />}
              onClick={() => navigate('/addjobs')}
            >
              <span className="add_job">Add Job Request</span>
            </Button>
          </Grid>
        </Grid>

        <hr className="horizontal" />
        <Box className="joblist_heading">
          <Grid container>
            <Grid item lg={9} md={9} sm={12} xs={12}>
              <h1 className="sub-heading">Jobs List</h1>
            </Grid>
            <Grid item lg={3} md={3} sm={12} xs={12}>
              <TextField
                label="Search"
                variant="outlined"
                fullWidth
                size="large"
                onChange={(e) => setSearchTerm(e.target.value)}
                // InputProps={{
                //   startAdornment: <InputAdornment position="start">{/* You can add a search icon here if needed */}</InputAdornment>
                // }}
                inputProps={{ style: { padding: '3px 10px' } }}
                InputLabelProps={{ shrink: true }}
                sx={{ mt: 2 }}
              />
            </Grid>
          </Grid>
        </Box>
        <TableContainer>
          {/* {isLoading ? (
            // Display loader while data is being fetched
            <div style={{ textAlign: 'center', padding: '20px' }}>
              <CircularProgress />
            </div>
          ) : ( */}
          <Table>
            <TableHead>
              <TableRow>
                {/* Render your table headers here */}
                {user.company_id && (
                  <TableCell onClick={() => handleSort('user_email')}>
                    User Email
                    {orderBy === 'user_email' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
                  </TableCell>
                )}
                <TableCell onClick={() => handleSort('job_name')}>
                  Job Name
                  {orderBy === 'job_name' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
                </TableCell>
                <TableCell onClick={() => handleSort('requested_timestamp')}>
                  Request Date
                  {orderBy === 'requested_timestamp' && (
                    <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>
                  )}
                </TableCell>
                <TableCell onClick={() => handleSort('calculated_cost')}>
                  Calculated Cost
                  {orderBy === 'calculated_cost' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
                </TableCell>
                <TableCell onClick={() => handleSort('area_sq_miles')}>
                  Area Sq Miles
                  {orderBy === 'area_sq_miles' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
                </TableCell>
                {/* <TableCell onClick={() => handleSort('plan_applied')}>
                  Plan
                  {orderBy === 'plan_applied' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
                </TableCell> */}
                <TableCell onClick={() => handleSort('overall_status')}>
                  Status
                  {orderBy === 'overall_status' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
                </TableCell>
                {/* <TableCell onClick={() => handleSort('job_guid')}>
                  Job Guid
                  {orderBy === 'job_guid' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
                </TableCell> */}
                <TableCell>Action</TableCell>
                <TableCell>View Map Layer</TableCell>
                {/* Add more columns as needed */}
              </TableRow>
            </TableHead>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={8} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {sortedData?.length === 0 ? (
                  <TableCell colSpan={8} align="center">
                    <Typography variant="h4" color="green" component="span" style={{ marginLeft: '0px', lineHeight: 4 }}>
                      No Records Found!
                    </Typography>
                  </TableCell>
                ) : (
                  <>
                    <TableBody>
                      {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item) => (
                        <React.Fragment key={item.job_guid}>
                          <TableRow onClick={() => handleRowClick(item.job_id)}>
                            {user.company_id && <TableCell style={checkStatus(item.overall_status)}>{item.user_email}</TableCell>}
                            <TableCell style={checkStatus(item.overall_status)}>{item.job_name}</TableCell>
                            <TableCell style={checkStatus(item.overall_status)}>{getFormattedDate(item.requested_timestamp)}</TableCell>
                            <TableCell style={checkStatus(item.overall_status)}>${item.calculated_cost?.toFixed(2)}</TableCell>
                            <TableCell style={checkStatus(item.overall_status)}>{item.area_sq_miles?.toFixed(2)}</TableCell>
                            {/* <TableCell style={checkStatus(item.overall_status)}>{item.plan_applied}</TableCell> */}
                            <TableCell style={checkStatus(item.overall_status)}>
                              {item.overall_status == 'Completed' ? 'Completed' : 'Pending'}
                            </TableCell>
                            {/* <TableCell style={checkStatus(item.job_status)}>{item.job_guid}</TableCell> */}
                            <TableCell>
                              {item.overall_status == 'Completed' ? (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  startIcon={<VisibilityIcon />}
                                  onClick={() => handleAdjustOutput(item)}
                                  className="viewresult"
                                >
                                  View Result
                                </Button>
                              ) : (
                                <Tooltip
                                  title="Your job result is pending. This button will be activated when your job is completed."
                                  arrow
                                >
                                  <Button variant="contained" color="primary" startIcon={<VisibilityOffIcon />} className="viewresult">
                                    View Result
                                  </Button>
                                </Tooltip>
                              )}
                            </TableCell>
                            <TableCell>
                              {item.overall_status == 'Completed' ? (
                                <img
                                  src={Map}
                                  width="35px"
                                  style={{ marginTop: '5px' }}
                                  onClick={() =>
                                    navigate('/map?l=TestX%2CGemeindegrenze&bl=mapnik&t=bauprojekte&c=970580%2C5999699&s=40000')
                                  }
                                />
                              ) : (
                                <></>
                              )}
                            </TableCell>
                            {/* Add more cells as needed */}
                          </TableRow>
                          {expandedRows.includes(item.job_id) && (
                            <TableRow>
                              <TableCell colSpan={7}>
                                <Table>
                                  <TableCell>{expandedRows.includes(item.job_id) && renderExpandableRow(item)}</TableCell>
                                </Table>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      ))}
                    </TableBody>
                  </>
                )}
              </>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sortedData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          className="page_btn"
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
      <hr className="horizontal" />
      {/* {userPlan != 'ENT' && ( */}
      <Grid item xs={12}>
        <UnbilledJobList isUserVerified={isUserVerified} unbilledJobList={unbilledJobList} />
      </Grid>
      {/* )} */}
      {user.company_id != null && (
        <>
          <hr className="horizontal" />

          <Grid item xs={12}>
            <PersonalJobList isUserVerified={isUserVerified} personalJobList={personalJobList} />
          </Grid>
        </>
      )}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{marginTop:50}}
        open={openSnackBar}
        autoHideDuration={10000}
        onClose={handleClosePaymentAlert}
      >
        <Alert onClose={handleClosePaymentAlert} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>

      {sessionExpired ? <SessionExpired handleSessionExpiredClose={handleSessionExpiredClose} /> : ''}
    </Container>
  );
};

export default JobList;
