// HomePage.js
import React, { useState, useRef, useEffect } from 'react';
import { Grid, Box, Container, Button, TextField, Snackbar, Alert } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Contactus from '../../image/contactus.png';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
import Contactus2 from '../../image/contact 2.png';
import { useSelector } from 'react-redux';
import { BaseUrl } from 'config';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import style from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';
import { Padding } from '../../../node_modules/@mui/icons-material/index';
const Contact = () => {
  const navigate = useNavigate();
  const { TOKEN } = useSelector((state) => state.menu);
  const [isLoading, setIsLoading] = useState(false);
  const [isCaptchaLoading, setIsCaptchaLoading] = useState(true); // State to track loading state of reCAPTCHA
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    subject: '',
    email: '',
    contactNumber: '',
    description: ''
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: 'User Login Successfully!',
    status: 'success'
  });
  const [errors, setErrors] = useState({});
  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [resetRecaptcha, setResetRecaptcha] = useState(false);
  const recaptchaRef = useRef();
  useEffect(() => {
    // Function to execute when reCAPTCHA is fully loaded
    const handleRecaptchaLoad = () => {
      setIsCaptchaLoading(false); // Set loading state to false once reCAPTCHA is loaded
    };

    // Dynamically load the reCAPTCHA script
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?onload=onRecaptchaLoadCallback&render=explicit';
    script.async = true;
    document.body.appendChild(script);

    // Set window function to handle reCAPTCHA load callback
    window.onRecaptchaLoadCallback = handleRecaptchaLoad;

    // Cleanup function to remove event listener and window function on component unmount
    return () => {
      document.body.removeChild(script);
      window.onRecaptchaLoadCallback = null;
    };
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) {
      newErrors.firstName = 'First Name is required';
    }
    if (!formData.lastName.trim()) {
      newErrors.lastName = 'Last Name is required';
    }
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }
    if (!formData.subject.trim()) {
      newErrors.subject = 'Subject is required';
    }
    if (!formData.description.trim()) {
      newErrors.description = 'Message is required';
    }
    if (formData.contactNumber && !/^\d{10,}$/.test(formData.contactNumber)) {
      newErrors.contactNumber = 'Contact number must have at least 10 digits';
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  function onChange(value) {
    console.log('Captcha value:', value);
    setRecaptchaToken(value);
    setRecaptchaError(false);
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate the reCAPTCHA token
    if (!recaptchaToken) {
      setRecaptchaError(true);
      return; // Prevent form submission if reCAPTCHA token is missing
    }
    if (validateForm()) {
      setIsLoading(true);

      const { firstName, lastName, email, contactNumber, subject, description } = formData;
      const requestData = {
        firstname: firstName,
        lastname: lastName,
        phone_number: contactNumber,
        email: email,
        subject: subject,
        message: description,
        recaptchaToken: recaptchaToken
      };

      try {
        const response = await axios.post(`${BaseUrl}/contact_us`, requestData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`
          }
        });

        if (response?.status === 200 && response.data == null) {
          setOpenSnackBar(true);
          setSnackBarMessage({
            message: 'Saved successfully!',
            status: 'success'
          });

          setFormData({
            firstName: '',
            lastName: '',
            subject: '',
            email: '',
            contactNumber: '',
            description: ''
          });
          setErrors({});
          recaptchaRef.current.reset();
          setResetRecaptcha(true);
          setRecaptchaToken('');
        }
      } catch (error) {
        setOpenSnackBar(true);
        console.log('registration error', error);
        setSnackBarMessage({
          message: 'Something went wrong. Try again later',
          status: 'error'
        });
      } finally {
        setIsLoading(false);
        // setRecaptchaToken('');
        // setResetRecaptcha(true);
      }
    }
  };
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  return (
    <Container>
      <Grid container>
        <Box className="contact" id="contact" sx={{ flexGrow: 1 }}>
          <Grid container spacing={8}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <img src={Contactus2} alt="" className="contact2" />
            </Grid>
            <Grid item lg={1} md={1} sm={12} xs={12}></Grid>
            <Grid item lg={5} md={5} sm={12} xs={12} className="contactFormContainer">
              <h1 className="heading" style={{ fontWeight: 700 }}>
                Contact
              </h1>
              <h3 className="sub-headings" style={{ margin: '0px 0px 10px 0px' }}>
                Please contact us for any help
              </h3>
              <form onSubmit={handleSubmit} className="myInput">
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      label="First Name"
                      name="firstName"
                      fullWidth
                      value={formData.firstName}
                      onChange={handleChange}
                      error={!!errors.firstName}
                      helperText={errors.firstName}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { padding: '3px 10px' } }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <TextField
                      label="Last Name"
                      name="lastName"
                      fullWidth
                      value={formData.lastName}
                      onChange={handleChange}
                      error={!!errors.lastName}
                      helperText={errors.lastName}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { padding: '3px 10px' } }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Phone Number (Optional)"
                      name="contactNumber"
                      fullWidth
                      value={formData.contactNumber}
                      onChange={handleChange}
                      error={!!errors.contactNumber}
                      helperText={errors.contactNumber}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { padding: '3px 10px' } }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Email"
                      name="email"
                      fullWidth
                      value={formData.email}
                      onChange={handleChange}
                      error={!!errors.email}
                      helperText={errors.email}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { padding: '3px 10px' } }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Subject"
                      name="subject"
                      fullWidth
                      value={formData.subject}
                      onChange={handleChange}
                      error={!!errors.subject}
                      helperText={errors.subject}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { padding: '3px 10px' } }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Message"
                      name="description"
                      fullWidth
                      multiline
                      rows={4}
                      value={formData.description}
                      onChange={handleChange}
                      error={!!errors.description}
                      helperText={errors.description}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ style: { padding: '3px 10px' } }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {isCaptchaLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        // sitekey="6LdwA-ApAAAAAH563WW4vxWvcCzmXVbj01ME4DgO"
                        // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI" // use this token for local
                        sitekey="6Ld3THgkAAAAANncBZ0lLFUeMUnoCjJOOa4bRoux"
                        onChange={onChange}
                        onExpired={() => {
                          setRecaptchaToken('');
                          setRecaptchaError(true);
                        }}
                        hl="en"
                        size="normal"
                        tabIndex={0}
                        reset={resetRecaptcha}
                        style={{ color: 'transparent' }} // Hide the "Privacy" and "Terms" text
                      />
                    )}
                    {recaptchaError && <span style={{ color: 'red' }}>Please complete the reCAPTCHA challenge</span>}
                  </Grid>
                  <Grid item xs={2} style={{ marginTop: '5px' }}>
                    {isLoading ? (
                      <Button type="submit" className="login_button" variant="contained" size="large" disabled>
                        {/* {isLoading ? <CircularProgress size={24} /> : 'Submit'} */}
                        Submit
                      </Button>
                    ) : (
                      <Button type="submit" className="login_button" variant="contained" size="large">
                        Submit
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={10} style={{ marginTop: '-22px' }}>
                    {isLoading && <CircularProgress className="circular" />}
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{marginTop:50}} open={openSnackBar} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Contact;
