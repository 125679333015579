// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.share-link-frame {
    display: flex;
}

div.share-link-frame > input {
    flex: 1 1 auto;
    margin-right: -1px;
}

span.share-link-button {
    flex: 0 0 auto;
    height: 2.5em;
    width: 2.5em;
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/components/share/style/ShareLink.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,YAAY;AAChB","sourcesContent":["div.share-link-frame {\n    display: flex;\n}\n\ndiv.share-link-frame > input {\n    flex: 1 1 auto;\n    margin-right: -1px;\n}\n\nspan.share-link-button {\n    flex: 0 0 auto;\n    height: 2.5em;\n    width: 2.5em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
