
import React, { useState, useEffect } from 'react';
import { Card, Container, Snackbar, Alert, Typography, CircularProgress } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { BaseUrl } from 'config';
import SessionExpired from 'pages/session/sessionExpired';
import Verified from '../../image/verified.png';
//import { useSelector, useDispatch } from 'react-redux';
//import { setCompanyVerifyToken, setCompanyVerifyVisited } from 'store/reducers/menu';

const VerifyCompanyUserByToken = () => {
  const navigate = useNavigate();
  //const dispatch = useDispatch();
  const location = useLocation();
 // const { TOKEN } = useSelector((state) => state.menu);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: '',
    status: 'info'
  });
  const [isLoading, setIsLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);

  // const handleSessionExpiredClose = (status) => {
  //   setSessionExpired(status);
  // };

  const VerifyCompanyByToken = async (companyToken) => {
    console.log(companyToken)
    try {
      const response = await axios.post(`${BaseUrl}/verify_company_user_by_token`, {
        token: companyToken,
        // headers: {
        //   'Content-Type': 'application/json',
        //   Authorization: `Bearer ${TOKEN}`
        // }
      });
      console.log(response.status)
      if(response.status == 200){
        return true;
      }
    } catch (err) {
      console.log('error occurred while fetching company details:', err);
      return false;
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    if (token) {
      // dispatch(setCompanyVerifyToken(token));
      // dispatch(setCompanyVerifyVisited(true));

      const verifyCompany = async () => {
        const isVerified = await VerifyCompanyByToken(token);
        console.log(isVerified)
        setIsVerified(isVerified);
        setIsLoading(false);

        if (isVerified) {
          setSnackBarMessage({
            message: 'User changed to ENT successfully and Company Verification is Completed.',
            status: 'success'
          });
          setTimeout(() => {
            navigate('/login');
          }, 3000); // Redirect after 3 seconds
        } else {
          setSnackBarMessage({
            message: 'Company Verification failed. Please try again.',
            status: 'error'
          });
        }
      };

      verifyCompany();
    } else {
      setIsLoading(false);
      setSnackBarMessage({
        message: 'No verification token found.',
        status: 'error'
      });
    }
  }, [location.search]);

  const handleCloseAlert = () => {
    setSnackBarMessage({ ...snackBarMessage, message: '', status: 'info' });
  };

  return (
    <Container fixed>
      <div className="addjobs" style={{ color: 'grey' }}>
        <Card style={{ padding: 30, textAlign: 'center', marginTop: '100px' }}>
          {isLoading ? (
            <>
              <CircularProgress />
              <Typography component="h1" variant="h5" className="heading">
                Verifying your company account...
              </Typography>
            </>
          ) : isVerified ? (
            <>
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                <img src={Verified} width={100} alt="Verified" />
              </div>
              <Typography component="h1" variant="h5" className="heading">
                Your company account is successfully verified!
              </Typography>
              <Typography variant="body1">
                You will be redirected to the login page shortly.
              </Typography>
            </>
          ) : (
            <Typography component="h1" variant="h5" className="heading">
              Verification failed. Please contact support.
            </Typography>
          )}
        </Card>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{marginTop: 50}} 
        open={!!snackBarMessage.message}
        autoHideDuration={10000}
        onClose={handleCloseAlert}
      >
        <Alert onClose={handleCloseAlert} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>

      {sessionExpired && <SessionExpired handleSessionExpiredClose={handleSessionExpiredClose} />}
    </Container>
  );
};

export default VerifyCompanyUserByToken;