// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.AttributeForm {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 0.25em;
}

div.AttributeForm > form {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

div.AttributeForm > form > * {
    flex: 1 1 auto;
}

div.AttributeForm > form > div.ButtonBar {
    flex: 0 0 auto;
}

div.AttributeForm div.attrib-form-geom-readonly {
    background-color: #FF6600;
    border: 1px solid #FF0000;
    color: white;
    padding: 0.25em;
    margin-bottom: 0.25em;
    font-weight: bold;
}

div.AttributeForm div.attrib-form-busy {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(127, 127, 127, 0.25);
    cursor: wait;
    z-index: 2;
}

div.link-feature-form-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(200, 200, 200, 0.5);
    padding: 1em;
    display: flex;
    flex-direction: column;
}

div.link-feature-form-container > div.link-feature-form {
    flex: 1 1 auto;
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/components/style/AttributeForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,2CAA2C;IAC3C,YAAY;IACZ,UAAU;AACd;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,MAAM;IACN,SAAS;IACT,0CAA0C;IAC1C,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB","sourcesContent":["div.AttributeForm {\n    display: flex;\n    flex-direction: column;\n    position: relative;\n    padding: 0.25em;\n}\n\ndiv.AttributeForm > form {\n    flex: 1 1 auto;\n    display: flex;\n    flex-direction: column;\n    overflow-y: auto;\n}\n\ndiv.AttributeForm > form > * {\n    flex: 1 1 auto;\n}\n\ndiv.AttributeForm > form > div.ButtonBar {\n    flex: 0 0 auto;\n}\n\ndiv.AttributeForm div.attrib-form-geom-readonly {\n    background-color: #FF6600;\n    border: 1px solid #FF0000;\n    color: white;\n    padding: 0.25em;\n    margin-bottom: 0.25em;\n    font-weight: bold;\n}\n\ndiv.AttributeForm div.attrib-form-busy {\n    position: absolute;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(127, 127, 127, 0.25);\n    cursor: wait;\n    z-index: 2;\n}\n\ndiv.link-feature-form-container {\n    position: absolute;\n    left: 0;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    background-color: rgba(200, 200, 200, 0.5);\n    padding: 1em;\n    display: flex;\n    flex-direction: column;\n}\n\ndiv.link-feature-form-container > div.link-feature-form {\n    flex: 1 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
