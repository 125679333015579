import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// project import
// import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';
import DashboardDefault from 'pages/dashboard/index';
import Home from 'pages/home/index';
import JobList from 'pages/joblists/index';
import AddJobs from 'pages/joblists/addjob';
import UnverifiedUser from 'pages/joblists/unverifiedUser';
import VerifyCompanyUserByToken from 'pages/authentication/verifyCompanyUserByToken';
import SelectLocation from 'pages/joblists/selectlocation/index';
import PaymentScreen from 'pages/payment/index';
import ForgotPassword from 'pages/forgotpassword/forgotpassword';
import ForgotPasswordFields from 'pages/forgotpassword/forgotPasswordFields';
import CompanyProfile from 'pages/profile/companyProfile';
import AccountType from 'pages/profile/accountType';
import Profile from 'pages/profile/index';
import ResetPassword from 'pages/profile/resetPassword';
import VerifyEmailScreen from 'pages/authentication/verifyEmail';
import Notification from 'pages/notification/notification';
import PaymentHistory from 'pages/paymentHistory/paymentHistory';
import PaymentDetail from 'pages/payment/paymentDetail';
import AuthLogin from '../pages/authentication/Login';
import AuthRegister from '../pages/authentication/Register';
import { useSelector } from 'react-redux';
import PageNotFound from 'pages/404page/pagenotfound';
import CaseStudyDashboard from 'pages/casestudy-dashboard/index';
import About from 'pages/about/about';
import Contact from 'pages/contact/contact';
import StandardApp from '../qwc2/components/StandardApp';
import appConfig from '../js/appConfig';
import '../qwc2/icons/build/qwc2-icons.css';

// import PayPal from 'pages/payment/paypal';
// const DashboardDefault = import('pages/dashboard');
// const Home = import('pages/home');
// const JobList = import('pages/joblists/index');
// const AddJobs = import('pages/joblists/addjob');
// const SelectLocation = import('pages/joblists/selectlocation/index');
// const PaymentScreen = import('pages/payment');
// const ForgotPassword = import('pages/forgotpassword/forgotpassword');
// const CompanyProfile = import('pages/profile/companyProfile');
// const Profile = import('pages/profile/index');
// const ChangePassword = import('pages/profile/changePassword');
// ==============================|| MAIN ROUTING ||============================== //
const NavigateToCarbon = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/login', { replace: true });
  }, [navigate]);

  return null;
};

const NavigateToDashboard = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/user/dashboard', { replace: true });
  }, [navigate]);

  return null;
};

const HomeRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.menu);
  return !isLoggedIn ? <Home /> : <NavigateToDashboard />;
};

const DashboardRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.menu);
  return isLoggedIn ? <DashboardDefault /> : <NavigateToCarbon />;
};
const ProfileRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.menu);
  return isLoggedIn ? <Profile /> : <NavigateToCarbon />;
};
const CompanyProfileRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.menu);
  return isLoggedIn ? <CompanyProfile /> : <NavigateToCarbon />;
};
const AccountTypeRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.menu);
  return isLoggedIn ? <AccountType /> : <NavigateToCarbon />;
};
const JobListRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.menu);
  return isLoggedIn ? <JobList /> : <NavigateToCarbon />;
};
const AddJobRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.menu);
  return isLoggedIn ? <AddJobs /> : <NavigateToCarbon />;
};
const UnverifiedUserRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.menu);
  return isLoggedIn ? <UnverifiedUser /> : <NavigateToCarbon />;
};
const VerifyCompanyUserByTokenRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.menu);
  return isLoggedIn ? <VerifyCompanyUserByToken/> : <NavigateToCarbon />;
};
const SelectLoationRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.menu);
  return isLoggedIn ? <SelectLocation /> : <NavigateToCarbon />;
};
const PaymentRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.menu);
  return isLoggedIn ? <PaymentScreen /> : <NavigateToCarbon />;
};
const PaymentDetailRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.menu);
  return isLoggedIn ? <PaymentDetail /> : <NavigateToCarbon />;
};
const ChangePasswordRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.menu);
  return isLoggedIn ? <ResetPassword /> : <NavigateToCarbon />;
};

const NotificationRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.menu);
  return isLoggedIn ? <Notification /> : <NavigateToCarbon />;
};
const PaymentHistoryRoute = () => {
  const { isLoggedIn, user } = useSelector((state) => state.menu);
  return isLoggedIn && user?.plan === 'PPU' ? <PaymentHistory /> : <NavigateToCarbon />;
};
const CaseStudyDashboardRoute = () => {
  const { isLoggedIn } = useSelector((state) => state.menu);
  return isLoggedIn ? <CaseStudyDashboard /> : <NavigateToCarbon />;
};

// const PayPalRoute = () => {
//   const { isLoggedIn } = useSelector((state) => state.menu);
//   return isLoggedIn ? <PayPal /> : <NavigateToCarbon />;
// };

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Home />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardRoute />
        }
      ]
    },
    {
      path: 'auth/verify_company_user_by_token',
      element: <VerifyCompanyUserByToken />
    },
    {
      path: 'profile',
      element: <ProfileRoute />
    },
    {
      path: 'accountType',
      element: <AccountTypeRoute />
    },
    {
      path: 'companyprofile',
      element: <CompanyProfileRoute />
    },
    {
      path: 'home',
      element: <HomeRoute />
    },
    {
      path: 'about',
      element: <About />
    },
    {
      path: 'contact',
      element: <Contact />
    },
    {
      path: 'joblists',
      element: <JobListRoute />
    },
    {
      path: 'user/dashboard',
      element: <CaseStudyDashboardRoute />
    },
    {
      path: 'map',
      element: <StandardApp appConfig={appConfig} />
    },
    {
      path: 'addjobs',
      element: <AddJobRoute />
    },
    {
      path: 'unverified_user',
      element: <UnverifiedUserRoute />
    },
   
    {
      path: 'selectlocation',
      element: <SelectLoationRoute />
    },
    {
      path: 'payment',
      element: <PaymentRoute />
    },
    {
      path: 'payment_detail',
      element: <PaymentDetailRoute />
    },
    {
      path: 'payment_history',
      element: <PaymentHistoryRoute />
    },
    // {
    //   path: 'checkout',
    //   element: <PayPalRoute />
    // },
    {
      path: 'forgotpassword',
      element: <ForgotPassword />
    },
    {
      path: 'auth/reset-password',
      element: <ForgotPasswordFields />
    },
    {
      path: 'resetpassword',
      element: <ChangePasswordRoute />
    },
    {
      path: 'notification',
      element: <NotificationRoute />
    },
    {
      path: 'auth/verify',
      element: <VerifyEmailScreen />
    },
    {
      path: 'auth/verify_company_user_by_token',
      element:<VerifyCompanyUserByTokenRoute/>
      
    },
    {
      path: 'login',
      element: <AuthLogin />
    },
    {
      path: 'register',
      element: <AuthRegister />
    },
    {
      path: '*',
      element: <PageNotFound />
    }
  ]
};

export default MainRoutes;
