// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.measure-body {
    margin-top: 0.25em;
    white-space: nowrap;
    overflow: visible;
    min-width: 17.5em;
    display: flex;
}

div.measure-body > span.measure-result {
    flex: 1 1 auto;
    border: 1px solid var(--border-color);
    background-color: var(--input-bg-color);
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
}

div.measure-body > select {
    flex: 0 0 auto;
    margin-left: -1px;
    z-index: 1;
}

div.measure-body > span.CopyButton {
    margin-left: -1px;
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/plugins/style/Measure.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,iBAAiB;IACjB,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,qCAAqC;IACrC,uCAAuC;IACvC,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":["div.measure-body {\n    margin-top: 0.25em;\n    white-space: nowrap;\n    overflow: visible;\n    min-width: 17.5em;\n    display: flex;\n}\n\ndiv.measure-body > span.measure-result {\n    flex: 1 1 auto;\n    border: 1px solid var(--border-color);\n    background-color: var(--input-bg-color);\n    height: 2em;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\ndiv.measure-body > select {\n    flex: 0 0 auto;\n    margin-left: -1px;\n    z-index: 1;\n}\n\ndiv.measure-body > span.CopyButton {\n    margin-left: -1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
