// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#PrintFrame {
    position: absolute;
    box-shadow: rgba(0, 0, 0, 0.5) 0 0 0 30000px;
    z-index: 2;
    pointer-events: none;
    text-align: center;
    font-size: small;
    overflow: hidden;
}

#PrintFrame span.size-box {
    background: rgba(255, 255, 255, 0.5);
    padding: 0.25em;
    white-space: nowrap;
}

#PrintFrameEventLayer {
    z-index: 3;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/components/style/PrintFrame.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,4CAA4C;IAC5C,UAAU;IACV,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,oCAAoC;IACpC,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,QAAQ;IACR,SAAS;AACb","sourcesContent":["#PrintFrame {\n    position: absolute;\n    box-shadow: rgba(0, 0, 0, 0.5) 0 0 0 30000px;\n    z-index: 2;\n    pointer-events: none;\n    text-align: center;\n    font-size: small;\n    overflow: hidden;\n}\n\n#PrintFrame span.size-box {\n    background: rgba(255, 255, 255, 0.5);\n    padding: 0.25em;\n    white-space: nowrap;\n}\n\n#PrintFrameEventLayer {\n    z-index: 3;\n    position: absolute;\n    left: 0;\n    top: 0;\n    right: 0;\n    bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
