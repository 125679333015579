import React, { useState, useEffect } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  TextField,
  InputAdornment,
  Grid,
  Container,
  Typography,
  Box
} from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { useSelector, useDispatch } from 'react-redux';
import { markNotificationAsRead, markNotificationsAsRead } from 'store/reducers/menu'; // Import the action
import CircularProgress from '@mui/material/CircularProgress';

let sortedData = [];

const Notification = () => {
  const { notificationInfo, readNotificationIds } = useSelector((state) => state.menu);
  const [orderBy, setOrderBy] = useState('requested_time');
  const [sortDirection, setSortDirection] = useState('desc');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch(); // Get dispatch function
  const [searchTerm, setSearchTerm] = useState(''); // Add this line for search functionality
  const [notificationsMarkedAsRead, setNotificationsMarkedAsRead] = useState(false); // Track if notifications are marked as read
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (notificationInfo) {
      setIsLoading(false); // Once data is available, set loading state to false
    }
  }, [notificationInfo]);
  const handleSort = (columnId) => {
    const isAsc = orderBy === columnId && sortDirection === 'asc';
    setOrderBy(columnId);
    setSortDirection(isAsc ? 'desc' : 'asc');
  };
  useEffect(() => {
    handleMarkNotificationsAsRead();
  }, []);
  useEffect(() => {
    const unreadNotificationIds =
      notificationInfo.length > 0 &&
      notificationInfo
        .filter((notification) => !readNotificationIds?.includes(notification.job_id) && !notification.read)
        .map((notification) => notification.job_id);

    if (unreadNotificationIds.length > 0) {
      dispatch(markNotificationsAsRead(unreadNotificationIds));
    }
  }, [dispatch, notificationInfo, readNotificationIds]);
  const handleMarkNotificationsAsRead = () => {
    const unreadNotificationIds =
      notificationInfo.length > 0 &&
      notificationInfo.filter((notification) => !notification.read).map((notification) => notification.job_id);

    if (unreadNotificationIds.length > 0) {
      dispatch(markNotificationAsRead(unreadNotificationIds));
      dispatch(markNotificationsAsRead(unreadNotificationIds));
    }
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Filter data based on search term

  const filteredData =
    notificationInfo.length > 0 &&
    notificationInfo?.filter((item) =>
      Object.values(item).some((value) => {
        if (typeof value === 'string') {
          return value?.toLowerCase()?.includes(searchTerm?.toLowerCase());
        }
        return false;
      })
    );

  if (filteredData?.length > 0) {
    sortedData = [...filteredData].sort((a, b) => {
      const dateA = new Date(a.requested_time);
      const dateB = new Date(b.requested_time);

      // Sort in descending order based on the dates
      return sortDirection === 'asc' ? dateA - dateB : dateB - dateA;
    });
  }

  const getDate = (timestampString) => {
    let formattedDate;
    if (timestampString != null && timestampString != '' && timestampString != 'N/A') {
      const timestamp = new Date(timestampString);
      // Format for the date (e.g., Feb 12, 2024)
      const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };
      formattedDate = timestamp.toLocaleDateString('en-US', dateOptions);
      return formattedDate;
    } else {
      return '';
    }
  };

  return (
    <Container fixed className="joblist">
      <Box className="joblist_heading">
        <h1 className="sub-heading">Notifications</h1>
      </Box>
      <Box>
        <TextField
          label="Search"
          variant="outlined"
          size="small"
          sx={{ mb: 2 }}
          stle={{ float: 'right' }}
          onChange={(e) => setSearchTerm(e.target.value)}
          // InputProps={{
          //   startAdornment: <InputAdornment position="start">{/* You can add a search icon here if needed */}</InputAdornment>
          // }}
          InputLabelProps={{ shrink: true }}
          inputProps={{ style: { padding: '3px 10px', width: '250px' } }}
        />
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {/* Render your table headers here */}
              <TableCell onClick={() => handleSort('job_name')}>
                Job Name
                {orderBy === 'job_name' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell>
              <TableCell onClick={() => handleSort('requested_time')}>
                Request Date
                {orderBy === 'requested_time' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell>
              <TableCell onClick={() => handleSort('message')}>
                Message
                {orderBy === 'message' && <span>{sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}</span>}
              </TableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableRow>
              <TableCell colSpan={8} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : (
            <>
              {sortedData?.length === 0 ? (
                <TableCell colSpan={8} align="center">
                  <Typography variant="h4" color="green" component="span" style={{ marginLeft: '0px', lineHeight: 4 }}>
                    No Records Found!
                  </Typography>
                </TableCell>
              ) : (
                <>
                  <TableBody>
                    {sortedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                      <React.Fragment key={index}>
                        <TableRow>
                          <TableCell>{item.job_name}</TableCell>
                          <TableCell>{getDate(item.requested_time)}</TableCell>
                          <TableCell>{item.message}</TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                </>
              )}
            </>
          )}
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={sortedData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Container>
  );
};

export default Notification;
