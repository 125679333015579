// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.CopyButton {
    position: relative;
}

span.copybutton-tooltip {
    position: absolute;
    visibility: hidden;
    background-color: var(--tooltip-bg-color);
    border: 1px solid var(--tooltip-border-color);
    color: var(--tooltip-text-color);
    font-size: 75%;
    font-weight: normal;
    padding: 0.5em;
    border-radius: 0.5em;
    top: 110%;
    white-space: nowrap;
}

span.CopyButton:hover span.copybutton-tooltip {
    visibility: visible;
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/components/widgets/style/CopyButton.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,yCAAyC;IACzC,6CAA6C;IAC7C,gCAAgC;IAChC,cAAc;IACd,mBAAmB;IACnB,cAAc;IACd,oBAAoB;IACpB,SAAS;IACT,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":["span.CopyButton {\n    position: relative;\n}\n\nspan.copybutton-tooltip {\n    position: absolute;\n    visibility: hidden;\n    background-color: var(--tooltip-bg-color);\n    border: 1px solid var(--tooltip-border-color);\n    color: var(--tooltip-text-color);\n    font-size: 75%;\n    font-weight: normal;\n    padding: 0.5em;\n    border-radius: 0.5em;\n    top: 110%;\n    white-space: nowrap;\n}\n\nspan.CopyButton:hover span.copybutton-tooltip {\n    visibility: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
