import { CLIENT_ID } from 'config';
import React, { useState, useEffect, useCallback } from 'react';
import { Button, Alert, Snackbar, CircularProgress } from '@mui/material';
import SessionExpired from 'pages/session/sessionExpired';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import axios from 'axios';
import { BaseUrl } from 'config';
import { useSelector } from 'react-redux';

const PayPal = ({ price, jobId, generateJobSteps, showPayment, handleOrderApiRes, onBackOrTabChange }) => {
  const { TOKEN } = useSelector((state) => state.menu);
  const [show, setShow] = useState(true);
  const [success, setSuccess] = useState(false);
  const [orderID, setOrderID] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [paymentToken, setPaymentToken] = useState();
  const [orderApiRes, setOrderApiRes] = useState([]);
  const [snackBarMessage, setSnackBarMessage] = useState({ message: '', status: '' });
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [sessionExpired, setSessionExpired] = useState(false);

  const createOrder = useCallback(
    (data, actions) => {
      return actions.order
        .create({
          purchase_units: [
            {
              amount: { currency_code: 'USD', value: price }
            }
          ],
          application_context: { shipping_preference: 'NO_SHIPPING' }
        })
        .then((orderID) => {
          setOrderID(orderID);
          return orderID;
        });
    },
    [price]
  );

  const onApprove = useCallback((data, actions) => {
    return actions.order.capture().then((details) => {
      setPaymentCompleted(true);
      setSuccess(true);
      setPaymentToken(data?.facilitatorAccessToken);
      setOrderApiRes(details);
    });
  }, []);

  const handleSessionExpiredClose = useCallback((status) => {
    setSessionExpired(status);
  }, []);

  const onError = useCallback(() => {
    setOpenSnackBar(true);
    setSnackBarMessage({ message: 'An Error occurred with your payment', status: 'error' });
  }, []);

  const handleClosePaymentAlert = useCallback(() => {
    setOpenSnackBar(false);
  }, []);
  useEffect(() => {
    const handleNavbarClick = () => {
      console.log('Navbar clicked');
      if (showPayment) {
        onBackOrTabChange();      }
    };

    const navbars = document.getElementsByClassName('myNav');
    for (let i = 0; i < navbars.length; i++) {
      navbars[i].addEventListener('click', handleNavbarClick);
    }

    // Cleanup event listeners on component unmount
    return () => {
      for (let i = 0; i < navbars.length; i++) {
        navbars[i].removeEventListener('click', handleNavbarClick);
      }
    };
  }, [showPayment]);
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden' && orderID && !paymentCompleted) {
        console.log('Calling onBackOrTabChange due to visibility change');
        onBackOrTabChange();
      }
    };

    const handlePopState = () => {
      if (!paymentCompleted) {
        console.log('Calling onBackOrTabChange due to pop state');
        onBackOrTabChange();
      }
    };

    const handleBeforeUnload = () => {
      if (!paymentCompleted) {
        console.log('Calling onBackOrTabChange due to beforeunload');
        onBackOrTabChange();
      }
    };

    const handleHashChange = () => {
      if (!paymentCompleted) {
        console.log('Calling onBackOrTabChange due to hash change');
        onBackOrTabChange();
      }
    };

    const handleClickEvent = (event) => {
      const target = event.target.closest('a');
      if (target && target.href && target.href !== window.location.href) {
        console.log('Calling onBackOrTabChange due to link click');
        onBackOrTabChange();
      }
    };

    let lastPathname = window.location.pathname;

    const checkUrlChange = () => {
      if (lastPathname !== window.location.pathname) {
        lastPathname = window.location.pathname;
        if (!paymentCompleted) {
          console.log('Calling onBackOrTabChange due to URL change');
          onBackOrTabChange();
        }
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('popstate', handlePopState);
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('hashchange', handleHashChange);
    document.addEventListener('click', handleClickEvent);
    const urlChangeInterval = setInterval(checkUrlChange, 100);

    // Observe DOM for dynamically added links
    const observer = new MutationObserver(() => {
      document.querySelectorAll('a').forEach(link => {
        link.addEventListener('click', handleClickEvent);
      });
    });
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('hashchange', handleHashChange);
      document.removeEventListener('click', handleClickEvent);
      clearInterval(urlChangeInterval);
      observer.disconnect();
    };
  }, [paymentCompleted, onBackOrTabChange]);

  
  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (document.hidden && orderID && !paymentCompleted) {
  //       onBackOrTabChange();
  //     }
  //   };

  //   const handlePopState = () => {
  //     if (orderID && !paymentCompleted) {
  //       onBackOrTabChange();
  //     }
  //   };

  //   document.addEventListener('visibilitychange', handleVisibilityChange);
  //   window.addEventListener('popstate', handlePopState);

  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //     window.removeEventListener('popstate', handlePopState);
  //   };
  // }, [orderID, paymentCompleted, onBackOrTabChange]);

  useEffect(() => {
    if (success) {
      setOpenSnackBar(true);
      setSnackBarMessage({
        message: `Payment successfully done! Order successful. Your order id is ${orderID}`,
        status: 'success'
      });
      handleOrderApiRes({ job_id: jobId, orderID, orderApiRes });
    } else {
      setOpenSnackBar(false);
      setSnackBarMessage({ message: `Something went wrong. Try again later!`, status: 'error' });
      handleOrderApiRes(null);
    }
  }, [success, orderID, jobId, handleOrderApiRes]);

  return (
    <PayPalScriptProvider options={{ 'client-id': CLIENT_ID }}>
      <div>
        <div className="product-price-btn">
          {/* <Button className="buy-btn" style={{ color: '#ffffff', marginTop: 10 }} fullWidth size="large" onClick={() => setShow(true)}>
            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Make Payment'}
          </Button> */}
        </div>
        <br />
        {show && <PayPalButtons style={{ layout: 'vertical' }} createOrder={createOrder} onApprove={onApprove} onError={onError} />}
      </div>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{marginTop:50}}
        open={openSnackBar}
        autoHideDuration={3000}
        onClose={handleClosePaymentAlert}
      >
        <Alert onClose={handleClosePaymentAlert} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>

      {sessionExpired && <SessionExpired handleSessionExpiredClose={handleSessionExpiredClose} />}
    </PayPalScriptProvider>
  );
};

export default PayPal;
