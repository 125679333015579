import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import navigation from 'menu-items';
import Breadcrumbs from 'components/@extended/Breadcrumbs';
// import BgImage from '../../image/dashboard_bg.png'
// types
import { openDrawer } from 'store/reducers/menu';
import Footer from './Footer/footer';

// ==============================|| MAIN LAYOUT ||============================== //
const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state) => state.menu);

  // drawer toggler
  const [open, setOpen] = useState(!drawerOpen);
  const handleDrawerToggle = () => {
    // console.log("handleDrawerToggle",!open)
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };




  // set media wise responsive drawer
  useEffect(() => {
    setOpen(!matchDownLG);
    dispatch(openDrawer({ drawerOpen: !matchDownLG }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(()=>{
  //   const currentUrl = window.location.pathname;
  //   if(currentUrl == '/'){

  //   }
  // },[])

  return (
    <>
      {/* <img src={BgImage} className="logRegImg" alt="" /> */}
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Header id="header" open={open} handleDrawerToggle={handleDrawerToggle} className='transparentheader'/>
        <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
        <Box component="main" style={{padding:0}} className="zindex" sx={{ width: '100%', mt:5, flexGrow: 1, p: { xs: 2, sm: 3 } }}>
          {/* <Toolbar /> */}
          <Box sx={{marginTop:'40px'}}></Box>
          <Breadcrumbs navigation={navigation} title />
          <Outlet />
        </Box>
      </Box>
      {/* {console.log("navigateion",navigation)} */}

      <Footer />
    </>
  );
};

export default MainLayout;
