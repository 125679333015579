// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#Editing div.editing-body {
    padding: 0.25em;
    position: relative;
    max-height: calc(100vh - 8.25em); /* viewport - topbar - bottombar - sidebar_titlebar*/
    max-height: calc(var(--vh, 1vh) * 100 - 8.25em); /* viewport - topbar - bottombar - sidebar_titlebar*/
    display: flex;
    flex-direction: column;
    height: 100%;
}

#Editing .editing-minimize-maximize {
    margin-left: 1em;
    cursor: pointer;
    padding: 0.25em;
}

#Editing div.editing-body > div.editing-layer-selection,
#Editing div.editing-body > div.editing-feature-selection,
#Editing div.editing-body > div.ButtonBar {
    flex: 0 0 auto;
}

#Editing div.editing-body > div.AttributeForm {
    margin-top: 0.5em;
    padding-top: 0.5em;
    border-top: 1px solid var(--border-color);
    flex: 1 1 auto;
    overflow: hidden;
}

#Editing select.editing-layer-select,
#Editing select.editing-feature-select {
    width: 100%;
}

#Editing select.editing-layer-select:disabled,
#Editing select.editing-feature-select:disabled {
    cursor: not-allowed;
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/plugins/style/Editing.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,gCAAgC,EAAE,oDAAoD;IACtF,+CAA+C,EAAE,oDAAoD;IACrG,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,eAAe;AACnB;;AAEA;;;IAGI,cAAc;AAClB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,yCAAyC;IACzC,cAAc;IACd,gBAAgB;AACpB;;AAEA;;IAEI,WAAW;AACf;;AAEA;;IAEI,mBAAmB;AACvB","sourcesContent":["#Editing div.editing-body {\n    padding: 0.25em;\n    position: relative;\n    max-height: calc(100vh - 8.25em); /* viewport - topbar - bottombar - sidebar_titlebar*/\n    max-height: calc(var(--vh, 1vh) * 100 - 8.25em); /* viewport - topbar - bottombar - sidebar_titlebar*/\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n\n#Editing .editing-minimize-maximize {\n    margin-left: 1em;\n    cursor: pointer;\n    padding: 0.25em;\n}\n\n#Editing div.editing-body > div.editing-layer-selection,\n#Editing div.editing-body > div.editing-feature-selection,\n#Editing div.editing-body > div.ButtonBar {\n    flex: 0 0 auto;\n}\n\n#Editing div.editing-body > div.AttributeForm {\n    margin-top: 0.5em;\n    padding-top: 0.5em;\n    border-top: 1px solid var(--border-color);\n    flex: 1 1 auto;\n    overflow: hidden;\n}\n\n#Editing select.editing-layer-select,\n#Editing select.editing-feature-select {\n    width: 100%;\n}\n\n#Editing select.editing-layer-select:disabled,\n#Editing select.editing-feature-select:disabled {\n    cursor: not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
