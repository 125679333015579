// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social-links .social-boxes {
    display: flex;
    align-items: flex-start;
}

.social-links .social-boxes .social-box {
    text-align: center;
    margin-right: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/components/share/style/ShareSocials.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;AACtB","sourcesContent":[".social-links .social-boxes {\n    display: flex;\n    align-items: flex-start;\n}\n\n.social-links .social-boxes .social-box {\n    text-align: center;\n    margin-right: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
