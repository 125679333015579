import React, { useEffect, useState } from 'react';
import DashNotification from './dashnotification';
import CaseStudy from './casestudy';
import JobDetails from './jobdetails';
import UserDetailsStats from './userdetailsstats';
import { Container, Grid, Box, Alert, Snackbar } from '@mui/material';
import axios from 'axios';
import { BaseUrl } from 'config';
import { useSelector } from 'react-redux';
import SessionExpired from 'pages/session/sessionExpired';

const CaseStudyDashboard = () => {
  const { TOKEN, user } = useSelector((state) => state.menu);

  const [isLoading, setIsLoading] = useState(false);
  const [jobDetails, setJobDetails] = useState([]);
  const [personalJobDetails, setPersonalJobDetails] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: '',
    status: ''
  });
  const personalJobList = true;
  const handleSessionExpiredClose = (status) => {
    setSessionExpired(status);
  };

  const handleClosePaymentAlert = () => {
    setOpenSnackBar(false);
  };

  const groupByJobId = (jobs) => {
    return jobs.reduce((result, item) => {
      const existingItem = result.find((groupedItem) => groupedItem.job_id === item.job_id);
      const getTime = (timestampString) => {
        const timestamp = new Date(timestampString);
        // Format for the time (e.g., 07:32:52)
        const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
        const formattedTime = timestamp.toLocaleTimeString('en-US', timeOptions);
        return formattedTime;
      };
      const getDate = (timestampString) => {
        const timestamp = new Date(timestampString);
        // Format for the date (e.g., Feb 12, 2024)
        const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };
        const formattedDate = timestamp.toLocaleDateString('en-US', dateOptions);
        return formattedDate;
      };
      if (existingItem) {
        existingItem.Location_geojson.push({
          Location_geojson: item.Location_geojson,
          location_name: item.location_name
        });
      } else {
        result.push({
          area_sq_miles: item.area_sq_miles,
          calculated_cost: item.calculated_cost,
          job_id: item.job_id,
          job_location_id: item.job_location_id,
          job_name: item.job_name,
          overall_status: item.overall_status,
          plan_applied: item.plan_applied,
          // requested_timestamp: getTime(item.requested_timestamp),
          requested_date: getDate(item.requested_timestamp),
          requested_time: getTime(item.requested_timestamp),
          Location_geojson: [
            {
              Location_geojson: item.Location_geojson,
              location_name: item.location_name
            }
          ]
        });
      }

      return result;
    }, []);
  };
  function getFormattedDate(timestamp) {
    const date = new Date(timestamp);

    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    console.log(`${month}/${day}/${year}`);
    return `${month}/${day}/${year}`;
  }

  function getFormattedTime(timestamp) {
    const date = new Date(timestamp);

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    console.log(`${hours}:${minutes}:${seconds}`);
    return `${hours}:${minutes}:${seconds}`;
  }
  const getJobDetails = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${BaseUrl}/home/jobslist`,
        // { payment_status: user?.company_status ? false : true },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`
          }
        }
      );
      console.log('JobDetails response', response);
      if (response?.status === 200 && response?.data[0]?.length > 0) {
        setIsLoading(false);
        //  const groupedData = groupByJobId(response?.data.filter((val) => val.payment_status != false && val.plan_applied === user.plan));

        // const groupedData = groupByJobId(response?.data);
        //sorting the latest job at top
        //  const sorted_data = groupedData.sort((a, b) => new Date(b.requested_date) - new Date(a.requested_date));
        // const sorted_data = groupedData.sort((a, b) => {
        //   let dateA = new Date(`${a.requested_date} ${a.requested_time}`);
        //   let dateB = new Date(`${b.requested_date} ${b.requested_time}`);
        //   return dateB - dateA;
        // });
        // console.log('cost data', sorted_data);
        const sorted_data_personal = response?.data[2]?.sort((a, b) => {
          let dateA = new Date(`${getFormattedDate(a.requested_timestamp)} ${getFormattedTime(a.requested_timestamp)}`);
          let dateB = new Date(`${getFormattedDate(b.requested_timestamp)} ${getFormattedTime(b.requested_timestamp)}`);
          return dateB - dateA;
        });
        const sorted_data = response?.data[0]?.sort((a, b) => {
          let dateA = new Date(`${getFormattedDate(a.requested_timestamp)} ${getFormattedTime(a.requested_timestamp)}`);
          let dateB = new Date(`${getFormattedDate(b.requested_timestamp)} ${getFormattedTime(b.requested_timestamp)}`);
          return dateB - dateA;
        });
        setJobDetails(sorted_data);
        setPersonalJobDetails(sorted_data_personal);
      } else if (response?.status === 200 && response?.data[0]?.length === 0) {
        //if user does not have jobs
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setOpenSnackBar(true);
        // setSnackBarMessage({
        //   message: 'Something went wrong. Try again later jdapi!',
        //   status: 'error'
        // });
      }
    } catch (error) {
      console.log('errror of JobDetails', error);
      setIsLoading(false);
      setOpenSnackBar(true);
      console.log('job request error', error);
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired please login again to continue',
          status: 'error'
        });
      } else {
        setSnackBarMessage({
          message: 'Something went wrong. Try again later!',
          status: 'error'
        });
      }
    }
  };

  useEffect(() => {
    getJobDetails([]);
  }, []);
  return (
    <Container sx={{ mt: 5 }} maxWidth="lg" className="casestudydash">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <UserDetailsStats isLoading={isLoading} jobDetails={jobDetails} />
        </Grid>
        {/* {user.plan != 'ENT' ? (
          <>
            <Grid item lg={4} md={4} sm={12} xs={12} className="casestudynotification">
              <Box className="boundryBox boundaryBox2">
                <DashNotification />
              </Box>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12} className="casestudyjob">
              <Box className="boundryBox">
                <JobDetails isLoading={isLoading} jobDetails={jobDetails} />
              </Box>
            </Grid>
          </>
        ) : ( */}
          <>
          <Grid item lg={4} md={4} sm={12} xs={12} className="casestudynotification">
              <Box className="boundryBox boundaryBox2">
                <DashNotification />
              </Box>
            </Grid>
            <Grid item lg={8} md={8} sm={12} xs={12} className="casestudyjob">
              <Box className="boundryBox">
                <JobDetails isLoading={isLoading} jobDetails={jobDetails} />
              </Box>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} className="casestudynotification">
              {user?.company_id != null &&
              <Box className="boundryBox boundaryBox2">
                <JobDetails isLoading={isLoading} jobDetails={personalJobDetails} personalJobList={personalJobList} />
              </Box>
}
            </Grid>
          </>
        {/* )} */}

        {/* <Grid item xs={12} >
          <Grid item >
            <center >
              <h1 className="heading" style={{ margin: '10px 0px' }}>
                Case study of Friendswood city
              </h1>
            </center>
          </Grid>
          <CaseStudy />
        </Grid> */}
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{marginTop:50}}
        open={openSnackBar}
        autoHideDuration={10000}
        onClose={handleClosePaymentAlert}
      >
        <Alert onClose={handleClosePaymentAlert} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>

      {sessionExpired ? <SessionExpired handleSessionExpiredClose={handleSessionExpiredClose} /> : ''}
    </Container>
  );
};

export default CaseStudyDashboard;
