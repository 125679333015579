import React from 'react';
import { Grid, Box, CircularProgress } from '../../../node_modules/@mui/material/index';
import UserImage from '../../image/dashboardIcon.png';
import CashIcon from '../../image/cashIcon.png';
import SearchIcon from '../../image/searchIcon.png';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const UserDetailsStats = ({ isLoading, jobDetails }) => {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.menu);

  return (
    <Grid container spacing={2} className="casestudyuser">
      <Grid item lg={7} md={7} sm={12} xs={12}>
        <Box className="boundryBox gradiant">
          <Grid container spacing={3}>
            <Grid item md={8} sm={8} xs={12}>
              <div className="welcome">
                Welcome,<span>{`${user?.first_name} ${user?.last_name}`} !</span>
              </div>
              <div className="thanks">Thanks to joining us.</div>
              <b>Now you are ready to contribute data of carbon emission in your area.</b>
              <div className="desc">
                This could involve providing information or statistics related to carbon emissions, such as levels of Carbon emission,
                sources of emissions like transportation or industry, house or efforts to reduce emissions like renewable energy adoption or
                carbon capture technologies.
              </div>
            </Grid>
            <Grid item md={4} sm={4} xs={12}>
              <img src={UserImage} alt="" height="140px" />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item lg={5} md={5} sm={12} xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Box className="boundryBox payment">
              <h3>Payment</h3>
              <Grid class="jobpaymentstats">
                <img src={CashIcon} alt="" />
                <div className='jobstats'>
                  <p>TOTAL PAYMENT</p>
                  <span className="value" style={{ fontSize: 25 }}>
                    {isLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      `$${jobDetails?.reduce((acc, location) => acc + Number(location.calculated_cost), 0).toFixed(0)}`
                    )}
                  </span>
                </div>
              </Grid>
              {/* <Button
                size="small"
                sx={{ float: 'right', marginTop: '40px' }}
                variant="contained"
                color="primary"
                onClick={() => navigate('/payment_history')}
              >
                More Details
              </Button> */}
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Box className="boundryBox search">
              <h3>Jobs</h3>
              <Grid class="jobpaymentstats">
                <img src={SearchIcon} alt="" />
                <div className="jobstats">
                  <p>
                    <div className="title">TOTAL JOBS</div>
                    <div className="value">{isLoading ? <CircularProgress size={12} /> : jobDetails?.length}</div>
                  </p>
                  <p>
                    <div className="title">PENDING JOBS</div>
                    <div className="value">
                      {isLoading ? <CircularProgress size={12} /> : jobDetails?.filter((val) => val.overall_status === 'Pending')?.length}
                    </div>
                  </p>
                  <p>
                    <div className="title">COMPLETED JOBS</div>
                    <div className="value">
                      {' '}
                      {isLoading ? <CircularProgress size={12} /> : jobDetails?.filter((val) => val.overall_status !== 'Pending')?.length}
                    </div>
                  </p>
                </div>
              </Grid>
              {/* <Grid> */}
                {/* <Button
                  size="small"
                  onClick={() => navigate('/joblists')}
                  sx={{ float: 'right', marginTop: '40px' }}
                  variant="contained"
                  color="primary"
                >
                  More Details
                </Button> */}
              {/* </Grid> */}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserDetailsStats;
