import React, { useState, useEffect } from 'react';
import {
  Button,
  TextField,
  Grid,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  InputAdornment,
  IconButton,
  Container
} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import { BaseUrl } from 'config';
import { useNavigate } from 'react-router-dom';

const ForgotPasswordFields = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState({
    newPassword: '',
    confirmPassword: ''
  });

  const [validationErrors, setValidationErrors] = useState({
    newPassword: '',
    confirmPassword: ''
  });

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: '',
    status: 'success'
  });

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [TOKEN, setTOKEN] = useState();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleChange = (prop) => (event) => {
    setInputValue({ ...inputValue, [prop]: event.target.value });
    // Reset validation errors when the user types
    setValidationErrors({ ...validationErrors, [prop]: '' });
  };

  const handleTogglePasswordVisibility = (prop) => {
    switch (prop) {
      case 'newPassword':
        setShowNewPassword(!showNewPassword);
        break;
      case 'confirmPassword':
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  const validateForm = () => {
    let isValid = true;
    // Add your validation logic here for each field
    const requiredFields = ['newPassword', 'confirmPassword'];

    requiredFields.forEach((field) => {
      if (!inputValue[field]) {
        setValidationErrors((prevErrors) => ({
          ...prevErrors,
          [field]: 'This field is required'
        }));
        isValid = false;
      }
    });

    // Password validation
    const passwordRegex = /^(?=.*[!@#$%^&*])(?=.*[a-zA-Z])(?=.*[0-9]).{6,}$/;
    if (!passwordRegex.test(inputValue.newPassword)) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        newPassword: 'Password must contain at least 6 characters, one special character, and a combination of letters and numbers'
      }));
      isValid = false;
    }

    // Confirm password validation
    if (inputValue.newPassword !== inputValue.confirmPassword) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        confirmPassword: 'Passwords do not match'
      }));
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validate before submitting
    if (validateForm()) {
      try {
        setIsLoading(true);
        const response = await axios.post(
          `${BaseUrl}/auth/reset-password`,
          {
            token: TOKEN,
            password: inputValue.newPassword
          },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        );
        console.log('Forgot password response', response);
        setIsLoading(false);
        setOpenSnackBar(true);
        if (response?.data == null) {
          setSnackBarMessage({
            message: 'Password Changed successfully!',
            status: 'success'
          });
          navigate('/login');
        } else if (response?.data?.detail === 'RESET_PASSWORD_BAD_TOKEN') {
          setSnackBarMessage({
            message: 'Your token expired! Please generate another token for forgot password.',
            status: 'error'
          });
        } else {
          setSnackBarMessage({
            message: 'Something went wrong. Try again later',
            status: 'error'
          });
        }
      } catch (error) {
        console.log('forgot password err', error);
        setIsLoading(false);
        setOpenSnackBar(true);
        setSnackBarMessage({
          message: 'Something went wrong. Try again later',
          status: 'error'
        });
      }
    }
  };

  useEffect(() => {
    let url = 'https://carbonui.digitalglyde.com/' + location?.pathname + location?.search;
    const searchParams = new URLSearchParams(new URL(url).search);
    const token = searchParams.get('token');
    console.log('forget password token token', token);
    setTOKEN(token);
  }, []);

  return (
    <Container>
      <Grid container>
        <Grid item lg={2} md={1} xs={0}></Grid>
        <Grid item lg={8} md={10} xs={12}>
          <Typography component="h1" variant="h5" className="heading">
            Forgot Password
          </Typography>
          <form style={{ width: '100%' }} onSubmit={handleSubmit}>
            <TextField
              label="New Password"
              type={showNewPassword ? 'text' : 'password'}
              fullWidth
              required
              value={inputValue.newPassword}
              onChange={handleChange('newPassword')}
              margin="normal"
              variant="outlined"
              error={!!validationErrors.newPassword}
              helperText={validationErrors.newPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => handleTogglePasswordVisibility('newPassword')}>
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
              InputLabelProps={{ shrink: true }}
            />

            <TextField
              label="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              fullWidth
              required
              value={inputValue.confirmPassword}
              onChange={handleChange('confirmPassword')}
              margin="normal"
              variant="outlined"
              error={!!validationErrors.confirmPassword}
              helperText={validationErrors.confirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => handleTogglePasswordVisibility('confirmPassword')}>
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
              InputLabelProps={{ shrink: true }}
            />

            <Grid container sx={{ mb: 1, mt: 1 }}>
              <Grid item xs></Grid>
              <Grid item>
                <Button type="submit" className="login_button" variant="contained" size="large">
                  {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Change Password'}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>

        <Grid item lg={2} md={1} xs={0}></Grid>
      </Grid>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{marginTop:50}} open={openSnackBar} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ForgotPasswordFields;
