// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.navbar {
    display: inline-flex;
    align-items: center;
    margin-right: 1em;
}

div.navbar > select {
    margin-left: 0.25em;
}
div.navbar > button.button:not(:first-child) {
    margin-left: -1px;
}

div.navbar > span.navbar-dots {
    padding: 0 0.25em;
}

div.navbar > span.navbar-dots + button.button {
    border-left-width: 1px;
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/components/widgets/style/NavBar.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;AACvB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":["div.navbar {\n    display: inline-flex;\n    align-items: center;\n    margin-right: 1em;\n}\n\ndiv.navbar > select {\n    margin-left: 0.25em;\n}\ndiv.navbar > button.button:not(:first-child) {\n    margin-left: -1px;\n}\n\ndiv.navbar > span.navbar-dots {\n    padding: 0 0.25em;\n}\n\ndiv.navbar > span.navbar-dots + button.button {\n    border-left-width: 1px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
