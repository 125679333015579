import React, { useEffect, useState } from 'react';
// import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Container, Grid, Button } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { BaseUrl } from 'config';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { login, userDetails, logout, companyDetails, mapKeyDetails, notificationDetails, paymentHistoryDetails } from 'store/reducers/menu';

const VerifyEmailScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [authStatus, setAuthStatus] = useState(false);

  const sendEmailVerificationLink = async (token) => {
    try {
      setIsLoading(true);
      console.log('token', token);
      const response = await axios.post(
        `${BaseUrl}/auth/verify`,
        { token: token },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log('User verification status', response);
      if (response?.data?.id) {
        setIsLoading(false);
        setAuthStatus(true);
      } else {
        setIsLoading(false);
        setAuthStatus(false);
      }
    } catch (error) {
      console.log('errror of verification', error);
      if (error?.response?.data?.detail === 'VERIFY_USER_ALREADY_VERIFIED') {
        setIsLoading(false);
        setAuthStatus(true);
      } else if (error?.response?.data?.detail === 'VERIFY_USER_BAD_TOKEN') {
        setIsLoading(false);
        setAuthStatus(false);
      } else {
        setIsLoading(false);
        setAuthStatus(false);
      }
    }
  };

  useEffect(() => {
    let url = 'https://carbonui.digitalglyde.com/' + location?.pathname + location?.search;
    const searchParams = new URLSearchParams(new URL(url).search);
    const token = searchParams.get('token');
    console.log('verificatin token', token);
    sendEmailVerificationLink(token);
    localStorage.clear();
    dispatch(logout(null));
    dispatch(userDetails(null));
    dispatch(companyDetails(null));
    dispatch(notificationDetails(null));
    dispatch(paymentHistoryDetails(null));
    dispatch(mapKeyDetails(1));
  }, []);

  return (
    <>
      <Container component="main" className="verification">
        <Grid>
          {isLoading ? (
            <>
              <div className="authSpinner"></div>
              <h1 className="heading">Email Verifying...</h1>
            </>
          ) : !authStatus ? (
            <>
              <div className="circle_cross">
                <ClearIcon />
              </div>
              <h1 className="heading" style={{ color: 'red' }}>
                Email Verification Failed. Try again later..
              </h1>
              <h3 className="sub-headings">
                We regret to inform you that the authentication token associated with your account has either expired or is invalid. Please
                ensure that you are using the latest token and try again.
              </h3>
              <Button className="login_button" sx={{ mt: 5 }} variant="contained" size="large" onClick={() => navigate('/login')}>
                Login
              </Button>
            </>
          ) : (
            <>
              <div className="circle_check">
                <CheckIcon />
              </div>
              <h1 className="heading">Email Verified Successfully</h1>
              <h3 className="sub-headings">
                Thank you for verifying your email address. Your email has been successfully confirmed, and your account is now fully
                activated. Please click below on login button to continue.
              </h3>
              <Button className="login_button" variant="contained" size="large" onClick={() => navigate('/login')}>
                Login
              </Button>
            </>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default VerifyEmailScreen;
