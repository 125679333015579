import React from 'react';
import { Box, Grid, Link, Button, Container, Typography } from '@mui/material';
import Step2Image from '../../image/step2.png';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';


const Step2 = () => {
  return (
    <>
    <Box className="step2" sx={{ flexGrow: 1 }} id="harnessingAI" style={{height:window.innerHeight}}>
    <Container>
      <Grid container >
        <Grid item lg={6} sm={12}>
          <Box data-aos="zoom-in-up" style={{textAlign:'center'}}>
            <img src={Step2Image} alt="" style={{ width: '50%', marginTop:'25px'}} className='steps'/>
          </Box>
        </Grid>
        <Grid item lg={6} sm={12}>
        {/* <Link href="#select"><Button variant="contained" size="large">
        <ArrowBackIcon/> Back 
            
            </Button></Link> */}
          <Box data-aos="slide-left">
            <Typography variant="h2" className="heading2">Harnessing AI for Precise Estimations</Typography>
            <Typography variant="body1">
              Our cutting edge deep learning algorithm kicks into action. We gather relevant data everything from energy consumption
              patterns and transportation modes to industrial emissions and land use. The algorithm crunches numbers, considers various
              factors, and provides an accurate estimate of the areas carbon footprint. It is like having a digital environmental detective!
            </Typography>
          </Box>
          
        </Grid>
      </Grid>
      <Link href="#unveiling" style={{margin:"10% auto", display:"table"}}><Button variant="contained" size="large">
              Next <ArrowForwardIcon/>
            
            </Button></Link>
      </Container>
      
    </Box>
    </>
  );
};

export default Step2;
