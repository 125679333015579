// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#BottomBar {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 3em;
    z-index: 100;
    color: var(--panel-text-color);
    background-color: var(--panel-bg-color);
    box-shadow: 0 -2px 4px rgba(136, 136, 136, 0.5);
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
    font-size: 75%;
    display: flex;
    align-items: center;
    padding-left: 125px;
}

#BottomBar > span {
    margin: 0.5em;
    display: flex;
    align-items: center;
}


#BottomBar input.bottombar-mousepos {
    width: 20ch;
    text-align: center;
    margin-right: -1px;
}

#BottomBar div.bottombar-scale-combo {
    position: relative;
    width: 10em;
}

#BottomBar span.bottombar-scale-combo-prefix {
    text-align: right;
    width: 2em;
}
#BottomBar div.bottombar-scale-combo > input {
    position: absolute;
    top: 0;
    left: 2em;
    /* width: 6em; */
}

#BottomBar span.bottombar-spacer {
    flex: 1 1 auto;
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/plugins/style/BottomBar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,SAAS;IACT,WAAW;IACX,YAAY;IACZ,8BAA8B;IAC9B,uCAAuC;IACvC,+CAA+C;IAC/C,kCAA0B;YAA1B,0BAA0B;IAC1B,cAAc;IACd,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;AACvB;;;AAGA;IACI,WAAW;IACX,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,iBAAiB;IACjB,UAAU;AACd;AACA;IACI,kBAAkB;IAClB,MAAM;IACN,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["#BottomBar {\n    position: absolute;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    height: 3em;\n    z-index: 100;\n    color: var(--panel-text-color);\n    background-color: var(--panel-bg-color);\n    box-shadow: 0 -2px 4px rgba(136, 136, 136, 0.5);\n    backdrop-filter: blur(3px);\n    font-size: 75%;\n    display: flex;\n    align-items: center;\n    padding-left: 125px;\n}\n\n#BottomBar > span {\n    margin: 0.5em;\n    display: flex;\n    align-items: center;\n}\n\n\n#BottomBar input.bottombar-mousepos {\n    width: 20ch;\n    text-align: center;\n    margin-right: -1px;\n}\n\n#BottomBar div.bottombar-scale-combo {\n    position: relative;\n    width: 10em;\n}\n\n#BottomBar span.bottombar-scale-combo-prefix {\n    text-align: right;\n    width: 2em;\n}\n#BottomBar div.bottombar-scale-combo > input {\n    position: absolute;\n    top: 0;\n    left: 2em;\n    /* width: 6em; */\n}\n\n#BottomBar span.bottombar-spacer {\n    flex: 1 1 auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
