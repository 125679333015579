import React, { useState, useEffect, useRef, useMemo } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import { useMap } from 'react-leaflet';
import { TextField, Button } from '@mui/material';
import PointerIcon from '@mui/icons-material/Room';
import { Grid, IconButton, Snackbar, Alert, CircularProgress } from '@mui/material';
import L from 'leaflet';
import SearchIcon from '@mui/icons-material/Search';
// import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';

const SearchMapLocation = ({ getLocationList, locationData }) => {
  const [location, setLocation] = useState('');
  const [locationName, setLocationName] = useState('');
  const [geometries, setGeometries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [mapKey, setMapKey] = useState(1000);
  const [locationNameError, setLocationNameError] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: '',
    status: ''
  });
  const [clearMapStatus, setClearMapStatus] = useState(false);

  const timeoutRef = useRef(null);
  const mapRef = useRef(null);
  // console.log('locationDatadddddddddd', locationData, !clearMapStatus);

  const clearMapLayers = (map) => {
    if (map) {
      map.eachLayer((layer) => {
        if (layer instanceof L.Path) {
          layer.removeFrom(map);
        }
      });
    }
  };

  const calculateTotalArea = (geometries) => {
    let totalAreaInSquareMeters = 0;
    let convertedCoordinates = [];
    console.log('geometries');
    geometries.forEach((geometry) => {
      const { type, coordinates } = geometry;
      console.log('rrrrrrrrrrrrrrrrrrrrrr', type, coordinates);
      switch (type) {
        case 'Polygon': {
          convertedCoordinates.push(coordinates[0].map((coord) => ({ lat: coord[1], lng: coord[0] })));
          console.log('convertedCoordinates', coordinates, convertedCoordinates);
          totalAreaInSquareMeters += L.GeometryUtil.geodesicArea(convertedCoordinates[0]);
          break;
        }
        case 'MultiPolygon': {
          coordinates.forEach((polygonCoords) => {
            if (polygonCoords?.length > 0) {
              const convertedPolygon = polygonCoords[0].map((coord) => ({ lat: coord[1], lng: coord[0] }));
              convertedCoordinates.push(convertedPolygon);
              console.log('convertedPolygon', convertedPolygon, polygonCoords);
              totalAreaInSquareMeters += L.GeometryUtil.geodesicArea(convertedPolygon);
            }
          });
          break;
        }
        default:
          console.error('Unsupported geometry type:', type);
      }
    });

    // Convert total area to square miles
    const totalAreaInSquareMiles = totalAreaInSquareMeters * 3.861e-7;
    return totalAreaInSquareMiles;
  };

  const createGeoJson = () => {
    //conver the coordinates into geojson
    const features = geometries.map((geometry, index) => ({
      type: 'Feature',
      id: index + 1,
      properties: {
        LocationName: locationName
      },
      geometry
    }));

    const geoJson = {
      type: 'FeatureCollection',
      features
    };
    console.log('geojson file', geoJson);
    return geoJson;
  };

  const handleAdd = async () => {
    if (!locationName.trim()) {
      setLocationNameError('Please enter a location Name.');
      return;
    }

    if (geometries.length > 0) {
      const totalArea = await calculateTotalArea(geometries);
      const geolocation = createGeoJson();
      const calculated_cost = 86 * totalArea;

      const getDate = () => {
        const options = { month: 'short', day: 'numeric', year: 'numeric' };
        return new Date().toLocaleDateString('en-US', options);
      };

      const capitalizeEachWord = (text) => {
        return text.replace(/\b\w/g, (match) => match.toUpperCase());
      };

      const location_list = {
        ...locationData,
        filename: `${locationName.replace(/\s/g, '').toLowerCase()}.geojson`,
        locationName: capitalizeEachWord(locationName),
        calculated_cost: calculated_cost.toFixed(2),
        location_area_sq_mile: totalArea.toFixed(2),
        requested_timestamp: getDate(),
        locations: geolocation,
        type: 'select'
      };

      console.log('locationData....', locationData);

      // if (locationData) {
      //   location_list.location_id = locationData.location_id;
      // setSnackBarMessage({
      //   message: 'Location updated Successfully!',
      //   status: 'success'
      // });
      // } else {
      // setSnackBarMessage({
      //   message: 'Location Added Successfully!',
      //   status: 'success'
      // });
      // setSnackBarMessage('Location Added Successfully!');
      // }

      console.log('location_list', location_list);
      getLocationList(location_list);

      //clear the map coordinates
      const map = mapRef.current;
      clearMapLayers(map);
    } else {
      console.warn('No geometries to calculate area.');
    }

    // setOpenSnackBar(true);
    setLocation('');
    setLocationName('');
    setGeometries('');
    setClearMapStatus(false);
  };

  const calculateArea = (coordinates) => {
    const convertedCoordinates = coordinates.map((coord) => ({ lat: coord[1], lng: coord[0] }));
    return L.GeometryUtil.geodesicArea(convertedCoordinates);
  };

  // Debounce function
  const debounce = (func, delay) => {
    return function (...args) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        func.apply(this, args);
      }, delay);
    };
  };

  const getCoordinatesForLocation = async (location) => {
    try {
      if (!location.trim()) {
        setLoading(false);
        setError('Please enter a location before searching.');
        return;
      }
      setLoading(true);
      // Clear the map before fetching new coordinates
      const map = mapRef.current;
      clearMapLayers(map);

      const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${location}&polygon_geojson=1`);
      const data = await response.json();
      console.log('Searched location data', data);
      setLoading(false);
      if (data.length > 0) {
        const geoJsondata = data.map((item) => item.geojson);
        const map = mapRef.current;
        const newLayers = [];
        let filteredGeometries = [];
        setMapKey(mapKey + 1);
        setClearMapStatus(true);
        // To display the coordinates on map
        let hasSupportedType = false;
        data.forEach((item) => {
          const { type, coordinates } = item.geojson;
          console.log('type of search location ', type, coordinates);
          switch (type) {
            case 'Polygon': {
              let polygon;
              const area = calculateArea(coordinates[0]);
              console.log('areaaaaaaaaaaaaaa', area);

              if (area >= 2589988.11 && coordinates?.length > 0) {
                polygon = L.polygon(
                  coordinates[0].map((coord) => [coord[1], coord[0]]),
                  { color: '#469100' }
                );
                map.fitBounds(
                  coordinates[0].map((coord) => {
                    if (coord?.length > 0) {
                      return [coord[1], coord[0]];
                    }
                  })
                );
                polygon.addTo(map);
                newLayers.push(polygon);
                filteredGeometries.push(item.geojson);
                console.log('hasSupportedType = true;', hasSupportedType);
                hasSupportedType = true;
              } else {
                hasSupportedType = true;
                setOpenSnackBar(true);
                setSnackBarMessage({
                  message: `Removed some shapes from ${location} due to area less than 1 square mile.`,
                  status: 'warning'
                });
              }
              break;
            }
            case 'MultiPolygon': {
              let polygon;
              coordinates.forEach((polygonCoords) => {
                const area = calculateArea(polygonCoords[0]);
                console.log('areaaaaaaaaaaaaaa', area);
                if (area >= 2589988.11) {
                  if (polygonCoords?.length > 0) {
                    polygon = L.polygon(
                      polygonCoords.map((coord) => {
                        if (coord?.length > 0) {
                          return [coord[1], coord[0]];
                        }
                      }),
                      { color: '#469100' }
                    );
                    polygon.addTo(map);
                    newLayers.push(polygon);
                    filteredGeometries.push({
                      type: 'Polygon',
                      coordinates: polygonCoords
                    });
                    map.fitBounds(
                      polygonCoords.map((coord) => {
                        if (coord?.length > 0) {
                          return [coord[1], coord[0]];
                        }
                      })
                    );
                  }
                  console.log('hasSupportedType = true;', hasSupportedType);
                  hasSupportedType = true;
                } else {
                  hasSupportedType = true;
                  setOpenSnackBar(true);
                  setSnackBarMessage({
                    message: `Removed some shapes from ${location} due to area less than 1 square mile.`,
                    status: 'warning'
                  });
                }
              });
              break;
            }
            default:
              console.error('Unsupported geometry type:', type);
          }
        });

        if (!hasSupportedType) {
          setOpenSnackBar(true);
          setSnackBarMessage({
            message: 'Please select a location that draws a polygon or multipolygon!',
            status: 'error'
          });
        }

        console.log('geoJsonData', map, hasSupportedType);
        // Add all new layers to the map at once
        L.layerGroup(newLayers).addTo(map);
        setGeometries(filteredGeometries);
        setError('');
      } else {
        setError('Location not found');
        setGeometries([]);
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      setLoading(false);
      setGeometries([]);
      setError('Something went wrong. Please try again later!');
    }
  };

  const debouncedGetCoordinates = debounce(getCoordinatesForLocation, 500);

  const handleSearch = () => {
    setLoading(true);
    debouncedGetCoordinates(location);
    // setGeometries([]); // Clear previous results
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  useEffect(() => {
    return () => {
      // Cleanup function to clear any remaining timeouts
      clearTimeout(timeoutRef.current);
    };
  }, []);

  useEffect(() => {
    if (locationData && locationData.clearMapStatus !== undefined) {
      // Only update clearMapStatus if it's different from the current value
      if (locationData.clearMapStatus === clearMapStatus) {
        setClearMapStatus(locationData.clearMapStatus);
      }
    } else {
      setLocationName('');
    }
  }, [locationData, clearMapStatus]);

  // useEffect(() => {
  //   console.log('locationData?.clearMapStatus', locationData?.clearMapStatus);
  //   setClearMapStatus(locationData?.clearMapStatus);
  // }, [locationData]);

  useEffect(() => {
    const map = mapRef.current;
    clearMapLayers(map);
  }, [mapKey, locationData, !clearMapStatus]);

  const DisplayLocation = () => {
    const map = useMap();
    mapRef.current = map;
    let allCoordinates = [];

    useEffect(() => {
      console.log('klsdfjds', locationData, locationName, clearMapStatus);

      // Clear the map before adding new layers
      if (locationData && locationData.locations && locationData.locations.features && locationData.locations.features.length > 0) {
        if (locationData.mode == 'edit' && locationName != '' && clearMapStatus) {
          // clearMapLayers(map);
          return;
        }
        setLocationName(locationData.locationName);
        setMapKey(locationData.id);

        locationData.locations.features.forEach((feature) => {
          const { geometry, properties } = feature;
          const { type, coordinates } = geometry;
          switch (type) {
            case 'Polygon': {
              let polygon;
              if (locationData.type == 'select' || locationData.type == 'upload') {
                if (coordinates?.length > 0) {
                  polygon = L.polygon(
                    coordinates[0].map((coord) => [coord[1], coord[0]]),
                    { color: '#469100' }
                  );
                  polygon.addTo(map);
                  map.fitBounds(
                    coordinates[0].map((coord) => [coord[1], coord[0]]),
                    { color: '#469100' }
                  );
                }
              } else {
                if (coordinates?.length > 0) {
                  polygon = L.polygon(coordinates[0], { color: '#469100' });
                  polygon.addTo(map);
                  map.fitBounds(coordinates[0]);
                }
              }
              allCoordinates = allCoordinates.concat(coordinates[0]);
              // Add Tooltip to the Polygon
              const tooltipContent = properties.LocationName;
              polygon.bindTooltip(tooltipContent).openTooltip();
              break;
            }
            case 'MultiPolygon': {
              let polygon;
              if (locationData.type == 'select' || locationData.type == 'upload') {
                coordinates.forEach((polygonCoords) => {
                  if (polygonCoords?.length > 0) {
                    polygon = L.polygon(
                      polygonCoords[0].map((coord) => [coord[1], coord[0]]),
                      { color: '#469100' }
                    );
                    polygon.addTo(map);
                    map.fitBounds(polygonCoords[0].map((coord) => [coord[1], coord[0]]));
                    allCoordinates = allCoordinates.concat(polygonCoords[0]);
                  }
                });
              } else {
                coordinates.forEach((polygonCoords) => {
                  if (polygonCoords?.length > 0) {
                    polygon = L.polygon(polygonCoords[0], { color: '#469100' });
                    polygon.addTo(map);
                    map.fitBounds(polygonCoords[0]);
                    allCoordinates = allCoordinates.concat(polygonCoords[0]);
                  }
                });
              }
              // Add Tooltip to the MultiPolygon
              const tooltipContentMulti = properties.LocationName;
              polygon.bindTooltip(tooltipContentMulti).openTooltip();
              break;
            }
            // case 'Point': {
            //   const pointCoordinates = [coordinates[1], coordinates[0]]; // Swap coordinates for Leaflet
            //    L.marker(pointCoordinates)
            //     .addTo(map)
            //     .bindPopup(properties.LocationName); // You can customize the popup content
            //   break;
            // }
            default:
              console.error('Unsupported geometry type:', type);
          }
        });
        // Calculate bounds
        console.log('allCoordinates', allCoordinates);
        if (allCoordinates.length > 0 && locationData?.hide) {
          const bounds = L.latLngBounds(allCoordinates.map((coord) => L.latLng(coord[1], coord[0])));
          // Fit map to bounds
          map.fitBounds(bounds);
        }
      } else {
        clearMapLayers(map);
      }
    }, [clearMapStatus]);

    return null;
  };

  const MyMap = () => {
    const map = useMap();
    mapRef.current = map;

    console.log('sdklfjddsgeometries', geometries);
    if (geometries.length > 0) {
      geometries.forEach((geometry) => {
        const { type, coordinates } = geometry;
        switch (type) {
          case 'Polygon': {
            if (coordinates?.length > 0) {
              const polygon = L.polygon(
                coordinates[0].map((coord) => [coord[1], coord[0]]),
                { color: '#469100' }
              );
              polygon.addTo(map);
              map.fitBounds(coordinates[0].map((coord) => [coord[1], coord[0]]));
            }
            break;
          }
          case 'MultiPolygon': {
            coordinates.forEach((polygonCoords) => {
              if (polygonCoords?.length > 0) {
                const polygon = L.polygon(
                  polygonCoords[0].map((coord) => [coord[1], coord[0]]),
                  { color: '#469100' }
                );
                polygon.addTo(map);
                map.fitBounds(polygonCoords[0].map((coord) => [coord[1], coord[0]]));
              }
            });
            break;
          }
          default:
            console.error('Unsupported geometry type:', type);
        }
      });
    }

    return null;
  };

  const MapContainerMemoized = useMemo(
    () => (
      <MapContainer key={mapKey} center={[0, 0]} zoom={2} style={{ height: '500px' }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        {locationData && !clearMapStatus ? <DisplayLocation /> : <MyMap />}
      </MapContainer>
    ),
    [mapKey, locationData, !clearMapStatus]
  );

  return (
    <Grid container spacing={2}>
      <Grid item md={4} sm={12} xs={12}>
        <h1 className="heading">{`${locationData?.hide ? '' : 'Select Location'}`}</h1>
      </Grid>
      <Grid item md={2} sm={0} xs={0}></Grid>
      {locationData?.mode == 'view' ? (
        ''
      ) : (
        <Grid item md={6} xs={12}>
          <div className="locationSearch">
            <TextField
              label={'Search Location'}
              variant="outlined"
              className="locationName searchLocation"
              fullWidth
              value={location}
              onChange={(e) => {
                setLocation(e.target.value);
                setError('');
              }}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
              InputProps={{
                startAdornment: (
                  // <Button disabled style={{ minWidth: 0 }}>
                  <PointerIcon style={{ color: 'green' }} />
                  // </Button>
                )
              }}
              error={Boolean(error)}
              helperText={error}
            />
            <IconButton color="primary" className="searchbtnicon" onClick={handleSearch} disabled={loading}>
              {loading ? <CircularProgress size={24} color="inherit" /> : <SearchIcon />}
            </IconButton>
          </div>
        </Grid>
      )}

      <Grid item xs={12}>
        {MapContainerMemoized}
      </Grid>
      <Grid item md={10} xs={8}>
        {locationData?.mode == 'view' ? (
          ''
        ) : (
          <TextField
            label="Enter Location Name"
            variant="outlined"
            className="locationName"
            value={locationName}
            style={{ width: '100%' }}
            onChange={(e) => {
              setLocationName(e.target.value);
              setLocationNameError('');
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleAdd();
              }
            }}
            error={Boolean(locationNameError)}
            helperText={locationNameError}
            // disabled={locationData?.mode == 'edit'}
            inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
            InputLabelProps={{ shrink: true }}
          />
        )}
      </Grid>
      <Grid item md={2} xs={4} style={{ paddingBottom: 16 }}>
        {locationData?.mode == 'edit' ? (
          <Button variant="contained" sx={{ mb: 5 }} className="addbtn searchbtn" fullWidth onClick={handleAdd}>
            Update
          </Button>
        ) : locationData?.mode == 'view' ? (
          ''
        ) : (
          <Button variant="contained" sx={{ mb: 5 }} className="addbtn searchbtn" fullWidth onClick={handleAdd}>
            Add <KeyboardArrowRightSharpIcon className="arrowicon" />
          </Button>
        )}
      </Grid>

      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{marginTop:50}} open={openSnackBar} autoHideDuration={10000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>
      {/* <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackBar} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar> */}
    </Grid>
  );
};

export default SearchMapLocation;
