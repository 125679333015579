import React, { useState } from 'react';
import { BaseUrl } from 'config';
import axios from 'axios';
import { useNavigate } from '../../../node_modules/react-router-dom/dist/index';
import CircularProgress from '@mui/material/CircularProgress';
import { Button, TextField, Grid, Typography, Container, Snackbar, Alert, Box } from '@mui/material';
import BgImage from '../../../src/image/login_bg.png';

const ForgotPassword = () => {
    const navigate = useNavigate();
  const [inputValue, setInputValue] = useState({
    email: ''
  });
  const [validationErrors, setValidationErrors] = useState({
    email: ''
  });
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: 'User Login Successfully!',
    status: 'success'
  });
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (prop) => (event) => {
    setInputValue({ ...inputValue, [prop]: event.target.value });
    setValidationErrors({ ...validationErrors, [prop]: '' });
  };

  const validateForm = () => {
    let isValid = true;

    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(inputValue.email)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, email: 'Enter a valid email address' }));
      isValid = false;
    }
    return isValid;
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Validate before submitting
    if (validateForm()) {
      console.log('kldsjf', BaseUrl);
      setIsLoading(true);
      axios
        .post(
          `${BaseUrl}/auth/forgot-password`,
          { email: inputValue.email },
          {
            headers: {
              'Content-Type': 'application/json'
            }
          }
        )
        .then((response) => {
          console.log('forgot password response', response);
          if (response?.data == null && response.status === 202) {
            setOpenSnackBar(true);
            setIsLoading(false);
            setSnackBarMessage({
              message: 'We have sent a password reset link to your email address. Please check your inbox to reset your password.',
              status: 'success'
            });
            setTimeout(() => {
              navigate('/login');
            }, 10000);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          setOpenSnackBar(true);
          console.log('error occured while forgot password..', error);
          if (error?.response?.data?.detail == 'LOGIN_BAD_CREDENTIALS') {
            setSnackBarMessage({
              message: 'Email not exist!',
              status: 'error'
            });
          } else {
            setSnackBarMessage({
              message: 'Something went wrong. Try again later',
              status: 'error'
            });
          }
        });
    }
  };

  return (
    <Box className="loginreg">
      <img src={BgImage} className="logRegImg" alt="" />
      <Grid container>
        <Grid item lg={5} md={5} xs={0}></Grid>
        <Grid item lg={6} md={7} xs={12} className="log_spacing logspacing">
          <Container component="main" maxWidth="xs" style={{ maxWidth: '475px' }}>
            <Typography component="h1" variant="h5">
              Forgot your password?
            </Typography>
            <Typography component="h3" variant="h5">
              Lost your password ? Please enter the email address to continue.
            </Typography>
            <form onSubmit={handleSubmit} className='myInput'>
              <TextField
                label="Email"
                type="email"
                fullWidth
                required
                value={inputValue.email}
                onChange={handleChange('email')}
                margin="normal"
                variant="outlined"
                error={!!validationErrors.email}
                helperText={validationErrors.email}
                inputProps={{ style: { backgroundColor: 'white',padding:'3px 10px' } }}
                InputLabelProps={{ shrink: true }}
              />
              {/* <Grid container> */}
                {/* <Grid item xs></Grid> */}
                {/* <Grid item> */}
                  <Button type="submit" className="login_button" variant="contained" size="large">
                    {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Continue'}
                  </Button>
                {/* </Grid> */}
              {/* </Grid> */}
            </form>
          </Container>
        </Grid>
        <Grid item lg={1} md={0} xs={0}></Grid>
      </Grid>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{marginTop:50}} open={openSnackBar} autoHideDuration={8000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ForgotPassword;
