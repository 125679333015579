// HomePage.js
import React from 'react';
import { Grid, Box, Container, Typography, List, ListItem, ListItemText } from '@mui/material';
import AboutImage from '../../image/about.png';
import AboutImageBackground from '../../image/home2.png';
import HomeIcon from '@mui/icons-material/Home';
import { useNavigate } from 'react-router-dom';
const About = () => {
  const navigate = useNavigate();
  return (

  
      <Container>
        <Grid container className="about" id="about" style={{paddingTop:"10px"}}>
          <Grid item lg={6} md={6} sm={12}>
            <Typography variant="h1" >
              Who we are?
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
              Empowering Change for a Greener Tomorrow.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Welcome to our platform a space where environmental consciousness meets actionable impact. We are passionate about addressing
              the urgent climate crisis by empowering individuals, communities, and organizations to take meaningful steps toward
              sustainability.
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
              Our Mission
            </Typography>
            <Typography variant="body1" gutterBottom>
              Our mission is crystal clear: reduce carbon emissions and enhance carbon sequestration. We believe that every small action
              counts, whether it is calculating your personal carbon footprint, supporting reforestation projects, or advocating for cleaner
              energy sources. By fostering awareness and providing practical tools, we aim to create a ripple effect one that transforms
              individual choices into collective progress.
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12}>
            <img src={AboutImage} alt="About Carbon Footprint" className="image-gap" />
          </Grid>
          <Grid item sm={12} className="about-texts">
            <Typography variant="h1" className="heading heading1" gutterBottom>
              What We Offer
            </Typography>
            <Typography component='ul'>
              <Typography component='li' gutterBottom>  
                <span>Carbon Footprint Estimation :</span> Our user-friendly calculators help you understand your carbon footprint. From
                daily commute to household energy consumption, we break down the numbers and guide you toward smarter choices.
                </Typography>
                <Typography component='li' gutterBottom> 
                <span>Local Insights :</span> Dive into localized data on emissions. Explore trends, discover regional challenges, and learn
                how your community contributes to the global carbon equation.
                </Typography>
                <Typography component='li' gutterBottom>
                <span>Sequestration Solutions :</span> Discover innovative ways to sequester carbon. Whether it is planting trees,
                supporting carbon capture technologies, or advocating for policy changes, we are here to inspire action.
                </Typography>
              </Typography>
          </Grid>
        </Grid>
      </Container>

  );
};

export default About;
