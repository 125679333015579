// HomePage.js
import React from 'react';
import { Grid, Box, Button, Typography } from '@mui/material';
import AboutImage from '../../image/about.png';
import { useNavigate } from 'react-router-dom';
const About = () => {
  const navigate = useNavigate();
  return (
    <Box className="about" id="about" style={{minHeight: window.innerHeight}}>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={12} className="about-text">
          <Box data-aos="fade-up" data-aos-duration="3000">
            <Typography variant="h1" className="heading">
              About Us
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
              Empowering Change for a Greener Tomorrow.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Welcome to our platform a space where environmental consciousness meets actionable impact. We are passionate about addressing
              the urgent climate crisis by empowering individuals, communities, and organizations to take meaningful steps toward
              sustainability.
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
              Our Mission
            </Typography>
            <Typography variant="body1" gutterBottom>
              Our mission is crystal clear: reduce carbon emissions and enhance carbon sequestration. We believe that every small action
              counts, whether it is calculating your personal carbon footprint, supporting reforestation projects, or advocating for cleaner
              energy sources. By fostering awareness and providing practical tools, we aim to create a ripple effect—one that transforms
              individual choices into collective progress.
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12}>
          <Box data-aos="zoom-in-up">
            <img src={AboutImage} alt="About Carbon Footprint" className="about-image" style={{ marginTop: '30px' }} />
          </Box>
        </Grid>
        
      </Grid>
      <Button variant="contained" size="large" onClick={() => navigate('/about')}>
          More Details
        </Button>
    </Box>
  );
};

export default About;
