// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.height-profile-chart-container {
    height: 100%;
}

div.height-profile-loading-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.5em;
    padding-bottom: 0.5em;
    height: 100%;
}

div.height-profile-loading-indicator > div.spinner {
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.25em;
}

div.height-profile-error {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
}`, "",{"version":3,"sources":["webpack://./src/qwc2/plugins/style/HeightProfile.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;IACnB,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;AACd","sourcesContent":["div.height-profile-chart-container {\n    height: 100%;\n}\n\ndiv.height-profile-loading-indicator {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding-left: 0.5em;\n    padding-bottom: 0.5em;\n    height: 100%;\n}\n\ndiv.height-profile-loading-indicator > div.spinner {\n    width: 1.5em;\n    height: 1.5em;\n    margin-right: 0.25em;\n}\n\ndiv.height-profile-error {\n    height: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
