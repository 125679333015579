import React from 'react';
import { Grid, Button, Typography, Box } from '../../../node_modules/@mui/material/index';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const DashNotification = () => {
  const { notificationInfo } = useSelector((state) => state.menu);
 // let upadtedNotificationInfo= notificationInfo?.filter((item)=>it)
  const navigate = useNavigate();
  let sortedNotifications = [];

  const getDate = (timestampString) => {
    if(timestampString != null && timestampString != "" && timestampString != "N/A" ){
      const timestamp = new Date(timestampString);
      // Format for the date (e.g., Feb 12, 2024)
      const dateOptions = { month: 'short', day: 'numeric', year: 'numeric' };
      const formattedDate = timestamp.toLocaleDateString('en-US', dateOptions);
      return formattedDate;
    }
    else
    return ""
  };

  // Sort notifications by requested_time in descending order
  if (notificationInfo?.length > 0) {
    sortedNotifications = [...notificationInfo].sort((a, b) => {
      const timestampA = new Date(a.requested_time);
      const timestampB = new Date(b.requested_time);
      return timestampB - timestampA;
    });
  }

  return (
    <Grid container spacing={3} className="dashnotification">
      <Grid item xs={12} className="notificationHead">
        <NotificationsIcon />
        <h3>Notifications</h3>

        {sortedNotifications?.length !== 0 && (
          <Button size="small" sx={{ float: 'right' }} variant="contained" color="primary" onClick={() => navigate('/notification')}>
            More Details
          </Button>
        )}
      </Grid>
      <Grid item xs={12} className="notificationContainer">
        <Box className="ticket">
          {sortedNotifications?.length > 0
            ? sortedNotifications.map((val, index) => {
                if (index <= 3) {
                  return (
                    <div className="notificationmap" key={index}>
                      <div className="notificationCotainerText">
                        <Typography variant="h6" className="title">
                          {val.job_name}
                        </Typography>
                        <Typography variant="body2" className="details">
                          {getDate(val.requested_time)}
                        </Typography>
                      </div>
                      <div className="msg">{val.message}</div>
                    </div>
                  );
                }
              })
            : 'Currently no notification found!'}
          {/* <div className="notificationmap">
            <div className="notificationCotainerText">
              <Typography variant="h6" className="title">
                Unbilled Ammount
              </Typography>
              <Typography variant="body2" className="details">
                10 sept, 2023
              </Typography>
            </div>
            <div>lorem30 sdfjs sjdf iow sdfsj foiew sjsj</div>
          </div> */}
        </Box>
      </Grid>
    </Grid>
  );
};

export default DashNotification;
