// third-party
// import { configureStore } from '@reduxjs/toolkit';

// project import
// import { store, persistor } from './reducers';

// ==============================|| REDUX TOOLKIT - MAIN STORE ||============================== //

// const store = configureStore({
//   reducer: reducers
// });

// const { dispatch } = store;

// export { store, dispatch };

// third-party
// import { combineReducers } from 'redux';

// project import

// ==============================|| COMBINE REDUCERS ||============================== //

// const reducers = combineReducers({ menu });

// export default reducers;

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage
import thunk from 'redux-thunk';
import menu from './reducers/menu';

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, combineReducers({ menu }));

const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
});
const persistor = persistStore(store);

export { store, persistor };

