import PropTypes from 'prop-types';
import { useNavigate } from '../../../../../../node_modules/react-router-dom/dist/index';
// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText, Badge } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import PaymentsIcon from '@mui/icons-material/Payments';
import LockResetIcon from '@mui/icons-material/LockReset';
import PersonIcon from '@mui/icons-material/Person';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { logout, markNotificationAsRead } from 'store/reducers/menu';
import { useDispatch, useSelector } from 'react-redux';
// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

const ProfileTab = () => {
  const { notificationInfo, readNotificationIds, user } = useSelector((state) => state.menu);
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logout(null));
    navigate('/login');
  };
  const unreadNotifications = notificationInfo?.filter(
    (notification) => !readNotificationIds?.includes(notification.job_id) && !notification.read
  );
  const userPlan = user?.plan != null && user.plan;
  return (
    <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
      <ListItemButton onClick={() => navigate('/profile')}>
        <ListItemIcon>
          <PersonIcon />
        </ListItemIcon>
        <ListItemText primary="View Profile" />
      </ListItemButton>
      { userPlan != null && userPlan !== 'PPU' && (
        <ListItemButton onClick={() => navigate('/companyprofile')}>
          <ListItemIcon>
            <SupervisedUserCircleIcon />
          </ListItemIcon>

          <ListItemText primary="Company Profile" />
        </ListItemButton>
      )}
      
      <ListItemButton onClick={() => navigate('/resetpassword')}>
        <ListItemIcon>
          <LockResetIcon />
        </ListItemIcon>
        <ListItemText primary="Change Password" />
      </ListItemButton>
      {/* <ListItemButton onClick={() => navigate('/payment_history')}>
        <ListItemIcon>
          <PaymentsIcon />
        </ListItemIcon>
        <ListItemText primary="Payment History" />
      </ListItemButton> */}
      <ListItemButton onClick={() => navigate('/notification')}>
        <ListItemIcon>
          <Badge badgeContent={unreadNotifications?.length} color="error">
            <NotificationsIcon />
          </Badge>
        </ListItemIcon>
        <ListItemText primary="Notification" />
      </ListItemButton>

      <ListItemButton onClick={handleLogout}>
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </ListItemButton>
    </List>
  );
};

ProfileTab.propTypes = {
  handleLogout: PropTypes.func
};

export default ProfileTab;
