import React from 'react';
import { Grid, Box, Typography, CircularProgress, Container } from '@mui/material';
import CarIcon from '../../image/carIcon.png';
import industryIcon from '../../image/industryIcon.png';
import HouseHoldIcon from '../../image/householdIcon.png';
import VegitationIcon from '../../image/vegitationIcon.png';
import Water from '../../image/water.png';
import Solar from '../../image/solar.png';

const TreeStats = ({ isLoading, dataFetchedStatus, detectedData }) => {
  console.log('detectedData', detectedData);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className="para" sx={{ bgcolor: '#257874' }}>
            <Typography variant="h2" gutterBottom>
              {isLoading ? (
                <CircularProgress size={20} />
              ) : dataFetchedStatus ? (
                <Typography variant="span" style={{ fontSize: '40px', color: '#ffff', fontFamily: 'Montserrat'}}>
                  {detectedData?.trees_needed}
                </Typography>
              ) : (
                0
              )}
            </Typography>
            <Typography variant="body1" className="totalResult" style={{ color: '#fff' }}>
              TREES NEEDED
            </Typography>
          </Box>
        </Grid>

        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className="para" sx={{ bgcolor: '#FF9900' }}>
            <Typography variant="h2" gutterBottom>
              {isLoading ? (
                <CircularProgress size={20} />
              ) : dataFetchedStatus ? (
                <Typography variant="span" style={{ fontSize: '40px', color: '#ffff',fontFamily: 'Montserrat' }}>
                  {(detectedData?.desired_oxygen_pounds / 2204.62).toFixed(2)}
                </Typography>
              ) : (
                0
              )}
            </Typography>

            <Typography variant="body1" className="totalResult" style={{ color: '#fff' }}>
              DESIRED OXYGEN (metric ton)
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default TreeStats;
