// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#MapCopyright {
    position: absolute;
    background-color: var(--panel-bg-color);
    color: var(--panel-text-color);
    box-shadow: 0 0 4px rgba(136, 136, 136, 0.5);
    -webkit-backdrop-filter: blur(3px);
            backdrop-filter: blur(3px);
    padding: 0.5em 0;
    font-size: x-small;
    display: none;
}

#MapCopyright > span {
    padding: 0 0.5em;
}

#MapCopyright span:not(:first-child) {
    border-left: 1px solid var(--panel-text-color);
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/plugins/style/MapCopyright.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,uCAAuC;IACvC,8BAA8B;IAC9B,4CAA4C;IAC5C,kCAA0B;YAA1B,0BAA0B;IAC1B,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,8CAA8C;AAClD","sourcesContent":["#MapCopyright {\n    position: absolute;\n    background-color: var(--panel-bg-color);\n    color: var(--panel-text-color);\n    box-shadow: 0 0 4px rgba(136, 136, 136, 0.5);\n    backdrop-filter: blur(3px);\n    padding: 0.5em 0;\n    font-size: x-small;\n    display: none;\n}\n\n#MapCopyright > span {\n    padding: 0 0.5em;\n}\n\n#MapCopyright span:not(:first-child) {\n    border-left: 1px solid var(--panel-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
