import { Box, useMediaQuery, Typography, Link } from '@mui/material';
import Profile from './Profile';
import { useSelector } from 'react-redux';
import MobileSection from './MobileSection';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation hook
import { useState } from 'react';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location
  const { isLoggedIn, user } = useSelector((state) => state.menu);
  const [newBlock, setNewBlock] = useState('none');

  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const header = location.pathname;
  // Check if current location is "/accountType", if true, return null to hide all tabs
  if (header === '/accountType') {
    return null;
  }
  console.log(isLoggedIn, 'isloggedin');

  
  return (
    <>
    <div className='myNav'>
      
        {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
        <Box sx={{ width: '100%', ml: 1 }} />
        {!isLoggedIn && (
          <a
            href="#home"
            className={header === '/' ? 'header-active-menu' : 'header-menu'}
            onClick={() => navigate('/')}
            // style={header === '/' ? { padding: 0 } : { padding: '0px 20px' }}
          >
            <Typography variant="h5" style={{ fontSize: '15px', padding: '10px 10px' }}>
              Home
            </Typography>
          </a>
        )}

        {header === '/' && !isLoggedIn || (header === '/home' && !isLoggedIn) ? (
          <div style={{width: "500", display: 'flex',  justifyContent: "space-between"}}>
            <a href="#about" className={header === '/about' ? 'header-active-menu' : 'header-menu'}>
              <Typography variant="h5" style={{ fontSize: '15px', padding: '10px 10px' }}>
                About
              </Typography>
            </a>
            <a href="#howitworks" className={header === '/howitworks' ? 'header-active-menu' : 'header-menu'}>
              <Typography variant="h5" style={{ fontSize: '15px', padding: '10px 10px' }}>
                How It Works
              </Typography>
            </a>
            <a href="#faq" className={header === '/faq' ? 'header-active-menu' : 'header-menu'}>
              <Typography variant="h5" style={{ fontSize: '15px', padding: '10px 10px' }}>
                FAQ
              </Typography>
            </a>
          </div>
        ) : (
          ''
        )}
      {isLoggedIn ? (
        <>
          <Link
            underline="none"
            className={header === '/user/dashboard' ? 'header-active-menu' : 'header-menu'}
            onClick={() => navigate('/user/dashboard')}
            style={{ width: '150px', marginRight:10 }}
          >
            <Typography variant="h5" style={{fontSize:'15px', padding:'10px 10px'}}>Dashboard</Typography>
          </Link>
          <Link
            underline="none"
            className={header === '/joblists' || header === '/addjobs' || header === '/payment' ? 'header-active-menu' : 'header-menu'}
            onClick={() => navigate('/joblists')}
            style={{ width: '150px', marginRight:10 }}
          >
            <Typography variant="h5" style={{fontSize:'15px', padding:'10px 10px'}}>Job List</Typography>
          </Link>
          {
            user?.plan === 'PPU' && 
            <Link
            underline="none"
            className={header === '/payment_history' ? 'header-active-menu' : 'header-menu'}
            onClick={() => navigate('/payment_history')}
            style={{ width: '150px', marginRight:10 }}
          >
            <Typography variant="h5" style={{fontSize:'15px', padding:'10px 0px'}}>Payment History</Typography>
          </Link>
          }
          
          <Link
            underline="none"
            className={header === '/contact' ? 'header-active-menu' : 'header-menu'}
            onClick={() => navigate('/contact')}
            style={{ width: '150px', marginRight:10 }}
          >
            <Typography variant="h5" style={{fontSize:'15px', padding:'10px 5px'}}>Contact</Typography>
          </Link>
        </>
      ) : (
        <>
          <Link
            underline="none"
            className={header === '/contact' ? 'header-active-menu' : 'header-menu'}
            onClick={() => navigate('/contact')}
            style={{marginRight:'0px'}}
          >
            <Typography variant="h5" style={{fontSize:'15px', padding:'10px 5px'}} >Contact</Typography>
          </Link>
          <Link underline="none" className={header === '/login' ? 'header-active-menu' : 'header-menu'} onClick={() => navigate('/login')} style={{marginRight:'0px'}}>
            <Typography variant="h5" style={{fontSize:'15px', padding:'10px 5px'}}>Login</Typography>
          </Link>
          <Link underline="none" className={header === '/register' ? 'header-active-menu' : 'header-menu'} onClick={() => navigate('/register')} style={{marginRight:'0px'}}>
            <Typography variant="h5" style={{fontSize:'15px', padding:'10px 5px'}}>Sign Up</Typography>
          </Link>
        </>
      )}

        {/* {isLoggedIn ? (
          <>
            <Link
              underline="none"
              className={header === '/user/dashboard' ? 'header-active-menu' : 'header-menu'}
              onClick={() => navigate('/user/dashboard')}
              style={{ width: '150px', marginRight: 10 }}
            >
              <Typography variant="h5" style={{ fontSize: '15px', padding: '10px 10px' }}>
                Dashboard
              </Typography>
            </Link>
            <Link
              underline="none"
              className={header === '/joblists' || header === '/addjobs' || header === '/payment' ? 'header-active-menu' : 'header-menu'}
              onClick={() => navigate('/joblists')}
              style={{ width: '150px', marginRight: 10 }}
            >
              <Typography variant="h5" style={{ fontSize: '15px', padding: '10px 10px' }}>
                Job List
              </Typography>
            </Link>
            <Link
              underline="none"
              className={header === '/contact' ? 'header-active-menu' : 'header-menu'}
              onClick={() => navigate('/contact')}
              style={{ width: '150px', marginRight: 10 }}
            >
              <Typography variant="h5" style={{ fontSize: '15px', padding: '10px 10px' }}>
                Contact
              </Typography>
            </Link>
          </>
        ) : (
          <>
            <Link
              underline="none"
              className={header === '/contact' ? 'header-active-menu' : 'header-menu'}
              onClick={() => navigate('/contact')}
              style={{ marginRight: '0px' }}
            >
              <Typography variant="h5" style={{ fontSize: '15px', padding: '10px 5px' }}>
                Contact
              </Typography>
            </Link>
            <Link
              underline="none"
              className={header === '/login' ? 'header-active-menu' : 'header-menu'}
              onClick={() => navigate('/login')}
              style={{ marginRight: '0px' }}
            >
              <Typography variant="h5" style={{ fontSize: '15px', padding: '10px 10px' }}>
                Login
              </Typography>
            </Link>
          </>
        )} */}
</div>
        {!matchesXs && isLoggedIn && <Profile />}
      {matchesXs && <MobileSection />}
    
    </>
  );
};

export default HeaderContent;
