import React, { useEffect, useState } from 'react';
import { Button, Container, Typography, Grid, Snackbar, Alert, Box } from '@mui/material';
import CarbonEmissionStats from './carbonemissionstats';
import ObjectDetectionPieChart from './objectdetectionpiechart';
import PopulationDensityStats from './populationdensitystats';
import TreesStats from './treesStats';
import IndividualStats from './individualstats';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { BaseUrl } from 'config';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SessionExpired from 'pages/session/sessionExpired';
import { CircularProgress } from '../../../node_modules/@mui/material/index';
// import NDVIChart from './ndvichart';
// import ObjectDetectionMap from './objectdetectionmap';
// import PopulationDensityChart from './polulationdensitychart';
// import CarbonEmissionCardsDashboard from './carbonemissioncardsdashboard';

const DashboardDefault = () => {
  const { TOKEN } = useSelector((state) => state.menu);
  const location = useLocation();
  const navigate = useNavigate();
  const [detectedData, setDetectedData] = useState();
  const [dataFetchedStatus, setDataFetchedStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mapLocationLoader, setMapLocationLoader] = useState(false);
  const [mapLocationList, setMapLocationList] = useState([]);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState({
    message: '',
    status: ''
  });

  const handleSessionExpiredClose = (status) => {
    setSessionExpired(status);
  };

  const handleClosePaymentAlert = () => {
    setOpenSnackBar(false);
  };

  const getObjectDetectionStats = async () => {
    try {
      setIsLoading(true);
      console.log(' location?.location', location);
      const response = await axios.post(
        `${BaseUrl}/final_results`,
        {
          job_id: location?.state?.job_id
          // number_of_house: 300 * location?.state,
          // number_of_vehicles: 200 * location?.state,
          // number_of_industry: 1 * location?.state,
          // number_of_vegeatation: 100 * location?.state,
          // number_of_popoulation: 2345 * location?.state
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`
          }
        }
      );
      if (response?.data && response?.status === 200) {
        setIsLoading(false);
        setDataFetchedStatus(true);
        setDetectedData(response?.data);
        console.log('calculate emission response', response?.data);
      } else {
        setIsLoading(false);
        setOpenSnackBar(true);
        setSnackBarMessage({
          message: 'Something went wrong. Try again later!',
          status: 'error'
        });
      }
    } catch (error) {
      console.log('errror of calculate emission', error);
      setIsLoading(false);
      setOpenSnackBar(true);
      console.log('job request error', error);
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired please login again to continue',
          status: 'error'
        });
      } else {
        setSnackBarMessage({
          message: 'Something went wrong. Try again later!',
          status: 'error'
        });
      }
    }
  };

  const getMultipleMapLocation = async () => {
    try {
      setMapLocationLoader(true);
      const response = await axios.post(
        `${BaseUrl}/map_url_for_qgisserver_new`,
        {
          job_id: location?.state?.job_id
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`
          }
        }
      );
      console.log('map location resonse', response);
      if (response?.status === 200) {
        setMapLocationLoader(false);
        setMapLocationList(response?.data?.location_id);
      } else {
        setMapLocationLoader(false);
        setOpenSnackBar(true);
        setSnackBarMessage({
          message: 'Something went wrong. Try again later!',
          status: 'error'
        });
      }
    } catch (error) {
      console.log('errror of calculate emission', error);
      setMapLocationLoader(false);
      setOpenSnackBar(true);
      console.log('job request error', error);
      if (error?.response?.data?.detail === 'Unauthorized' && error?.response?.status == 401) {
        setSessionExpired(true);
        setSnackBarMessage({
          message: 'Your session expired please login again to continue',
          status: 'error'
        });
      } else {
        setSnackBarMessage({
          message: 'Something went wrong. Try again later!',
          status: 'error'
        });
      }
    }
  };

  const onViewMapClick = (location_id) => {
    localStorage.setItem('job_location_id', location_id);
    navigate('/map?l=TestX%2CGemeindegrenze&bl=mapnik&t=bauprojekte&c=970580%2C5999699&s=40000');
  };

  useEffect(() => {
    getObjectDetectionStats();
    getMultipleMapLocation();
  }, []);

  return (
    <Container sx={{ mt: 5 }} maxWidth="lg" className="dashboard">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" className="heading" gutterBottom>
            Overall CO<sub>2</sub> Emission Dashboard
          </Typography>

          <CarbonEmissionStats isLoading={isLoading} dataFetchedStatus={dataFetchedStatus} detectedData={detectedData} />
        </Grid>

        <Grid item sm={12} md={6} lg={6} xs={12}>
          <ObjectDetectionPieChart
            isLoading={isLoading}
            dataFetchedStatus={dataFetchedStatus}
            detectedData={detectedData?.calculated_emissions}
          />
        </Grid>
        <Grid item sm={12} md={6} lg={6} xs={12}>
          <PopulationDensityStats isLoading={isLoading} dataFetchedStatus={dataFetchedStatus} detectedData={detectedData?.Census} isToken={TOKEN} />
        </Grid>

        {/* <Grid item sm={12} md={12}>
          <Box className="boundryBox">
            <NDVIChart />  
            <ObjectDetectionMap isLoading={isLoading} dataFetchedStatus={dataFetchedStatus} /> 
          </Box>
        </Grid> */}
        <Grid item xs={12}>
          <TreesStats isLoading={isLoading} dataFetchedStatus={dataFetchedStatus} detectedData={detectedData?.objectdetection} />
        </Grid>
        <Grid item sm={12} md={12} xs={12} lg={12}>
          <Box className="boundryBox">
            {/* <Button variant="contained" color="primary" fullWidth onClick={()=>navigate("/map")}> */}
            {mapLocationLoader ? (
              <CircularProgress />
            ) : mapLocationList?.length > 0 ? (
              mapLocationList.map((val, index) => {
                return (
                  <Button variant="contained" color="primary" fullWidth sx={{ mt: 2 }} onClick={() => onViewMapClick(val)}>
                    {`View Map ${index + 1}`}
                  </Button>
                );
              })
            ) : (
              'No Map Found'
            )}
          </Box>
        </Grid>

        <IndividualStats isLoading={isLoading} dataFetchedStatus={dataFetchedStatus} detectedData={detectedData} />

        {/* <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h4" className="heading" gutterBottom>
            Carbon Emission
          </Typography>
          <CarbonEmissionCardsDashboard />
        </Grid> */}

        {/* <Grid item xs={12} md={12} lg={12}>
          <ObjectDetectionPieChart />
        </Grid> */}

        {/* <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h4" className="heading" gutterBottom>
            Population Density
          </Typography>
          <PopulationDensityChart />
        </Grid> */}
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} style={{marginTop:50}}
        open={openSnackBar}
        autoHideDuration={10000}
        onClose={handleClosePaymentAlert}
      >
        <Alert onClose={handleClosePaymentAlert} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.message}
        </Alert>
      </Snackbar>

      {sessionExpired ? <SessionExpired handleSessionExpiredClose={handleSessionExpiredClose} /> : ''}
    </Container>
  );
};

export default DashboardDefault;
