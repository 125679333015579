import React, { useEffect, useState } from 'react';
import UploadFiles from './uploadFile';
import DrawLocation from './drawLocation';
import SelectMapLocation from './selectmaplocation';
const SelectLocation = ({ locationType, getLocationList }) => {
  const [locationtype, setLocationtype] = useState(locationType);
  useEffect(() => {
    setLocationtype(locationType);
  }, [locationType]);
  console.log('displayUploadLocationFile', locationtype, locationType);

  return (
    <>
      {locationtype.upload ? (
        <UploadFiles getLocationList={getLocationList} locationData={locationtype.location} />
      ) : locationtype.draw ? (
        <DrawLocation getLocationList={getLocationList} locationData={locationtype.location} />
      ) : locationtype.search ? (
        <SelectMapLocation getLocationList={getLocationList} locationData={locationtype.location} />
      ) : (
        ''
      )}
    </>
  );
};

export default SelectLocation;
