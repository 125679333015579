// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.feature-query-body {
    display: flex;
    flex-direction: column;
    height: 100%;
}

div.feature-query-body div.feature-query-selection {
    flex: 0 0 auto;
    padding-bottom: 0.25em;
    border-bottom: 1px solid var(--border-color);
    margin-bottom: 0.25em;
}

div.feature-query-body div.feature-query-selection > select {
    width: 100%;
}

div.feature-query-body div.AttributeForm {
    flex: 1 1 auto;
    overflow: auto;
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/plugins/style/FeatureForm.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,sBAAsB;IACtB,4CAA4C;IAC5C,qBAAqB;AACzB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,cAAc;IACd,cAAc;AAClB","sourcesContent":["div.feature-query-body {\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n}\n\ndiv.feature-query-body div.feature-query-selection {\n    flex: 0 0 auto;\n    padding-bottom: 0.25em;\n    border-bottom: 1px solid var(--border-color);\n    margin-bottom: 0.25em;\n}\n\ndiv.feature-query-body div.feature-query-selection > select {\n    width: 100%;\n}\n\ndiv.feature-query-body div.AttributeForm {\n    flex: 1 1 auto;\n    overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
