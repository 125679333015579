import React, { useState, useEffect } from 'react';
import { Button, Grid, Card, CardContent, Typography, TextField } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import * as turf from '@turf/turf';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
let acceptFile = null;

const UploadFiles = ({ getLocationList, locationData }) => {
  const [locationName, setLocationName] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  // const [acceptFile, setAcceptFile] = useState(null);
  const [locationError, setLocationError] = useState('');
  const [selectedFileName, setSelectedFileName] = useState(locationData?.filename);
  const [fileError, setFileError] = useState('');
  const [totalArea, setTotalArea] = useState(null);
  const [coordinates, setCoordinates] = useState(null);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  // const [openSnackBar, setOpenSnackBar] = useState(false);
  // const [snackBarMessage, setSnackBarMessage] = useState('');

  // console.log('location ata', locationData);

  // const handleClose = (event, reason) => {
  //   if (reason === 'clickaway') {
  //     return;
  //   }
  //   setOpenSnackBar(false);
  // };

  const getCoordinatesFromGeoJSON = (geojson) => {
    const coordinates = [];

    console.log('sdklfjs', geojson);
    if (geojson && geojson.features) {
      geojson.features.forEach((feature) => {
        const geometry = feature.geometry;
        if (geometry && geometry.coordinates) {
          coordinates.push({ type: geometry.type, coordinates: geometry.coordinates });
        }
      });
    }

    return coordinates;
  };

  const calculateArea = (file) => {
    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const geojson = JSON.parse(event.target.result);
        const areaInSquareMeters = turf.area(geojson); // Turf.js function to calculate area
        const areaInSquareMiles = areaInSquareMeters * 3.861e-7;
        if (areaInSquareMiles < 1) {
          // Show error using snackbar
          setOpenSnackBar(true);
          setSnackBarMessage({ message: 'Area must be at least 1 square mile. Please try with another file.', status: 'error' });
          setFileError('Area must be at least 1 square mile. Please try with another file.');
          setSelectedFile(null); // Clear selected file
          return;
        }
        // Extract coordinates from GeoJSON
        const geojsonCoordinates = getCoordinatesFromGeoJSON(geojson);
        setCoordinates(geojsonCoordinates);
        setTotalArea(areaInSquareMiles?.toFixed(2));
        setSelectedFileName(file?.name);
      } catch (error) {
        console.error('Error parsing GeoJSON:', error);
        setFileError('Please select geojson file');
        setOpenSnackBar(true);
        setSnackBarMessage({ message: 'Please select geojson file.', status: 'error' });
      }
    };

    reader.readAsText(file);
  };

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    setSelectedFile(null);
    setSelectedFile(file.path);
    // setAcceptFile(file)
    acceptFile = file;
    // console.log('acceptedFiles', acceptedFiles);
    setFileError('');
    // Calculate area when a new file is dropped
    calculateArea(file);
  };

  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
    accept: ['.geojson'],
    onDrop,
    noClick: true,
    useFsAccessApi: false
  });

  console.log('acdddceptedFiles', acceptedFiles);

  const handleLocationChange = (event) => {
    setLocationName(event.target.value);
    setLocationError('');
  };

  const createGeoJson = () => {
    // Prepare GeoJSON FeatureCollection
    // console.log('location name for geojson', locationName);
    // const actualShape = shapes;
    console.log('coordinates', coordinates);
    const geoJsonFeatures = coordinates.map((shape, index) => ({
      type: 'Feature',
      id: index,
      properties: {
        LocationName: locationName
      },
      geometry: {
        type: shape.type,
        coordinates: shape.coordinates
      }
    }));

    const geoJsonData = {
      type: 'FeatureCollection',
      features: geoJsonFeatures
    };

    return geoJsonData;
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Basic validation
    if (!locationName.trim()) {
      setLocationError('Location Name is required');
      return;
    }

    if (fileError?.length > 0) {
      setOpenSnackBar(true);
      setSnackBarMessage({ message: 'Please select geojson file', status: 'error' });
      setFileError('Please select geojson file');
      return;
    }

    if (!selectedFile) {
      setOpenSnackBar(true);
      setSnackBarMessage({ message: 'Please choose a file', status: 'error' });
      setFileError('Please choose a file');
      return;
    }

    const capitalizeEachWord = (text) => {
      return text.replace(/\b\w/g, (match) => match.toUpperCase());
    };

    const getDate = () => {
      const options = { month: 'short', day: 'numeric', year: 'numeric' };
      return new Date().toLocaleDateString('en-US', options);
    };

    const calculated_cost = 86 * totalArea;
    const createLocation = await createGeoJson();
    console.log('ksdlfjsdk', createLocation);
    const location_list = {
      ...locationData,
      filename: selectedFile,
      locationName: capitalizeEachWord(locationName),
      calculated_cost: calculated_cost?.toFixed(2),
      // location_area_sq_mile: totalArea?.toFixed(2),
      location_area_sq_mile: totalArea,
      requested_timestamp: getDate(),
      locations: createLocation,
      type: 'upload'
    };

    // if (locationData) {
    //   location_list.id = locationData.id;
    //   setSnackBarMessage('Location updated Successfully!');
    // } else {
    //   setSnackBarMessage('Location Added Successfully!');
    // }

    getLocationList(location_list);
    setSelectedFile(null);
    // setOpenSnackBar(true);
    setLocationName('');
    setCoordinates();
    acceptFile = null;
  };

  const handleCardClick = () => {
    const input = document.getElementById('fileInput');
    if (input) {
      input.click();
    }
  };

  const handleDragEnter = (event) => {
    event.preventDefault();
    document.body.style.backgroundColor = 'lightgray'; // Optional: Change background color on drag enter
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    document.body.style.backgroundColor = ''; // Optional: Reset background color on drag leave
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
    setSnackBarMessage({ message: '', status: '' });
  };

  useEffect(() => {
    if (locationData && locationData.locations && locationData.locations.features) {
      setLocationName(locationData?.locationName);
    }
  }, [locationData]);

  return (
    <form onSubmit={handleFormSubmit}>
      <Grid container spacing={2}>
        <Grid item md={12}>
          <Typography variant="h1" className="heading">
            Upload File
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Card
            {...getRootProps()}
            className={`uploadCard ${isDragActive ? 'dragActive' : ''}`}
            onClick={handleCardClick}
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
          >
            <CardContent>
              <Grid
                //className="uploadFile"
                sx={{
                  textAlign: 'center',
                  marginTop: '30px',
                  '@media (max-width: 512px)': {
                    flexDirection: 'column',
                    textAlign: 'center'
                  }
                }}
              >
                <CloudUploadIcon />

                {/* <Typography variant="h4" component="div" sx={{ color: '#3BADC1' }}>
                  {isDragActive ? 'Drop the files here' : 'Drag or upload .geojson file here'}
                </Typography> */}
                <Typography
                  variant="h4"
                  component="div"
                  sx={{
                    color: '#3BADC1',
                    '@media (max-width: 512px)': {
                      whiteSpace: 'pre-wrap'
                    }
                  }}
                >
                  {isDragActive ? (
                    'Drop the files here'
                  ) : (
                    <>
                      Drag or upload <br className="mobile-break" />
                      .geojson file here
                    </>
                  )}
                </Typography>
              </Grid>
              {/* {console.log('kdskjfs', acceptFile?.name, selectedFile?.length > 0 ? selectedFile : '')} */}
              {!locationData ? (
                acceptFile?.path && selectedFile?.length > 0 ? (
                  <Typography variant="body1" color="textSecondary">
                    Selected File: {acceptFile.name}
                  </Typography>
                ) : (
                  ''
                )
              ) : (
                <Typography variant="body1" color="textSecondary">
                  Selected File: {selectedFileName}
                </Typography>
              )}
              <Typography variant="h5" color="error">
                {fileError}
              </Typography>
            </CardContent>
            <input {...getInputProps()} style={{ display: 'none' }} id="fileInput" />
          </Card>
        </Grid>

        <Grid item md={10} xs={8}>
          <TextField
            fullWidth
            label="Location Name"
            className="locationName"
            variant="outlined"
            value={locationName}
            onChange={handleLocationChange}
            margin="normal"
            error={Boolean(locationError)}
            helperText={locationError}
            inputProps={{ style: { backgroundColor: 'white', padding: '3px 10px' } }}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          {locationData?.mode == 'edit' ? (
            <Button type="submit" className="searchbtn" sx={{ mt: 2, mb: 5 }} fullWidth variant="contained" color="primary">
              Update
            </Button>
          ) : (
            <Button type="submit" className="searchbtn" sx={{ mt: 2, mb: 5 }} fullWidth variant="contained" color="primary">
              Upload
            </Button>
          )}
        </Grid>
      </Grid>
      {/* 
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={openSnackBar} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage}
        </Alert>
      </Snackbar> */}
      <Snackbar
        open={openSnackBar}
        autoHideDuration={8000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        style={{marginTop:50}}
      >
        <MuiAlert onClose={handleCloseSnackbar} severity={snackBarMessage.status} variant="filled" sx={{ width: '100%' }}>
          {snackBarMessage.locationNameError || snackBarMessage.message}
        </MuiAlert>
      </Snackbar>
    </form>
  );
};

export default UploadFiles;
