// HomePage.js
import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { Container, Grid } from '@mui/material';
import About from './about';
// import Services from './services';
// import ContactUs from './contactus';
// import BreatheChart from './breatheChart';
import img1 from '../../image/home1.png';
import img2 from '../../image/home2.png';
import img3 from '../../image/home1.png';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import FAQ from './faq';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import horizontalLine from '../../image/Group 55.png';
import { Button } from '@mui/material';
import Content from './content';
import CaseStudy from 'pages/casestudy-dashboard/casestudy';
const Home = () => {
  const currentUrl = window.location.pathname;

  // console.log(' const currentUrl = window.location.href;', currentUrl);

  const slidesData = [
    {
      image: img1,
      title: (
        <div>
          Calculate Your
          <br />
          <span> Carbon Footprint</span>
        </div>
      ),
      text: 'Get an estimate of your carbon footprints and your contribution to your city.'
    },
    {
      image: img2,
      title: (
        <div>
          Tracking
          <br />
          <span> Local Emissions</span>
        </div>
      ),
      text: 'It is crucial to understand the emissions and their impact on overall global warming. '
    },
    {
      image: img3,
      title: (
        <div>
          Promoting
          <br />
          <span> Carbon Sequestration</span>
        </div>
      ),
      text: 'Get an estimated value for your sequestration efforts and plan the future steps. '
    }
  ];
  return (
    <div className="frame-by-frame-container">
      {/* <div className="swiper-fixed-text">
        <Grid item xs={12}>
          <h1>
            Calculate
            <span>
              {' '}
              Your <br /> Carbon Emission
            </span>
          </h1>
          <h3>Get an estimate of your carbon footprints and your contribution to your city.</h3>
        </Grid>
      </div> */}
      <Swiper
        id="home"
        pagination={{
          dynamicBullets: true
        }}
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 5000
          // disableOnInteraction: false
        }}
        loop={true}
        // style={{ height: '100vh' }}
        className="mySwiper"
        style={{ height: window.innerHeight }}
      >
        {slidesData.map((slide, index) => (
          <SwiperSlide key={index}>
            <img src={slide.image} alt={`Slide ${index + 1}`} className="swiper-image" />
            {/* <div className="swiper-slide-text">{slide.text}</div> */}
            <div className="swiper-fixed-text">
              <Grid item xs={12}>
                <h1>{slide.title}</h1>
                <h3>{slide.text}</h3>
              </Grid>

              <Grid item xs={12}>
                <a href="#about">
                  <Button variant="outlined" className="aboutus">
                    About Us
                  </Button>
                </a>
              </Grid>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Container fixed style={{ paddingBottom: 25 }}>
        <About />
      </Container>
      {/* <img src={horizontalLine} alt="" style={{ width: '100%', height: '250px', position: "absolute" }} /> */}

      <div id="howitworks" style={{ paddingTop: 50 }}>
        <Step1 />
        <Step2 />
        <Step3 />
      </div>
      <Container>
        {/* <Grid item xs={12} >
          <Grid item > */}
        <center>
          <h1 className="heading" style={{ margin: '10px 0px' }}>
            Case study of Friendswood city
          </h1>
        </center>
        {/* </Grid> */}
        <CaseStudy />
      </Container>
      <br />
      {/* </Grid> */}
      <Content />
      <FAQ />
      {/* <Services /> */}
      {/* <BreatheChart /> */}
      {/* <ContactUs /> */}

      <Grid item xs={12} sx={{ m: 3, mt: 1 }}></Grid>
    </div>
  );
};

export default Home;
