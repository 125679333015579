// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.messagebar {
    position: absolute;
    top: 3.5em;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    max-width: calc(100% - 0.5em);
    padding: 0 1.25em 0 0.25em;
    background-color: var(--container-bg-color);
    box-shadow: 0px 2px 4px rgba(136, 136, 136, 0.5);
    border-top: 1px solid rgba(136, 136, 136, 0.5);
    z-index: 3;
}

div.messagebar > div.body {
    padding: 0.25em 0.5em 0.25em 0.25em;
    display: inline-block;
}

div.messagebar > span.closewrapper {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    padding: 0.125em 0.125em 0 0;
}
`, "",{"version":3,"sources":["webpack://./src/qwc2/components/style/MessageBar.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,2BAA2B;IAC3B,qBAAqB;IACrB,6BAA6B;IAC7B,0BAA0B;IAC1B,2CAA2C;IAC3C,gDAAgD;IAChD,8CAA8C;IAC9C,UAAU;AACd;;AAEA;IACI,mCAAmC;IACnC,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,MAAM;IACN,SAAS;IACT,4BAA4B;AAChC","sourcesContent":["div.messagebar {\n    position: absolute;\n    top: 3.5em;\n    left: 50%;\n    transform: translateX(-50%);\n    display: inline-block;\n    max-width: calc(100% - 0.5em);\n    padding: 0 1.25em 0 0.25em;\n    background-color: var(--container-bg-color);\n    box-shadow: 0px 2px 4px rgba(136, 136, 136, 0.5);\n    border-top: 1px solid rgba(136, 136, 136, 0.5);\n    z-index: 3;\n}\n\ndiv.messagebar > div.body {\n    padding: 0.25em 0.5em 0.25em 0.25em;\n    display: inline-block;\n}\n\ndiv.messagebar > span.closewrapper {\n    position: absolute;\n    right: 0;\n    top: 0;\n    bottom: 0;\n    padding: 0.125em 0.125em 0 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
