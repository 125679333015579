// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
  openItem: ['dashboard'],
  defaultId: 'dashboard',
  openComponent: 'buttons',
  drawerOpen: false,
  isAccountTypeChangeToEnt: false,
  isEditProfileClicked: false,
  componentDrawerOpen: true,
  isLoggedIn: false,
  TOKEN: null,
  user: null,
  companyInfo: null,
  companyVerifyToken:null,
  locationInfo: [],
  notificationInfo: [],
  paymentHistoryInfo: [],
  map_key: 1,
  readNotificationIds: [],
  companyVerifyVisited: false
};

// console.log('lksdjdfkds', window.innerWidth);

// ==============================|| SLICE - MENU ||============================== //

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    login: (state, action) => {
      (state.isLoggedIn = true), (state.TOKEN = action.payload);
    },

    logout: (state, action) => {
      (state.isLoggedIn = false), (state.TOKEN = action.payload);
    },

    userDetails: (state, action) => {
      console.log( action.payload)
      state.user = action.payload;
    },
    companyDetails: (state, action) => {
      state.companyInfo = action.payload;
    },

    locationDetails: (state, action) => {
      state.locationInfo = action.payload;
    },

    notificationDetails: (state, action) => {
      console.log('action.payload', action.payload);
      state.notificationInfo = action.payload;
    },
    // markNotificationAsRead: (state, action) => {
    //   const notificationId = action.payload;
    //   state.notificationInfo = state.notificationInfo.map((notification) =>
    //     notification.job_id == notificationId ? { ...notification, read: true } : notification
    //   );
    // },
    markNotificationAsRead: (state, action) => {
      const notificationIds = action.payload;
      state.notificationInfo = state.notificationInfo.map((notification) =>
        notificationIds.includes(notification.job_id) ? { ...notification, read: true } : notification
      );
    },

    markNotificationsAsRead: (state, action) => {
      const notificationIds = action.payload;
      state.readNotificationIds = Array.isArray(state.readNotificationIds)
        ? [...state.readNotificationIds, ...notificationIds]
        : [...notificationIds];
    },
    paymentHistoryDetails: (state, action) => {
      state.paymentHistoryInfo = action.payload;
    },
    mapKeyDetails: (state, action) => {
      state.map_key = action.payload;
    },

    activeItem(state, action) {
      state.openItem = action.payload.openItem;
    },

    activeComponent(state, action) {
      state.openComponent = action.payload.openComponent;
    },

    openDrawer(state, action) {
      state.drawerOpen = action.payload.drawerOpen;
    },
    AccountTypeChangeToEnt(state, action) {
      state.isAccountTypeChangeToEnt = action.payload.isAccountTypeChangeToEnt;
    },
    EditProfileClicked(state, action) {
      state.isEditProfileClicked = action.payload.isEditProfileClicked;
    },
    openComponentDrawer(state, action) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    },
    updateUserPlan(state, action) {
      if (state.user) {
        state.user.plan = action.payload;
      }
    },
    setCompanyVerifyToken(state, action) {
      state.companyVerifyToken = action.payload;
    },
    setCompanyVerifyVisited(state, action) {
      state.companyVerifyVisited = action.payload;
    },
  }
});

export default menu.reducer;

export const {
  activeItem,
  activeComponent,
  openDrawer,
  AccountTypeChangeToEnt,
  EditProfileClicked,
  openComponentDrawer,
  login,
  logout,
  userDetails,
  userUpdatedDetails,
  companyDetails,
  mapKeyDetails,
  locationDetails,
  notificationDetails,
  paymentHistoryDetails,
  markNotificationAsRead,
  markNotificationsAsRead,
  setCompanyVerifyToken,
  setCompanyVerifyVisited,
  updateUserPlan
} = menu.actions;
